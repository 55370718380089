import {  hrOrgType } from './../types';
import axios from "axios";


//const apiUrl: string = "https://b0svdbuld4.execute-api.eu-west-1.amazonaws.com/my-iris-alerts";
const apiUrl:string = process.env.REACT_APP_API_URL_ROOT + "/hrorg"

const fetchHrOrg = async (user_signature: string, auth:string,): Promise<hrOrgType[]> => {

   try {
    const response = await axios.get(apiUrl, {
        params: {
          user_signature: user_signature,
          
        } ,
        headers: {'Authorization': auth},
      });

     if (response === undefined) throw new Error("Response is undefined");
      
     const result:hrOrgType[] = response.data;
     return result;
   } catch (error: any) {
     if (axios.isAxiosError(error)) {
       if (error.response?.status === 500){
       console.log(error)
        throw new Error("Internal server error")}
       if (error.response?.status === 404){
        console.log(error)
         throw new Error("The resource was not found");}
    }
    console.log(error)
     throw error;
  }

};

export default fetchHrOrg;
