
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SortDepType } from "../../../types";

import { initSortDep } from "./initSortDep";




// Slice con action e reducers.
const sortingDepSlice = createSlice({
  name: "sort", // Nome della slice
  initialState: initSortDep, // Valore iniziale vuoto
  // Reducer con le azioni che si possono fare sulla slice (in questo caso solo una)
  reducers: {
    updateSortDep(state, action: PayloadAction<SortDepType>) {      
      return  action.payload;
    }
  },
});

// Action generate che verrano utilizzate per aggiornare lo store.
export const { updateSortDep } = sortingDepSlice.actions;

export default sortingDepSlice.reducer;
