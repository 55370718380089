import { useAuthenticator } from "@aws-amplify/ui-react";
import { Alert, AlertTitle, Box, Button, IconButton, Modal, SvgIcon, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import TableHR from "../../components/configure/TableHR";
import Heading from "../../components/Heading";
import Loading from "../../components/Loading";
import ButtonWizard from "../../components/wizard/ButtonWizard";
import HashingForm from "../../components/wizard/hrdbSteps/HashingForm";
import StepEmptyValues from "../../components/wizard/hrdbSteps/StepEmptyValues";
import StepKCheck from "../../components/wizard/hrdbSteps/StepKCheck";
import StepOrg from "../../components/wizard/hrdbSteps/StepOrg";
import StepSuccess from "../../components/wizard/hrdbSteps/StepSuccess";
import StepperWizard from "../../components/wizard/StepperWizard";
import useHrDb from "../../hooks/useHrDb";
import { useHrdbAnon } from "../../hooks/useHrdbAnon";
import hrlogos from "../../images/hrModal.png";
import { hrPage } from "../../styles/styles";
import DragDrop from "./dragAndDrop/DragDrop";
import DragNDropContainer from "./dragAndDrop/DragNDropContainer";

const PageHRDBConf = () => {
  const nav = useNavigate();
  const [openConnector, setOpenConnector] = useState(false);
  const handleOpenConnector = () => setOpenConnector(true);
  const handleCloseConnector = () => setOpenConnector(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    if(activeStep >= 2) nav(0);
  };
  const handleCloseError = () => {
    setOpen(false);
  };
  const { user } = useAuthenticator((context) => [context.user]);
  const { hrFile, loaded } = useHrDb(
    user.getSignInUserSession()?.getIdToken().payload.user_signature,
    user.getSignInUserSession()?.getIdToken().getJwtToken() || ""
  );

  const {
    steps,
    activeStep,
    buttonsConfig,
    columns,
    changeColumns,
    storeHead,
    storePeople,
    storeFile,
    hashClientFile,
    file,
    stepEmpty,
    stepCheck,
    stepOrg,
    sendClientFile,
    onError,
    k,
  } = useHrdbAnon(
    user.getSignInUserSession()?.getIdToken().payload.user_signature,
    user.getSignInUserSession()?.getIdToken().getJwtToken() || "",
    handleOpen
  );

  const { t } = useTranslation();

  const onDownload = () => {
    const link = document.createElement("a");
    link.download = `HR_template.csv`;
    link.href = process.env.PUBLIC_URL + "/demo_files/HR Template.csv";
    link.click();
  };

  return (
    <Box className="container">
      <Heading title="hrdb.title" />
      <Typography className="body1">{t("hrdb.description")}</Typography>

      <Box className="flexTop" sx={{ marginTop: "3rem" }}>
        <Box sx={{ maxWidth: "17%" }}>
          <Typography className="h4">{t("hrdb.upload.title")}</Typography>

          <Typography className="h6-bold" sx={{ marginBottom: "10px" }}>
            {t("hrdb.tips")}
          </Typography>

          <Typography className="body2" sx={{ marginBottom: "10px" }}>
            &#9679; {t("hrdb.tip4")}
          </Typography>
          <Typography className="body2" sx={{ marginBottom: "10px" }}>
            &#9679; {t("hrdb.tip5")}
          </Typography>
          <Typography className="body2" sx={{ marginBottom: "10px" }}>
            &#9679; {t("hrdb.tip6")}
          </Typography>
          <Typography className="body2" sx={{ marginBottom: "10px" }}>
            &#9679; {t("hrdb.tip1")}
          </Typography>
          <Typography className="body2" sx={{ marginBottom: "20px" }}>
            &#9679; {t("hrdb.tip3")}
          </Typography>

          <Typography className="body2 " sx={{ marginBottom: "30px" }}>
            {t("hrdb.download.text")}
            <Button
              variant="text"
              onClick={onDownload}
              sx={{
                fontSize: "0.5rem",
                padding:"0 0 0 0",
                borderRadius: "20px",
                textTransform: "none",
                textDecoration: "underline",
                color: "text.primary",
                fontWeight: "bold",
                "&:hover": {
                  color: "#00bfa5",
                },
              }}
            >
              <Typography>{t("hrdb.download.button")}
                </Typography>
            </Button>
          </Typography>

          <Typography className="h6-bold" sx={{ marginBottom: "30px" }}>
            {t("hrdb.anonymus")}
          </Typography>

          <DragDrop
            storeHead={storeHead}
            storePeople={storePeople}
            storeFile={storeFile}
          />
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            key={"modal stepper"}
            disableEscapeKeyDown={true}

          >
            <Box sx={styleDrag}>
              <Box>
                <Box
                  className="flex"
                  sx={{ width: "100%", alignItems: "baseline" }}
                >
                  <StepperWizard steps={steps} activeStep={activeStep} />
                  <IconButton onClick={handleClose}>
                    <SvgIcon>
                      <path d="M6.4 19.8 4.2 17.6 9.8 12 4.2 6.4 6.4 4.2 12 9.8 17.6 4.2 19.8 6.4 14.2 12 19.8 17.6 17.6 19.8 12 14.2Z" />
                    </SvgIcon>
                  </IconButton>
                </Box>

                <Typography
                  className="h4"
                  fontWeight="bolder"
                  sx={{ marginTop: "1rem" }}
                >
                  {t("hrdb.modal.title")}
                </Typography>

                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-evenly",
                  }}
                >
                  {onError.status ? (
                    <Alert severity="error">
                      <AlertTitle>Error</AlertTitle>
                      {t("generic.error.message")}
                    </Alert>
                  ) : (
                    (() => {
                      switch (activeStep) {
                        case 0:
                          return (
                            <DragNDropContainer
                              columns={columns}
                              setColumns={changeColumns}
                            />
                          );

                        case 1:
                          return (
                            <HashingForm
                              hashFile={hashClientFile}
                              columnMapping={columns}
                              clientFile={file}
                            />
                          );

                        case 2:
                          return (
                            <StepEmptyValues
                              viewTable={stepEmpty.rows.length !== 0}
                              rows={stepEmpty.rows}
                              columns={stepEmpty.columns}
                              status={stepEmpty.result}
                              handleClose={handleCloseError}
                            />
                          );

                        case 3:
                          return (
                            <StepOrg
                              rows={stepOrg.rows}
                              columns={stepOrg.columns}
                              status={stepOrg.result}
                              sendFile={sendClientFile}
                              handleClose={handleCloseError}
                            />
                          );

                        case 4:
                          return (
                            <StepKCheck
                              rows={stepCheck.rows}
                              columns={stepCheck.columns}
                              status={stepCheck.result}
                              handleClose={handleCloseError}
                              k={k}
                            />
                          );

                        case 5:
                          return <StepSuccess 
                          handleClose={handleClose}
                          />;

                        default:
                          return <></>;
                      }
                    })()
                  )}
                </Box>
              </Box>
              {onError.status ? (
                <></>
              ) : (
                <ButtonWizard activeStep={activeStep} info={buttonsConfig} />
              )}
            </Box>
          </Modal>
          <Typography
            className="body2"
            sx={{ marginBottom: "1rem", marginTop: "1rem" }}
          >
            {t("hrdb.or")}
          </Typography>
          <Button sx={hrPage.button.connect} onClick={handleOpenConnector}>
            {t("hrdb.connect")}
          </Button>
          <Modal
            open={openConnector}
            onClose={handleCloseConnector}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            key={"modal connectors"}
          >
            <Box sx={style}>
              <Box
                className="flex"
                sx={{ width: "100%", flexDirection: "row-reverse" }}
              >
                <IconButton onClick={handleCloseConnector}>
                  <SvgIcon>
                    <path d="M6.4 19.8 4.2 17.6 9.8 12 4.2 6.4 6.4 4.2 12 9.8 17.6 4.2 19.8 6.4 14.2 12 19.8 17.6 17.6 19.8 12 14.2Z" />
                  </SvgIcon>
                </IconButton>
              </Box>

              <Typography className="h4" fontWeight="bolder">
                {t("hrdb.connect.modal.title")}
              </Typography>
              <Typography className="body1">
                {t("hrdb.connect.modal.subtitle")}
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={hrlogos} alt="hrlogos" width="770px" />
              </Box>
            </Box>
          </Modal>
        </Box>
        <Box
          sx={{
            minWidth: "2rem",
            minHeight: "2rem",
            width: "75%",
          }}
        >
          {loaded ? (
            <>
              {hrFile?.rows ? (
                <TableHR rows={hrFile.rows} columns={hrFile.columns} />
              ) : (
                <Alert
                  severity="warning"
                  sx={{
                    backgroundColor: "rgb(229, 246, 253)",
                    color: "rgb(1, 67, 97)",
                  }}
                  icon={
                    <SvgIcon>
                      <path
                        d="M 11 9 H 13 V 7 H 11 M 12 20 C 7.59 20 4 16.41 4 12 C 4 7.59 7.59 4 12 4 C 16.41 4 20 7.59 20 12 C 20 16.41 16.41 20 12 20 M 12 2 A 10 10 0 0 0 2 12 A 10 10 0 0 0 12 22 A 10 10 0 0 0 22 12 A 10 10 0 0 0 12 2 M 11 17 H 13 V 11 H 11 V 17 Z"
                        fill="rgb(3, 169, 244)"
                      />
                    </SvgIcon>
                  }
                >
                  <AlertTitle>Attenzione!</AlertTitle>
                  Non hai ancora caricato un file per la mappatura HR
                </Alert>
              )}
            </>
          ) : (
            <Loading />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PageHRDBConf;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  borderRadius: "25px",
  bgcolor: "ts.sidebar.text.active",
  paddingX: 4,
  paddingY: 3,
};

const styleDrag = {
  boxShadow: 24,
  borderRadius: "25px",
  bgcolor: "ts.sidebar.text.active",
  paddingX: 4,
  paddingY: 3,
  height: "100%",
  length: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  overflow: "scroll",
};
