
import axios from "axios";
import {  MetricTypeF, SingleMetricType } from "../types";



//const apiUrl: string = "https://wi4rtbm7mk.execute-api.eu-west-1.amazonaws.com/my-iris-cards";
const apiUrl:string = process.env.REACT_APP_API_URL_ROOT + "/cards"

// TODO: implementare vera chiamata una volta ottenuto endpoint
const fetchMetrics =async (user_signature: string, auth:string, scope:string,  levInfo:string, id:string): Promise<MetricTypeF[] | SingleMetricType[]> => {
  try {
    const response = await axios.get(apiUrl, {
        params:  {
          user_signature: user_signature,
          level_info:levInfo,
          complete_key:  id,
          scope: scope
        } ,
        headers: {'Authorization': auth},
      });

     if (response === undefined) throw new Error("Response is undefined");
      
     const result:MetricTypeF[] = response.data;
     
     return result;
   } catch (error: any) {
     if (axios.isAxiosError(error)) {
       if (error.response?.status === 500){
       console.log(error)
        throw new Error("Internal server error")}
       if (error.response?.status === 404){
        console.log(error)
         throw new Error("The resource was not found");}
    }
    console.log(error)
     throw error;
  }
 
};

export default fetchMetrics;
