import i18n from "i18next";

const addDynamicTimeUnit = (time: any, prefix: any = '') => {
  if (time < 60) return prefix + time.toString() + '"'; //under 60 seconds
  time = time / 60;
  if (time < 60) return prefix + Math.floor(time).toString() + "'"; //under 60 minutes
  const minutes = time % 60;
  time = time / 60;
  if (time < 24) return prefix + Math.floor(time).toString() + "h " + Math.floor(minutes) + "'"; //under 24 hours
  const hours = time % 24;
  time = time / 24;
  return prefix + Math.floor(time).toString() + "g " + Math.floor(hours) + "h";
}

const bubbleTooltipBody = (context: any, yFormat: string = '', xFormat: string = '') => {
  const keys = Object.keys(context.raw)
  const content = [' ' + context.dataset.label + ': ']
  if (keys.includes("yLabel")) {
    const value = (yFormat === 'addDynamicTimeUnit') ? addDynamicTimeUnit(context.parsed.y) : (yFormat === 'addPercent') ? context.parsed.y +'%' : context.parsed.y
    content.push(' ' + i18n.t(context.raw.yLabel) + ': ' + value)
  }
  if (keys.includes("xLabel")) {
    const value = (xFormat === 'addDynamicTimeUnit') ? addDynamicTimeUnit(context.parsed.x) : (xFormat === 'addPercent') ? context.parsed.x +'%' : context.parsed.x
    content.push(' ' + i18n.t(context.raw.xLabel) + ': ' + value)
  }
  if (keys.includes("rLabel")) {
    content.push(' ' + i18n.t(context.raw.rLabel) + ': ' + context.parsed._custom)
  }
  for (const key of keys.filter((x) => !["yLabel", "xLabel", "rLabel", 'x', 'y', 'r'].includes(x))){
    content.push(' ' + i18n.t(key) + ': ' + context.raw[key])
  }    
  return content
}

export type FormatType = {
  ":ticks_addPercent": (label: any) => string;
  ":ticks_addDynamicTimeUnit": (label: any) => string;
  ":tooltip_label_addPercent": (context: any) => string;
  ":tooltip_label_addDynamicTimeUnit": (context: any) => string;
  ":tooltip_label_addPercent_x": (context: any) => string;
  ":tooltip_label_addPercent_y": (context: any) => string;
  ":tooltip_label_addDynamicTimeUnit_x": (context: any) => string;
  ":tooltip_label_addDynamicTimeUnit_y": (context: any) => string;
  ":tooltip_label_y_x": (context: any) => string[];
  ":tooltip_label_y_addPercent_x": (context: any) => string[];
  ":tooltip_label_y_addDynamicTimeUnit_x": (context: any) => string[];
  ":tooltip_label_addPercent_y_x": (context: any) => string[];
  ":tooltip_label_addPercent_y_addPercent_x": (context: any) => string[];
  ":tooltip_label_addPercent_y_addDynamicTimeUnit_x": (context: any) => string[];
  ":tooltip_label_addDynamicTimeUnit_y_x": (context: any) => string[];
  ":tooltip_label_addDynamicTimeUnit_y_addPercent_x": (context: any) => string[];
  ":tooltip_label_addDynamicTimeUnit_y_addDynamicTimeUnit_x": (context: any) => string[];
  ":tooltip_title_cleanTitle": (context: any) => string;
  ":tooltip_title_cleanLabel": (context: any) => string;
  ":addPercent": (value: string) => string;
  ":addDynamicTimeUnit": (value: number) => string;
  "": (value: any) => string;
};

export const formatRules: FormatType = {
  ":ticks_addPercent": function (label: any) {
    return Math.floor(label) + "%";
  },
  ":ticks_addDynamicTimeUnit": function (label: any) {
    return addDynamicTimeUnit(label)
  },
  ":tooltip_label_addPercent": function (context: any) {
    return " " + context.label + ": " + context.parsed + "%";
  },
  ":tooltip_label_addDynamicTimeUnit": function (context: any) {
    return addDynamicTimeUnit(context.parsed)
  },
  ":tooltip_label_addPercent_x": function (context: any) {
    return (" " + context.dataset.label + ": " + Math.floor(context.parsed.x) + "%");
  },
  ":tooltip_label_addPercent_y": function (context: any) {
    return (" " + context.dataset.label + ": " + Math.floor(context.parsed.y) + "%");
  },
  ":tooltip_label_addDynamicTimeUnit_x": function (context: any) {
    return addDynamicTimeUnit(context.parsed.x, " " + context.dataset.label + ": ")
  },
  ":tooltip_label_addDynamicTimeUnit_y": function (context: any) {
    return addDynamicTimeUnit(context.parsed.y, " " + context.dataset.label + ": ")
  },
  ":tooltip_label_y_x": function (context: any) {
    return bubbleTooltipBody(context, '', '')
  },
  ":tooltip_label_y_addPercent_x": function (context: any) {
    return bubbleTooltipBody(context, '', 'addPercent')
  },
  ":tooltip_label_y_addDynamicTimeUnit_x": function (context: any) {
    return bubbleTooltipBody(context, '', 'addDynamicTimeUnit')
  },
  ":tooltip_label_addPercent_y_x": function (context: any) {
    return bubbleTooltipBody(context, 'addPercent', '')
  },
  ":tooltip_label_addPercent_y_addPercent_x": function (context: any) {
    return bubbleTooltipBody(context, 'addPercent', 'addPercent')
  },
  ":tooltip_label_addPercent_y_addDynamicTimeUnit_x": function (context: any) {
    return bubbleTooltipBody(context, 'addPercent', 'addDynamicTimeUnit')
  },
  ":tooltip_label_addDynamicTimeUnit_y_x": function (context: any) {
    return bubbleTooltipBody(context, 'addDynamicTimeUnit', '')
  },
  ":tooltip_label_addDynamicTimeUnit_y_addPercent_x": function (context: any) {
    return bubbleTooltipBody(context, 'addDynamicTimeUnit', 'addPercent')
  },
  ":tooltip_label_addDynamicTimeUnit_y_addDynamicTimeUnit_x": function (context: any) {
    return bubbleTooltipBody(context, 'addDynamicTimeUnit', 'addDynamicTimeUnit')
  },
  ":tooltip_title_cleanTitle": function (context: any) {
    return " " + context[0].label;
  },
  ":tooltip_title_cleanLabel": function (context: any) {
    return " x: " + context.parsed.x + "; y: " + context.parsed.y;
  },
  ":addPercent": function (value: string) {
    return value + "%";
  },
  ":addDynamicTimeUnit": function (time: number) {
    return addDynamicTimeUnit(time)
  },
  "": function (value: any) {
    return value.toString();
  },
};

export const getFormat = (format: keyof FormatType) => {
  return formatRules[format];
};
