import { configureStore, Action } from '@reduxjs/toolkit'
import { ThunkAction } from 'redux-thunk'

import rootReducer from './rootReducer'

// Store per Redux inizializzato con il rootReducer
const store = configureStore({
  reducer: rootReducer
})

// Solo per sviluppo (devtools) per hot reload veloce dei reducers
// Permette di vedere il contenuto dello store e delle action in tempo reale nel browser
if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default
    store.replaceReducer(newRootReducer)
  })
}


/**
 * Le export seguenti (tranne la default) sono "convenienti" in Typescript in modo da poter
 * utilizzare le diverse funzionalità di Redux Toolkit senza dover dichiarare i type in ogni
 * componente
 */ 
// Type per il dispatch delle azioni
export type AppDispatch = typeof store.dispatch
// Type per l'AppThunk (operazioni asincrone es. call API)
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>


// Export dello store vero e proprio
export default store