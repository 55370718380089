import { Box } from "@mui/material";
import { PackageEvalType } from "../../../types";
import MetricsTabs from "./MetricsTabs";

interface Props {
  index: string;
  valueLicense: string;
  licPackages: PackageEvalType[];
 
}

const MetricTabLicensePanel = ({
  licPackages,
  valueLicense,
  index,
 
  ...other
}: Props) => {




  return (
    <div
      role="tabpanel"
      hidden={valueLicense !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {valueLicense === index && (
        <Box sx={{ p: 3 }}>
          <MetricsTabs valueLicense={valueLicense} licPackages={licPackages} />
        </Box>
      )}
    </div>
  );
};

export default MetricTabLicensePanel;
