import { useAuthenticator } from "@aws-amplify/ui-react";
import { Box, Stack } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import MetricActions from "../../components/analyze/company-l3-metrics/MetricActions";
import MetricBodyHeader from "../../components/analyze/company-l3-metrics/MetricBodyHeader";
import MetricInsights from "../../components/analyze/company-l3-metrics/MetricInsights";
import MetricsChartTabs from "../../components/analyze/company-l3-metrics/MetricsChartTabs";

import SimpleLoading from "../../components/SimpleLoading";
import { useMetric } from "../../hooks";
import { useCharts } from "../../hooks/useCharts";

type Props = {};

const PageMetric = (props: Props) => {
  const [searchParams] = useSearchParams();
  const { user } = useAuthenticator((context) => [context.user]);

  const callKey: string | undefined =
    searchParams.get("id") !== null
      ? (searchParams
          .get("id")
          ?.concat("|" + searchParams.get("package"))
          .concat("|" + searchParams.get("kpi")) as string)
      : "";

  const { metric, metLoading } = useMetric(
    user.getSignInUserSession()?.getIdToken().payload.user_signature,
    user.getSignInUserSession()?.getIdToken().getJwtToken() || "",
    "card",
    "metric",
    callKey
  );

  const { charts, chartsLoading } = useCharts(
    user.getSignInUserSession()?.getIdToken().payload.user_signature,
    user.getSignInUserSession()?.getIdToken().getJwtToken() || "",
    "card",
    "chart",
    callKey
  );


  return (
    <>
      <Box
        sx={{
          padding: "1rem",
          margin: "1rem",
          width: "100%"
        }}
      >
        <Stack id="metricBody" spacing={2} sx={{ width:"100%" }}>
        
          {metLoading ? (
            <SimpleLoading />
          ) : (
            <>
              {metric && (
                <>
                
                  <MetricBodyHeader metric={metric} loading={metLoading} />
                  <MetricInsights
                    keyDict={metric.package_id + "." + metric.kpi_id}
                    insights={metric?.insights}
                  />

                  <MetricActions metric={metric} />
                </>
              )}
            </>
          )}
          {chartsLoading ? (
            <SimpleLoading />
          ) : (
            <>
              {charts &&  (
                <MetricsChartTabs charts={charts} loading={chartsLoading}  />
              )}
            </>
          )}
        </Stack>
      </Box>
    </>
  );
};

export default PageMetric;
