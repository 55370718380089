import { HRFilesType } from './../types';
import axios from "axios";


//const apiUrl: string = "https://ogmdhypi10.execute-api.eu-west-1.amazonaws.com/my-iris-faq";
const apiUrl: string = process.env.REACT_APP_API_URL_ROOT + "/hrfiles"

const fetchHrdbFile = async (user_signature: string, auth:string): Promise<HRFilesType[]> => {


   try {
    const response = await axios.get(apiUrl, {
      params: {
        user_signature: user_signature,
          max_depth: 0
        },
        headers: {'Authorization': auth}},
      );

     if (response === undefined) throw new Error("Response is undefined");

     const result:HRFilesType[] = response.data;
     return result;
   } catch (error: any) {
     if (axios.isAxiosError(error)) {
       if (error.response?.status === 500){
       console.log(error)
        throw new Error("Internal server error")}
       if (error.response?.status === 404){
        console.log(error)
         throw new Error("The resource was not found");}
    }
    console.log(error)
     throw error;
  }

};

export default fetchHrdbFile;
