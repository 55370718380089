import { Box } from "@mui/material";
import "./SeverityBadge.css";


export const SEVERITY_BADGE_COLORS ={
  critic: ":red",
  risk: ":yellow",
  target: ":green",
  alert: "alert"
}

type Props = {
  severity: string | null | undefined;
  type: string;
};

const SeverityBadge = ({ severity, type }: Props) => {
  return (
    <Box
      className={type}
      sx={{
        bgcolor:
          severity === SEVERITY_BADGE_COLORS.target
            ? "ts.severity.target"
            : severity === SEVERITY_BADGE_COLORS.risk
            ? "ts.severity.risk"
            : severity === SEVERITY_BADGE_COLORS.critic
            ? "ts.severity.critic"
            : severity === SEVERITY_BADGE_COLORS.alert
            ? "ts.severity.alert"
            : "ts.severity.disabled",

        borderRadius: "250px",
        padding: ".1rem",
      }}
    ></Box>
  );
};

export default SeverityBadge;

export const SEVERITY_BADGE_TYPES = {
  circle: "circle",
  vertTab: "vert-tab",
  summaryMetric: "summary-metric",
  indicator: "indicator",
  point: "point",
  initiative: "initiative",
  table: "table"
};


