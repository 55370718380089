import { Droppable } from "@hello-pangea/dnd";
import { Alert, Box, Stack, SvgIcon, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { dragDropStyle } from "../../../styles/styles";
import DragNDropItem from "./DragNDropItem";

type ColumnProps = {
  col: {
    id: string;
    title: string;
    list: string[];
  };
  disabledDrop: boolean;
};



const DragNDropColumn = ({
  col: { list, title, id },
  disabledDrop,
}: ColumnProps) => {

  const {t} = useTranslation();

  const styleStack = {
    c: {
      minWidth: "300px",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      width:"100%",
      marginTop: "2rem"
    },
    sta: {
      border: "2px solid",
      borderColor:"text.primary",
      borderRadius: "10px",
      paddingTop: "1rem",
      paddingBottom: "1rem",
      paddingLeft: "1rem",
      paddingRight: "1rem",
      backgroundColor:"rgb(237,244,255)"
  
  
    },
    singleitem:{
      minHeight: "20px",
      backgroundColor: disabledDrop? "rgba(0, 0, 0, 0.04)": "rgb(237,244,255)"
    },
    multipleItems:{
      minHeight: "70px",
  
    }
  };
  const styleSingle = {
    c: {
      minWidth: "15%",
      borderRadius: "10px",
      maxWidth: "50%",
      display:"flex",
      flexDirection:"column",
      justifyContent:"center"
    },
    sta: {
      border: "2px solid  ",
      borderColor:"text.primary",
      borderRadius: "10px",
      paddingTop: "1rem",
      paddingBottom: "2rem",
      paddingLeft: "1rem",
      paddingRight: "1rem",
      backgroundColor: "white"
    },
  };


  return (
    <Droppable droppableId={id} isDropDisabled={disabledDrop}>
      {(provided) => (
        <Box sx={id === "items" ? styleSingle.c : styleStack.c}>
          <Typography className="h2" color="text.primary">
            {t(title)}
          </Typography>
          
          <Typography className="h6" color="text.primary" sx={{maxWidth:"400px", marginBottom:"10px"}}>
            {t(`hrdb.modal.step.mapping.${id}.specifics`)}
          </Typography>
          {id !=="items" ?
          list.length === 0 ?

          id==="attributes" ? 
          <Alert
                  severity="warning"
                  sx={{ ...dragDropStyle.alert.warning,
                    backgroundColor: "rgb(255, 244, 229)",
                    color: "rgb(102, 60, 0)",
                  
                  }}
                  icon={
                    <SvgIcon sx={{padding:0}}>
                      <path
                        d="M 12 5.99 L 19.53 19 H 4.47 L 12 5.99 M 12 2 L 1 21 h 22 L 12 2 Z m 1 14 h -2 v 2 h 2 v -2 Z m 0 -6 h -2 v 4 h 2 v -4 Z"
                        fill="rgb(255, 152, 0)"
                      />
                    </SvgIcon>
                  }
                >{t("hrdb.modal.step.mapping.attributes.missing")}</Alert>
            :
        
          <Alert  sx={dragDropStyle.alert.warning} severity="error">{t(`hrdb.modal.step.mapping.${id}.missing`)}</Alert>
        
        :<></>

          :
          <></>}
          <Stack
            ref={provided.innerRef}
            {...provided.droppableProps}
            sx={id === "items" ? styleSingle.sta : id==="node_id" ? {...styleStack.sta, ...styleStack.singleitem} : {...styleStack.sta, ...styleStack.multipleItems}}
          >
            {list.map((text, index) => (
              <DragNDropItem key={text} text={text} index={index} />
            ))}
            {provided.placeholder}
          </Stack>
        </Box>
      )}
    </Droppable>
  );
};

export default DragNDropColumn;
