import mockupConnectors from "../mocks/mockupConnectors.json";
import { ConnectorType } from "../types";
// const apiUrl: string = process.env.REACT_APP_LICENSE_API_ENDPOINT || "";

const fetchConnector = async (): Promise<ConnectorType[]> => {


//   try {
//     const response = await axios.get(apiUrl);

//     if (response === undefined) throw new Error("Response is undefined");

//     return response.data;
//   } catch (error: any) {
//     if (axios.isAxiosError(error)) {
//       if (error.response?.status === 500)
//         throw new Error("Internal server error");
//       if (error.response?.status === 404)
//         throw new Error("The resource was not found");
//     }
//     throw error;
//   }
  return mockupConnectors;
};

export default fetchConnector;
