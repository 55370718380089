import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableHRColumn from "./TableHRColumn";
import TableHRRow from "./TableHRRow";
import { v4 as uuid } from "uuid";

type Props = {
  rows?: (
    | string
    | { value: number | string; status: string; message?: string }
  )[][];
  columns?: string[];
};

const TableHR = ({ rows, columns }: Props) => {
  return (
    <>
      {rows && columns && (
        <Table
          aria-label="collapsible table"
          sx={{
            bgcolor: "ts.card.bg",
            borderRadius: "8px",
            borderColor: "ts.card.bg",
            width: "100%",
          }}
        >
          <TableHead>
            <TableRow>
              {columns.map((c, index) => {
                return <TableHRColumn key={uuid()} data={c} />;
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.map((row, index) => {
              return <TableHRRow data={row} key={uuid()} index={index} />;
            })}
            <TableRow
              sx={{ "& > *": { borderBottom: "unset" } }}
              className="noBorderbot"
              key={"emptyrow"}
            >
              {columns.map((c, index) => {
                return (
                  <TableCell
                    key={"emptycell" + index}
                    id={"emptycell" + index}
                    component="th"
                    scope="row"
                    align="center"
                    className="noBorderBot"
                  ></TableCell>
                );
              })}
            </TableRow>
          </TableBody>
        </Table>
      )}
    </>
  );
};

export default TableHR;
