import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { CardTypeF } from "../../../types";

import CardMetricsGraph from "./CardMetricsGraph";

type Props = {
  cardInfo: CardTypeF;
};

const CardMetrics = ({ cardInfo }: Props) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ marginTop: "1rem" }}>
      <Typography variant="subtitle2" fontWeight={"bold"}>
        {t("cards.metrics")}
      </Typography>
  
      <CardMetricsGraph cardInfo={cardInfo}/>
    </Box>
  );
};

export default CardMetrics;

