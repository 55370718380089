import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import InitiativeTableRow from "./InitiativeDetailTableRow";
import { useSearchParams } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useInitiativesDetails } from "../../hooks";
import Loading from "../../components/Loading";
import InitiativeDetailTableColumn from "./InitiativeDetailTableColumn";

const InitiativeDetailTable = () => {
  const [searchParams] = useSearchParams();
  const { user } = useAuthenticator((context) => [context.user]);
  
  const { initiativeDetails, onError, loaded } = useInitiativesDetails(
    user.getSignInUserSession()?.getIdToken().payload.user_signature,
    user.getSignInUserSession()?.getIdToken().getJwtToken() || "",
    "detail",
    searchParams.get("init_key") !== null ? searchParams.get("init_key") as string : ""
  );

  return (
    <>
      { !loaded && !onError && <Loading /> } 
      { loaded && !onError && initiativeDetails &&
      <Table
          aria-label="collapsible table"
          sx={{
            bgcolor: "ts.card.bg",
            borderRadius: "8px",
            borderColor: "ts.card.bg",
          }}
        >          
          <TableHead>
            <TableRow>
              {initiativeDetails.headers && initiativeDetails.headers.map((init) => <InitiativeDetailTableColumn key={init} data={init} />)}
            </TableRow>
          </TableHead>
          <TableBody>
            {initiativeDetails.rows && initiativeDetails.rows.map((row, index) => <InitiativeTableRow infoRow={row} key={"rowN" + index} />)}
            <TableRow
              sx={{ "& > *": { borderBottom: "unset" } }}
              className="noBorderbot"
              key={"emptyrow"}
            >
              {initiativeDetails.headers && initiativeDetails.headers.map((c, index) => {
                return (
                  <TableCell
                    key={"emptycell" + index}
                    id={"emptycell" + index}
                    component="th"
                    scope="row"
                    align="center"
                    className="noBorderBot"
                  />
                );
              })}
            </TableRow>
          </TableBody>
        </Table>
      }
    </>
  );
};

export default InitiativeDetailTable;