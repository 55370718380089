import { useAuthenticator } from "@aws-amplify/ui-react";
import { Alert, Box, Snackbar, Typography } from "@mui/material";
import { useEffect,} from "react";
import { useTranslation } from "react-i18next";
import KpiAutocomplete from "../../components/configure/target/KpiAutocomplete";
import PackageCheckBoxTree from "../../components/configure/target/PackageCheckBoxTree";
import TargetForm from "../../components/configure/target/TargetForm";
import Heading from "../../components/Heading";
import Loading from "../../components/Loading";
import SimpleLoading from "../../components/SimpleLoading";
import { useHrOrg } from "../../hooks/useHrOrg";
import { useKpis } from "../../hooks/useKpis";
import { useTarget } from "../../hooks/useTarget";

const PageTargetConf = () => {
  const { user } = useAuthenticator((context) => [context.user]);
  const { formatKpis, selectedKpi, changeSelectedKpi, loaded, valueKpi, changeValueKpi } =
    useKpis(
      user.getSignInUserSession()?.getIdToken().payload.user_signature,
      user.getSignInUserSession()?.getIdToken().getJwtToken() || ""
    );
  const { t } = useTranslation();

  const {
    selectAllNodes,
    onChangeText,
    newNodes,
    changeFilteredTree,
    changeExpanded,
    filtered,
    filteredTree,
    changeChecked,
    checked,
    expanded,
    loadedHrOrg,
    changeFiltered
  } = useHrOrg(
    user.getSignInUserSession()?.getIdToken().payload.user_signature,
    user.getSignInUserSession()?.getIdToken().getJwtToken() || ""
  );

  const {
    kpiOn,
    manOn,
    handleChangeKpi,
    handleChangeMan,
    changeKpi,
    changeMan,
    changeTarget,
    openSnack,
    snackMessage,
    loadingButton,
    changeLoadingButton,
    handleCloseSnack,
    onErrorPut,
  } = useTarget(
    user.getSignInUserSession()?.getIdToken().payload.user_signature,
    user.getSignInUserSession()?.getIdToken().getJwtToken() || "",
    selectedKpi,
    checked
  );

  useEffect(() => {
    if (!openSnack) {
      changeValueKpi(null);
      changeSelectedKpi(null);
      changeChecked([]);
      changeExpanded([]);
      changeFiltered("");
     
     
    }
  }, [openSnack]);

  return (
    <Box className="container">
      <Heading title="target.title" />
      <Typography className="body1" sx={{ marginBottom: "1rem" }}>
        {t("target.description")}
      </Typography>
      {loaded ? (
        <>
          <KpiAutocomplete setKpi={changeSelectedKpi} kpis={formatKpis} valueKpi={valueKpi} changeValueKpi={changeValueKpi} changeMan={changeMan} changeChecked={changeChecked} changeKpi={changeKpi}/>

          <Box sx={{ marginTop: "1.5rem", width: "100%" }}>
            {selectedKpi && (
              <>
                <Box className="flexTopEven" sx={{ flexWrap: "wrap" }}>
                  {loadedHrOrg ? (
                    <PackageCheckBoxTree
                      checked={checked}
                      setChecked={changeChecked}
                      filtered={filtered}
                      onChangeText={onChangeText}
                      filteredTree={filteredTree || []}
                      selectAllNodes={selectAllNodes}
                      expanded={expanded}
                      setExpanded={changeExpanded}
                      newNodes={newNodes}
                      setFilteredTree={changeFilteredTree}
                      changeFiltered={changeFiltered}
                    />
                  ) : (
                    <SimpleLoading />
                  )}
                  <TargetForm
                    selectedKpi={selectedKpi}
                    disableButton={checked.length > 0}
                    checked={checked}
                    kpiOn={kpiOn}
                    manOn={manOn}
                    handleChangeKpi={handleChangeKpi}
                    handleChangeMan={handleChangeMan}
                    changeTarget={changeTarget}
                    loadingButton = {loadingButton}
                    changeLoadingButton = {changeLoadingButton}
                  />
                </Box>

                
               
                <Snackbar
                    open={openSnack}
                    autoHideDuration={6000}
                    message={snackMessage}
                    onClose={handleCloseSnack}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}

                  >
                    <Alert
                      onClose={handleCloseSnack}
                      severity={onErrorPut ? "error" : "success"}
                      sx={{ width: "100%", backgroundColor:"white" , color:"ts.succes"}}
                    >
                      {snackMessage}
                    </Alert>
                  </Snackbar>
              </>
            )}
          </Box>
        </>
      ) : (
        <Loading />
      )}
    </Box>
  );
};

export default PageTargetConf;
