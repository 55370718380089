//import React, { useState, useEffect, useContext } from "react";
//import { useSearchParams } from "react-router-dom";
//import { CognitoContext } from "../../contexts/CognitoContext";
//import { ErrorContext } from "../../contexts/ErrorContext";
//import axios from "axios";
//import styled from "styled-components/macro";
//import { spacing } from "@mui/system";
//import { LinearProgress as MuiLinearProgress } from "@mui/material";
//import { Link } from "react-router-dom";

import React from "react";
import { useConnectors } from "../../hooks";

//const LinearProgress = styled(MuiLinearProgress)(spacing);

const PageConnectorList = (props: any) => {
    const { connectors } = useConnectors();
  //const [searchParams] = useSearchParams();
  //const queryString = '{"' + decodeURI(searchParams.toString()).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}';

  //const { ctxGetProp, ctxGetIsValid} = useContext(CognitoContext);
  //const poolId = ctxGetProp("poolId");
  //const token = ctxGetProp("token");
  //const isValid = ctxGetIsValid();

  //const { setErrore } = useContext(ErrorContext);;

  //const [isLoading, setIsLoading] = useState<boolean>(false);
  //const [error, setError] = useState<boolean>(false);
  //const [result, setResult] = useState<string>("");

  //useEffect(() => {
    //if (isValid && queryString && queryString !== "") {
      //setIsLoading(true);
      //const addConnector = async () => {
        //const response = await axios.post('https://s76b3g55k1.execute-api.eu-west-1.amazonaws.com/Viz-ConnectorGateway', {
          //"UserPoolId": poolId,
          //"Token": token,
          //"invocationType": "PUT",
          //"connectorType": props.type,
          //"connectorBody": queryString
        //});
        //if (response.data.errorMessage) throw new Error(response.data.errorMessage);
        //setResult("" + JSON.stringify(response.data.body));
        //setIsLoading(false);
      //}

      //addConnector().catch((errEx) => {
        //setErrore(errEx.toString());
        //setIsLoading(false);
        //setError(true);
      //});
    //}
  //}, [setErrore, poolId, token, isValid, queryString, props.type]);

  return (
    <>
      <h1>I tuoi connettori:</h1>
      {connectors.map((connector, index) => (
        <div key={"connectors"+connector.id+index}>
            ●{" "}{connector.name}
        </div>
      ))}
      {/*<div className="resultConnector">
        {!isLoading && !error &&
          <p>
            <h1>Connettote Aggiunto: {props.type}</h1>
            <h1>Token Aggiunto: {queryString}</h1>
            {result}
          </p>}
        {!isLoading && error && <p>C'è stato un errore nel caricare il connettore</p>}
        {isLoading && <LinearProgress my={2} />}
        <Link to="/">Fantastico!</Link>
        </div>*/}
    </>
  );
}

export default PageConnectorList;