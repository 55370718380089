import { useEffect, useState } from "react";
import fetchPackages from "../api/fetchPackages";
import { PackageTypeF } from "../types";

export const usePackages = (signature:string, auth:string, scope: string, leveInfo:string, id: string ) => {
  const [packages, setPackages] = useState<PackageTypeF[]>([]);
  
  const [onPackageError, setOnPackageError] = useState(false);
  const [packageLoading, setPackageLoading] = useState(true);

  useEffect(() => {
    try{
      (async()=>{
        /*MOD20221128: Rimuovere il check sull'agile*/
        const init = await fetchPackages(signature, auth, scope, leveInfo, id) as PackageTypeF[];
        const result = id.toLowerCase().includes(("IT e Innov. Digitale|Sviluppo e Architetture").toLowerCase()) ? init.concat([{
            "package_id": "card.package.agile",
            "parent_list": [
                "It e innov. digitale",
                "It e innov. digitale|Sviluppo e architetture"
            ],
            "red_counter": 0,
            "score": 0,
            "card_key": "It e innov. digitale|Sviluppo e architetture",
            "green_counter": 0,
            "complete_key": "It e innov. digitale|Sviluppo e architetture|card.package.agile",
            "color": ":grey",
            "yellow_counter": 0
        }]) : init
        setPackages(result);
        setOnPackageError(false);
        setPackageLoading(false);
          
      })()}
      catch(error:any){
        setOnPackageError(true);
        setPackageLoading(true);
      }
    
  }, [id]);

  return { packages,  onPackageError, packageLoading};
};