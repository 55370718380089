import { Breadcrumbs, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Link, useSearchParams } from "react-router-dom";
import { useBread } from "../../hooks/useBread";
const NewBreadcrumb = () => {
  const { t } = useTranslation(); // t è la funzione che fa lookup nella traduzione

  const [searchParams] = useSearchParams();
  const { bread } = useBread(searchParams.get("id") || "");

  
  const breadcrumb = bread.root.concat(bread.crumbs);

  return (
    <Breadcrumbs
      maxItems={3}
      aria-label="breadcrumb"
      separator=">"
      sx={{ color: "primary.main" }}
      itemsBeforeCollapse={1}
      itemsAfterCollapse={1}
    >
      {breadcrumb.map((crumb, index) => {
       
        return  (
          <Link
            style={{ textDecoration: "none", color: "inherit", pointerEvents: index === breadcrumb.length - 1  ? "none" : "auto"  }}
            to={`${crumb.url}`}
            key={"bread"+index}
            onClick={index === breadcrumb.length - 1  ? e => e.preventDefault() : ()=>{}}
          >
            <Typography
              color="primary.main"
              sx={{
                textDecoration: "none",
                fontWeight: index === breadcrumb.length - 1 ? "bolder" : "normal",

                ":hover": {
                  textDecoration: "underline",
                },
              }}
            >
              {index === 0 ? t(crumb.name) : crumb.name}
            </Typography>
          </Link>
        ) 
      })}
    </Breadcrumbs>
  );
};

export default NewBreadcrumb;
