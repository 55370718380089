import { getColor } from "./paletteChart";

export const quadrants = {
    id: 'quadrants',
    beforeDraw: (chart: any, args: any, options: any) => {
        const {ctx, chartArea: {left, top, right, bottom}, scales: {x, y}} = chart;
        const midX = x.getPixelForValue(options.x_divider);
        const midY = y.getPixelForValue(options.y_divider);
        ctx.save();
        ctx.fillStyle = getColor(options.topLeft);
        ctx.fillRect(left, top, midX - left, midY - top);
        ctx.fillStyle = getColor(options.topRight);
        ctx.fillRect(midX, top, right - midX, midY - top);
        ctx.fillStyle = getColor(options.bottomRight);
        ctx.fillRect(midX, midY, right - midX, bottom - midY);
        ctx.fillStyle = getColor(options.bottomLeft);
        ctx.fillRect(left, midY, midX - left, bottom - midY);
        ctx.restore();
    },
    afterDraw: (chart: any, args: any, options: any) => {
        const {ctx, scales: {x, y}} = chart;
        ctx.save();
        ctx.textAlign = 'center';
        ctx.font = options.font;
        ctx.fillStyle = getColor(options.textColor);
        ctx.fillText(options.topLeftText, x.left + ctx.measureText(options.topLeftText).width / 1.5, y.top + 18);
        ctx.fillText(options.bottomLeftText, x.left + ctx.measureText(options.bottomLeftText).width / 1.5, y.bottom - 18);
        ctx.fillText(options.topRightText, x.right - ctx.measureText(options.topRightText).width / 1.5, y.top + 18);
        ctx.fillText(options.bottomRightText, x.right - ctx.measureText(options.bottomRightText).width / 1.5, y.bottom - 18);
        ctx.restore();
    }
}

export type PluginType = {
    "quadrants": any
}

export const pluginRules: PluginType = {
    "quadrants": quadrants,
}

export const allPlugins = [quadrants]
export const allPluginsName = ["quadrants"]

export const getPlugin = (plugin: keyof PluginType) => {
    return pluginRules[plugin];
};
