import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CardTypeF } from "../../../types";

import CardBody from "../card-elements/CardBody";
import CardHeader from "../card-elements/CardHeader";
import OfficesPopOver from "./OfficesPopOver";

type Props = {
  cardInfo: CardTypeF;
};

const CardDep = ({ cardInfo }: Props) => {
  const nav = useNavigate();
 

  return (
    <Box
      sx={{
        minHeight: "300px",
        height: "100%",
        padding: ".8rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box
         onClick={() => {
          
          
          nav(
            `/company?id=${encodeURIComponent(cardInfo.complete_key)}`
          );
        }}
        sx={{
          cursor: "pointer",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <CardHeader
          title={cardInfo.parent_list?.at(-1) || ""}
          officeListLen={cardInfo.child_number}
          resources={cardInfo.resources}
        />
        
      </Box>
      <Box>
      <CardBody  officeList={cardInfo.child_list} cardInfo={cardInfo} />
      <OfficesPopOver officeList={cardInfo.child_list} />
      </Box>
    </Box>
  );
};

export default CardDep;
