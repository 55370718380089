import { yupResolver } from "@hookform/resolvers/yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, CircularProgress, IconButton, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import * as yup from "yup";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { dragDropStyle } from "../../../styles/styles";
import { ColumnsType } from "../../../types";
import { Visibility, VisibilityOff } from '@mui/icons-material';

type Props = {
  hashFile: (hrdb: any[], mapping: ColumnsType, keyw: string) => void;
  columnMapping: ColumnsType;
  clientFile: any[];
};

const HashingForm = ({
 
  hashFile,
  columnMapping,
  clientFile,
}: Props) => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    title: yup
      .string()
      .required(t("hrdb.modal.step.hashing.error.none"))
      .min(3, t("hrdb.modal.step.hashing.error.less")),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const onSubmit = (data: any) => {
    
    setLoading(true);
    hashFile(clientFile, columnMapping, data.title);
  };

  return (
    <Stack
      sx={{
        width: "100%",
        height: "100%",
        marginTop: "2rem",
        display: "flex",
        alignItems: "center",
      }}
      spacing={3}
    >
      <Box>
        <Typography variant="h4">
          {t("hrdb.modal.step.hashing.title")}
        </Typography>
        <Typography variant="body1">
          {t("hrdb.modal.step.hashing.subtitle")}
        </Typography>
      </Box>
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Controller
          render={({ field, formState }) => (
            <TextField
              {...field}
              label={t("hrdb.modal.step.hashing.insert")}
              variant="standard"
              error={!!formState.errors?.title}
              helperText={
                formState.errors?.title?.message
                  ? formState.errors?.title?.message.toString()
                  : ""
              }
              sx={dragDropStyle.hashing.text}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          )}
          name="title"
          control={control}
          defaultValue=""
        />
        <LoadingButton
          size="small"
          onClick={handleSubmit(onSubmit)}
          loading={loading}
          loadingIndicator={
            <CircularProgress size={26} sx={{ color: "#00bfa5" }} />
          }
          variant="outlined"
          sx={dragDropStyle.hashing.button}
        >
          {t("hrdb.modal.button.submit")}
        </LoadingButton>
      </Box>

      <Box sx={{ "& > button": { m: 1 } }}></Box>
    </Stack>
  );
};

export default HashingForm;
