import { useEffect, useState } from "react";
import { Box, Typography, Modal, IconButton, SvgIcon, Divider, Snackbar, Alert } from "@mui/material";
import ButtonWizard from "../../components/wizard/ButtonWizard";
import StepperWizard from "../../components/wizard/StepperWizard";
import GenericData from "../wizard/initiativeSteps/GenericData";
import Perimeter from "../wizard/initiativeSteps/Perimeter";
import Metrics from "../wizard/initiativeSteps/Metrics";
import Summary from "../wizard/initiativeSteps/Summary";
import Ending from "../wizard/initiativeSteps/Ending";
import { useInitiativeModal } from "../../hooks/useInitiativeModal";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks";
import { useTranslation } from "react-i18next";
import { updateInitiativeTemplateGeneric } from "../../redux/slices/initiative/initiativeTemplateSlice";

type Props = {
  openModal: boolean;
  setOpenModal: (status:boolean) => void;
};

const InitiativesModal = ({ openModal, setOpenModal }: Props) => {
  const { user } = useAuthenticator((context) => [context.user]);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const nav = useNavigate();
  const [scope, setScope] = useState<boolean>(false);
  const [metric, setMetric] = useState<boolean>(false);
  
  const { steps, activeStep, buttonsConfig, onFormStatusChange, openSnack, snackMessage, handleCloseSnack, onErrorPut, setActiveStep } = useInitiativeModal(
    user.getSignInUserSession()?.getIdToken().payload.user_signature,
    user.getSignInUserSession()?.getIdToken().getJwtToken() || ""
  );

  const handleCloseModal = () => {
    setOpenModal(false);
    if(activeStep >= 3){
      nav(0);
    } 
    else{
      setActiveStep(0);
      dispatch(updateInitiativeTemplateGeneric(null));
    }
  }

  useEffect(()=>{
    if (scope === true && metric === true) onFormStatusChange(1, true);
    else onFormStatusChange(1, false);
  },[scope, metric]);
  
  return(
    <>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        key={"modal stepper"}
        disableEscapeKeyDown={true}
      >
        <Box sx={styleDrag}>
          <Box>
            <Box className="flex" sx={{ width: "100%", alignItems: "baseline" }}>
              <StepperWizard steps={steps} activeStep={activeStep} />
              <IconButton onClick={handleCloseModal}>
                <SvgIcon>
                  <path d="M6.4 19.8 4.2 17.6 9.8 12 4.2 6.4 6.4 4.2 12 9.8 17.6 4.2 19.8 6.4 14.2 12 19.8 17.6 17.6 19.8 12 14.2Z" />
                </SvgIcon>
              </IconButton>
            </Box>
            <Typography className="h4" fontWeight="bolder" sx={{ marginTop: "1rem", marginBottom: "2rem" }}>
              {t("initiative.modal.title")}
            </Typography>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-evenly" }}>
              {(() => {
                switch (activeStep) {
                  case 0:
                    return (<GenericData onFormStatusChange={onFormStatusChange} />);
                  case 1:
                    return (<>
                      <Perimeter onFormStatusChange={setScope} />
                      <Divider orientation="vertical" flexItem sx={{ borderWidth: "1px" }}/>
                      <Metrics onFormStatusChange={setMetric} />
                    </>);
                  case 2:
                    return (<Summary />);
                  case 3:
                    return (<Ending />);
                  default:
                    return (<></>);
              }})()}
            </Box>
          </Box>
          <ButtonWizard activeStep={activeStep} info={buttonsConfig} />
        </Box>
      </Modal>
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        message={snackMessage}
        onClose={handleCloseSnack}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={onErrorPut ? "error" : "success"}
          sx={{ width: "100%", backgroundColor:"white" , color:"ts.succes"}}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default InitiativesModal;

const styleDrag = {
  boxShadow: 24,
  borderRadius: "25px",
  bgcolor: "ts.sidebar.text.active",
  paddingX: 4,
  paddingY: 3,
  height: "100%",
  length: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  overflow: "scroll",
};