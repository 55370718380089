import { Box, CircularProgress, } from "@mui/material"
import logo from "../images/logo.png"


 const JustLoading = () => {
  return (
   
    <Box sx={{ position: 'relative', display: 'inline-flex', alignContent:'center', justifyContent:'center'}}>
      <CircularProgress  sx={{color:"rgb(28,223,200)"}} size={69} thickness={1.5}/>
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
       <Box>
          <img src={logo} alt="logo" height={"60px"} className="logo"></img>
        </Box>
      </Box>
    </Box>
   
  
  )
}

export default JustLoading