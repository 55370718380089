import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { MetricTypeF } from "../../../types";
import SeverityBadge, {
  SEVERITY_BADGE_TYPES
} from "../../SeverityBadge/SeverityBadge";

type Props = {
  metric: MetricTypeF;
  value: string;
  index: string;
  disabled: boolean;
};

const MetricVertTab = ({ metric, value, index, disabled }: Props) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        bgcolor: value === index ? "ts.card.bg" : "ts.primary.white",

        padding: ".5rem",
        borderTopLeftRadius: "10px",
        borderBottomLeftRadius: "10px",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          border: value === index ? "none" : "2px solid #e0e0e0",
          padding: ".8rem",
          borderRadius: "10px",
          display: "flex",
          alignItems: "center",
          gap: "1rem",
          height: "100%",
          position: "relative",
        }}
      >
        <SeverityBadge
          severity={metric.color}
          type={SEVERITY_BADGE_TYPES.vertTab}
        />
        <Box sx={{ marginLeft: "1rem", display: "flex", alignItems: "center" }}>
          <Typography
            variant="subtitle2"
            color={ disabled? "#bcc1c2": "ts.card.text.main"}
            fontWeight={value === index ? "bolder" : "none"}
            textAlign="left"
            sx={{minWidth: { xs: "171px", sm: "171px", md: "171px", lg: "175px" },
            maxWidth: { xs: "171px", sm: "171px", md: "171px", lg: "175px" },}}
          >
            {  t(metric?.package_id + "." +  metric?.kpi_id +".shoulder")}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default MetricVertTab;
