import { MetricTypeF } from "../../types";

/*MOD20221128: Rimuovere*/
export const metricList: MetricTypeF[] = [
    {
        "card_key": "IT e Innov. Digitale|Sviluppo e Architetture",
        "complete_key": "IT e Innov. Digitale|Sviluppo e Architetture|card.package.agile|kpi_001",
        "score": 65.0,
        "package_id": "card.package.agile",
        "color": ":grey",
        "kpi_status": "on",
        "kpi_id": "kpi_001"
    },
    {
        "card_key": "IT e Innov. Digitale|Sviluppo e Architetture",
        "complete_key": "IT e Innov. Digitale|Sviluppo e Architetture|card.package.agile|kpi_002",
        "score": 70.0,
        "package_id": "card.package.agile",
        "color": ":grey",
        "kpi_status": "on",
        "kpi_id": "kpi_002"
    },
    {
        "card_key": "IT e Innov. Digitale|Sviluppo e Architetture",
        "complete_key": "IT e Innov. Digitale|Sviluppo e Architetture|card.package.agile|kpi_003",
        "score": 75.0,
        "package_id": "card.package.agile",
        "color": ":grey",
        "kpi_status": "on",
        "kpi_id": "kpi_003"
    },
    {
        "card_key": "IT e Innov. Digitale|Sviluppo e Architetture",
        "complete_key": "IT e Innov. Digitale|Sviluppo e Architetture|card.package.agile|kpi_004",
        "score": 80.0,
        "package_id": "card.package.agile",
        "color": ":grey",
        "kpi_status": "on",
        "kpi_id": "kpi_004"
    },
    {
        "card_key": "IT e Innov. Digitale|Sviluppo e Architetture",
        "complete_key": "IT e Innov. Digitale|Sviluppo e Architetture|card.package.agile|kpi_005",
        "score": 85.0,
        "package_id": "card.package.agile",
        "color": ":grey",
        "kpi_status": "on",
        "kpi_id": "kpi_005"
    },
    {
        "card_key": "IT e Innov. Digitale|Sviluppo e Architetture",
        "complete_key": "IT e Innov. Digitale|Sviluppo e Architetture|card.package.agile|kpi_006",
        "score": 90.0,
        "package_id": "card.package.agile",
        "color": ":grey",
        "kpi_status": "on",
        "kpi_id": "kpi_006"
    },
    {
        "card_key": "IT e Innov. Digitale|Sviluppo e Architetture",
        "complete_key": "IT e Innov. Digitale|Sviluppo e Architetture|card.package.agile|kpi_008",
        "score": 100.0,
        "package_id": "card.package.agile",
        "color": ":grey",
        "kpi_status": "on",
        "kpi_id": "kpi_008"
    }
];