export const appBar = {
  bgcolor: "rgb(15,20,33)",
  color: "white",
  "@media (min-width: 600px)": {
    minHeight: "85px",
  },
};

export const userSection = {
  accordion: {
    summary: {
      margin: "0px 0px",
      "& .MuiAccordionSummary-content": { margin: "0px 0px" },
    },
    icon: {
      color: "ts.sidebar.text.hover",
      fontSize: "medium",
      marginLeft: ".4rem",
      "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(90deg)",
      },
    },
    details: {
      position: "fixed",
      bottom: "58px",
      left: "0px",
      bgcolor: "#ffffff",
      width: "249px",
      borderRadius: "10px 10px 0 0",
    },
    list: {
      ul: {
        padding: ".5rem",
        width: "92%",
        margin: "auto",
      },
      item: {
        button: {
          borderRadius: "25px",
          alignItems: "center",
          justifyContent: "space-between",
        },
      },
    },
  },
};

export const metricSection = {
  insights: {
    box: {
      width: 190,
      height: 160,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      bgcolor: "white",
      borderRadius: "10px",
      paddingTop: "0.7rem",
      paddingBottom: "0.7rem",
      paddingLeft: "0.5rem",
      paddingRight: "0.5rem",
    },
    highlight: {
      width: 190,
      height: 160,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      bgcolor: "white",
      borderRadius: "10px",
      paddingTop: "0.7rem",
      paddingBottom: "0.7rem",
      paddingLeft: "0.5rem",
      paddingRight: "0.5rem",
      border: "2px solid",
      borderColor: "ts.sidebar.selected",
    },
  },
};

export const detailSection = {
  info: {
    generic: {
      height: "130px",
      padding: "0px 15px 0px 0px"
    }
  },
};

export const topbarSearch = {
  width: 300,

  "& .MuiFormLabel-root .MuiInputLabel-root": {
    color: "palette.primary.main",
  },

  "& label.Mui-focused": {
    borderColor: "ts.topbar.search.label.text",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "ts.sidebar.bg",
  },
  "& .MuiOutlinedInput-root": {
    color: "text.primary",
    "& fieldset": {
      borderColor: "text.primary",
      border: "1px solid",
    },
    "&:hover fieldset": {
      borderColor: "ts.sidebar.selected",
    },
    "&.Mui-focused fieldset": {
      borderColor: "ts.sidebar.selected",
    },
  },
};

export const metricTabStyle = {
  stack: {
    border: "none",
    textTransform: "none",
    padding: 0,
    bgcolor: "white",
    minWidth: { xs: "200px", sm: "200px", md: "200px", lg: "234px" },
    maxWidth: { xs: "200px", sm: "200px", md: "200px", lg: "234px" },
  },
  tab: {
    textTransform: "none",
    margin: 0,
    padding: "0px",

    height: "100%",
    minWidth: { s: "200px", m: "200px", l: "200px", xl: "234px", xxl: "234px" },
    maxWidth: { s: "200px", m: "200px", l: "200px", xl: "234px", xxl: "234px" },
    "&.Mui-selected": {
      minWidth: {
        s: "200px",
        m: "200px",
        l: "200px",
        xl: "234px",
        xxl: "234px",
      },
      maxWidth: {
        s: "200px",
        m: "200px",
        l: "200px",
        xl: "234px",
        xxl: "234px",
      },
    },
  },
};

export const initiativeWizard = {
  step5: {
    error: {
      box: {
        minWidth: "100%",
        minHeight: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        marginTop: "1rem",
      },
      icon: {
        color: "rgb(253,154,156)",
        width: "150px",
        height: "150px",
        margin: "2rem",
      },
    },
    success: {
      box: {
        minWidth: "100%",
        minHeight: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        marginTop: "1rem",
      },
      icon: {
        color: "rgb(128,211,203)",
        width: "150px",
        height: "150px",
        margin: "2rem",
      },
    },
  },
};

export const initiativesAction = {
  actions: {
    button: {
      textTransform: "none",
      color: "white",
      padding: ".8rem",
      paddingTop: ".4rem",
      paddingBottom: ".4rem",
      borderRadius: "20px",
      fontWeight: "bolder",
    },
    modal: {
      list: {
        container: {
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: 24,
          p: 4,
        },
        box: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "1rem",
        },
        button: {
          textTransform: "none",
          color: "white",
          padding: ".8rem",
          paddingTop: ".4rem",
          paddingBottom: ".4rem",
          borderRadius: "20px",
          fontWeight: "bolder",
          marginTop: "1rem",
        },
      },

      new: {
        container: {
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          display: "grid",
          placeItems: "center",
          width: "100%",
          height: "100%",
          boxShadow: 24,
          borderRadius: "25px",
        },
      },
    },
  },
  kpiRow: {
    box: {
      bgcolor: "rgb(237,244,255)",
      border: "1px solid rgb(204,229,248)",
      borderRadius: "8px",
      padding: "1rem ",
    },
  },
};

export const logoStyle = {
  chip: {
    backgroundColor: "rgb(98,141,226)",
    borderRadius: "5px",
    color: "white",
    fontSize: "60%",
    height: "18px",
    marginTop: "-16px",
    fontFamily: "Poppins",
    padding: "2px 0",
    "& span": {
      paddingLeft: "5px",
      paddingRight: "5px",
    },
  },
};

export const suggInitiativeCard = {
  button: {
    create: {
      color: "text.primary",
      backgroundColor: "white",
      textTransform: "capitalize",
      textAlign: "center",
      borderRadius: "10px",
      fontWeight: "bold",
      alignItems: "center",
      width: "100%",
      border: "1.5px dotted",
      borderColor: "text.primary",
      paddingTop: "0.5rem",
      paddingBottom: "0.5rem",
      display: "flex",
      flexDirection: "column",
    },
    act: {
      backgroundColor: "white",
      color: "text.primary",
      textTransform: "capitalize",
      textAlign: "center",
      borderRadius: "10px",
      fontWeight: "bold",
      alignItems: "center",
      width: "100%",
      border: "1.5px dotted",
      borderColor: "text.primary",
      paddingTop: "0.5rem",
      paddingBottom: "0.5rem",
      display: "flex",
      flexDirection: "column",
      marginTop: "1rem",
    },
    overlay: {
      position: "absolute",
      top: "0",
      left: "0",
      backgroundColor: "rgba(242, 244, 246, 0.7)",
      height: "100%",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "10px",
    },
  },
};

export const hrPage = {
  button: {
    connect: {
      backgroundColor: "ts.sidebar.selected",
      textTransform: "capitalize",
      textAlign: "center",
      borderRadius: "10px",
      fontWeight: "bold",
      alignItems: "center",
      color: "white",
      minWidth: "6rem",
      "&:hover": {
        backgroundColor: "ts.sidebar.selected",
        color: "text.primary",
      },
    },
  },
};

export const buttonStyle = {
  bgcolor: "ts.card.chip",
  color: "text.primary",
  fontWeight: "bolder",
  width: "100%",
  display: "flex",
  alignItems: "center",
  gap: ".5rem",
  textTransform: "none",
  "&.Mui-disabled": {
    color: "text.primary",
    bgcolor: "ts.card.chip",
    border: "none",
  },
};

export const faqContactBox = {
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
  },
  rowText: {
    fontWeight: "600",
    fontSize: "12px",
  },
  rowIcon: {
    width: "1em",
    paddingRight: "5px",
    color: "#00bfa5",
  },
  contactsTitle: {
    fontWeight: "600",
    fontSize: "16px",
    marginBottom: "0.7rem",
  },
};

export const pageFaq = {
  accordion: {
    order: "0",
    paddingRight: "2rem",
    maxWidth: "75%",
  },
  contacts: {
    order: "0",
    paddingLeft: "2rem",
  },
  body: {
    display: "flex",
    flexFlow: "wrap-reverse",
    width: "100%",
  },
};

export const faqAccordion = {
  row: {
    padding: "1rem",
    backgroundColor: "rgb(237,244,255)",
    borderColor: "rgb(204,229,248  )",
    marginBottom: "20px !important",
    "& .Mui-expanded:last-of-type": { marginBottom: "20px" },
    borderRadius: "5px",
  },
  expand: { marginBottom: "0.7rem" },
};

export const userCard = {
  body: {
    display: "flex",
    alignItems: "center",
    gap: "1",
    marginLeft: ".5rem",
    padding: "6px 7px 6px 8px",
  },
  bodyIcon: {
    width: "1rem !important",
    color: "#3F475A !important",
  },
  editIcon: {
    width: "34px !important",
    height: "34px !important",
    color: "#00bfa5",
    backgroundColor: "rgba(242, 244, 246, 1)",
    padding: "8px",
    borderRadius: "50%",
  },
  labelIcon: {
    width: "1rem !important",
    color: "#00bfa5",
  },
  labelCategory: {
    display: "flex",
    alignItems: "center",
    gap: "1",
    marginLeft: ".5rem",
    border: "1px solid #E1E4EA",
    borderRadius: "8px",
    padding: "6px 7px 6px 8px",
  },
  labelText: {
    width: "100%",
    textAlign: "center",
    marginLeft: "10px !important",
    marginRight: "10px !important",
  },
  buttonDetails: {
    backgroundColor: "#00bfa5 !important",
    textTransform: "capitalize !important",
    textAlign: "center",
    borderRadius: "30px !important",
    fontWeight: "bold !important",
    display: "flex",
    alignItems: "center",
    paddingRight: "4rem !important",
    paddingLeft: "4rem !important",
    marginTop: "0.5rem !important",
  },
  buttonProfile: {
    color: "#00bfa5 !important",
    textTransform: "capitalize !important",
    textAlign: "center",
    borderRadius: "30px !important",
    fontWeight: "bold !important",
    display: "flex",
    alignItems: "center",
  },
  button: {
    justifyContent: "flex-end",
    display: "flex",
    width: "100%",
  },
};

export const userConf = {
  blueprintProfile: {
    minHeight: "224px",
    borderRadius: "12px",
    backgroundColor: "rgba(100, 141, 229, 0.16)",
    border: "1px solid rgb(204,229,248)",
    padding: "2rem !important",

    width: "531px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
  bluprintOther: {
    minHeight: "184px",
    borderRadius: "12px",
    backgroundColor: "rgba(100, 141, 229, 0.08)",
    border: "1px solid rgb(204,229,248)",
    padding: ".8rem !important",
    paddingTop: "1.2rem",
    width: "455px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
};

export const alertGrid = {
  header: {
    marginBottom: "2rem !important",
    textAlign: "center",
    fontWeight: "bold !important",
    width: "100%",
  },
  row: {
    alertRow: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      flexWrap: "wrap",
      gap: "5px",
      justifyContent: "center",
    },
    rowName: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      flexWrap: "wrap",
      justifyContent: "space-evenly",
      alignItems: "center",
      height: "100%",
    },
  },
  gridRow: {
    maxWidth: "100%",
    backgroundColor: "rgb(237,244,255) !important",
    border: "1px solid rgb(204,229,248)",
    paddingTop: "1.6rem !important",
    paddingBottom: "1.6rem",
    textAlign: "center",
    alignItems: "center",
    borderRadius: "5px !important",
    marginBottom: "5px !important",
  },
  gridRowItem: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
  },

  gridRowName: {
    fontWeight: "bold !important",
    height: "100%",
    width: "80%",
  },
  gridRowAim: {
    fontSize: "0.75rem !important",
  },
  gridRowButtons: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "100%",
    marginLeft: "1rem",
  },
  gridRowButton: {
    paddingLeft: "4px",
    paddingRight: "4px",
    color: "#3F475A !important",
    minWidth: "auto !important",
  },
  gridRowContainer: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
  },
};

export const cardDocStyle = {
  main: {
    blueprint: {
      minHeight: "322px",
      borderRadius: "12px",
      backgroundColor: "rgba(101,142,230,0.08)",
      padding: ".8rem",
      paddingTop: "1.2rem",
      width: "370px",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
    },
    button: {
      textAlign: "right !important",
      borderRadius: "30px !important",
      justifyContent: "right !important",
      paddingTop: "0.5rem",
      textTransform: "capitalize !important",
      "&:hover": {
        backgroundColor: "#CFF5F1 !important",
      },
    },

    buttonText: {
      paddingRight: "0.2rem",
      fontWeight: "bold",
    },
  },
  label: {
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    category: {
      display: "flex",
      alignItems: "center",
      gap: "1",
      marginLeft: ".5rem",
      border: "1px solid #E1E4EA",
      borderRadius: "8px",
      padding: "6px 16px 6px 8px",
    },
    date: {
      display: "flex",
      alignItems: "center",
      gap: "1",
      marginRight: ".7rem",
    },
  },
  body: {
    container: {
      display: "flex",
      alignItems: "left",
      justifyContent: "space-between",
      flexDirection: "column",
      paddingTop: "1rem",
    },
    bold: {
      fontWeight: "bold",
      paddingBottom: "0.2rem",
    },
    spacing: {
      paddingBottom: "1rem",
    },
  },
};

export const inDetailStyle = {
  infoIcon: {
    minWidth: "1.2rem !important",
    width: "0.7rem",
    margin: "5px 2px 0px 0px",
  },
  infoSvg: {
    fontSize: "1em !important",
    color: "#00bfa5",
  },
};

export const initiativeStyle = {
  main: {
    container: {
      display: "flex",
      gap: "0.5",
      alignItems: "baseline",
      width: "100%",
      flexDirection: "column",
      margin: "1rem",
    },
    containerRow: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      flexWrap: "wrap",
      gap: "5px",
    },
    buttonAdvanced: {
      color: "#00bfa5 !important",
      border: "1px solid #3F475A !important",
      borderRadius: "30px !important",
      textTransform: "capitalize !important",
      fontWeight: "bold !important",
      paddingLeft: "1rem !important",
      paddingRight: "1rem !important",
    },
  },
  suggested: {
    container: {
      display: "flex",
      gap: "0.5",
      alignItems: "baseline",
      flexDirection: "column",
    },
    row: {
      marginTop: "1rem",
      display: "flex",
      flexDirection: "row",
      width: "100%",
      flexWrap: "wrap",
      gap: "20px",
    },
    card: {
      outline: {
        minHeight: "200px",
        borderRadius: "12px",
        backgroundColor: "white",
        border: "1px dashed #3F475A",
        padding: ".8rem",
        paddingTop: "1.2rem",
        width: "370px",
        position: "relative",
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      },
      outlineButton: {
        textAlign: "center",
        color: "#3F475A !important",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      },
      outlineText: {
        textTransform: "capitalize",
        fontWeight: "bold !important",
      },
      blueprint: {
        minHeight: "200px",
        borderRadius: "12px",
        backgroundColor: "rgb(237,244,255)",
        border: "1px solid rgb(204,229,248)",
        padding: ".8rem",
        paddingTop: "1.2rem",
        width: "370px",
        position: "relative",
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      },
      labelCategory: {
        display: "flex",
        alignItems: "center",
        gap: "1",
        marginLeft: ".5rem",
        border: "1px solid #E1E4EA",
        borderRadius: "8px",
        padding: "4px 6px 4px 7px",
        fontSize: "x-small",
        marginRight: "65%",
      },
      labelIcon: {
        width: "1 rem",
        color: "#00bfa5",
      },
      labelText: {
        width: "100%",
        textAlign: "center",
        marginLeft: "10px !important",
        marginRight: "10px !important",
      },
      bodyTitle: {
        fontSize: "1rem !important",
        fontWeight: "bold",
        marginBottom: "0.5rem !important",
        marginLeft: "0.5rem !important",
        marginTop: "1rem !important",
      },
      bodyCritical: {
        display: "flex",
        alignItems: "center",
        gap: ".5rem",
      },
      bodyCriticalChip: {
        marginLeft: "0.5rem !important",
        backgroundColor: "#D2E8F9 !important",
      },
      img: {
        maxWidth: "90%",
        height: "90px",
      },
    },
    popover: {
      buttonContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginLeft: "0.5rem !important",
        marginRight: "0.5rem !important",
        width: "100%",
      },
      buttonText: {
        color: "#3F475A !important",
        textTransform: "capitalize !important",
        fontWeight: "bold !important",
      },
      buttonDown: {
        color: "#3F475A",
        "& :hover": {
          color: "#3F475A",
          backgroundColor: "transparent !important",
        },
      },
      text: {
        marginBottom: "0.5rem !important",
      },
      impactedContainer: {
        display: "flex",
        justifyContent: "left",
        gap: ".5rem",
        flexDirection: "column",
      },
      buttonUp: { width: "100%" },
    },
  },
  grid: {
    header: {
      container: {
        textAlign: "center",
        fontWeight: "bold !important",
      },
      nested: {
        width: "100% !important",
      },
    },
    row: {
      main: {
        backgroundColor: "rgb(237,244,255)",
        border: "1px solid rgb(204,229,248)",
        paddingTop: "1.6rem !important",
        paddingBottom: "1.6rem",
        textAlign: "center",
        alignItems: "center",
        borderRadius: "5px",
        marginBottom: "5px !important",
        maxWidth: "100% !important",
      },
      item: {
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
      },
      name: {
        fontWeight: "bold !important",
      },
      description: {
        fontSize: "0.75rem !important"
      },
      buttons: {
        display: "flex",
        justifyContent: "space-evenly",
        width: "100%",
        marginLeft: "1rem",
      },
      button: {
        paddingLeft: "4px",
        paddingRight: "4px",
        color: "#3F475A !important",
        minWidth: "auto !important",
      },
    },
  },
};

export const initativeModal = {
  GenericData: {
    "& label.Mui-focused": {
      color: "#17B5A5"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "ts.sidebar.bg",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "text.primary",
        border: "1px solid"
      },
      "&:hover fieldset": {
        borderColor: "ts.sidebar.text.inactive"
      },
      "&.Mui-focused fieldset": {
        borderColor: "ts.sidebar.bg"
      }
    }
  }
}

export const dragDropStyle = {
  container: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "50%",
    justifyContent: "space-between",
    marginTop: "2rem",
    alignContent: "center",
    alignItems: "center",
  },
  alert: {
    warning: {
      display: "flex",
      alignItems: "center",
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: "6px",
      marginBottom: "5px",
    },
  },
  item: {
    border: "1.5px solid #00bfa5",
    borderRadius: "10px",
    padding: "3px",
    textAlign: "center",
    marginBottom: "3px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  hashing:{
    text:{
      minWidth: "250px",
      maxWidth: "20%",
      "& label.Mui-focused": {
        color: "#17B5A5",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "ts.sidebar.bg",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "text.primary",
          border: "1px solid",
        },
        "&:hover fieldset": {
          borderColor: "ts.sidebar.text.inactive",
        },
        "&.Mui-focused fieldset": {
          borderColor: "ts.sidebar.bg",
        },
      },
    },
    button:{
      fontSize: "1.2rem",
      padding: "0.5rem 1rem",
      borderRadius: "10px",
      textTransform: "none",
      color: "#00bfa5",
      fontWeight: "bold",
      backgroundColor: "white",
      border: "1px solid #00bfa5",
      marginTop: "1rem",
      marginBottom: "1rem",
      maxWidth: "20%",
      minWidth: "250px",

      "& .MuiButtonBase-root .MuiButton-root .MuiLoadingButton-root .MuiButton-outlined .MuiButton-outlinedPrimary .MuiButton-sizeSmall .MuiButton-outlinedSizeSmall .MuiButton-root .MuiLoadingButton-root .MuiButton-outlined .MuiButton-outlinedPrimary .MuiButton-sizeSmall .MuiButton-outlinedSizeSmall":
        {
          "& :hover": {
            color: "white",
            backgroundColor: "#00bfa5",
          },
        },
    }

  },

  empty:{
    box:{
      width: "100%",
      overflow: "auto",
      marginTop: "2rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirecton:"column"
    }
  },

  org:{
    button:{
      fontSize: "1.2rem",
      padding: "0.5rem 1rem",
      borderRadius: "10px",
      textTransform: "none",
      color: "#00bfa5",
      fontWeight: "bold",
      backgroundColor: "white",
      border: "1px solid #00bfa5",
      marginTop: "1rem",
      marginBottom: "1rem",
      maxWidth: "20%",
      "& .MuiButtonBase-root .MuiButton-root .MuiLoadingButton-root .MuiButton-outlined .MuiButton-outlinedPrimary .MuiButton-sizeSmall .MuiButton-outlinedSizeSmall .MuiButton-root .MuiLoadingButton-root .MuiButton-outlined .MuiButton-outlinedPrimary .MuiButton-sizeSmall .MuiButton-outlinedSizeSmall":
        {
          "& :hover": {
            color: "white",
            backgroundColor: "#00bfa5",
          },
        },
    }
  },

  success:{
    box:{minWidth: "100%",
    minHeight: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: "1rem",},
    svg:{color: "rgb(128,211,203)",
    width: "150px",
    height: "150px",
    margin: "2rem",}

  },

  tableHr:{
    column:{
      bgcolor: "ts.card.chip",
      color: "text.primary",
      fontWeight: "bolder",
      width: "100%",
      display: "flex",
      alignItems: "center",
      gap: ".5rem",
      textTransform: "none",
      "&.Mui-disabled": {
        color: "text.primary",
        bgcolor: "ts.card.chip",
        border: "none",
      },
    }
  }
};
