import AddIcon from "@mui/icons-material/Add";
import { Alert, Box, Button, Snackbar, Typography } from "@mui/material";
import { parse } from "papaparse";
import React from "react";
import { useTranslation } from "react-i18next";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useLicense } from "../../../hooks";

type Props = {
  storePeople: (p: any[]) => void;
  storeHead: (h: any[]) => void;
  storeFile: (f: any[]) => void;
};

const DragDrop = ({ storePeople, storeHead, storeFile }: Props) => {
  const [highlighted, setHighlighted] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackMessage, setSnackMessage] = React.useState("");
  const { license } = useLicense();

  const droppedFile = async (file: any) => {
    const text = await file.text();
  
    const result = parse(text, {
      header: false,
      skipEmptyLines: "greedy",
      
    });
    const rows = result.data;
    const newFirstRow : any[] = [];
    (Array.from(rows[0] as any[])).map((col:any)=>{ return newFirstRow.push((col as string).toLowerCase())})
    storeFile([[...newFirstRow], ...rows.slice(1)]);
    storeHead(newFirstRow);
    storePeople([...rows.slice(1)]);
   
  };
  const { t } = useTranslation();


  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };
  return (
    <>
      <Button
        disabled={license.sidebar.configs.filter(item => item.id === 'configs.hrdb')[0].state === 'read-only'}
        sx={{
          border: "2px dashed",
          borderColor: highlighted ? "ts.sidebar.selected" : "text.primary",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "4rem",
          minWidth: "6rem",
          padding: "1rem 2rem",
          color: "text.primary",
          backgroundColor: highlighted ? "#e6fcf9" : "white",
        }}
        component="label"
        onDragEnter={() => {
          setHighlighted(true);
        }}
        onDragLeave={() => {
          setHighlighted(false);
        }}
        onDragOver={(e: any) => {
          setHighlighted(true);
          e.preventDefault();
        }}
        onDrop={(e: any) => {
          e.preventDefault();
          setHighlighted(false);

          if (e.dataTransfer.files.length > 1) {
            setSnackMessage("hrdb.snack.error.many")
            setOpenSnack(true)
            
          } else {
            if (e.dataTransfer.files[0].size > 3000000) {
              setSnackMessage("hrdb.snack.error.size")
              setOpenSnack(true)
            } else {

              if(e.dataTransfer.files[0].type !== "text/csv"){
                setSnackMessage("hrdb.snack.error.type")
              setOpenSnack(true)

              }

              else {
             
              droppedFile(e.dataTransfer.files[0]);
              }
            }
          }
        }}

        
      >
        <AddIcon />
        {
          license.sidebar.configs.filter(item => item.id === 'configs.hrdb')[0].state === 'read-only' && <LockOutlinedIcon style={{ position: "absolute", bottom: "auto", right: "auto", transform: "scale(2.5)" }}/>
        }
        <Box sx={{ minHeight: "10pt" }}></Box>
        <Typography sx={{ textAlign: "center" }}>
          {t("hrdb.upload")}
        </Typography>

        <input hidden accept=".csv" type="file" onChange={(e: any) => {
           if (e.target.files.length > 1) {
            setSnackMessage("hrdb.snack.error.many")
            setOpenSnack(true)
            
          } else {
            if (e.target.files[0].size > 3000000) {
              setSnackMessage("hrdb.snack.error.size")
              setOpenSnack(true)
            } else {

              
             if(e.target.files[0].type !== "text/csv"){
                setSnackMessage("hrdb.snack.error.type")
              setOpenSnack(true)

              }

              else {
             
              droppedFile(e.target.files[0]);
              }
            }
          }
         }}
         onClick={( event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
          const element = event.target as HTMLInputElement
          element.value = ''}
      }/>
      </Button>

      <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleClose} anchorOrigin={ {vertical: "top" , horizontal: "center"}} >
  <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
    {t(snackMessage)}
  </Alert>
</Snackbar>
    </>
  );
};

export default DragDrop;
