import axios from "axios";

//const apiUrl: string = "https://qp9slmiux0.execute-api.eu-west-1.amazonaws.com/my-iris-dictionary";
const apiUrl: string =
process.env.REACT_APP_API_URL_ROOT + "/anonymization"

const sendHrdbFile = async (
  hrFile: string,
  k: number | undefined,
  mapping: { node_id: string[]; org: string[]; attributes: string[] },
  user_signature: string,
  auth: string
): Promise<any> => {

  const k_check = k ? k : 5

  try {
    const response = await axios.post(apiUrl, {
        user_signature: user_signature,
        hr_file: hrFile,
        k_value: k_check,
        mapping: mapping
      },
      { headers: {'Authorization': auth} }
    );
    if (response === undefined) throw new Error("Response is undefined");
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 500)
        throw new Error("Internal server error");
      if (error.response?.status === 404)
        throw new Error("The resource was not found");
    }
    throw error;
  }
};

export default sendHrdbFile;
