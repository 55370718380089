import { updateSortDep } from '../redux/slices/sortingDep/sortingDepSlice';
import { updateVisualDep } from "../redux/slices/visualDep/visualDepSlice";
import { VisualDepType } from "../types";
import { SortDepType } from './../types';
import { useAppDispatch, useAppSelector } from "./reduxHooks";

export const useNav = (search?: string) => {
  const order: "title" |  "resources" | "severity" = useAppSelector((state) => state.sortingDepSlice.order);
  /*const searchParam: string = useAppSelector(
    (state) => state.searchSlice.search
  );*/

  const dispatch = useAppDispatch();

  const view: VisualDepType = useAppSelector((state) => state.visualDepSlice);

  const handleVisual = () => {
    const newVisual: VisualDepType = {
      visual: view.visual === "cards" ? "table" : "cards",
    };
    dispatch(updateVisualDep(newVisual));
  };


  const handleNavSort = (clickedOrder: "title" |"resources"  | "severity") => {
    const newOrder:SortDepType = {order: clickedOrder};
    dispatch(updateSortDep(newOrder));
  };




  return {

    view,
    handleVisual,
    order,
    handleNavSort
 
  };
};
