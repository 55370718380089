import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  InputAdornment,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import {  useEffect } from "react";
import CheckboxTree from "react-checkbox-tree";

import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

import _ from "lodash";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { checkboxtree } from "../../../types";
import { useTranslation } from "react-i18next";

type Props = {
  checked: string[];
  setChecked: (check : string[]) => void;
  filtered: string;
  onChangeText : (e:any, search:checkboxtree[]) =>void;
  filteredTree: checkboxtree[] ;
  selectAllNodes : (all:boolean) => void;
  expanded: string [];
  setExpanded: (exp:string[])=> void;
  setFilteredTree: (newC : checkboxtree[]) => void;
  newNodes: checkboxtree[];
  changeFiltered : (value:string) => void


  
}

const PackageCheckBoxTree = ({checked, setChecked, filtered, onChangeText, filteredTree, selectAllNodes, expanded, setExpanded, setFilteredTree, newNodes, changeFiltered}:Props) => {
 
  useEffect(() => {
    setFilteredTree(keywordFilter(_.cloneDeep(newNodes), filtered));
  }, [filtered]);

  const {t} = useTranslation()

  const keywordFilter = (nodes: checkboxtree[], keyword: string) => {
    let newNodes = [];
    for (let n of nodes) {
      if (n.children) {
        const nextNodes = keywordFilter(n.children, keyword);
        if (nextNodes.length > 0) {
          n.children = nextNodes;
        } else if (
          typeof n.label === "string" &&
          n.value.toLowerCase().includes(keyword.toLowerCase())
        ) {
          n.children = nextNodes.length > 0 ? nextNodes : [];
        }
        if (
          nextNodes.length > 0 ||
          (typeof n.label === "string" &&
            n.value.toLowerCase().includes(keyword.toLowerCase()))
        ) {
          newNodes.push(n);
        }
      } else {
        if (
          typeof n.label === "string" &&
          n.value.toLowerCase().includes(keyword.toLowerCase())
        ) {
          newNodes.push(n);
        }
      }
    }
    return newNodes;
  };

  const getHighlightText = (text: string, keyword: string) => {
    const startIndex = text.indexOf(keyword);
    return startIndex !== -1 ? (
      <span>
        {text.substring(0, startIndex)}
        <span style={{ color: "rgb(204, 26, 0)" }}>
          {text.substring(startIndex, startIndex + keyword.length)}
        </span>
        {text.substring(startIndex + keyword.length)}
      </span>
    ) : (
      <span>{text}</span>
    );
  };

  return (
    <Box sx={{marginTop:"1.5rem"}}>
      <Box sx={{ display: "flex", marginBottom:"1rem" }}>
        <TextField
          id="outlined-basic"
          label={t("target.tree.search")}
          variant="outlined"
          InputProps={{
            endAdornment: <InputAdornment position="end">
              <IconButton onClick={()=>{changeFiltered("")}} disabled={filtered===""}>
                <SvgIcon sx={{fontSize: "1rem"}}>
                  <path d="M6.4 19.8 4.2 17.6 9.8 12 4.2 6.4 6.4 4.2 12 9.8 17.6 4.2 19.8 6.4 14.2 12 19.8 17.6 17.6 19.8 12 14.2Z" fill={filtered==="" ? "white" :"lightgrey"}/>
                </SvgIcon>
              </IconButton>
            </InputAdornment>
          }}
          size="small"
          value={filtered}
          onChange={(e) => onChangeText(e, filteredTree)}
          placeholder={t("target.tree.search")}
          sx={{padding:"0!important", marginRight: "0.5rem"}}
        />
        <ButtonGroup
          variant="contained"
          aria-label="outlined primary button group"
          size="small"
          sx={{ border:"1px solid #00bfa5 !important", "& .MuiButtonGroup-grouped:hover": { backgroundColor: "white !important", color:"#00bfa5"} }}
        >
          <Button onClick={() => selectAllNodes(true)} sx={{background: "#00bfa5",  borderRight:"1px solid white !important", color: "white"}}> <Typography>{t("target.tree.select.all")}</Typography></Button>
          <Button   onClick={() => selectAllNodes(false)} sx={{background: "#00bfa5",color: "white", }}>
          <Typography>{t("target.tree.remove.all")}</Typography>
          </Button>
        </ButtonGroup>
      </Box>
      <CheckboxTree
        nodes={filteredTree}
        checked={checked}
        expanded={expanded}
        onCheck={(check) => setChecked(check)}
        onExpand={(expand) => setExpanded(expand)}
        showNodeTitles={true}
        showExpandAll={true}
        icons={{
          check: <CheckBoxIcon sx={{ color: "#00bfa5" }} />,
          uncheck: <CheckBoxOutlineBlankIcon sx={{ color: "#00bfa5" }} />,
          halfCheck: <IndeterminateCheckBoxIcon sx={{ color: "#00bfa5" }} />,
          expandClose: <ChevronRightIcon />,
          expandOpen: <ExpandMoreIcon />,
          leaf: <></>,
          parentClose: <></>,
          parentOpen: <></>,
          collapseAll: <RemoveCircleOutlineIcon sx={{ color: "#00bfa5" }} />,
          expandAll: <AddCircleOutlineIcon sx={{ color: "#00bfa5" }} />,
        }}
      />
    </Box>
  );
};

export default PackageCheckBoxTree;
