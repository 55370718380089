import {
  Flex,
  Heading,
  TextField,
  PasswordField,
  Button,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import { useState } from "react";
type Props = {
  handleClose: () => void;
};

const ChangePassword = ({ handleClose }: Props) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [username, setUsername] = useState("");

  const { user } = useAuthenticator((context) => [context.user]);

  const handleChangePassword = async (e: any) => {
    e.preventDefault();
    if (
      user?.attributes?.email === username &&
      newPassword === newPasswordConfirm
    ) {
      try {
        await user.changePassword(oldPassword, newPassword, (err, data) => {});
      } catch (error) {
        console.log(error);
      }
      handleClose();
    } else {
      alert("Username not valid");
    }
  };

  return (
    <Flex as="form" direction="column" onSubmit={handleChangePassword}>
      <Heading level={3} style={{ color: "#3F475A", fontWeight: "bold" }}>
        Change Password
      </Heading>
      <TextField
        label="Username"
        name="username"
        autoComplete="username"
        value={username}
        onChange={(e: any) => setUsername(e.target.value)}
        hasError={user?.attributes?.email !== username}
        errorMessage="Username not valid"
        style={{ color: "#3F475A" }}
      />
      <PasswordField
        label="Current password"
        name="current_password"
        autoComplete="current-password"
        value={oldPassword}
        onChange={(e) => setOldPassword(e.target.value)}
        style={{ color: "#3F475A" }}
        hideShowPassword
      />
      <PasswordField
        label="New password"
        name="new_password"
        autoComplete="new-password"
        descriptiveText="Password must be at least 8 characters"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
        style={{ color: "#3F475A" }}
      />
      <PasswordField
        label="Confirm password"
        name="confirm_password"
        autoComplete="new-password"
        value={newPasswordConfirm}
        onChange={(e) => setNewPasswordConfirm(e.target.value)}
        hasError={newPassword !== newPasswordConfirm}
        errorMessage="Password mismatch"
        style={{ color: "#3F475A" }}
      />
      <Button variation="primary" type="submit">
        Submit
      </Button>
    </Flex>
  );
};

export default ChangePassword;

