import { DragDropContext, DropResult } from "@hello-pangea/dnd";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { dragDropStyle } from "../../../styles/styles";
import { ColumnsType } from "../../../types";
import DragNDropColumn from "./DragNDropColumn";

type Props = {
  columns: ColumnsType;
  setColumns: (col: any) => void;
};

const DragNDropContainer = ({ columns, setColumns }: Props) => {
  const { t } = useTranslation();

  const onDragEnd = ({ source, destination }: DropResult) => {
    // Make sure we have a valid destination
    if (destination === undefined || destination === null) return null;

    // Make sure we're actually moving the item
    if (
      source.droppableId === destination.droppableId &&
      destination.index === source.index
    )
      return null;

    // Set start and end variables
    const start = columns[source.droppableId as keyof typeof columns];
    const end = columns[destination.droppableId as keyof typeof columns];

    // If start is the same as end, we're in the same column
    if (start === end) {
      // Move the item within the list
      // Start by making a new list without the dragged item
      const newList = start.list.filter(
        (_: any, idx: number) => idx !== source.index
      );

      // Then insert the item at the right location
      newList.splice(destination.index, 0, start.list[source.index]);

      // Then create a new copy of the column object
      const newCol = {
        id: start.id,
        title: start.title,
        list: newList,
      };

      // Update the state
      setColumns((state: any) => ({ ...state, [newCol.id]: newCol }));
      return null;
    } else {
      // If start is different from end, we need to update multiple columns
      // Filter the start list like before
      const newStartList = start.list.filter(
        (_: any, idx: number) => idx !== source.index
      );

      // Create a new start column
      const newStartCol = {
        id: start.id,
        title: start.title,
        list: newStartList,
      };

      // Make a new end list array
      const newEndList = end.list;

      // Insert the item into the end list
      newEndList.splice(destination.index, 0, start.list[source.index]);

      // Create a new end column
      const newEndCol = {
        id: end.id,
        title: end.title,
        list: newEndList,
      };

      // Update the state
      setColumns((state: any) => ({
        ...state,
        [newStartCol.id]: newStartCol,
        [newEndCol.id]: newEndCol,
      }));
      return null;
    }
  };


  return (
    <Stack sx={{ width: "100%" }}>
      <Typography
        variant="h4"
        sx={{ textAlign: "center", width: "100%", marginTop: "1rem" }}
      >
        {t("hrdb.modal.step.mapping.title")}
      </Typography>

      <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <DragNDropColumn
            col={columns.items}
            key={columns.items.id}
            disabledDrop={
              columns.items.id === "node_id" && columns.items.list.length === 1
            }
          />
          <Box
            sx={dragDropStyle.container}
          >
            {Object.values(columns).map((col) => {
              return (
                <React.Fragment key={uuid()}>
                  {col.id !== "items" ? (
                    <DragNDropColumn
                      col={col}
                      key={col.id}
                      disabledDrop={
                        col.id === "node_id" && col.list.length === 1
                      }
                    />
                  ) : (
                    <></>
                  )}
                </React.Fragment>
              );
            })}
          </Box>
        </DragDropContext>
      </Box>
    </Stack>
  );
};

export default DragNDropContainer;
