
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Box } from '@mui/material';
import PageNav from '../../components/pagenav/PageNav';
import SimpleLoading from '../../components/SimpleLoading';
import CardDoc from '../../components/update/CardDoc';
import { useDocuments } from '../../hooks/index';




const PageDocsUpdate = () => {
  const { user } = useAuthenticator((context) => [context.user]);

  const {docSearch, loaded, handleSearchParam} = useDocuments(user.getSignInUserSession()?.getIdToken().payload.user_signature, user.getSignInUserSession()?.getIdToken().getJwtToken() || "");


  return (
    <Box className="container">
      <PageNav handleSearchParam={handleSearchParam} title="documents.title" length={docSearch.length} >
      </PageNav>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2, width: "100%" }}>

      {
          loaded? 
          <>
        
        {docSearch.map(document => (
          <CardDoc key={document.document_key} info={document} />
        ))}
        </>
        :
        <SimpleLoading />
        }

       
        </Box>
    </Box>
  )
}

export default PageDocsUpdate