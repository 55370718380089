import React, { useState, useEffect } from "react";
import { Link, useSearchParams, useLocation } from "react-router-dom";
import { useConnectors } from "../../hooks";
//import { CognitoContext } from "../../contexts/CognitoContext";
//import { ErrorContext } from "../../contexts/ErrorContext";
//import axios from "axios";
import { LinearProgress } from "@mui/material";

const PageConnectorAdd = () => {
  const { pathname } = useLocation();

  const [searchParams] = useSearchParams();
  const queryString = '{"' + decodeURI(searchParams.toString()).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}';
  const newConnectorName = pathname.split("/")[pathname.split("/").length - 1]
  const { addConnector } = useConnectors()

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    if (queryString && queryString !== "") {
      try{
        setIsLoading(true);
        addConnector({
          id: newConnectorName.toLowerCase(),
          name: newConnectorName
        })
        setIsLoading(false);
      }
      catch (error: any) {
        setIsLoading(false);
        setError(true)
      }
    }
  }, [queryString, addConnector, newConnectorName]);

  return (
    <>
      {!isLoading && !error &&
        <>
          <h1>Aggiunto connettore: {newConnectorName}</h1>
          <h1>Con token: {queryString}</h1>
        </>}
      {!isLoading && error && <p>C'è stato un errore nel caricare il connettore</p>}
      {isLoading && <LinearProgress />}
      <Link to="/connectors/list">I tuoi token!</Link>
    </>
  );
}

export default PageConnectorAdd;