import { useEffect, useState } from "react";
import fetchCards from "../api/fetchCards";
import { updateBread } from "../redux/slices/bread/breadSlice";

import { BreadContextType, CardTypeF } from "../types";
import { useAppDispatch, useAppSelector } from "./reduxHooks";


export const useTitle = ( signature:string, auth:string, scope: string, leveInfo:string, id?: string, search?: string, ) => {
  const dispatch = useAppDispatch();
  const [titleCard, setTitleCard] = useState<CardTypeF | undefined >(undefined);
  const [err, setErr] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const oldBread: BreadContextType = useAppSelector(
    (state) => state.breadSlice
  );


  const handleBread = (info?: CardTypeF) => {
   
    let newCrumbs = info? 
            info.parent_list.map((par, index) => {
              return {
                url: index === info.parent_list.length -1 ? "/company/packages?id=" + encodeURIComponent(info.complete_key) : "/company?id=" + encodeURIComponent(par),
                name: par.includes("|")? par.slice(par.lastIndexOf('|') + 1) : par
              };
            }) : []
        ;

    const newBread: BreadContextType = {
      root: [...oldBread.root],
      crumbs: [...newCrumbs],
    };

   
    return newBread;
  };

  

  useEffect(() => {
    try{
      (async()=>{
        
          const init= id ? await fetchCards(signature, auth, scope, leveInfo, id) as CardTypeF[] : undefined;
          setTitleCard(init? init[0] : undefined);
          const newBread = handleBread(init? init[0] : undefined);
         
          dispatch(updateBread(newBread));
          
          setErr(false);
          setLoading(false);
          
      })()}
      catch(error:any){
        setErr(true);
        setLoading(true);
      }
    
  }, [id]);



  return {
   titleCard,
   loading,
   err
  };
};
