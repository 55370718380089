import { useEffect } from "react";
import { BreadContextType } from "../types";
import { CardTypeF } from "./../types";

import { updateBread } from "../redux/slices/bread/breadSlice";
import { useAppDispatch, useAppSelector } from "./reduxHooks";

export const useBread = (id: string, info?: CardTypeF) => {
  const dispatch = useAppDispatch();
  const oldBread: BreadContextType = useAppSelector(
    (state) => state.breadSlice
  );
  let bread = { ...oldBread };

  if (oldBread.crumbs.length < 1 && info) {
   
    bread.crumbs =
      info?.parent_list && info.parent_list?.length > 0
        ? info.parent_list?.map((par) => {
            return {
              url: "/company?id=" + encodeURIComponent(par),
              name: par,
            };
          })
        : [];
  }

  const handleBread = () => {
    let newCrumbs = info?.first
      ? []
      : info?.parent_list
      ? info.last 
        ? info.parent_list
            .map((par) => {
              return {
                url: "/company?id=" + encodeURIComponent(par),
                name: par,
              };
            })
            .concat({
              name: info.card_key,
              url: "/company/packages?id=" + encodeURIComponent(info.complete_key),
            })
        : info.parent_list?.map((par) => {
            return {
              url: "/company?id=" + encodeURIComponent(par),
              name: par,
            };
          })
      : [];

    const newBread: BreadContextType = {
      root: [...oldBread.root],
      crumbs: [...newCrumbs],
    };

    dispatch(updateBread(newBread));
  };

  useEffect(() => {
    handleBread();
  }, [id]);

  return {
    handleBread,

    bread,
  };
};
