import axios from "axios";
import { kpisListSimpleType, hrKpiType } from './../types';

//const apiUrl: string = "https://b0svdbuld4.execute-api.eu-west-1.amazonaws.com/my-iris-alerts";
const apiUrl:string = process.env.REACT_APP_API_URL_ROOT + "/kpis"

const fetchKpis = async (user_signature: string, auth:string, resultType?:string): Promise<kpisListSimpleType[]> => {
  try {
    const response = await axios.get(apiUrl, {
      params: {
        user_signature: user_signature,
        result_type: resultType
      },
      headers: {'Authorization': auth}
    });
    if (response === undefined) throw new Error("Response is undefined");
    const result:kpisListSimpleType[] = response.data;
    return result;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 500){
        console.log(error)
        throw new Error("Internal server error")}
      if (error.response?.status === 404){
        console.log(error)
        throw new Error("The resource was not found");}
    }
    console.log(error)
    throw error;
  }
};

const fetchKpiOrg = async (user_signature: string, auth:string, resultType?:string): Promise<hrKpiType[]> => {
  try {
    const response = await axios.get(apiUrl, {
      params: {
        user_signature: user_signature,
        result_type: resultType
      },
      headers: {'Authorization': auth}
    });
    if (response === undefined) throw new Error("Response is undefined");
    const result:hrKpiType[] = response.data;
    return result;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 500){
        console.log(error)
        throw new Error("Internal server error")}
      if (error.response?.status === 404){
        console.log(error)
        throw new Error("The resource was not found");}
    }
    console.log(error)
    throw error;
  }
};

export { fetchKpis, fetchKpiOrg };