import { InitiativeType, InitiativeDetailTableType } from '../types';
import axios from "axios";

//const apiUrl: string = "https://wi4rtbm7mk.execute-api.eu-west-1.amazonaws.com/my-iris-cards";
const apiUrl:string = process.env.REACT_APP_API_URL_ROOT +"/initiatives"

const fetchInitiatives = async (user_signature: string, auth: string, levelInfo: string, initiative_key?: string, complete_key?: string, involved?: boolean): Promise<InitiativeType[]> => {
  try {
    const body:any = {
      user_signature: user_signature,
      level_info: levelInfo
    }
    if (initiative_key) body["initiative_key"] = initiative_key
    if (complete_key) body["complete_key"] = complete_key
    if (involved) body["involved"] = involved
    
    const response = await axios.get(apiUrl, {
      params: body,
      headers: {'Authorization': auth}
    });
    if (response === undefined) throw new Error("Response is undefined");
    const result:InitiativeType[] = response.data;
    return result;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 500) {
        console.log(error);
        throw new Error("Internal server error")
      }
      if (error.response?.status === 404) {
        console.log(error);
        throw new Error("The resource was not found");
      }
    }
    console.log(error)
    throw error;
  }
};

const fetchInitiativeDetails = async (user_signature: string, auth: string, levelInfo: string, initiative_key: string): Promise<InitiativeDetailTableType> => {
  try {
    const response = await axios.get(apiUrl, {
      params: {
        user_signature: user_signature,
        level_info: levelInfo,
        initiative_key: initiative_key
      },
      headers: {'Authorization': auth}
    });
    if (response === undefined) throw new Error("Response is undefined");
    const result:InitiativeDetailTableType = response.data;
    return result;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 500) {
        console.log(error);
        throw new Error("Internal server error")
      }
      if (error.response?.status === 404) {
        console.log(error);
        throw new Error("The resource was not found");
      }
    }
    console.log(error)
    throw error;
  }
};

export { fetchInitiatives, fetchInitiativeDetails };