import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { SingleMetricType } from "../../../types";
import SeverityBadge, {
  SEVERITY_BADGE_TYPES
} from "../../SeverityBadge/SeverityBadge";

import { useState } from "react";
import { FormatType, getFormat } from "../../charts/chartFormatting";
import SimpleLoading from "../../SimpleLoading";
import parse from "html-react-parser";

type Props = {
  metric?: SingleMetricType;
  loading: boolean
};

const MetricBodyHeader = ({ metric,loading }: Props) => {
  const [expanded, setExpanded] = useState(false);

  const handleAccordion = () => {
    setExpanded(!expanded);
  };

  const { t, i18n } = useTranslation();

  const myFormat = getFormat(metric?.value_format as keyof FormatType);
  

  return (
    <>
      {
        loading ? <SimpleLoading /> :
        <>
          {
            metric && <Box className="flexTop" sx={{ marginTop: "2rem", gap: "1rem"}}>
              <Box className="flexTop" sx={{ width:{ xs: "20%", sm: "20%", md: "20%", lg: "20%" } , justifyContent: "start" }}>
                <SeverityBadge
                  severity={metric ? metric.color : ""}
                  type={SEVERITY_BADGE_TYPES.indicator}
                />

                <Typography
                  lineHeight={0.8}
                  fontFamily="Barlow"
                  variant="h1"
                  fontSize={"50pt"}
                  color="ts.card.text.main"
                  fontWeight="bolder"
                >
                  {myFormat(metric?.value)}
                </Typography>
              </Box>

              <Box sx={{ width:{ xs: "25%", sm: "25%", md: "40%", lg: "40%" }}}>
                <Typography
                  fontFamily="Barlow"
                  variant="h4"
                  color="ts.text.secondary"
                  fontWeight={"bolder"}
                >
                {  parse(t(`${metric?.package_id}.${metric?.kpi_id}.title`))}
                  
                
                </Typography>
                <Typography className="subtitle2">
            
                {  parse(t(`${metric?.package_id}.${metric?.kpi_id}.subtitle`))}
                </Typography>
              </Box>

              {
                (i18n.exists(`${metric?.package_id}.${metric?.kpi_id}.importance`) && t(`${metric?.package_id}.${metric?.kpi_id}.importance`) !== '') ? <Box
                  sx={{
                    padding: "1rem",
                    paddingBottom: "0",
                    bgcolor: "ts.primary.white",
                    borderRadius: "10px",
                    width:{ xs: "25%", sm: "25%", md: "40%", lg: "40%" }
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    color="ts.card.text.main"
                    fontWeight={"bolder"}
                    fontFamily="Barlow"
                  >
                    {t("cards.metrics.importance")}
                  </Typography>
                  <Typography variant="subtitle2" color="ts.card.text.main">
                    {parse(t(`${metric?.package_id}.${metric?.kpi_id}.importance`))}
                  </Typography>
                  {
                    i18n.exists(`${metric?.package_id}.${metric?.kpi_id}.hint`) && t(`${metric?.package_id}.${metric?.kpi_id}.hint`) !== '' && <Accordion expanded={expanded} elevation={0}>
                      <AccordionSummary
                        onClick={() => {
                          handleAccordion();
                        }}
                        sx={{ padding: 0, margin: 0 }}
                      >
                        <Typography
                          fontWeight={"bolder"}
                          sx={{ textDecoration: "underline", color: "ts.avatar.text" }}
                        >
                          {expanded
                            ? t("cards.metrics.importance.hide")
                            : t("cards.metrics.importance.show")}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{ padding: 0, margin: 0, paddingBottom: "1rem" }}
                      >
                        <Typography variant="subtitle2" color="ts.card.text.main">
                          <b>{t("cards.metrics.hint")}</b>
                          {  parse(t(`${metric?.package_id}.${metric?.kpi_id}.hint`))}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  }
                </Box> : <Box
                  sx={{
                    padding: "1rem",
                    paddingBottom: "0",
                    borderRadius: "10px",
                    width:{ xs: "25%", sm: "25%", md: "40%", lg: "40%" }
                  }}
                />
              }
            </Box>
          }
        </>
      }
    </>
  );
};

export default MetricBodyHeader;
