import { Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import SearchDep from "../analyze/company-l1-deps/all-deps/nav/SearchDep";


type PropsType = {
  children: React.ReactNode;
  title: string;
  length: number;
  handleSearchParam: (param: string) => void;
};

const PageNav = ({ title, children, length, handleSearchParam }: PropsType) => {
  const { t } = useTranslation();

  return (

    <Box className="flex width100 wrap margin-bottom2"
      

    >
      <Box className="flexGap3" >
      <Box sx={{ display: "flex", gap: 0.5, alignItems: "baseline" }}>
        <Typography variant="h2">{t(title)}</Typography>
        {length > 0 && (
          <Typography variant="h4">&#40;{length}&#41;</Typography>
        )}
      </Box>
      <SearchDep handleSearchParam={handleSearchParam} />

      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default PageNav;
