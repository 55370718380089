
import { useEffect, useState } from "react";

import fetchCharts from '../api/fetchCharts';
import { ChartType } from "../types";
import { card_charts } from "../mocks/apiL3/mockup_chart";

export const useCharts = (signature:string, auth:string, scope: string, leveInfo:string, id: string , tab?:string, kpi?:string) => {
  const [charts, setCharts] = useState<ChartType[]>();
  const [onChartsError, setOnChartsError] = useState(false);
  const [chartsLoading, setChartsLoading] = useState(true);



  useEffect(() => {
    try{
      (async()=>{
      
        /*MOD20221128: Rimuovere il check sull'agile*/
        const fetchedCharts = id.toLowerCase().includes("card.package.agile") ? card_charts.filter((metric) => metric.complete_key.toLowerCase() === id.toLowerCase())[0] : await fetchCharts(signature, auth, scope, leveInfo, id);
        
        setCharts(fetchedCharts?.charts);
        setOnChartsError(false);
        setChartsLoading(false)
    })()} catch (error: any) {
      setChartsLoading(true)
      setOnChartsError(true);
      throw error;
    }
    
  }, [id]);
  
  
  
  return { charts, onChartsError, chartsLoading  };
};




