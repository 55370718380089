
import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import SinglePicker from "./SinglePicker";

type Props = {
  control: any;
  on: boolean;
};

const TargetTimePicker = ({ control, on }: Props) => {

  const {t} = useTranslation();
  
  return (
    <Box>
      <Typography
        id="input-slider"
        gutterBottom
        sx={{ color: on ? "black" : "grey" }}
      >
       { t("target.kpi.select.new")}
      </Typography>
      <Grid container spacing={2} alignItems="center" sx={{marginBottom: "0.5rem"}}>
        
                <SinglePicker control={control} on={on} name={"target_days"} initial={"d"}  max={30}/>
                <SinglePicker control={control} on={on} name={"target_hrs"} initial={"h"}  max={23}/>
                <SinglePicker control={control} on={on} name={"target_mins"} initial={"m"}  max={59}/>
      </Grid>
    {
      control.getFieldState("target_days").error  && <Typography fontSize={"10pt"} color={"error"} sx={{ maxWidth: "200px", textAlign: "center" }}>{control.getFieldState("target_days").error.message }</Typography>
    }
    {
      control.getFieldState("target_hrs").error  && <Typography fontSize={"10pt"} color={"error"} sx={{ maxWidth: "200px", textAlign: "center" }}>{control.getFieldState("target_hrs").error.message }</Typography>
    }
    {
      control.getFieldState("target_mins").error  && <Typography fontSize={"10pt"} color={"error"} sx={{ maxWidth: "200px", textAlign: "center" }}>{control.getFieldState("target_mins").error.message }</Typography>
    }
    </Box>
  );
};

export default TargetTimePicker;
