import { useAuthenticator } from "@aws-amplify/ui-react";
import { Box, Stack, SvgIcon, Tab, Tabs, Typography } from "@mui/material";
import { SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLicense, usePackages } from "../../../hooks";
import { LicenseEvalType } from "../../../types";
import SeverityBadge, { SEVERITY_BADGE_COLORS, SEVERITY_BADGE_TYPES } from "../../SeverityBadge/SeverityBadge";
import SimpleLoading from "../../SimpleLoading";
import MetricTabLicensePanel from "./MetricTabLicensePanel";

type Props = {
  metricsSev: {
    target: number;
    risk: number;
    critic: number;
  };
};

const checkLicense = (license: LicenseEvalType) => {
  let valid: boolean = false;
  let pack: string = "";
  while (!valid) {
    let i = 0;
    if (license.packages[i].valid) {
      pack = license.packages[i].id;
      valid = true;
    }
    i++;
  }
  return pack;
};

const MetricsLicenseTabs = ({ metricsSev }: Props) => {
  const { user } = useAuthenticator((context) => [context.user]);
  const { license } = useLicense();
  const [searchParams] = useSearchParams();
  const cardKey: string | undefined = searchParams.get("id") !== null ? (searchParams.get("id") as string) : "";

  const { packages, packageLoading } = usePackages(
    user.getSignInUserSession()?.getIdToken().payload.user_signature,
    user.getSignInUserSession()?.getIdToken().getJwtToken() || "",
    "card",
    "package",
    cardKey
  );

  const [value, setValue] = useState<string>(
    searchParams.get("package") || checkLicense(license)
  );

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const { t } = useTranslation();
  const nav = useNavigate();

  useEffect(() => {
    if ( searchParams.get("kpi") !== null && searchParams.get("package") != null) {
      nav(`metric?id=${encodeURIComponent(searchParams.get("id") || "")}&package=${searchParams.get("package")}&kpi=${searchParams.get("kpi")}`);
    } else {
      if (packages) {
        setValue(license.packages[0].id);
        nav(`metric?id=${encodeURIComponent(searchParams.get("id") || "")}&package=${license.packages[0].id}`);
      } 
    }
  }, [license.packages, nav, packages]);

  return (
    <>
      {packageLoading ? 
        <SimpleLoading /> :
        (
          <Stack>
            <Box
              sx={{ borderBottom: 1, borderColor: "divider" }}
              className="flex"
            >
              <Tabs
                value={value}
                onChange={handleChange}
                TabIndicatorProps={{sx: {bgcolor: "ts.sidebar.bg"}}}
                textColor="secondary"
              >
                {license.packages.map((tab, index) => (
                  <Tab
                    value={tab.id}
                    onClick={() => {
                      if (searchParams.get("package") !== tab.id) {
                        nav(`metric?id=${encodeURIComponent(searchParams.get("id") || "")}&package=${tab.id}`);
                      }
                    }}
                    disabled={/*MOD20221128: Rimuovere il check sui package list*/ (!tab.valid || (packages).filter((p) => p.package_id.toLowerCase() === tab.id.toLowerCase()).length === 0)}
                    key={index}
                    sx={{ textTransform: "none" }}
                    label={
                      <Box className="flexGap05">
                        <SeverityBadge severity={packages.filter(
                            (p) => (p.package_id === tab.id && p.complete_key === (searchParams.get("id") || "").concat("|" + tab.id)) || "")[0]?.color
                          }
                          type="license-tab"
                        />
                        <Typography
                          variant="subtitle1"
                          color={"ts.text.secondary"}
                          fontWeight={value === tab.id ? "bold" : "normal"}
                        >
                          {t(tab.title)}
                        </Typography>
                        { /*MOD20221128: Rimuovere il check sui package list*/
                          (!tab.valid || (packages).filter((p) => p.package_id.toLowerCase() === tab.id.toLowerCase()).length === 0) && (
                            <SvgIcon sx={{ color: "ts.primary.grey" }}>
                              <path d="M18 8.5H17V6.5C17 3.74 14.76 1.5 12 1.5C9.24 1.5 7 3.74 7 6.5V8.5H6C4.9 8.5 4 9.4 4 10.5V20.5C4 21.6 4.9 22.5 6 22.5H18C19.1 22.5 20 21.6 20 20.5V10.5C20 9.4 19.1 8.5 18 8.5ZM9 6.5C9 4.84 10.34 3.5 12 3.5C13.66 3.5 15 4.84 15 6.5V8.5H9V6.5ZM18 20.5H6V10.5H18V20.5ZM12 17.5C13.1 17.5 14 16.6 14 15.5C14 14.4 13.1 13.5 12 13.5C10.9 13.5 10 14.4 10 15.5C10 16.6 10.9 17.5 12 17.5Z" />
                            </SvgIcon>
                          )
                        }
                      </Box>
                    }
                  />
                ))}
              </Tabs>
              <Box className="flexGap1" sx={{ marginRight: "2rem" }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: ".3rem" }}>
                  <SeverityBadge severity={SEVERITY_BADGE_COLORS.critic} type={SEVERITY_BADGE_TYPES.summaryMetric}/>
                  <Typography
                    variant="body2"
                    fontWeight={"bolder"}
                    color="ts.text.secondary"
                  >
                    {`${t("cards.metrics.summary.critical")}: ${metricsSev.critic}`}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: ".3rem" }}>
                  <SeverityBadge severity={SEVERITY_BADGE_COLORS.risk} type={SEVERITY_BADGE_TYPES.summaryMetric}/>
                  <Typography
                    variant="body2"
                    fontWeight={"bolder"}
                    color="ts.text.secondary"
                  >
                    {`${t("cards.metrics.summary.risk")}: ${metricsSev.risk}`}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: ".3rem" }}>
                  <SeverityBadge severity={SEVERITY_BADGE_COLORS.target} type={SEVERITY_BADGE_TYPES.summaryMetric}/>
                  <Typography
                    variant="body2"
                    fontWeight={"bolder"}
                    color="ts.text.secondary"
                  >
                    {`${t("cards.metrics.summary.target")}: ${metricsSev.target}`}
                  </Typography>
                </Box>
              </Box>
            </Box>
            {license.packages.map((tab, index) => (
              <MetricTabLicensePanel
                valueLicense={value}
                licPackages={license.packages}
                index={tab.id}
                key={index}
              />
            ))}
          </Stack>
        )
      }
    </>
  );
};

export default MetricsLicenseTabs;
