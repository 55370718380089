import { Box, Grid, Stack } from "@mui/material";
import { useSearchParams } from "react-router-dom";


import "../../styles/global.css";

import { useAuthenticator } from "@aws-amplify/ui-react";
import TableDeps from "../../components/analyze/company-l1-deps/all-deps/nav/TableDeps";
import TableOfs from "../../components/analyze/company-l2-offices/TableOfs";
import TitlePageTree from "../../components/analyze/TitlePageTree";
import CardBluePrint from "../../components/cards/CardBluePrint";
import CardDep from "../../components/cards/dep/CardDep";
import CardOffice from "../../components/cards/office/CardOffice";
import Loading from "../../components/Loading";
import { useCardsF } from "../../hooks/useCardsF";
import { useTitle } from "../../hooks/useTitle";

const PageCards = () => {
  const { user } = useAuthenticator((context) => [context.user]);
  const [searchParams] = useSearchParams();

  const cardKey: string | undefined =
    searchParams.get("id") !== null
      ? (searchParams.get("id") as string)
      : undefined;
  const { newCards, loaded, view, handleSearchParam } = useCardsF(
    user.getSignInUserSession()?.getIdToken().payload.user_signature,
    user.getSignInUserSession()?.getIdToken().getJwtToken() || "",
    "children",
    "card",
    cardKey
  );
  const { titleCard, loading } = useTitle(
    user.getSignInUserSession()?.getIdToken().payload.user_signature,
    user.getSignInUserSession()?.getIdToken().getJwtToken() || "",
    "card",
    "card",
    cardKey
  );

  const treeCards= newCards.filter( (card)=>!card.last);
  const leafCards= newCards.filter( (card)=>card.last);

  return (
    <Box className="container">
      <Stack spacing={6}>
        <Box>
          {!loading ? (
            <TitlePageTree
            handleSearchParam={handleSearchParam}
              first={cardKey ? false : true}
              length={newCards.length}
              title={cardKey ? "cards.offices" : "cards.all"}
              name={titleCard?.parent_list?.at(-1)}
              children={titleCard?.child_number}
              resources={titleCard?.resources}
              color={titleCard?.color}
            />
          ) : (
            <Loading />
          )}

          {loaded ? (
            <>
              {view.visual === "cards" ? (
                <Grid container spacing={3}>
                  {newCards.map((card, index) => (
                    <Grid item key={"gridItemDeps" + index}>
                      <CardBluePrint key={index} severity={card.color}>
                        {card.last ? (
                          <CardOffice key={"card" + index} cardInfo={card} />
                        ) : (
                          <CardDep key={"card" + index} cardInfo={card} />
                        )}
                      </CardBluePrint>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <>
                  {treeCards.length > 0 && <TableDeps cardInfo={treeCards} />}
                  {leafCards.length > 0 && <TableOfs cardInfo={leafCards} />}
                </>
              )}
            </>
          ) : (
            <Loading />
          )}
        </Box>
      </Stack>
    </Box>
  );
};

export default PageCards;
