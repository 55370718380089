
import { Box, Grid, Input, Slider, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

type Props = {
  value?: number |  Array<number >;
  onChange?: (e: any, n: any) => void;
  handleInputChangeSlider?: (e: any) => void;
  handleBlur?: () => void;
  on: boolean;
  control: any;
  name: string;
  label: string;
  setSliderValue: any;
};

const TargetSlider = ({

  on,
  control,
  name,
  setSliderValue,
}: Props) => {
  const [sliderValue, setSlider] = useState<number>(30);
  const [sliderValueInput, setSliderInput] = useState<number>(sliderValue);

  useEffect(() => {
    if (sliderValue) setSliderValue(name, sliderValue as number | [number]);
    
  }, [sliderValue, sliderValueInput]);

  const handleChange = (event: any, newValue: number | number[]) => {
    
    setSlider(  newValue as number);
    setSliderInput(newValue as number);
  };

  const handleChangeInput = (event: any) =>{
    setSlider(  event.target.value as number);
    setSliderInput(event.target.value as number);

  }

  const {t} = useTranslation()

  return (
    <Box sx={{ width: 250 }}>
      <Typography
        id="input-slider"
        gutterBottom
        sx={{ color: on ? "black" : "grey" }}
      >
        { t("target.kpi.select.new")}
      </Typography>
      <Stack>
     
        <Controller
          name={name}
          control={control}
          render={({ field, fieldState, formState }) => (
            <>
             <Grid container spacing={2} alignItems="center">
              <Grid item xs>
                <Slider
                  name="target_percentage"
                  id="sliderPercentage"
                  value={sliderValue}
                  onChange={handleChange}
                  sx={{ color: "#00bfa5" }}
                  disabled={!on}
                />
              </Grid>
              <Grid item sx={{ display: "flex" }}>
                <Input
                  value={sliderValueInput}
                  error={fieldState.error?.message? true : false}
                  onChange={handleChangeInput}
                  name="target_percentage_input"
                  id="inputPercentageInput"
                  size="small"
                  disabled={!on}
                  inputProps={{
                    step: 10,
                    min: 0,
                    max: 100,
                    type: "number",
                    "aria-labelledby": "input-slider",
                  }}
                />

                <Typography sx={{ color: on ? "black" : "grey" }}>%</Typography>
              </Grid>
              </Grid>
              {fieldState.error?.message &&
                <Typography fontSize={"10pt"} color={"error"}>{fieldState.error?.message}</Typography>}

            </>
          )}
        />
     
      </Stack>
    </Box>
  );
};

export default TargetSlider;
