import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import pathIdentifier from "./pathIdentifier";

type Props = {
  location: string;
};

const Breadcrumb = ({ location }: Props) => {
  const { t } = useTranslation();
  const { bread1, bread2, bread3 } = pathIdentifier(location);

  return (
    <Box sx={{ padding: "1rem", display: "flex", gap: ".5rem" }}>
      {bread1.name !== "" && (
        <Link
          style={{ textDecoration: "none", color: "inherit" , pointerEvents: bread2.name === "" ? "none" : "auto" }}
          to={`/${bread1.url}`}
          onClick={bread2.name === "" ? e => e.preventDefault() : ()=>{}}
        >
          <Typography
            sx={{
              textDecoration: "none",
              fontWeight: bread2.name === "" ? "bolder" : "normal",
              ":hover": {
                textDecoration: "underline",
              },
            }}
          >
            {t(bread1.name)}
          </Typography>{" "}
        </Link>
      )}
      {bread2.name !== "" && (
        <Link
          style={{
            textDecoration: "none",
            color: "inherit",
            display: "flex",
            gap: ".5rem",
            pointerEvents: bread3.name === "" ? "none" : "auto"
          }}
          color="inherit"
          to={`/${bread1.url}/${bread2.url}`}
          onClick={bread3.name === "" ? e => e.preventDefault() : ()=>{}}
        >
          &gt;{" "}
          <Typography
            sx={{
              textDecoration: "none",
              fontWeight: bread3.name === "" ? "bolder" : "normal",
              ":hover": {
                textDecoration: "underline",
              },
            }}
          >
            {t(bread2.name)}
          </Typography>{" "}
        </Link>
      )}
      {bread3.name !== "" && (
        <Link
          style={{
            textDecoration: "none",
            color: "inherit",
            display: "flex",
            gap: ".5rem",
            pointerEvents:   "none" 
          }}
          color="inherit"
          to={`/${bread1.url}/${bread2.url}/${bread3.url}`}
          onClick={e => e.preventDefault() }
        >
          &gt;
          <Typography
            sx={{
              textDecoration: "none",
              fontWeight: "bolder",
              ":hover": {
                textDecoration: "underline",
              },
            }}
          >
            {t(bread3.name)}
          </Typography>
        </Link>
      )}
    </Box>
  );
};

export default Breadcrumb;
