import axios from "axios";
import { InitiativeType } from "../types";

//const apiUrl: string = "https://qp9slmiux0.execute-api.eu-west-1.amazonaws.com/my-iris-dictionary";
const apiUrl: string =
process.env.REACT_APP_API_URL_ROOT + "/initiatives"

const putInitiative = async (user_signature: string, auth:string, initiativeInfo: InitiativeType): Promise<any> => {
  try {
    const response = await axios.put(apiUrl,
      {
        user_signature: user_signature,
        ...initiativeInfo
      },
      { headers: {'Authorization': auth} }
    );
    if (response === undefined) throw new Error("Response is undefined");
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 500)
        throw new Error("Internal server error");
      if (error.response?.status === 404)
        throw new Error("The resource was not found");
    }
    throw error;
  }
};

export default putInitiative;
