import { Typography } from '@mui/material'
import React from 'react'
import { Draggable } from "@hello-pangea/dnd"
import { dragDropStyle } from '../../../styles/styles';
import { capitalizeString } from '../../../util/capitalizeString';


type ItemProps = {
  text: string;
  index: number;
}



const DragNDropItem = ({ text, index }:ItemProps) => {

   
  return (
    <Draggable draggableId={text} index={index} key={text} >
      {provided => (
        <Typography ref={provided.innerRef } {...provided.draggableProps}
        {...provided.dragHandleProps}
        key={text}
        sx={dragDropStyle.item}>
       
          {capitalizeString(text)}
          </Typography>
       
      )}
    </Draggable>
  )
}

export default DragNDropItem
