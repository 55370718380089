import { CardTypeF } from "../../../../types";

const sortingWizard: (cards: CardTypeF[], method: string) => CardTypeF[] = (
  cards: CardTypeF[],
  method: string
) => {
  switch (method) {
    case "title": {
      return cards.sort((a: CardTypeF, b: CardTypeF) => {
        if (a.card_key < b.card_key) {
          return -1;
        }
        if (a.card_key > b.card_key) {
          return 1;
        }
        return 0;
      });
    }

    case "offices": {
      return cards.sort((a: CardTypeF, b: CardTypeF) => {
        if ((a.child_list?.length || 0 )< ( b.child_list?.length || 0)) {
          return 1;
        }
        if ((a.child_list?.length || 0 )> ( b.child_list?.length || 0)) {
          return -1;
        }
        return 0;
      });
    }

    case "resources": {
      return cards.sort((a: CardTypeF, b: CardTypeF) => {
        if (a.resources < b.resources) {
          return 1;
        }
        if (a.resources > b.resources) {
          return -1;
        }
        return 0;
      });
    }

    
    case "severity": {
      return cards.sort((a: CardTypeF, b: CardTypeF) => {
        if (a.score === null) {
          a.score = Number.MAX_VALUE;
        }

        if (b.score === null) {
          b.score = Number.MAX_VALUE;
        }

        if (a.score < b.score) {
          return -1;
        }
        if (a.score > b.score) {
          return 1;
        }
        return 0;
      });
    }
  }

  return cards;
};

export default sortingWizard;
