import { useCallback, useEffect, useState } from "react";

import fetchAlerts from "../api/fetchAlerts";
import { AlertType } from "../types";

export const useAlerts = (signature:string, auth: string, cat?:string) => {
  const [alerts, setAlerts] = useState<AlertType[]>([]);
  const [alertSearch, setAlertSearch] = useState<AlertType[]>([]);

  const [searchParam, setSearchParam] = useState<string>("");
  const [onError, setOnError] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect( () => {
    try{
    (async()=>{
      
        const init= cat ? await fetchAlerts(signature, auth, cat) as AlertType[] : await fetchAlerts(signature, auth ) as AlertType[];
        setAlerts(init);
        setAlertSearch(init);
        setOnError(false);
        setLoaded(true);
        
    })()}
    catch(error:any){
      setOnError(true);
      setLoaded(false);
    }
   
}, [])



  const handleSearchParam = (param: string) => {
    setSearchParam(param);
  };

  const handleSearch = useCallback(() => {
    setAlertSearch(
      alerts.filter((alert) =>
        alert.title.toLocaleLowerCase().includes(searchParam)
      )
    );
  }, [alerts, searchParam]);

  useEffect(() => {
    let mounted = true;

    if (mounted) handleSearch();

    return () => {
      mounted = false;
    };
  }, [searchParam, handleSearch]);

  return { alertSearch, handleSearchParam, loaded, onError };

};
