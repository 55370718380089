
import { Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useTranslation } from "react-i18next";

import { CardTypeF } from "../../../../../types";
import TableDepRow from "./TableDepRow";

type Props = {
  cardInfo: CardTypeF[];

};

const TableDeps = ({ cardInfo }: Props) => {
  const {t} = useTranslation();
  

  return (
    <Table
      aria-label="collapsible table"
      
    >
      <TableHead >
        <TableRow sx={{backgroundColor:"ts.primary.white"}}>
          <TableCell />
          <TableCell sx={{ minWidth: "10%" }}>
            
              <Typography sx={{color: "text.primary",
                fontWeight: "bolder",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: ".5rem",}}>{t("card.l1.table.header.l1")}</Typography>
             
          
          </TableCell>
          <TableCell align="center" sx={{ minWidth: "10%" }}>
            
              <Typography sx={{color: "text.primary",
                fontWeight: "bolder",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: ".5rem",}}>{t("card.l1.table.header.l2")}</Typography>
       
          
          </TableCell>
          <TableCell align="center" sx={{ minWidth: "10%" }}>
           
              <Typography sx={{color: "text.primary",
                fontWeight: "bolder",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: ".5rem",}}>{t("card.l1.table.header.resources")}</Typography>
          
          </TableCell>
          <TableCell align="center" sx={{ minWidth: "10%" }}>
          <Typography sx={{color: "text.primary",
                fontWeight: "bolder",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: ".5rem",}}>  {t("card.l1.table.header.initiatives")}</Typography>
            
       
          </TableCell>
          <TableCell align="center" sx={{ minWidth: "10%" }}>
           
              <Typography sx={{color: "text.primary",
                fontWeight: "bolder",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: ".5rem",}}>{t("card.l1.table.header.critical")}</Typography>
           
          </TableCell>
          <TableCell align="center" sx={{ minWidth: "10%" }}>
           
        
              <Typography sx={{color: "text.primary",
                fontWeight: "bolder",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: ".5rem",}}>{t("card.l1.table.header.score")}</Typography>
          
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {cardInfo.map((dep, index) => (
          <TableDepRow key={index} card={dep} />
        ))}
      </TableBody>
    </Table>
  );
};

export default TableDeps;
