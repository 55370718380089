import { Box, Stack } from "@mui/material";
import SuggestedInitiatives from "../../components/monitor/SuggestedInitiatives";
import Initiatives from "../../components/monitor/Initiatives";

const PageInitiatives = () => {
  return (
      <Box className="container" >
        <Stack className="full-width" spacing={2}>
          <SuggestedInitiatives />
          <Initiatives />
        </Stack>
      </Box>
  );
};

export default PageInitiatives;