import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { getColor } from "./paletteChart";

type Props = {
  data: any;
  options: any;
};

const Matrix = ({ data, options }: Props) => {
  const titles = data.titles
  const datas = data.datas
  return (
      <>
        {data &&
          <Table sx={{ backgroundColor: getColor(options.header_background_color), borderRadius: "10px 10px 0 0"}}>
            <TableHead>
              <TableRow>
              {
                titles && titles.map((titleData: any, i: number) =>
                  <TableCell key={i} sx={{ fontWeight: "bold" }}>
                    {titleData}
                  </TableCell>
                )               
              }
              </TableRow>
            </TableHead>
            <TableBody>
            {
              datas && datas.map((rowDatas: any[], i: number) =>
                options["highlight_row"].includes(i) ?
                <TableRow key={i} sx={{ border: "solid 2px " + getColor(options.border_color) }}>
                  {rowDatas.map((cellData: any, i: number) =>
                    <TableCell sx={{ backgroundColor: getColor(options.background_color) }} key={i}>{cellData}</TableCell>
                  )}
                </TableRow> :
                <TableRow key={i} >
                  {rowDatas.map((cellData: any, i: number) =>
                    <TableCell sx={{ backgroundColor: getColor(options.background_color) }} key={i}>{cellData}</TableCell>
                  )}
                </TableRow>
              )
            }
            </TableBody>
          </Table>
        }
      </>
  );
}
export default Matrix;