
import { Grid, Input,  Typography } from "@mui/material";
import { Controller } from "react-hook-form";

type Props = {
  control: any;
  on: boolean;
  name:string;
  initial: string;
  max:number
};

const TargetTimePicker = ({ control, on, name, initial, max }: Props) => {
  return (

        <Grid item xs sx={{ display: "flex" }}>
          <Controller
            name={name}
            control={control}
            render={({
                 field, fieldState, formState 
            }) => (
              <>
                <Input
                  size="small"
                  disabled={!on}
                  value={field.value}
                  error={fieldState.error?.message? true : false}
                  onChange={field.onChange}
                  name={name}
                  inputProps={{
                    step: 1,
                    min: 0,
                    max: max,
                    type: "number",
                    
                  }}
                />
                <Typography sx={{ color: on ? "black" : "grey" }}>{initial}</Typography>
               
              </>
            )}
          />
        </Grid>

        
  );
};

export default TargetTimePicker;
