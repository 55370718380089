import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Box,
  CssBaseline,
  Drawer,
  IconButton,
  Toolbar,
  useTheme
} from "@mui/material";
import { useState } from "react";
import { appBar } from "../../styles/styles";
import AccountSection from "./side-navigation/AccountSection";
import AppBar from "./side-navigation/AppBar";
import DrawerHeader from "./side-navigation/DrawerHeader";
import Logo from "./side-navigation/Logo";
import Main from "./side-navigation/MainContent";
import Sidebar from "./side-navigation/Sidebar";
import Topbar from "./Topbar";

const drawerWidth = 250;

type Props = {
  children: React.ReactNode;
  pathname: string;
};

const NavDrawer = ({ pathname, children }: Props) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar elevation={1} position="fixed" open={open} sx={appBar}>
        <Toolbar sx={appBar}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Topbar pathname={pathname} />
        </Toolbar>
      </AppBar>
      <Drawer sx={styleDrawer} variant="persistent" anchor="left" open={open}>
        <Box sx={{ width: "100%" }}>
          <DrawerHeader className="fixedTop">
            <Logo />
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon sx={{ color: "ts.sidebar.text.active" }} />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Sidebar />
        </Box>
        <AccountSection />
      </Drawer>

      <Main open={open}>
        <Box
          sx={{
            display: { xl: "grid" },
            placeItems: { xl: "center" },
            marginTop: "5rem",
          }}
        >
          <Box
            id="main-container"
            sx={{
              width: {
                xs: "100%",
                xl: "1536px",
              },
            }}
          >
            {children}
          </Box>
        </Box>
      </Main>
    </Box>
  );
};

export default NavDrawer;

const styleDrawer = {
  width: drawerWidth,
  height: "100%",
  flexShrink: 0,
  "& .MuiDrawer-paper": {
    width: drawerWidth,
    boxSizing: "border-box",
    bgcolor: "ts.sidebar.bg",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "space-between",
  },
};

//bgcolor: "ts.sidebar.bg",
