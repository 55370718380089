import { Box, Button, Typography } from "@mui/material";
import SuggestedInitiativesCard from "../../components/monitor/SuggestedInitiativesCard";
import AddIcon from "@mui/icons-material/Add";
import { initiativeStyle } from "../../styles/styles";
import Heading from "../Heading";
import { useSuggestedInitiatives } from "../../hooks/index";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import InitiativesModal from "./InitiativesModal";
import { useAuthenticator } from "@aws-amplify/ui-react";

const SuggestedInitiatives = () => {
  /*MOD20230111: Rimuovere la useAuth e l'argomento da useSuggestedInitiatives*/
  const { user } = useAuthenticator((context) => [context.user]);
  const { t } = useTranslation();
  /*MOD20230111: Rimuovere la useAuth e l'argomento da useSuggestedInitiatives*/
  const { suggested } = useSuggestedInitiatives(user.attributes ? user.attributes['custom:company'] : '');
  const [openModal, setOpenModal] = useState(false);

  return (
    <Box sx={initiativeStyle.suggested.container}>
      <Heading title="initiatives.suggested.title" />
      <Box sx={initiativeStyle.suggested.row}>
        {suggested.map((init, index) => (
          <SuggestedInitiativesCard init={init} key={index} />
        ))}
        <Box sx={initiativeStyle.suggested.card.outline}>
          <Button sx={initiativeStyle.suggested.card.outlineButton}
            onClick={() => setOpenModal(true)}
          >
            <AddIcon />
            <Typography variant="subtitle2" sx={initiativeStyle.suggested.card.outlineText}>
              {t("initiatives.suggested.card.button.inline")}
            </Typography>
          </Button>
        </Box>
      </Box>
      <InitiativesModal openModal={openModal} setOpenModal={setOpenModal}/>
    </Box>
  );
};

export default SuggestedInitiatives;