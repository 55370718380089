import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { faqAccordion } from "../../styles/styles";
import { useTranslation } from "react-i18next";
import { FaqType } from "../../types";

type Props = {
  faq: FaqType;
};

const FaqAccordion = ({ faq }: Props) => {
  const { t } = useTranslation();
  return (
    <div>
      <Accordion sx={faqAccordion.row}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="h6 bold">{t(faq.question)}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={faqAccordion.expand}>
          <Typography variant="h6">{t(faq.answer)}</Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default FaqAccordion;
