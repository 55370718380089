import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";

import checkLocation from "../util/checkLocation";
import AllDepsFilterNav from "./analyze/company-l1-deps/all-deps/nav/AllDepsFilterNav";

import AllDepsPagination from "./analyze/company-l1-deps/all-deps/nav/AllDepsPagination";

type Props = {
  location: string;
  handleSearchParam?: (param: string) => void;
  handleVisual: () => void;
  view: "cards" | "table";
  order: "title" | "resources" | "severity";
  handleOrder: (paramr: "title" | "resources" | "severity") => void;
  handleOrderOfs?: (paramr: "title" | "resources" | "severity") => void;
};

const TopBarNav = ({
  handleVisual,
  view,
  order,
  handleOrder,
  handleOrderOfs,
}: Props) => {
  const locale = useLocation();

  return (
    <>
      {checkLocation(locale.pathname) && (
        <>
          <Box className="flexGap05">
            {/*<Search handleSearchParam={handleSearchParam} /> */}
          </Box>

          <Box className="flex">
            <AllDepsFilterNav order={order} handleOrder={handleOrder} />
            <AllDepsPagination visual={view} handleVisual={handleVisual} />
          </Box>
        </>
      )}
    </>
  );
};

export default TopBarNav;
