import { yupResolver } from "@hookform/resolvers/yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { CircularProgress, Stack } from "@mui/material";

import * as yup from "yup";

import { useForm } from "react-hook-form";
import TargetInputControl from "./TargetInputControl";
import TargetSliderControl from "./TargetSliderControl";
import TargetTimePickerControl from "./TargetTimePickerControl";
import TurnOnAuto from "./TurnOnAuto";
import TurnOnKpi from "./TurnOnKpi";
import { FormInput, kpisListType } from "../../../types";
import { useTranslation } from "react-i18next";

type Props = {
  selectedKpi: kpisListType;
  disableButton:boolean;
  checked:string[];
  kpiOn:boolean;
  manOn:boolean;
  handleChangeKpi: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeMan: (event: React.ChangeEvent<HTMLInputElement>) => void;
  changeTarget:(data:FormInput) => void;
  loadingButton: boolean;
  changeLoadingButton: (newB:boolean) => void
};



const initValues: FormInput = {
  sliderValue: 0,
  target_days: 1,
  target_hrs: 1,
  target_mins: 30,
  target_input: 5,
  target_percentage: 0,
  target_percentage_input: 0,
};

const TargetForm = ({ selectedKpi,disableButton, checked, manOn, kpiOn, handleChangeKpi, handleChangeMan, changeTarget, loadingButton, changeLoadingButton }: Props) => {
  const {t} = useTranslation()

  const schema = yup.object().shape(
    {
      "target_input": yup
        .number()
        .typeError(t("target.form.error.nan"))
        .when("target_input", (val, schema) => {
          if (selectedKpi.format === "" && val === "" && kpiOn && manOn) {
            return yup
              .number()
              .typeError(t("target.form.error.nan"))
              .required(t("target.form.error.required"))
              .positive(t("target.form.error.positive"))
              .min(0);
          } else {
            return yup.number().typeError(t("target.form.error.nan")).notRequired();
          }
        }),
      "target_percentage": yup
        .number()
        .when("target_percentage", (val, schema) => {
          if (val) {
            return yup
              .number()
              .typeError(t("target.form.error.nan"))
              .required(t("target.form.error.required"))
              .positive(t("target.form.error.positive"))
              .max(100, t("target.form.error.percentage"));
          } else {
            return yup.number().typeError(t("target.form.error.nan")).notRequired();
          }
        }),
      "target_days":yup
      .number()
      .when("target_days", (val, schema) => {
        if (val) {
          return yup
            .number()
            .typeError(t("target.form.error.nan"))
            .positive(t("target.form.error.positive"))
            .integer(t("target.form.error.integer"))
            .max(30, t("target.form.error.days"));
        } else {
          return yup.number().typeError(t("target.form.error.nan")).notRequired();
        }
      }),
      "target_hrs":yup
      .number()
      .when("target_hrs", (val, schema) => {
        if (val) {
          return yup
            .number()
            .typeError(t("target.form.error.nan"))
            .positive(t("target.form.error.positive"))
            .integer(t("target.form.error.integer"))
            .max(23, t("target.form.error.hrs"));
        } else {
          return yup.number().typeError(t("target.form.error.nan")).notRequired();
        }
      }),
      "target_mins":yup
      .number()
      .typeError(t("target.form.error.nan"))
      .when("target_mins", (val, schema) => {
        if (val) {
          return yup
            .number()
            .typeError(t("target.form.error.nan"))
            .positive(t("target.form.error.positive"))
            .integer(t("target.form.error.integer"))
            .max(59, t("target.form.error.mins"));
        } else {
          return yup.number().typeError(t("target.form.error.nan")).notRequired();
        }
      }),
    },
    [
      ["target_input", "target_input"],
      ["target_percentage", "target_percentage"],
      ["target_days", "target_days"],
      ["target_hrs", "target_hrs"],
      ["target_mins", "target_mins"],
    ]
  );

  const methods = useForm<FormInput>({
    defaultValues: initValues,
    resolver: yupResolver(schema),
  });
  const { handleSubmit,  control, setValue, formState:{errors}} = methods;



  const onSubmit = (data: FormInput) => {
  
    changeLoadingButton(true)
    changeTarget(data)
  };

  return (
    <Stack
      sx={{
        marginTop: "1.5rem",
        display: "flex",
        alignItems: "center",
      }}
      spacing={2}
    >
      <TurnOnKpi checked={kpiOn} onChange={handleChangeKpi} />

      <TurnOnAuto checked={manOn} onChange={handleChangeMan} on={kpiOn} />
      {selectedKpi.format === ":addPercent" ? (
        <TargetSliderControl
          on={kpiOn && manOn}
          control={control}
          name={"target_percentage"}
          label={"Target Percentage"}
          setSliderValue={setValue}
        />
      ) : selectedKpi.format === "" ? (
        <TargetInputControl
          kpiOn={kpiOn && manOn}
          control={control}
          name={"target_input"}
        />
      ) : (
        <TargetTimePickerControl on={kpiOn && manOn} control={control} />
      )}

      <LoadingButton
        size="small"
        disabled={!disableButton}
        onClick={handleSubmit(onSubmit)}
        loading={loadingButton}
        loadingIndicator={
          <CircularProgress size={26} sx={{ color: "white" }} />
        }
        variant="outlined"
        sx={{
          fontSize: "1.2rem",
          padding: "0.5rem 1rem",
          borderRadius: "10px",
          textTransform: "none",
          color: "white ",
          fontWeight: "bold",
          backgroundColor: disableButton ?   "#00bfa5" : "lightgrey",
          border: "1px solid #00bfa5",
          marginBottom: "1rem",
          maxWidth: "20%",
          minWidth: "250px",

          ":hover": {
                color: "#00bfa5 !important",
                borderColor: "#00bfa5 !important",
                backgroundColor: "white !important",
              },
            
        }}
      >
        {t("target.form.submit")}
      </LoadingButton>
    </Stack>
  );
};

export default TargetForm;
