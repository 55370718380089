import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Collapse,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {CardTypeF } from "../../../../../types";
import SeverityBadge, {SEVERITY_BADGE_COLORS} from "../../../../SeverityBadge/SeverityBadge";

type RowProps = {
  card: CardTypeF;
};

const TableDepRow = ({ card }: RowProps) => {
  const [open, setOpen] = useState(false);
  
  
  const {t} = useTranslation();

  const nav = useNavigate();

  const handleClick = () => {
   
     

    nav(
      `/company?id=${encodeURIComponent(card.complete_key)}`
    );
  
  }

  return (
    <>
      <TableRow sx={{backgroundColor:"ts.card.bg", "& > *": { borderBottom: "unset" } }}  >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell
         onClick={handleClick}
          component="th"
          scope="row"
          align="center"
          sx={{ cursor: "pointer" }}
        >
          {card.parent_list?.at(-1)}
        </TableCell>

        <TableCell
          align="center"
          onClick={handleClick}
          sx={{ cursor: "pointer" }}
        >
          {card.child_list?.length}
        </TableCell>
        <TableCell
          align="center"
          onClick={handleClick}
          sx={{ cursor: "pointer" }}
        >
          {card.resources}
        </TableCell>
        <TableCell
          align="center"
          onClick={handleClick}
          sx={{ cursor: "pointer" }}
        >
          { card.initiatives}
       
        </TableCell>
        <TableCell
          align="center"
          onClick={handleClick}
          sx={{ cursor: "pointer" }}
        >{`${
          card.child_list?.filter((office) => office.color === SEVERITY_BADGE_COLORS.critic)
            .length
        }/${card.child_list?.length}`}</TableCell>
        <TableCell
          align="center"
          sx={{ minWidth: "12%", cursor: "pointer" }}
          onClick={handleClick}
        >
          <Box
            sx={{
              width: "100%",
              display: "grid",
              placeItems: "center",
            }}
          >
            <SeverityBadge severity= {card.color} type={"table"} />
          </Box>
        </TableCell>
      </TableRow>
      <TableRow
        sx={{ bgcolor: "ts.card.chip", cursor: "pointer" }}
        
      >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography
                variant="subtitle2"
                fontWeight={"bolder"}
                gutterBottom
                component="div"
              >
                {t("card.l1.table.details.label")}
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Stack>
                        {card.child_list?.map((office) => (
                          <Box
                            key={office.id}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: ".5rem",
                            }}
                          >
                            {/** TODO: Controllare match colori con il mock */ }
                            <Box
                              sx={{
                                bgcolor:
                                  office.color === SEVERITY_BADGE_COLORS.critic
                                    ? "ts.severity.critic"
                                    : office.color === SEVERITY_BADGE_COLORS.risk
                                    ? "ts.severity.risk"
                                    : "ts.severity.target",
                                width: "10px",
                                height: "10px",
                                borderRadius: "50%",
                              }}
                            ></Box>
                            <Typography variant="body2">
                              {office.id}
                            </Typography>
                          </Box>
                        ))}
                      </Stack>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody></TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default TableDepRow;
