import { Box, Button, Typography, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import CardDocLabel from "./CardDocLabel";
import CardDocBody from "./CardDocBody";
import { cardDocStyle } from "../../styles/styles";
import { DocumentType } from "../../types";

type PropsType = {
  info: DocumentType
}

const CardDoc = ({info}: PropsType) => {
  const { t } = useTranslation();

  return (
    <Box sx={cardDocStyle.main.blueprint}>
      <Box>
        <CardDocLabel date={info.date} category={info.category}/>
        <CardDocBody title={info.title} author={info.author} abstract={info.abstract} />
      </Box>
      <Button variant="text" color="success" sx={cardDocStyle.main.button} disabled>
        <Typography variant="subtitle2" sx={cardDocStyle.main.buttonText}>
          <Link target="_blank" rel="noopener" variant="inherit" color="inherit" underline="none" href={info.url}>
          {t("documents.card.download")}
          </Link>
        </Typography>
      </Button>
    </Box>
  );
};

export default CardDoc;
