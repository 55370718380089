
import { Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useTranslation } from "react-i18next";
import { CardTypeF } from "../../../types";
import TableOfRow from "./TableOfRow";


type Props = {
  cardInfo: CardTypeF[];

};

const TableOfs = ({ cardInfo }: Props) => {

  const {t} = useTranslation();
  


  return (
    <Table
      aria-label="collapsible table"
      
    >
      <TableHead >
        <TableRow sx={{backgroundColor:"ts.primary.white"}}>
        <TableCell align="center" sx={{ minWidth: "10%" }}>
           
        
           <Typography sx={{color: "text.primary",
             fontWeight: "bolder",
             width: "100%",
             display: "flex",
             alignItems: "center",
             justifyContent: "center",
             gap: ".5rem",}}>{t("card.l2.table.header.score")}</Typography>
       
       </TableCell>
          <TableCell sx={{ minWidth: "10%" }}>
            
              <Typography sx={{color: "text.primary",
                fontWeight: "bolder",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: ".5rem",}}>{t("card.l2.table.header.l2")}</Typography>
             
          
          </TableCell>
         
          <TableCell align="center" sx={{ minWidth: "10%" }}>
           
              <Typography sx={{color: "text.primary",
                fontWeight: "bolder",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: ".5rem",}}>{t("card.l2.table.header.resources")}</Typography>
          
          </TableCell>
          <TableCell align="center" sx={{ minWidth: "10%" }}>
          <Typography sx={{color: "text.primary",
                fontWeight: "bolder",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: ".5rem",}}> {t("card.l2.table.header.initiatives")}</Typography>
            
       
          </TableCell>
          <TableCell align="center" sx={{ minWidth: "10%" }}>
           
              <Typography sx={{color: "text.primary",
                fontWeight: "bolder",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: ".5rem",}}>{t("card.l2.table.header.metrics")}</Typography>
           
          </TableCell>
      
        </TableRow>
      </TableHead>
      <TableBody>
        {cardInfo.map((of, index) => (
          <TableOfRow key={index} card={of} />
        ))}
      </TableBody>
    </Table>
  );
};

export default TableOfs;
