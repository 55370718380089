import { Box, Button, ButtonGroup, Popover, SvgIcon, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { SingleMetricType } from "../../../types";
import InitiativesAction from "./InitiativesAction";
import MetricAlerts from "./MetricAlerts";
import { useLicense } from "../../../hooks";

type Props = {
  metric: SingleMetricType;
};

const MetricActions = ({ metric }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { license } = useLicense();

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  //const initiatives: InitiativeButtonKpi[] = filterInitiatives(metric);

  const { t } = useTranslation();

  return (
    <Box className="flex">
      <Box className="flexGap05">
        <SvgIcon sx={{color:"ts.primary.black"}}>
          <path d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM19 19H5V5H19V19ZM7 10H9V17H7V10ZM11 7H13V17H11V7ZM15 13H17V17H15V13Z"/>
        </SvgIcon>
        <Typography variant="subtitle1" fontFamily="Barlow" fontWeight="bold">
          {t("cards.metrics.details")}
        </Typography>
      </Box>
      <Box className="flexGap1">
        {/*<ButtonGroup
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          disabled
          variant="contained"
          sx={{ borderRadius: "10px", height: "1.7rem" }}
          color={"info"}
        >
          <Button
            variant="contained"
            sx={{ borderRadius: "5px", paddingX: "1.2rem", color:"ts.primary.white" }}
          >
            <SvgIcon sx={{ padding: ".1rem", color:"ts.primary.white" }}>
              <path d="M18 15V18H6V15H4V18C4 19.1 4.9 20 6 20H18C19.1 20 20 19.1 20 18V15H18ZM17 11L15.59 9.59L13 12.17V4H11V12.17L8.41 9.59L7 11L12 16L17 11Z"/>
            </SvgIcon>
          </Button>
          <MetricAlerts metric={metric} />
        </ButtonGroup>*/}
        {license.sidebar?.monitoring?.filter(item => item.id === 'monitoring.initiatives').length > 0 && <InitiativesAction scope={metric.card_key} metric={metric.package_id + "|" + metric.kpi_id}/>}
      </Box>
      <Popover
        id="mouse-over-popover"
        sx={{
          maxWidth: "20rem",
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 2, textAlign: "center", bgcolor: "lavenderblush", }}>
          {t("cards.metrics.actions.disabled")}
        </Typography>
      </Popover>
    </Box>
  );
};

export default MetricActions;