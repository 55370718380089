import { Box, TableCell, TableRow } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { CardTypeF } from "../../../types";
import CardMetricsGraph from "../../cards/office/CardMetricsGraph";
import SeverityBadge from "../../SeverityBadge/SeverityBadge";

type RowProps = {
  card: CardTypeF;
};

const TableDepRow = ({ card }: RowProps) => {
  const nav = useNavigate();


  const handleClick = () => {
    nav(`/company/packages?id=${encodeURIComponent(card.complete_key)}`);
  };

  return (
    <>
      <TableRow
        sx={{
          backgroundColor: "ts.card.bg",
          "& > *": { borderBottom: "unset" },
        }}
      >
        <TableCell
          align="center"
          sx={{ minWidth: "12%", cursor: "pointer" }}
          onClick={handleClick}
        >
          <Box
            sx={{
              width: "100%",
              display: "grid",
              placeItems: "center",
            }}
          >
            <SeverityBadge severity={card.color} type={"table"} />
          </Box>
        </TableCell>
        <TableCell
          onClick={handleClick}
          component="th"
          scope="row"
          align="center"
          sx={{ cursor: "pointer" }}
        >
          {(card.parent_list?.at(-1)|| "").includes("|")? (card.parent_list?.at(-1)|| "").slice((card.parent_list?.at(-1)|| "").lastIndexOf('|') + 1) : card.parent_list?.at(-1)}
        </TableCell>

        <TableCell
          align="center"
          onClick={handleClick}
          sx={{ cursor: "pointer" }}
        >
          {card.resources}
        </TableCell>
        <TableCell
          align="center"
          onClick={handleClick}
          sx={{ cursor: "pointer" }}
        >
          {card.initiatives}
        </TableCell>
        <TableCell
          align="center"
          onClick={handleClick}
          sx={{ cursor: "pointer" }}
        >
          <CardMetricsGraph cardInfo={card} />
        </TableCell>
      </TableRow>
    </>
  );
};

export default TableDepRow;
