import { Button } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { initiativesAction } from "../../../styles/styles";
import MetricInitiativeModal from "./MetricInitiativeModal";

type Props = {
  scope: string;
  metric: string;
};

const InitiativesAction = ({ scope, metric } : Props) => {
  const [openMetricModal, setOpenMetricModal] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Button
        variant="contained"
        color={"success"}
        sx={initiativesAction.actions.button}
        onClick={() => setOpenMetricModal(true)}
      >
        {t("cards.metrics.manage.open")}
      </Button>
      <MetricInitiativeModal openModal={openMetricModal} setOpenModal={setOpenMetricModal} scope={scope} metric={metric}/>
    </>
  );
};

export default InitiativesAction;