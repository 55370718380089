import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { initiativeStyle } from "../../styles/styles";

const InitiativesGridHeader = () => {

  const {t} = useTranslation();

  return (
    <Grid container spacing={1} sx={initiativeStyle.grid.header.container}>
      <Grid item xs="auto" sm="auto" md={12} sx={initiativeStyle.grid.header.nested}>
        <Grid container spacing={0} >
          <Grid item xs={3} sm={3} md={3} lg={3}  >
            {t("initiatives.initiative.row.header.name")}
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3}  >
            {t("initiatives.initiative.row.header.description")} 
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2}  >
            {t("initiatives.initiative.row.header.owner")}
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={1} >
            {t("initiatives.initiative.row.header.start")}
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={1}  >
            {t("initiatives.initiative.row.header.end")}
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2}  >
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InitiativesGridHeader;
