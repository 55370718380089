import { useAuthenticator } from "@aws-amplify/ui-react";
import { Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import { SyntheticEvent, useEffect, useState } from "react";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { useMetrics } from "../../../hooks";
import { useSelectedKpi } from "../../../hooks/useSelectedKpi";
import { metricTabStyle } from "../../../styles/styles";
import { PackageEvalType } from "../../../types";
import SimpleLoading from "../../SimpleLoading";
import MetricVertTab from "./MetricVertTab";
import { sortBySeverity } from "./utils/sortSeverity";

type Props = {
  valueLicense: string;
  licPackages: PackageEvalType[];
};

const MetricsTabs = ({ valueLicense, licPackages }: Props) => {
  const [searchParams] = useSearchParams();
  const [value, setValue] = useState<string | undefined>(undefined);

  const nav = useNavigate();
  const { user } = useAuthenticator((context) => [context.user]);

  const callKey: string | undefined =
    searchParams.get("id") !== null
      ? (searchParams
          .get("id")
          ?.concat("|" + searchParams.get("package")) as string)
      : "";

   

  const { metrics, metricLoading } = useMetrics(
    user.getSignInUserSession()?.getIdToken().payload.user_signature,
    user.getSignInUserSession()?.getIdToken().getJwtToken() || "",
    "card",
    "kpi",
    callKey
  );



  const filteredMetrics = metrics;

  const { handleClick, selection } = useSelectedKpi();

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    const resultMetrics = sortBySeverity(filteredMetrics);

    const existingVertical = selection.filter(
      (pack) => pack.vertical === searchParams.get("package")
    );

    if (existingVertical.length > 0) {
      setValue(searchParams.get("kpi") || existingVertical[0].selected);
      nav(
        `metric?id=${encodeURIComponent(
          searchParams.get("id") || ""
        )}&package=${encodeURIComponent(
          searchParams.get("package") || ""
        )}&kpi=${encodeURIComponent(existingVertical[0].selected)}`
      );
    } else {
      if (resultMetrics.length > 0) {
        setValue(searchParams.get("kpi") || resultMetrics[0].kpi_id);

        if (searchParams.get("kpi") === null && resultMetrics.length > 0) {
          nav(
            `metric?id=${encodeURIComponent(
              searchParams.get("id") || ""
            )}&package=${encodeURIComponent(
              searchParams.get("package") || ""
            )}&kpi=${encodeURIComponent(resultMetrics[0].kpi_id)}`
          );
          
        }
      }
   
    }


  }, [metrics, searchParams.get("package")]);

  return (
    <>
      {metricLoading && value === undefined ? (
        <SimpleLoading />
      ) : (
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: "ts.card.bg",
            display: "flex",
            height: "100%",
            borderRadius: "10px",
          }}
        >
          {value && (
            <>
              <Tabs
                orientation="vertical"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={metricTabStyle.stack}
                TabIndicatorProps={{
                  sx: {
                    bgcolor: "rgba(0, 0, 0, 0)",
                  },
                }}
              >
                {sortBySeverity(
                  filteredMetrics.filter(
                    (met) => met.package_id === valueLicense
                  )
                ).map((metric, index) => (
                  <Tab
                    value={metric.kpi_id}
                    disabled={
                      metric.kpi_status && metric.kpi_status === "disabled"
                        ? true
                        : false
                    }
                    onClick={() => {
                      handleClick(metric.package_id, metric.kpi_id);
                      nav(
                        `metric?id=${encodeURIComponent(
                          searchParams.get("id") || ""
                        )}&package=${metric.package_id}&kpi=${metric.kpi_id}`
                      );
                    }}
                    key={index}
                    sx={{
                      textTransform: "none",
                      margin: 0,
                      padding: "0px",
                      width: "100%",
                      height: "100%",
                    }}
                    label={
                      <MetricVertTab
                        metric={metric}
                        value={value ? value : ""}
                        index={metric.kpi_id}
                        disabled={
                          metric.kpi_status && metric.kpi_status === "disabled"
                            ? true
                            : false
                        }
                      />
                    }
                  />
                ))}
              </Tabs>

              <Outlet context={filteredMetrics} />
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default MetricsTabs;
