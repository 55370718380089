import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import { Box, Typography } from "@mui/material";
import { cardDocStyle } from "../../styles/styles";


type PropsType ={
  date: string,
  category: string
}

const CardDocLabel = (props:PropsType) => {
 

  return (
    <Box sx={cardDocStyle.label.container}>
      <Box  sx={cardDocStyle.label.category}>
        <FeedOutlinedIcon fontSize={"small"} color="success" />
        <Typography variant="subtitle1">
          {props.category}
        </Typography>
      </Box>
      <Box sx={cardDocStyle.label.date}>
        <CalendarTodayOutlinedIcon fontSize={"small"} color="success" />
        <Typography variant="body2">{props.date}</Typography>
      </Box>
    </Box>
  );
};

export default CardDocLabel;
