import { Box } from "@mui/material";
import { SEVERITY_BADGE_COLORS } from "../SeverityBadge/SeverityBadge";

type Props = {
  children: React.ReactNode;
  severity: string;
};

const CardBluePrint = ({ children, severity }: Props) => {
  return (
    <Box
      id="card-bp"
      sx={{
        minHeight: 300,
        borderRadius: "12px",
        bgcolor: "ts.card.bg",
        width: 222,
        position: "relative",
        marginBottom: "1rem",
        boxShadow: "10px 10px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <>
        <Box
          sx={{
            ...badgeStyle,
            borderRightColor:
              severity === SEVERITY_BADGE_COLORS.target
                ? "ts.severity.target"
                : severity === SEVERITY_BADGE_COLORS.risk
                ? "ts.severity.risk"
                : severity === SEVERITY_BADGE_COLORS.critic
                ? "ts.severity.critic"
                : severity === SEVERITY_BADGE_COLORS.alert
                ? "ts.severity.alert"
                : "ts.severity.disabled",
            borderTopColor:
              severity === SEVERITY_BADGE_COLORS.target
                ? "ts.severity.target"
                : severity === SEVERITY_BADGE_COLORS.risk
                ? "ts.severity.risk"
                : severity === SEVERITY_BADGE_COLORS.critic
                ? "ts.severity.critic"
                : severity === SEVERITY_BADGE_COLORS.alert
                ? "ts.severity.alert"
                : "ts.severity.disabled",
          }}
        />
        {children}
      </>
    </Box>
  );
};

export default CardBluePrint;

const badgeStyle = {
  position: "absolute",
  right: 0,
  width: 0,
  height: 0,
  borderTop: "25px solid #555",
  borderBottom: "25px solid transparent",
  borderLeft: "20px solid transparent",
  borderRight: "20px solid #555",
  borderTopRightRadius: "12px",
};

