import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import fetchFaq from "../api/fetchFaq";
import { FaqType } from "../types";

export const useFaq = ( signature: string, auth: string) => {
  const [faqs, setFaqs] = useState<FaqType[]>([]);
  const [faqSearch, setFaqSearch] = useState<FaqType[]>([]);
  const [onError, setOnError] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect( () => {
    try{
    (async()=>{
        const init=await fetchFaq(signature, auth) as FaqType[];
        setFaqs(init);
        setFaqSearch(init);
        setOnError(false);
      setLoaded(true);
      
        
    })()}
    catch(error:any){
      setOnError(true);
      setLoaded(false);

    }
   
}, [])

  const [searchParam, setSearchParam] = useState<string>("");

  const { t } = useTranslation();

  const handleSearchParam = (param: string) => {
    setSearchParam(param);
  };

  const handleSearch = useCallback(() => {
    setFaqSearch(
      faqs.filter((faq) =>
        t(faq.question).toLocaleLowerCase().includes(searchParam)
      )
    );
  }, [faqs, searchParam, t]);

  useEffect(() => {
    let mounted = true;

    if (mounted) handleSearch();

    return () => {
      mounted = false;
    };
  }, [searchParam, handleSearch]);

  return { faqSearch, handleSearchParam, loaded, onError };
};
