//TODO: File json su S3 da prendere ed importare!

export type PaletteType = {
  ":target": string;
  ":target_transparent": string;
  ":generic": string;
  ":generic_transparent": string;
  ":border": string;
  ":survey_aa": string;
  ":survey_ab": string;
  ":survey_ba": string;
  ":survey_bb": string;
  ":basic": string;
  ":basic_light": string;
  ":neutral": string;
  ":neutral_light": string;
  ":critical": string;
  ":risk": string;
  ":target_green": string;
  ":gray": string;
  ":chart_text": string;
  ":white": string;
  ":matrix_header": string;
};

export const paletteChartRules: PaletteType = {
  ":target": "#1EE3CF",
  ":target_transparent": "#1EE3CF33",
  ":generic": "#376fd0",
  ":generic_transparent": "#376fd033",
  ":border": "#fafafa",
  ":survey_aa": "#376fd033",
  ":survey_ab": "#1EE3CF33",
  ":survey_ba": "#a0a0a033",
  ":survey_bb": "#344b7233",
  ":basic": "#a0a0a0",
  ":basic_light": "#fafafa",
  ":neutral": "#a0a0a0",
  ":neutral_light": "#fafafa",
  ":critical": "#CC1A00",
  ":risk": "#EB9B00",
  ":target_green": "#2DB517",
  ":gray": "#e5e5e5",
  ":chart_text": "#0F1421aa",
  ":white": "#ffffff",
  ":matrix_header": "#D2E8F9"
};

export const getColor = (color: keyof PaletteType) => {
  return paletteChartRules[color];
};
