import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";


type Props = {
  title: string;
length?: number;};

const Heading = ({ title, length }: Props) => {
  const { t } = useTranslation();

  return (
   
    <Box sx={{ display: "flex", gap: 0.5, alignItems: "baseline" }} className="margin-bottom2">
    <Typography variant="h2">{t(title)}</Typography>
    {length  && length > 0 && (
      <Typography variant="h4">&#40;{length}&#41;</Typography>
    )}
  </Box>
  );
};

export default Heading;
