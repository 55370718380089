import { matchPath } from "react-router-dom";

const checkLocation = (location: string) => {

  const paths = ['/departments/:department', '/departments', "/company"];
const matched = paths.find(path => matchPath(path, location));


    if(matched!=null ){
      return true;
    }
    return false;
  }

  export default checkLocation;