import { Box, Chip, Typography } from "@mui/material";
import logo from "../../../images/logo.svg";
import { logoStyle } from "../../../styles/styles";

type Props = {};

const Logo = (props: Props) => {
  return (
    <Box sx={{ display: "grid", placeItems: "center", width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: ".5rem",
        }}
      >
        <Box>
          <img src={logo} alt="logo" height={"34px"} className="logo"></img>
        </Box>

        <Typography
          color="ts.sidebar.text.active"
          fontSize="14pt"
          
          fontFamily="Poppins"
          sx={{marginLeft:"4px"}}
        >
          MyIRIS
        </Typography>
        <Chip
          label="By Teamsight"
          sx={logoStyle.chip}
        />
      </Box>
    </Box>
  );
};

export default Logo;
