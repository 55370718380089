import { MetricTypeF } from "../../../../types";

export const sortBySeverity = (metrics : MetricTypeF[]) => {
  return metrics.sort((a:  MetricTypeF, b:  MetricTypeF) => {
    if (a.score === null) {
      a.score = Number.MAX_VALUE;
    }

    if (b.score === null) {
      b.score = Number.MAX_VALUE;
    }

    if (a.score < b.score) {
      return -1;
    }
    if (a.score > b.score) {
      return 1;
    }
    return 0;
  });
};
