import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Routing from "./routing/Routing";
import ColorContextProvider from "./themes/ColorContextProvider";
import { Provider } from "react-redux"; // Import del provider
import store from "./redux/store"; // Store di contesto con tutte le info per questa App
// import i18n init
import "./i18n";

import { Amplify } from "aws-amplify";
import aws_export from "./aws-exports";
import { Authenticator } from "@aws-amplify/ui-react";
import Loading from "./components/Loading";

Amplify.configure(aws_export);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Authenticator.Provider>
      <Suspense fallback={<Loading />}>
        <Provider store={store}>
          <ColorContextProvider>
            <Routing />
          </ColorContextProvider>
        </Provider>
      </Suspense>
    </Authenticator.Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
