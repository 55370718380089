import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Search from "../Search";
import SeverityBadge, {
  SEVERITY_BADGE_TYPES,
} from "../SeverityBadge/SeverityBadge";

type Props = {
  title: string;
  length: number;
  first: boolean;
  name?: string;
  children?: number;
  resources?: number;
  handleSearchParam: (search: string) => void;
  color?: string;
};

const TitleCards = ({
  title,
  length,
  first,
  name,
  children,
  resources,
  color,
  handleSearchParam,
}: Props) => {
  const { t } = useTranslation();

  

  return (
    <>
      {!first && name && (
        <Box className="margin-bottom2">
          <Box className="flexGap1">
            <SeverityBadge
              severity={color}
              type={SEVERITY_BADGE_TYPES.circle}
            />
            <Box className="flexColumn">
              <Typography
                variant={"h2"}
                fontWeight={"bolder"}
                fontFamily="Barlow"
              >
                {name.includes("|")? name.slice(name.lastIndexOf('|') + 1) : name}
             
              </Typography>
              <Typography variant={"h5"} sx={{ fontWeight: "normal" }}>
                {`${children} ${t("cards.offices")} | ${resources} ${t(
                  "cards.resources"
                )}`}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}

      <Box className="margin-bottom2 flex">
        <Box sx={{ display: "flex", gap: 0.5, alignItems: "baseline" }}>
          <Typography variant={first ? "h2" : "h4"}>{t(title)}</Typography>
          {length > 0 && (
            <Typography variant={first ? "h4" : "h5"}>
              &#40;{length}&#41;
            </Typography>
          )}
        </Box>
        <Search  handleSearchParam={handleSearchParam}/>
      </Box>
    </>
  );
};

export default TitleCards;
