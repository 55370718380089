import { Box, Button, Modal, SvgIcon, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import { initiativesAction, initiativeStyle } from "../../../styles/styles";
import InitiativesModal from "../../monitor/InitiativesModal";
import { useState } from "react";
import InitiativesGridHeader from "../../monitor/InitiativesGridHeader";
import Loading from "../../Loading";
import InitiativesGridRow from "../../monitor/InitiativesGridRow";
import { useAppDispatch, useInitiatives } from "../../../hooks";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { InitiativeType } from "../../../types";
import { updateInitiativeTemplateGeneric } from "../../../redux/slices/initiative/initiativeTemplateSlice";
import dayjs from 'dayjs';

type Props = {
  scope: string;
  metric: string;
  openModal: boolean;
  setOpenModal: (status:boolean) => void;
};

const MetricInitiativeModal = ({ scope, metric, openModal, setOpenModal }: Props) => {
  const dispatch = useAppDispatch();
  const { user } = useAuthenticator((context) => [context.user]);
  const [openInitModal, setOpenInitModal] = useState(false);
  
  console.log(scope +"|" + metric);
  
  const { initiativesSearch, onError, loaded } = useInitiatives(
    user.getSignInUserSession()?.getIdToken().payload.user_signature,
    user.getSignInUserSession()?.getIdToken().getJwtToken() || "",
    "detail",
    undefined,
    scope +"|" + metric,
    true
  );

  const handelOpenInitModal = () => {
    const initForm : InitiativeType = {
      initiative_key: "",
      status: "active",
      title: "",
      description: "",
      owner: user.attributes?.email || "",
      insert_date: "",
      starting_date: dayjs(new Date()).format("YYYY-MM-DD"),
      ending_date: "",
      color: "",
      score: 0,
      scope: [scope],
      kpis: [metric],
    };
    dispatch(updateInitiativeTemplateGeneric(initForm));
    setOpenInitModal(true);
  }

  const { t } = useTranslation();

  return (
    <>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={initiativesAction.actions.modal.list.container}>
          <Box sx={initiativesAction.actions.modal.list.box}>
            <Typography
              fontWeight="bolder"
              id="modal-modal-title"
              variant="h4"
              component="h2"
            >
              {t("cards.metrics.manage.title")}
            </Typography>
            <Button onClick={() => setOpenModal(false)} sx={{ color: "ts.primary.black" }} >
              <SvgIcon>
                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
              </SvgIcon>
            </Button>
          </Box>
          { !loaded && !onError && <Loading /> } 
          { loaded && !onError && initiativesSearch.length > 0 &&
            <Box sx={initiativeStyle.main.containerRow}>
              <InitiativesGridHeader />
              {initiativesSearch.map((init) => 
                <InitiativesGridRow init={init} key={init.initiative_key} />
              )}
            </Box>
          }
          { loaded && !onError && initiativesSearch.length === 0 &&
            <Box className="flexEven" sx={{ paddingBottom: "10px" }}>{t("initiatives.metric.modal.no.initatives")}</Box>
          }
          <Box className="flexEven">
            <Button
              variant="contained"
              color={"success"}
              sx={initiativesAction.actions.modal.list.button}
              onClick={handelOpenInitModal}
            >
              <Box className="flexEven">
                <AddIcon />
                <Typography
                  fontWeight="bold"
                  sx={{ paddingLeft: "5px", paddingRight: "2px" }}
                >
                  {t("initiatives.suggested.card.button.inline")}
                </Typography>
              </Box>
            </Button>
          </Box>
        </Box>
      </Modal>
      <InitiativesModal openModal={openInitModal} setOpenModal={setOpenInitModal}/>
    </>
  );
};

export default MetricInitiativeModal;