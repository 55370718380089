
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { VisualDepType } from "../../../types";
import { initVisual } from "./initVisual";




// Slice con action e reducers.
const visualDepSlice = createSlice({
  name: "search", // Nome della slice
  initialState: initVisual, // Valore iniziale vuoto
  // Reducer con le azioni che si possono fare sulla slice (in questo caso solo una)
  reducers: {
    updateVisualDep(state, action: PayloadAction<VisualDepType>) {      
      return  action.payload;
    }
  },
});

// Action generate che verrano utilizzate per aggiornare lo store.
export const { updateVisualDep } = visualDepSlice.actions;

export default visualDepSlice.reducer;
