import axios from "axios";
import { LicenseType } from "../types";
//const apiUrl: string = process.env.REACT_APP_LICENSE_API_ENDPOINT || "";
//const apiUrl: string = "https://f0q5e4w2ld.execute-api.eu-west-1.amazonaws.com/my-iris-license"
const apiUrl:string = process.env.REACT_APP_API_URL_ROOT + "/license"
const fetchLicense = async (user_signature: string, auth:string): Promise<LicenseType> => {


  try {
    const response = await axios.get(apiUrl, {
        params: 
        {
          user_signature:user_signature,
        },
        headers: {'Authorization': auth},
      });

     if (response === undefined) throw new Error("Response is undefined");
     if (typeof response.data === 'string') throw new Error(response.data);
      
     const result:LicenseType = response.data;
     return result;
   } catch (error: any) {
     if (axios.isAxiosError(error)) {
       if (error.response?.status === 500){
       console.log(error)
        throw new Error("Internal server error")}
       if (error.response?.status === 404){
        console.log(error)
         throw new Error("The resource was not found");}
    }
    console.log(error)
     throw error;
  }

};

export default fetchLicense;
