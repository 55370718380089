import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import putInitiative from '../api/putInitiative';
import { InitiativeType } from '../types';
import { useAppSelector } from './reduxHooks';

export const useInitiativeModal = (signature: string, auth: string) => {
  const {t} = useTranslation();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [isFormCompiled, setIsFormCompiled] = useState<boolean[]>([false, false]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [onErrorPut, setOnErrorPut] = useState<boolean>(false);

  const storedTemplate: InitiativeType | null = useAppSelector(
    (state) => state.initiativeTemplateSlice
  );

  const onFormStatusChange = (index: number, compiled: boolean) => {
    setIsFormCompiled((prevState) => {
      const newState = Object.assign([], prevState) as boolean[];
      newState[index] = compiled;
      return newState
    });
  };

  const handleForward = () => {setActiveStep((prevActiveStep) => prevActiveStep + 1)};
  const handleBack = () => {setActiveStep((prevActiveStep) => prevActiveStep - 1)};

  const handleCreate = () => {
    if (storedTemplate) {
      setIsLoading(true);
      createInitative(storedTemplate);
    }
  }

  const handleCloseSnack = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };

  const changeLoadingButton = (newStatus:boolean ) => {
    setIsLoading(newStatus);
  }

  const steps = [
    {name: "initiative.create.modal.steps.step1.label"},
    {name: "initiative.create.modal.steps.step2.label"},
    {name: "initiative.create.modal.steps.step4.label"},
    {name: "initiative.create.modal.steps.step5.label"}
  ];

  const buttonsConfig = [
    {
      buttons: [{
        label: "initiative.create.modal.button.forward",
        onClick: handleForward,
        disabled: !isFormCompiled[0]
      }]
    },
    {
      buttons: [
        {
          label: "initiative.create.modal.button.forward",
          onClick: handleForward,
          disabled: !isFormCompiled[1]
        },
        {
          label: "initiative.create.modal.button.backward",
          onClick: handleBack
        }
      ]
    },
    {
      buttons: [
        {
          label: "initiative.create.modal.button.create",
          onClick: handleCreate,
          type: "loadingButton",
          isLoading: isLoading
        },
        {
          label: "initiative.create.modal.button.backward",
          onClick: handleBack,
          disabled: isLoading
        }
      ]
    },
    {
      buttons: []
    }
  ]

  const createInitative = async (storedTemplate: InitiativeType) => {
    try {
      const response = await putInitiative(signature, auth, storedTemplate);
      if(typeof response === "string")
      {
        setOnErrorPut(true);
        changeLoadingButton(false)
        setSnackMessage(response)
        setOpenSnack(true)
      }
      if(response === 200)
      {
        setActiveStep(3);
        changeLoadingButton(false)
        setOnErrorPut(false);
      }
    } catch (error: any) {
      setOnErrorPut(error);
      changeLoadingButton(false)
      setSnackMessage(t("generic.error.message"))
      setOpenSnack(true)
      throw error;
    }
  };
  
  return { steps, activeStep, buttonsConfig, onFormStatusChange, openSnack, snackMessage, handleCloseSnack, onErrorPut, setActiveStep }
}