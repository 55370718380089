import { Box, Stack, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from 'dayjs';
import 'dayjs/locale/it';
import 'dayjs/locale/en';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { updateInitiativeTemplateGeneric } from "../../../redux/slices/initiative/initiativeTemplateSlice";
import { InitiativeType } from "../../../types";
import { initativeModal } from "../../../styles/styles"
import { useAuthenticator } from "@aws-amplify/ui-react";

type Props = {
  onFormStatusChange: (index: number, compiled: boolean) => void;
};

const GenericData = ({ onFormStatusChange }: Props) => {
  const { i18n, t } = useTranslation();
  const { user } = useAuthenticator((context) => [context.user]);
  const dispatch = useAppDispatch();
  
  const storedTemplate: InitiativeType = useAppSelector(
    (state) => state.initiativeTemplateSlice
  ) || {
    initiative_key: "",
    status: "active",
    title: "",
    description: "",
    owner: user.attributes?.email || "",
    insert_date: "",
    starting_date: dayjs(new Date()).format("YYYY-MM-DD"),
    ending_date: "",
    color: "",
    score: 0,
    scope: [],
    kpis: [],
  };

  const [initForm, setInitForm] = useState<InitiativeType>(storedTemplate);

  const updateInitForm = <Key extends keyof InitiativeType>(attribute: Key, value: InitiativeType[Key]) => {
    setInitForm((prevState) => {
      const newState: InitiativeType = {
        ...prevState
      } ;
      newState[attribute] = value;
      return newState;
    });
  };

  useEffect(()=>{
    dispatch(updateInitiativeTemplateGeneric(initForm));
    if (
      initForm.title.trim().length >= 5 &&
      initForm.starting_date != 'Invalid Date' &&
      initForm.ending_date != 'Invalid Date' &&
      initForm.starting_date <= initForm.ending_date
    ) onFormStatusChange(0, true);
    else onFormStatusChange(0, false);
  },[initForm])

  return (
    <Stack sx={{ width: "50%", height: "100%", marginTop: "2rem" }} spacing={3}>
      <Box>
        <Typography variant="h4">
          {t("initiative.wizard.step1.title")}
        </Typography>
        <Typography variant="body1">
          {t("initiative.wizard.step1.description")}
        </Typography>
      </Box>
      <TextField
        size="small"
        label={t("initiative.wizard.step1.title_label")}
        value={initForm.title}
        onChange={(e) => updateInitForm("title", e.target.value)}
        fullWidth
        sx={initativeModal.GenericData}
      />
      <TextField
        size="small"
        label={t("initiative.wizard.step1.description_label")}
        value={initForm.description}
        onChange={(e) => updateInitForm("description", e.target.value)}
        fullWidth
        minRows={3}
        maxRows={5}
        multiline
        sx={initativeModal.GenericData}
      />
      <Stack spacing={3}>
        <TextField
          disabled
          size="small"
          value={initForm.owner}
          fullWidth
          label={
            <Box className="flexGap05">
              <Typography variant="body1">
                {t("initiative.wizard.step1.owner_label")}
              </Typography>
            </Box>
          }
          sx={initativeModal.GenericData}
        />
        <Box className="flex" sx={{ width: "100%" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.resolvedLanguage}>
            <DatePicker
              label={t("initiative.wizard.step1.start_label")}
              value={initForm.starting_date}
              minDate={dayjs("2000-01-01")}
              disableFuture
              onChange={(date) => updateInitForm("starting_date", dayjs(date).format('YYYY-MM-DD'))}
              renderInput={(params) => (
                <TextField size="small" {...params} sx={initativeModal.GenericData} />
              )}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.resolvedLanguage}>
            <DatePicker
              label={t("initiative.wizard.step1.end_label")}
              value={initForm.ending_date}
              minDate={dayjs(initForm.starting_date)}
              maxDate={dayjs("2050-12-31")}
              onChange={(date) => updateInitForm("ending_date", dayjs(date).format('YYYY-MM-DD'))}
              renderInput={(params) => (
                <TextField size="small" {...params} sx={initativeModal.GenericData} />
              )}
            />
          </LocalizationProvider>
        </Box>
      </Stack>
    </Stack>
  );
};

export default GenericData;