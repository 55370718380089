import { useEffect, useState } from "react";
import fetchCards from "../api/fetchCards";
import sortingWizard from "../components/analyze/company-l1-deps/all-deps/sorting";
import { updateVisualDep } from "../redux/slices/visualDep/visualDepSlice";
import { CardTypeF, VisualDepType } from "../types";
import { useAppDispatch, useAppSelector } from "./reduxHooks";

export const useCardsF = ( signature:string, auth:string, scope: string, leveInfo:string, id?: string ) => {
  const order: "title" | "resources" | "severity" = useAppSelector(
    (state) => state.sortingDepSlice.order
  );
  const dispatch = useAppDispatch();

  const view: VisualDepType = useAppSelector((state) => state.visualDepSlice);

  

  const [searchParam, setSearchParam] = useState( "");
  const [newCards, setNewCards] = useState<CardTypeF[]>([]);
  const [treeCards, setTreeCards] = useState<CardTypeF[]>([]);
  const [leafCards, setLeafCards] = useState<CardTypeF[]>([]);
  const [onError, setOnError] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);


//TODO: il filtro va fatto sulla traduzione del card_key, adesso è messo sui nomi attuali e controlla per la presenza di pipe
  useEffect(() => {
    try{
      (async()=>{
        
          const init= id !== null ? await fetchCards(signature, auth, scope, leveInfo, id) as CardTypeF[] : await fetchCards(signature, auth, scope, leveInfo ) as CardTypeF[];
          const filteredCards = searchParam !== "" ? sortingWizard(init, order).filter((single)=>(single.card_key.includes("|")? single.card_key.slice(single.card_key.lastIndexOf('|') + 1) : single.card_key).toLowerCase().includes(searchParam.toLowerCase())) : sortingWizard(init, order)
          setNewCards(filteredCards);
          setTreeCards(init.filter((card)=>!card.last))
          setLeafCards(init.filter((card)=>card.last))
          setOnError(false);
          setLoaded(true);
          
      })()}
      catch(error:any){
        setOnError(true);
        setLoaded(false);
      }
    
  }, [id,order,searchParam]);


  const handleVisual = () => {
    const newVisual: VisualDepType = {
      visual: view.visual === "cards" ? "table" : "cards",
    };
    dispatch(updateVisualDep(newVisual));
  };



  const handleSearchParam = (param: string) => {
    setSearchParam(param);
  };


  return {
    newCards,
    loaded,
    view,
    treeCards,
    leafCards,
    handleSearchParam,
    handleVisual,
    onError
  };
};
