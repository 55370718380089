import { Box, Typography } from "@mui/material";
//import { useTranslation } from "react-i18next";
import SeverityBadge, {
  SEVERITY_BADGE_TYPES,
} from "../../SeverityBadge/SeverityBadge";
//import { SEVERITY_BADGE_COLORS } from "../../SeverityBadge/SeverityBadge";

type Props = {
  officeName: string;
  severity: string;

};

const Header = ({ officeName, severity }: Props) => {
  //const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexWrap: "wrap",
      }}
    >
      <Box className="flexGap1">
        <SeverityBadge severity={severity} type={SEVERITY_BADGE_TYPES.circle} />
        <Typography variant={"h4"} >
          {officeName}
        </Typography>
      </Box>
{/* FIXME: Ho spostato questo pezzo sotto per maggiore chiarezza utente, lo lascio in caso si voglia tornare indietro 
      <Box className="flexGap1" sx={{ marginRight: "2rem" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: ".3rem" }}>
          <SeverityBadge
            severity={SEVERITY_BADGE_COLORS.critic}
            type={SEVERITY_BADGE_TYPES.summaryMetric}
          />

          <Typography
            variant="body2"
            fontWeight={"bolder"}
            color="ts.text.secondary"
          >
            {`${t("analyze.metrics.summary.critical")}: ${metrics.critic}`}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: ".3rem" }}>
          <SeverityBadge
            severity={":yellow"}
            type={SEVERITY_BADGE_TYPES.summaryMetric}
          />

          <Typography
            variant="body2"
            fontWeight={"bolder"}
            color="ts.text.secondary"
          >
            {`${t("analyze.metrics.summary.risk")}: ${metrics.risk}`}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: ".3rem" }}>
          <SeverityBadge
            severity={":green"}
            type={SEVERITY_BADGE_TYPES.summaryMetric}
          />
          <Typography
            variant="body2"
            fontWeight={"bolder"}
            color="ts.text.secondary"
          >
            {`${t("analyze.metrics.summary.target")}: ${metrics.target}`}
          </Typography>
        </Box>
    </Box>*/}
    </Box>
  );
};

export default Header;
