import { Avatar, Badge, Button} from "@mui/material";
import { useAuthenticator } from "@aws-amplify/ui-react";

type Props = {
  alertsNumber?: number;
  userData?: {};
};

//TODO: userData non viene mai usato, ma viene passato come parametro. In attesa dell'oggetto account.
const Account = ({ alertsNumber, userData }: Props) => {
  const { user } = useAuthenticator((context) => [context.user]);

  const handleAvatar = (name: string | undefined) => {
    if (name) {
      return `${name.split(" ")[0].charAt(0).toUpperCase()}${name.split(" ")[1].charAt(0).toUpperCase()}`;
    }
  };

  return (
    <>
      <Button >
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          badgeContent={alertsNumber ? alertsNumber : 0}
          color="info"
        >
          {user && (
            <Avatar sx={{ bgcolor: "ts.avatar.bg", color: "ts.avatar.text" }}>
              {handleAvatar(user.attributes?.name)}
            </Avatar>
          )}
        </Badge>
      </Button>
    </>
  );
};

export default Account;