import * as am5 from "@amcharts/amcharts5";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useLayoutEffect, useState } from "react";
import JustLoading from "../JustLoading";
import { getColor, PaletteType } from "./paletteChart";
import { Box } from "@mui/material";

type Props = {
  data: any;
  options: any;
};

const AmGraph = ({ data, options }: Props) => {
  const chartID = "AmGraph";
  const [graphLoad, setGraphLoad] = useState<boolean>(true);

  useLayoutEffect(() => {
    const root = am5.Root.new(chartID);

    const tooltip = am5.Tooltip.new(root, {
      getFillFromSprite: false,
      labelText: "[fontSize: 12px fontFamily: Helvetica]{ufficio} | {name}[/]",
    });

    tooltip.get("background")?.setAll({
      fill: am5.color(0x00000),
      fillOpacity: 0.8,
    });

    root.setThemes([am5themes_Animated.new(root)]);

    const container = root.container.children.push(
      am5.Container.new(root, {
        width: am5.percent(100),
        height: am5.percent(100),
        layout: root.verticalLayout,
      })
    );

    const series = container.children.push(
      am5hierarchy.ForceDirected.new(root, {
        singleBranchOnly: false,
        downDepth: 1,
        initialDepth: 2,
        maxRadius: 10,
        topDepth: 1,
        valueField: "value",
        categoryField: "name",
        childDataField: "children",
        idField: "name",
        linkWithStrength: 0.3,
        linkWithField: "link",
        manyBodyStrength: -20,
        centerStrength: 2,
        tooltip: tooltip,
        templateField: "ufficio",
      })
    );

    const appliedColor = options.colors.map((colorCode: keyof PaletteType) => {
      return getColor(colorCode);
    });

    series.get("colors")?.set("colors", appliedColor);

    let timeout: any;
    root.events.on("frameended", exportChart);
    function exportChart() {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(function () {
        root.events.off("frameended", exportChart);

        series.data.setAll(data);
        series.set("selectedDataItem", series.dataItems[0]);
        series.appear(1000, 100);
        setGraphLoad(false);
      }, 100);
    }

    return () => {
      root.dispose();
    };
  }, []);

  return (
    <>
      {graphLoad && <JustLoading />}
      <Box className="flex-container" id={chartID} />
    </>
  );
};

export default AmGraph;
