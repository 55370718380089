import { SuggestedInitiativesType } from "../../types";

export const mockInitiativesSuggested: SuggestedInitiativesType[] = [
  {
    title: "Miglioramento Work-Life Balance",
    description: "Alcuni uffici hanno un'eccessiva tendenza al lavoro fuori dai normali orari con potenziali ripercussioni su burnout.",
    critical: 5,
    partner:"gk",
    metrics: [
      "card.package.workload|kpi_006",
      "card.package.workload|kpi_018",
      "card.package.workload|kpi_019"
    ],
    scope: [
      "IT e Innov. Digitale|Sistemi e Monitoring",
      "IT e Innov. Digitale|User Experience",
      "IT e Innov. Digitale|Sviluppo e Architetture",
      "Comunicazione|Eventi e Brand"
    ]
  },
  {
    title: "Riduzione attività sovrapposte",
    description: "Alcuni uffici presentano un elevato tasso di meeting sovrapposti a calendario, segno di eccessivi carichi di lavoro o di inefficienza nella gestione delle agende.",
    critical: 4,
    partner:"eh",
    metrics: [
      "card.package.smart|kpi_015"
    ],
    scope: [
      "HR, Finance & Operations|Legale"
    ]
  }
];
