
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SearchSliceType } from "../../../types";
import { initSearch } from "./initSearch";



// Slice con action e reducers.
const searchSlice = createSlice({
  name: "search", // Nome della slice
  initialState: initSearch, // Valore iniziale vuoto
  // Reducer con le azioni che si possono fare sulla slice (in questo caso solo una)
  reducers: {
    updateSearch(state, action: PayloadAction<SearchSliceType>) {      
      return  action.payload;
    }
  },
});

// Action generate che verrano utilizzate per aggiornare lo store.
export const { updateSearch } = searchSlice.actions;

export default searchSlice.reducer;
