import { Box, Tab, Tabs, Typography } from "@mui/material";
import React from "react";
import { SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { ChartType } from "../../../types";
import SimpleLoading from "../../SimpleLoading";
import MetricChart from "./MetricChart";

type Props = {
  charts: ChartType[];
  loading: boolean;
};

const MetricsChartTabs = ({ charts, loading,}: Props) => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();

  const [value, setValue] = useState<string>('001');



  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    setValue('001');
   
  },[searchParams.get('kpi')])





  return (
    <>
      {loading ? (
        <SimpleLoading />
      ) : (
        <> { charts && !loading &&
          
           <Tabs
            value={value}
            onChange={handleChange}
            TabIndicatorProps={{
              sx: {
                bgcolor: "ts.sidebar.bg",
              },
            }}
            textColor="secondary"
          >
           
            { charts.map((chart, index) => (
              <Tab
                value={chart.chart_id}
                key={index}
                sx={{ textTransform: "none"  }}
                label={
                  <Box className="flexGap05">
                    <Typography
                      variant="subtitle1"
                      color={"ts.text.secondary"}
                      fontWeight={value === chart.chart_id ? "bold" : "normal"}
                     
                    >
                     { loading ?  "" : t(`${chart.package_id}.${chart.kpi_id}.chart_${chart.chart_id}.title`)}
                    </Typography>
                  </Box>
                }
                
              />
              ))}
          </Tabs>
      
}

          {charts.map((chart, index) => (

            <React.Fragment key={chart.chart_id+index}>
              { chart.chart_id === value &&
            <Typography  sx={{textAlign: "center",   fontSize: "12pt"}}>
              {t(`${chart.package_id}.${chart.kpi_id}.chart_${chart.chart_id}.subtitle`)}
            </Typography>
}
            
            <MetricChart
              chart={chart}
              selectedChart={value}
              key={index}
            />

            </React.Fragment>
            
          ))}
          
        </>
      )}
    </>
  );
};

export default MetricsChartTabs;
