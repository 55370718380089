import { Button, Typography } from "@mui/material";

import TableCell from "@mui/material/TableCell";

import { useTranslation } from "react-i18next";
import { dragDropStyle } from "../../styles/styles";
import { capitalizeString } from "../../util/capitalizeString";

type Props = {
  data: string
};

const TableHRRow = ({ data }: Props) => {
  const {t} =useTranslation();
  return (
    <TableCell sx={{ minWidth: "20%" }} className="noBorderBot">
    <Button
      variant="outlined"
      disabled
      sx={dragDropStyle.tableHr.column}
    >
      <Typography className="bold">
        {data.includes(".")? t(data) : capitalizeString(data.toLowerCase())}
      </Typography>
    </Button>
    </TableCell>
  );
};

export default TableHRRow;
