import { useAuthenticator } from "@aws-amplify/ui-react";
import {
  Box,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItemButton,
  ListItemText,
  Badge,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Account from "../../../components/Account";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import { useState } from "react";

import ArrowDropUpOutlinedIcon from "@mui/icons-material/ArrowDropUpOutlined";
import { userSection } from "../../../styles/styles";
import { useTranslation } from "react-i18next";

type PropsType = {
  alertsNumber?: number;
};

const AccountSection = ({ alertsNumber }: PropsType) => {
  const [expanded, setExpanded] = useState(false);
  const nav = useNavigate();
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const { t } = useTranslation();
  const handleClose = () => {
    setExpanded(!expanded);
  };
  const handleOpen = () => {
    setExpanded(!expanded);
  };
  return (
    <Box className="flexStart fixedBottom">
      <Accordion
        disableGutters
        elevation={0}
        expanded={expanded}
        onClick={() => handleOpen()}
      >
        <AccordionSummary
          sx={userSection.accordion.summary}
          expandIcon={
            <ArrowDropUpOutlinedIcon sx={userSection.accordion.icon} />
          }
        >
          <Box className="flex">
            <Account />
            <Typography
              fontFamily="Poppins"
              variant="subtitle1"
              color={"rgb(15,20,33)"}
              textAlign="center"
            >
              {user.attributes?.name}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={userSection.accordion.details}>
          <List sx={userSection.accordion.list.ul}>
            <ListItemButton
              onClick={() => {
                nav("/updates/alerts");
                handleClose();
              }}
              sx={userSection.accordion.list.item.button}
            >
              <ListItemText
                primary={t("sidebar.user.accordion.alerts")}
                primaryTypographyProps={{
                  variant: "body2",
                  fontWeight: "bold",
                }}
              />

              <Badge
                badgeContent={alertsNumber ? alertsNumber : 0}
                color={"info"}
                sx={{ marginRight: ".6rem" }}
              />
            </ListItemButton>
            <ListItemButton
              onClick={() => {
                // nav("/settings/profile");
                nav("/configure/users");
                handleClose();
              }}
              sx={userSection.accordion.list.item.button}
            >
              <ListItemText
                primary={t("sidebar.user.accordion.profile")}
                primaryTypographyProps={{
                  variant: "body2",
                  fontWeight: "bold",
                }}
              />

              <ManageAccountsOutlinedIcon fontSize="small" />
            </ListItemButton>
            <ListItemButton
              onClick={() => {
                nav("/");
                handleClose();
                signOut();
              }}
              sx={userSection.accordion.list.item.button}
            >
              <ListItemText
                primary={t("sidebar.user.accordion.signout")}
                primaryTypographyProps={{
                  variant: "body2",
                  fontWeight: "bold",
                }}
              />
              <ExitToAppOutlinedIcon fontSize="small" />
            </ListItemButton>
          </List>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default AccountSection;
