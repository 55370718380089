import { useCallback, useEffect, useState } from "react";
import fetchConnectors from "../api/fetchConnectors";
import connectorsList from "../mocks/mockupConnectors.json";
import { ConnectorType } from "../types";

export const useConnectors = () => {
  const [connectors, setConnectors] = useState<ConnectorType[]>(connectorsList);
  const [error, setError] = useState(null);
  const [onError, setOnError] = useState(false);

  const getConnectors = async () => {
    try {
      const fetchedConnectors = await fetchConnectors();
      setConnectors(fetchedConnectors);
      setOnError(false);
    } catch (error: any) {
      setError(error);
      setOnError(true);
      throw error;
    }
  };

  const addConnector = useCallback((newConnector: ConnectorType) => {
   
    try {
      setConnectors((prev) => {
        const newConnectors = [...prev, newConnector]
        return newConnectors
      });
      setOnError(false);
    } catch (error: any) {
      setError(error);
      setOnError(true);
      throw error;
    }
  },[]);

  useEffect(() => {
    let mounted = true;

    if (mounted) getConnectors();

    return () => {
      mounted = false;
    };
  }, []);

  return { connectors, error, onError, getConnectors, addConnector };
};