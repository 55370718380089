import moment from "moment";
import { useState } from "react";
import fetchLicense from "../api/fetchLicense";
import { updateLicense } from "../redux/slices/license/licenseSlice";
import {
  LicenseEvalType,
  PackageEvalType,
  PackageType,
} from "../types";
import { useAppDispatch, useAppSelector } from "./reduxHooks";
import { CognitoUser } from '@aws-amplify/auth'

export const useLicense = (user?:CognitoUser) => {
  // Lettura del context
  const license: LicenseEvalType = useAppSelector((state) => state.licenseSlice);

  // Usato per la scrittura del context
  const dispatch = useAppDispatch();

  const [onError, setOnError] = useState<{state: boolean; message: string}>({state: false, message:""});
  const [loaded, setLoaded] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>(undefined);

  

  const getLicense = async () => {
    try {
      const fetchedLicense = await fetchLicense(user?.getSignInUserSession()?.getIdToken().payload.user_signature, user?.getSignInUserSession()?.getIdToken().getJwtToken() || "");
      if (typeof fetchedLicense === 'string') throw new Error("Lambda problem");
      fetchedLicense.packages = licenseFilter(fetchedLicense.packages);

      const evalLicense: LicenseEvalType = {
      
        packages: licenseFilter(fetchedLicense.packages),
        sidebar: fetchedLicense.sidebar,
        loaded: true,
        k_check: fetchedLicense.k_check
      };

      dispatch(updateLicense(evalLicense));
      setOnError({state:false, message:""});
      setLoaded(true);
    } catch (error: any) {
      setError(error);
      setOnError({state:true, message:error});
      setLoaded(false);
      throw error;
    }
  };

  return { license, onError, error, getLicense, loaded };
};

const licenseFilter = (packages: PackageType[]) => {
  

  const filterPackages: PackageEvalType[] = packages.map((pkg) => {
    const filteredPackage: PackageEvalType = {
      ...pkg,
      valid:
        moment() > moment(pkg.validFrom) &&
        moment(pkg.validFrom) < moment(pkg.validTo),
      title: pkg.id
    };

    return filteredPackage;
  });

  return filterPackages;
};
