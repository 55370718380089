import { LoadingButton } from "@mui/lab";
import { Alert, Button, CircularProgress, Stack, SvgIcon } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { dragDropStyle } from "../../../styles/styles";
import TableHR from "../../configure/TableHR";

type Props = {
  rows?: (
    | string
    | { value: number | string; status: string; message: string }
  )[][];
  columns?: string[];
  status: string;
  sendFile: (idStep: string) => void;
  handleClose:()=>void;
};

const StepOrg = ({ rows, columns, status, sendFile, handleClose }: Props) => {
  const [loading, setLoading] = useState(false);

  const onSubmit = (data: any) => {
    setLoading(true);
    sendFile("k_check");
  };

  const {t} = useTranslation();

  return (
    <Stack sx={{minWidth: "70%", display:"flex", alignItems:"center", marginTop: "2rem" }}>
      {
        status === "warning" && 
        <Alert
                  severity="warning"
                  sx={{ ...dragDropStyle.alert.warning,
                    backgroundColor: "rgb(255, 244, 229)",
                    color: "rgb(102, 60, 0)",
                    marginBottom:"1rem"
                  
                  }}
                  icon={
                    <SvgIcon sx={{padding:0}}>
                      <path
                        d="M 12 5.99 L 19.53 19 H 4.47 L 12 5.99 M 12 2 L 1 21 h 22 L 12 2 Z m 1 14 h -2 v 2 h 2 v -2 Z m 0 -6 h -2 v 4 h 2 v -4 Z"
                        fill="rgb(255, 152, 0)"
                      />
                    </SvgIcon>
                  }
                >{t("hrdb.modal.step.hierarchy.warning")}</Alert>
      }
      <TableHR rows={rows} columns={columns} />

      {status === "warning" && (
        <LoadingButton
          size="small"
          onClick={onSubmit}
          loading={loading}
          loadingIndicator={
            <CircularProgress size={26} sx={{ color: "#00bfa5" }} />
          }
          variant="outlined"
          sx={dragDropStyle.org.button}
        >
         {t("hrdb.modal.button.confirm")}
        </LoadingButton>
      )}
      {status === "error" && (
        <Button
          size="small"
          onClick={handleClose}
          variant="outlined"
          sx={dragDropStyle.org.button}
        >
         {t("hrdb.modal.button.retry")}
        </Button>)}
    </Stack>
  );
};

export default StepOrg;
