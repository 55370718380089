import { Stack, styled, Switch, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";




type Props = {
  checked: boolean;
  onChange : (event: React.ChangeEvent<HTMLInputElement>) => void
  on: boolean
}



const TurnOnAuto= (
  {checked, onChange, on} :Props

 
 ) => {
  

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: on?   '#00bfa5' : "#e0e0e0cc",
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: on? 
      '#00bfa5' : "grey",
      boxSizing: 'border-box',
    },
  }));

  const {t} = useTranslation()

  return (
    <Stack alignItems="center">
      <Typography className="h4"  color={on? "black" : "grey"}>{t("target.kpi.input.title")}</Typography>
    <Stack direction="row" spacing={1} alignItems="center" sx={{marginTop: "1rem"}}>
    <Typography color={on? "black" : "grey"}>{t("target.kpi.input.auto")}</Typography>
    <AntSwitch  inputProps={{ 'aria-label': 'ant design' }} checked={checked} onChange={onChange} disabled={!on}/>
    <Typography color={on? "black" : "grey"}>{t("target.kpi.input.manual")}</Typography>
  </Stack>
  </Stack>
  );
};

export default TurnOnAuto
