
import { useAppDispatch, useAppSelector } from "./reduxHooks";
import { updateSelectedKpi } from "../redux/slices/selectedKpi/selectedKpiSlice";

export const useSelectedKpi = ( tab?:string, kpi?:string) => {
 
  const dispatch = useAppDispatch();

  
  
  
  const selection = useAppSelector((state) => state.selectedKpiSlice.selection);

  const handleClick = (tab?:string, kpi?:string) =>{
   
    const ta = tab? tab : "";
    const kp= kpi? kpi : "";
    const existingVertical = selection.find((obj)=> obj.vertical===ta);
   
    let newSelection = {selection:[{vertical: "", selected:""}]};
    if(existingVertical)
    {

     
      newSelection = {selection:selection.map((item)=>item.vertical===ta? {vertical:ta, selected: kp}: item)}

    }
    else{
    newSelection = {selection:[...selection, {vertical:ta, selected: kp}]};
   
    }
    dispatch(updateSelectedKpi(newSelection));



  }

  return {  handleClick, selection };
};
