import { Grid } from "@mui/material";
import {useTranslation} from "react-i18next";
import { alertGrid } from "../../styles/styles";

const AlertGridHeader = () => {
    const {t} = useTranslation();
  return (
    <Grid container spacing={1} sx={alertGrid.header}>
      <Grid item xs="auto" sm="auto" md={12} className="width100">
        <Grid container spacing={0} >
          <Grid item xs={4} sm={4} md={4} lg={3}  >
            {t("alerts.header.name")}
          </Grid>
          <Grid item xs={4} sm={4} md={5} lg={5}  >
          {t("alerts.header.message")}
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2}  >
          {t("alerts.header.author")}
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2}  >
          {t("alerts.header.date")}
          </Grid>
          
          {/* <Grid item xs={2} sm={2} md={2} lg={1}  >
           
          </Grid> */}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AlertGridHeader;