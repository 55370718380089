import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  Typography,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SidebarSectionType } from "../../../types";

const sidebar = "sidebar.";

const svgCodes = [
  {
    id: "analyze.company",
    code: "M10 4V0H0V18H20V4H10ZM8 16H2V14H8V16ZM8 12H2V10H8V12ZM8 8H2V6H8V8ZM8 4H2V2H8V4ZM18 16H10V6H18V16ZM16 8H12V10H16V8ZM16 12H12V14H16V12Z",
  },
  {
    id: "monitoring.initiatives",
    code: "M19.88 17.97C20.32 17.27 20.58 16.46 20.58 15.58C20.58 13.09 18.57 11.08 16.08 11.08C13.59 11.08 11.58 13.09 11.58 15.58C11.58 18.07 13.59 20.08 16.07 20.08C16.95 20.08 17.77 19.82 18.46 19.38L21.58 22.5L23 21.08L19.88 17.97ZM16.08 18.08C14.7 18.08 13.58 16.96 13.58 15.58C13.58 14.2 14.7 13.08 16.08 13.08C17.46 13.08 18.58 14.2 18.58 15.58C18.58 16.96 17.46 18.08 16.08 18.08ZM15.72 9.58C14.98 9.6 14.27 9.76 13.62 10.03L13.07 9.2L9.27 15.38L6.26 11.86L2.63 17.67L1 16.5L6 8.5L9 12L13 5.5L15.72 9.58ZM18.31 10.08C17.67 9.8 16.98 9.63 16.26 9.59L21.38 1.5L23 2.68L18.31 10.08Z",
  },
  {
    id: "configs.hrdb",
    code: "M22 11V3H15V6H9V3H2V11H9V8H11V18H15V21H22V13H15V16H13V8H15V11H22ZM7 9H4V5H7V9ZM17 15H20V19H17V15ZM17 5H20V9H17V5Z",
  },
  {
    id: "configs.users",
    code: "M19 4H14.82C14.4 2.84 13.3 2 12 2C10.7 2 9.6 2.84 9.18 4H5C3.9 4 3 4.9 3 6V20C3 21.1 3.9 22 5 22H19C20.1 22 21 21.1 21 20V6C21 4.9 20.1 4 19 4ZM12 3.75C12.22 3.75 12.41 3.85 12.55 4C12.67 4.13 12.75 4.31 12.75 4.5C12.75 4.91 12.41 5.25 12 5.25C11.59 5.25 11.25 4.91 11.25 4.5C11.25 4.31 11.33 4.13 11.45 4C11.59 3.85 11.78 3.75 12 3.75ZM19 20H5V6H19V20ZM12 7C10.35 7 9 8.35 9 10C9 11.65 10.35 13 12 13C13.65 13 15 11.65 15 10C15 8.35 13.65 7 12 7ZM12 11C11.45 11 11 10.55 11 10C11 9.45 11.45 9 12 9C12.55 9 13 9.45 13 10C13 10.55 12.55 11 12 11ZM6 17.47V19H18V17.47C18 14.97 14.03 13.89 12 13.89C9.97 13.89 6 14.96 6 17.47ZM8.31 17C9 16.44 10.69 15.88 12 15.88C13.31 15.88 15.01 16.44 15.69 17H8.31Z",
  },
  {
    id: "configs.target",
    code: "M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM15 12C15 13.66 13.66 15 12 15C10.34 15 9 13.66 9 12C9 10.34 10.34 9 12 9C13.66 9 15 10.34 15 12Z",
  },
  {
    id: "news.docs",
    code: "M16.5 1H4.5C3.4 1 2.5 1.9 2.5 3V17H4.5V3H16.5V1ZM15.5 5H8.5C7.4 5 6.51 5.9 6.51 7L6.5 21C6.5 22.1 7.39 23 8.49 23H19.5C20.6 23 21.5 22.1 21.5 21V11L15.5 5ZM8.5 21V7H14.5V12H19.5V21H8.5Z",
  },
  {
    id: "news.alerts",
    code: "M20 2H4C2.9 2 2 2.9 2 4V22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM20 16H5.17L4 17.17V4H20V16Z",
    code2:
      "M12 15L13.57 11.57L17 10L13.57 8.43L12 5L10.43 8.43L7 10L10.43 11.57L12 15Z",
  },
  {
    id: "news.faq",
    code: "M4 6H2V20C2 21.1 2.9 22 4 22H18V20H4V6ZM20 2H8C6.9 2 6 2.9 6 4V16C6 17.1 6.9 18 8 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM20 16H8V4H20V16ZM13.51 10.16C13.92 9.43 14.69 9 15.14 8.36C15.62 7.68 15.35 6.42 14 6.42C13.12 6.42 12.68 7.09 12.5 7.65L11.13 7.08C11.51 5.96 12.52 5 13.99 5C15.22 5 16.07 5.56 16.5 6.26C16.87 6.86 17.08 7.99 16.51 8.83C15.88 9.76 15.28 10.04 14.95 10.64C14.82 10.88 14.77 11.04 14.77 11.82H13.25C13.26 11.41 13.19 10.74 13.51 10.16ZM12.95 13.95C12.95 13.36 13.42 12.91 14 12.91C14.59 12.91 15.04 13.36 15.04 13.95C15.04 14.53 14.6 15 14 15C13.42 15 12.95 14.53 12.95 13.95Z",
  },
];

type Props = {
  listItem: SidebarSectionType[];
  mainSection: string;
  selectedSection: string;
};

//FIXME: ottimizzare, comporre dinamicamente gli oggetti di ListItemIcon
const SidebarItem = ({
  listItem,
  mainSection,
  selectedSection,
}: Props) => {
  const { t } = useTranslation();

  const nav = useNavigate();

  return (
    <>
      {mainSection !== "docs" && (
        <Box>
          <Typography
            fontFamily="Barlow"
            fontWeight="bold"
            variant="subtitle1"
            color={"ts.primary.white"}
          >
            {t(`${sidebar}${mainSection}`)}
          </Typography>
          <List
            sx={{
              paddingTop: "0px",
              paddingBottom: "0px",
              color: "ts.sidebar.text.inactive",
              "&:hover": {
                color: "ts.sidebar.text.hover",
              },
              "&.Mui-selected": {
                color: "ts.sidebar.text.active",
              },
              "&.Mui-selected:hover": {
                color: "ts.sidebar.text.active",
              },
            }}
          >
            {listItem.map((section) => (
              <ListItemButton
                selected={selectedSection.includes(section.href)}
                onClick={() => {
                  nav(section.href);
                }}
                sx={{
                  marginTop: ".5rem",
                  padding: "none",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                  borderRadius: "35px",
                  cursor: "pointer",
                  color: "ts.sidebar.text.inactive",
                  "&:hover": {
                    backgroundColor: "ts.sidebar.hover",
                    color: "ts.sidebar.text.hover",
                  },
                  "&.Mui-selected": {
                    backgroundColor: "ts.sidebar.selected",
                    color: "ts.sidebar.text.active",
                  },
                  "&.Mui-selected:hover": {
                    backgroundColor: "ts.sidebar.selected",
                    color: "ts.sidebar.text.active",
                  },
                }}
                key={section.id}
              >
                <ListItemIcon sx={{ marginTop: "4px", marginBottom: "4px" }}>
                  <SvgIcon
                    sx={{
                      color:
                        selectedSection.includes(section.href)
                          ? "ts.sidebar.text.active"
                          : "ts.sidebar.inactiveIcon",
                    }}
                  >
                    <path
                      d={
                        svgCodes.filter((svg) => svg.id === section.id)[0].code
                      }
                    />

                    <path
                      d={
                        svgCodes.filter((svg) => svg.id === section.id)[0].code2
                      }
                    />
                  </SvgIcon>
                </ListItemIcon>
                <ListItemText
                  primary={t(`${sidebar}${section.id}`)}
                  primaryTypographyProps={{
                    variant: "subtitle1",
                    fontWeight: "bolder",
                    fontSize: "0.9rem",
                  }}
                />
              </ListItemButton>
            ))}
          </List>
        </Box>
      )}
    </>
  );
};

export default SidebarItem;
