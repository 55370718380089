import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CardTypeF } from "../../../types";
import CardBody from "../card-elements/CardBody";
import CardHeader from "../card-elements/CardHeader";
import CardMetrics from "./CardMetrics";

type Props = {
  cardInfo: CardTypeF;
};

const CardOffice = ({ cardInfo }: Props) => {
  const nav = useNavigate();
  

  return (
    <Box
      sx={{
        minHeight: "300px",
        height: "100%",
        position: "relative",
        padding: ".8rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box
        onClick={() => {
          
          nav(
            `/company/packages?id=${encodeURIComponent(
              cardInfo.card_key
            )}`
          );
        }}
        sx={{
          cursor: "pointer",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <CardHeader title={cardInfo.parent_list?.at(-1) || ""} resources={cardInfo.resources} />
       
      </Box>
      <Box>
          <CardBody cardInfo={cardInfo}  />
          <CardMetrics cardInfo={cardInfo} />
        </Box>
    </Box>
  );
};

export default CardOffice;
