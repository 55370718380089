import { Stack } from "@mui/material";
import SidebarItem from "./SidebarItem";
import { useLicense } from "../../../hooks/index";
import { SidebarSectionType } from "../../../types";
import { useLocation } from "react-router-dom";

type Props = {};

const Sidebar = (props: Props) => {
  const location = useLocation();

  const { license } = useLicense();

  return (
    <Stack sx={{ padding: "1rem" }} spacing={".5rem"}>
      {Object.values(license.sidebar).map(
        (section: SidebarSectionType[], index) => (
          <SidebarItem
            key={index}
            listItem={section}
            selectedSection={location.pathname}
            mainSection={Object.keys(license.sidebar)[index]}
          />
        )
      )}
    </Stack>
  );
};

export default Sidebar;
