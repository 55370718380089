import { Box, IconButton } from "@mui/material";
import TableRowsOutlinedIcon from "@mui/icons-material/TableRowsOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";

type Props = {
  handleVisual: (view: "cards" | "table") => void;
  visual: "cards" | "table";
};

const AllDepsPagination = ({ visual, handleVisual }: Props) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <IconButton
        size="medium"
        onClick={() => {
          handleVisual("cards");
        }}
      >
        <DashboardOutlinedIcon
          fontSize="inherit"
          sx={{ color: visual === "cards" ? "ts.sidebar.selected" : "ts.primary.white" }}
        />
      </IconButton>
      <IconButton
        size="medium"
        onClick={() => {
          handleVisual("table");
        }}
      >
        <TableRowsOutlinedIcon
          fontSize="inherit"
          sx={{ color: visual === "table" ? "ts.sidebar.selected" : "ts.primary.white" }}
        />
      </IconButton>
    </Box>
  );
};

export default AllDepsPagination;
