import { Box, List, ListItem, ListItemIcon, ListItemText, SvgIcon, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Heading from "../../components/Heading";
import InitiativesTabs from "../../components/monitor/InitiativesTabs";
import { inDetailStyle } from "../../styles/styles";
import { useInitiatives } from "../../hooks/index";
import { useSearchParams } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { detailSection } from "../../styles/styles";
import Loading from "../../components/Loading";
import SeverityBadge, { SEVERITY_BADGE_TYPES } from "../../components/SeverityBadge/SeverityBadge";

const PageInitiativeDetail = () => {
  const [searchParams] = useSearchParams();
  const { user } = useAuthenticator((context) => [context.user]);
  const { t } = useTranslation();
  
  const { initiativesSearch, onError, loaded } = useInitiatives(
    user.getSignInUserSession()?.getIdToken().payload.user_signature,
    user.getSignInUserSession()?.getIdToken().getJwtToken() || "",
    "master",
    searchParams.get("init_key") !== null ? searchParams.get("init_key") as string : ""
  );
  
  const initiativeMaster = initiativesSearch[0]
  
  return (
    <Box className="container">
      { !loaded && !onError && <Loading /> }
      { loaded && !onError && initiativeMaster && (          
        <>
          <List dense={true}>
            <ListItem disableGutters key="list-1" sx={{ alignItems: "center", display: "flex", alignContent: "center", justifyContent: "center" }}>
              <Box sx={{ marginBottom: "2rem" }}>
                <SeverityBadge severity={initiativeMaster.color} type={SEVERITY_BADGE_TYPES.circle} />
              </Box>
              <ListItemText sx={{ marginLeft: "1rem" }}>
                <Heading title={initiativeMaster.title} />
              </ListItemText>
            </ListItem>
          </List>
          <Box className="flexTop wrap" sx={{ paddingBottom: "20px" }}>
            <Box sx={{ maxWidth: "30%" }}>
              <Typography className="h6 bold">
                {t("initiatives.initiativedetail.info")}
              </Typography>
              <List
                dense={true}
                sx={detailSection.info.generic}
                className="customized-scrollbar"
              >
                <ListItem disableGutters key="list-1" sx={{ alignItems: "start" }}>
                  <ListItemIcon sx={inDetailStyle.infoIcon}>
                    <SvgIcon sx={inDetailStyle.infoSvg}>
                      <path d="M 8 16 h 8 v 2 H 8 Z m 0 -4 h 8 v 2 H 8 Z m 6 -10 H 6 c -1.1 0 -2 0.9 -2 2 v 16 c 0 1.1 0.89 2 1.99 2 H 18 c 1.1 0 2 -0.9 2 -2 V 8 l -6 -6 Z m 4 18 H 6 V 4 h 7 v 5 h 5 v 11 Z" />
                    </SvgIcon>
                  </ListItemIcon>
                  <ListItemText>
                    <Typography className="body">
                      <span className="bold">
                        {t("initiatives.initiativedetail.description")}{": "}
                      </span>
                      {t(initiativeMaster.description)}
                    </Typography>
                  </ListItemText>
                </ListItem>
                <ListItem disableGutters key="list-2" sx={{ alignItems: "start" }}>
                  <ListItemIcon sx={inDetailStyle.infoIcon}>
                    <SvgIcon sx={inDetailStyle.infoSvg}>
                      <path d="M12 12C13.65 12 15 10.65 15 9C15 7.35 13.65 6 12 6C10.35 6 9 7.35 9 9C9 10.65 10.35 12 12 12ZM12 8C12.55 8 13 8.45 13 9C13 9.55 12.55 10 12 10C11.45 10 11 9.55 11 9C11 8.45 11.45 8 12 8ZM18 16.58C18 14.08 14.03 13 12 13C9.97 13 6 14.08 6 16.58V18H18V16.58ZM8.48 16C9.22 15.49 10.71 15 12 15C13.29 15 14.78 15.49 15.52 16H8.48ZM19 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM19 19H5V5H19V19Z" />
                    </SvgIcon>
                  </ListItemIcon>
                  <ListItemText>
                    <Typography className="body">
                      <span className="bold">
                        {t("initiatives.initiativedetail.info.owner")}{": "}
                      </span>
                      {t(initiativeMaster.owner)}
                    </Typography>
                  </ListItemText>
                </ListItem>
                <ListItem disableGutters key="list-3"  sx={{ alignItems: "start" }}>
                  <ListItemIcon sx={inDetailStyle.infoIcon}>
                    <SvgIcon sx={inDetailStyle.infoSvg}>
                      <path d="M16.24 7.76C15.07 6.59 13.54 6 12 6V12L7.76 16.24C10.1 18.58 13.9 18.58 16.25 16.24C18.59 13.9 18.59 10.1 16.24 7.76ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z" />
                    </SvgIcon>
                  </ListItemIcon>
                  <ListItemText>
                    <Typography className="body">
                      <span className="bold">
                        {t("initiatives.initiativedetail.info.period")}{": "}
                      </span>
                      {initiativeMaster.starting_date}{" / "}{initiativeMaster.ending_date}
                    </Typography>
                  </ListItemText>
                </ListItem>
              </List>
            </Box>
            <Box sx={{ maxWidth: "30%" }}>
              <Typography className="h6 bold">
                {t("initiatives.initiativedetail.metrics")}
              </Typography>
              <List
                dense={true}
                sx={detailSection.info.generic}
                className="customized-scrollbar"
              >
                <ListItem disableGutters key="list-0">
                  <ListItemText>
                    {initiativeMaster.kpis.map((metric, index) => (
                      <Typography className="body" key={"listheader" + index}>
                        {"- " + t(metric.replaceAll("|",".") + ".title")}
                      </Typography>
                    ))}
                  </ListItemText>
                </ListItem>
              </List>
            </Box>
            <Box sx={{ maxWidth: "30%" }}>
              <Typography className="h6 bold">
                {t("initiatives.initiativedetail.info.scope")}
              </Typography>
              <List
                dense={true}
                sx={detailSection.info.generic}
                className="customized-scrollbar"
              >
                <ListItem disableGutters key="list-0">
                  <ListItemText>
                    {initiativeMaster.scope.map((scope, index) => (
                      <Typography className="body" key={"listheader" + index}>
                        {"- "}{t(scope.split("|")[scope.split("|").length - 1])}
                      </Typography>
                    ))}
                  </ListItemText>
                </ListItem>
              </List>
            </Box>
          </Box>
        </>
      )}
      <Box>
        <InitiativesTabs />
      </Box>
    </Box>
  );
};

export default PageInitiativeDetail;
