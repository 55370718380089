import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

type Props = {
  title: string;
  resources: number;
  officeListLen?: number;
};

const CardHeader = ({ title, resources, officeListLen }: Props) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ marginBottom: "1rem", minHeight: "130px" }}>
      <Typography className="h4" sx={{ marginRight: "8px" }}>
        {title.includes("|")? title.slice(title.lastIndexOf('|') + 1) : title}
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {officeListLen && (
          <Typography className="body1">
            {`${officeListLen} ${t("cards.offices")} |`}
            &nbsp;
          </Typography>
        )}
        <Typography className="body1">
          {resources} {t("cards.resources")}
        </Typography>
      </Box>
    </Box>
  );
};

export default CardHeader;
