// import "@fontsource/poppins";
// import "@fontsource/barlow";
import { createTheme } from "@mui/material";

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true; // removes the `xs` breakpoint
    sm: true;
    md: true;
    lg: true;
    xl: true;
  }
}

declare module "@mui/material/ListItemButton" {
  interface ListItemButtonPropsVariantOverrides {
    hovered: true;
  }
}
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    poster: true;
    h3: false;
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    poster: React.CSSProperties;
    h6Bold: React.CSSProperties;
    h7Barlow: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    poster?: React.CSSProperties;
    h6Bold?: React.CSSProperties;
    h7Barlow?: React.CSSProperties;
  }
  interface Palette {
    ts?: {
      primary: {
        main: string;
        white: string;
        black: string;
        grey:string;
      };
      avatar: {
        bg: string;
        text: string;
      };
      alertsBadge: string;
      sidebar: {
        bg?: string;
        hover?: string;
        selected?: string;
        text: {
          title?: string;
          active?: string;
          inactive?: string;
          hover?: string;
        };
        inactiveIcon?: string;
      };
      topbar: {
        search: {
          label: {
            text: string;
          };
        };
      };

      card?: {
        bg?: string;
        bgDetail?: string;
        chip?: string;
        badge?: string;
        text?: {
          title?: string;
          main?: string;
        };
      };

      severity?: {
        target?: string;
        risk?: string;
        critic?: string;
        disabled?: string;
      };
      initiative:{
        action?: string
      };
      metrics:{
        actions:{
          buttonGroup:{
            bgC?: string
          }
        }
      }
    };
  }

  interface PaletteOptions {
    ts?: {
      primary: {
        main: string;
        white: string;
        black: string;
        grey:string;
      };
      avatar: {
        bg: string;
        text: string;
      };
      alertsBadge: string;
      sidebar: {
        bg?: string;
        hover?: string;
        selected?: string;
        text: {
          title?: string;
          active?: string;
          inactive?: string;
          hover?: string;
        };
        inactiveIcon?: string;
      };
      topbar: {
        search: {
          label: {
            text: string;
          };
        };
      };

      card?: {
        bg?: string;
        bgDetail: string;
        chip?: string;
        badge?: string;
        text?: {
          title?: string;
          main?: string;
        };
      };
      severity?: {
        target?: string;
        risk?: string;
        critic?: string;
        disabled?: string;
        alert?: string;
      };
      initiative:{
        action?: string
      };
      metrics:{
        actions:{
          buttonGroup:{
            bgC?: string
          }
        }
      }
    };
  }

  interface PaletteColor {
    sidebar?: string;
  }
  interface SimplePaletteColorOptions {
    sidebar?: string;
  }
  interface PaletteColor {}

  // interface BreakpointsOptions {
  //   mobile: number;
  //   tablet: number;
  //   laptop: number;
  //   desktop: number;
  // }
}

export const themeTeamsight = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      // small
      sm: 811,
      // medium
      md: 1045,
      // large
      lg: 1280,
      // extra-large
      xl: 1536,
    },
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: "#D2E8F9",
          height: 3,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#1B2845",
          },
        },
      },
    },
  },

  typography: {
    fontFamily: ["Poppins", "Barlow"].join(","),
    h1: {
      fontSize: "50pt",
      fontWeight: "bold",
    },
    h2: {
      fontWeight: "bold",
      fontFamily: "Barlow",
      fontSize: "24pt",
    },
    h4: {
      fontWeight: "bold",
      fontFamily: "Barlow",
      fontSize: "18pt",
    },
    h5: {
      fontWeight: "bold",
      fontFamily: "Barlow",
      fontSize: "13pt",
    },
    h6: {
      fontSize: "10pt",
    },
    h6Bold: {
      fontWeight: "bold",
      fontSize: "10pt",
    },
    h7Barlow: {
      fontWeight: "bold",
      fontFamily: "Barlow",
      fontSize: "10pt",
    },
    body2: {
      fontSize: "10pt",
    },
    caption: {
      fontSize: "10pt",
    },
  },
  palette: {
    primary: {
      main: "#ffffff",
    },
    secondary: {
      // main: "#D2E8F9",
      main: "rgba(255, 255, 255, 0)",
      light: "#000000",
    },
    info: {
      main: "#1D4CAF",
    },
    warning: {
      main: "#00bfa5",
    },
    success: {
      main: "#00bfa5",
      light: "rgba(23, 181, 165, .5)",
    },
    ts: {
      primary: {
        main: "#00bfa5",
        white: "#ffffff",
        black: "#000000",
        grey:"rgb(128,128,128)"
      },
      avatar: {
        bg: "#00bfa5",
        text: "rgb(15,20,33)",
      },
      alertsBadge: "#1D4CAF",
      sidebar: {
        bg: "rgb(15,20,33)",
        hover: "rgb(242, 244, 246)",
        selected: "#00bfa5",
        text: {
          title: "#6A7895",
          active: "#F2F4F6",
          inactive: "rgb(223,225,227)",
          hover: "rgb(15,20,33)",
        },
        inactiveIcon: "#00bfa5",
      },
      topbar: {
        search: {
          label: {
            text: "#00bfa5"
          }
        }

      },
      card: {
        bg: "rgba(101,142,230,0.08)",
        bgDetail: "rgba(101,142,230,0.16)",
        chip: "#D2E8F9",

        text: {
          title: "#0E1423",
          main: "#3F475A",
        },
      },
      severity: {
        target: "#2DB517",
        risk: "#EB9B00",
        critic: "#CC1A00",
        disabled: "rgb(217,218,220)",
        alert: "rgb(27,67,165)",
      },
      initiative:{
        action: "rgba(29, 76, 175, 1)",
      },
      metrics:{
        actions:{
          buttonGroup:{
            bgC: "#0D4066"
          }
        }
      }
    },
    text: {
      primary: "#3F475A",
      secondary: "#0E1423",
     
    },
  },
});
