import { useEffect, useState } from "react";
import fetchMetrics from "../api/fetchMetrics";
import { MetricTypeF } from "../types";
import { SingleMetricType } from './../types';

import { metricsLic } from "../mocks/apiL3/mockup_metric";
import { metricList } from "../mocks/apiL3/mockup_kpi";

export const useMetrics = (signature:string, auth:string, scope: string, leveInfo:string, id: string , tab?:string, kpi?:string) => {
  const [metrics, setMetrics] = useState<MetricTypeF[]>([]);
  const [onMetricError, setOnMetricError] = useState(false);
  const [metricLoading, setMetricLoading] = useState(true);

  useEffect(() => {
    try{
      (async()=>{
        /*MOD20221128: Rimuovere il check sull'agile*/
        const fetchedMetrics = id.toLowerCase().includes("card.package.agile") ? metricList.filter((metric) => metric.complete_key.toLowerCase().includes(id.toLowerCase())) : await fetchMetrics(signature, auth, scope, leveInfo, id)
        
        setMetrics(fetchedMetrics as MetricTypeF[]);
        setOnMetricError(false);
        setMetricLoading(false)
    })()} catch (error: any) {
      setMetricLoading(true)
      setOnMetricError(true);
      throw error;
    }
    
  }, [id]);
  
  
  
  return { metrics, onMetricError, metricLoading,  };
};

export const useMetric = (signature:string, auth:string, scope: string, leveInfo:string, id: string , tab?:string, kpi?:string) => {
 
  const [metric, setMetric] = useState<SingleMetricType>();
  const [onMetError, setOnMetError] = useState(false);
  const [metLoading, setMetLoading] = useState(true);



  useEffect(() => {
    try{
      (async()=>{
        /*MOD20221128: Rimuovere il check sull'agile*/
        const fetchedMetrics = id.toLowerCase().includes("card.package.agile") ? metricsLic.filter((metric) => metric.complete_key.toLowerCase() === id.toLowerCase()) : await fetchMetrics(signature, auth, scope, leveInfo, id) as SingleMetricType[];
        
        setMetric(fetchedMetrics[0] as SingleMetricType);
        setOnMetError(false);
        setMetLoading(false)
    })()} catch (error: any) {
      setMetLoading(true)
      setOnMetError(true);
      throw error;
    }
    
  }, [id]);
  
  
  
  return { metric, onMetError, metLoading,  };
};