import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import backend from 'i18next-http-backend';
import axios from 'axios';
import { initReactI18next } from "react-i18next";
import { CognitoUser } from '@aws-amplify/auth'

const usei18n = (user: CognitoUser) => {
  const userSig = user?.getSignInUserSession()?.getIdToken().payload.user_signature
  const token = user?.getSignInUserSession()?.getIdToken().getJwtToken() || ""
  const apiUrl:string = process.env.REACT_APP_API_URL_ROOT + "/dictionary"
  
  const loadResources=async(locale:string)=> {
    
    return await axios.get(apiUrl, { params: { language: locale, user_signature: userSig }, headers: { 'Authorization': token}})
        .then((response) => { return response.data[locale].translations })
        .catch((error) => {console.log('error:' + error)});
  }

  const backendOptions = {
    loadPath: '{{lng}}|{{ns}}', 
    request: (options:any, url:any, payload:any, callback:any) => {
      try {
        
        const [lng] = url.split('|');
        loadResources(lng).then((response) => {
          callback(null, {
            data: response,
            status: 200, 
          });
        });
      } catch (e) {
        console.error(e);
        callback(null, {
          status: 500,
        });
      }
    },
  };
 
  i18n.use(LanguageDetector).use(backend).use(initReactI18next).init(
    {
      backend: backendOptions,
      fallbackLng: "en",
      debug: false,
      load:"languageOnly",
      ns: ["translations"],
      defaultNS: "translations",
      keySeparator: false, 
      interpolation: {
        escapeValue: false, 
        formatSeparator: ","
      }
    }
  );

  return { i18n }
} 
export default usei18n;