import { Box } from "@mui/material";

import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import NewBreadcrumb from "../../components/breadcrumb/NewBreadcrumb";
import TopBarNav from "../../components/TopBarNav";

import { useNav } from "../../hooks/useNav";

type Props = {
  pathname: string;
};

const Topbar = ({ pathname }: Props) => {
  const { order, handleNavSort, handleVisual, view } = useNav();
  const pathArray: string[] = pathname.split("/");

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}
    >
      {pathArray[1] !== "company" ? (
        <Breadcrumb location={pathname} />
      ) : (
        <NewBreadcrumb />
      )}
      <TopBarNav
        location={pathname}
        handleVisual={handleVisual}
        view={view.visual}
        order={order}
        handleOrder={handleNavSort}
      />
    </Box>
  );
};

export default Topbar;
