
import { OfficeListTypeF } from "../types";


export const sortOfficesBySeverity = (metrics: OfficeListTypeF[]) => {
  return metrics.sort((a: OfficeListTypeF, b: OfficeListTypeF) => {
    if (a.score === null) {
      a.score = Number.MAX_VALUE;
    }

    if (b.score === null) {
      b.score = Number.MAX_VALUE;
    }

    if (a.score < b.score) {
      return -1;
    }
    if (a.score > b.score) {
      return 1;
    }
    return 0;
  });
};