import { ChartMetricType } from "../../types";

/*MOD20221128: Rimuovere*/
export const card_charts: ChartMetricType[] = [
    {
        "card_key": "IT e Innov. Digitale|Sviluppo e Architetture",
        "complete_key": "IT e Innov. Digitale|Sviluppo e Architetture|card.package.agile|kpi_001",
        "package_id": "card.package.agile",
        "kpi_id": "kpi_001",
        "charts": [
            {
                "chart_id": "001",
                "chart_data": [
                    {
                        "children": [
                            {
                                "name": "00d7df14c3d418e6b9aa1fdbf670004b7bdde6bed851cbdb44d095c5d53633c3",
                                "value": 50,
                                "ufficio": "Analisi funzionali",
                                "link": [
                                    "18f8f073dd89fcf946d09157db5342889d19543390c8cd4c9dd8b05bc65fd518",
                                    "21128e9f23759392be2b2b17596d9d7c496d188162fd9ece9f8aa096e189a929",
                                    "2d5cdaed96acc015fa15ee3403b327066febef58d4ff9a20e06dfb7b261628ce",
                                    "352097edcec7e4cc4332657c589d7506b13fa583c8078c419c1f1e22fc6bb66a",
                                    "413612c9c7052356812afdabf6504212199907ccb172b3e1e5b4e8ca29eac0a7",
                                    "71852c01834ad9f0e850f43c6418b83681f7acb82eb134a23d164ae3db8e7760",
                                    "a85ecef49e3b754918db9058c0e27dd64b37c25ecba47822a608accad8957746",
                                    "b93c96af6004749e2165b74e24a7178092634997794da2c1916a4a41786bd3f4"
                                ]
                            },
                            {
                                "name": "01383b73d276c1b3293acb0f6f25ea1f284cf1de900cfae83e1d9b15a4175a9f",
                                "value": 50,
                                "ufficio": "Analisi funzionali",
                                "link": [
                                    "8c065cfe40a3279cd689a512a27dfcd0afd5c725384b0bf22eaa22308bba5302"
                                ]
                            },
                            {
                                "name": "01bc86e78cd69cc938888e5d58529827da10d3afacc31a4e746b1b235c362cb0",
                                "value": 50,
                                "ufficio": "Sviluppo e Architetture",
                                "link": [
                                    "d080f6246f89a229aff787012e414f09e420a8f174d2c85a2f342cebd9d54864"
                                ]
                            },
                            {
                                "name": "01cb3d1f0b05deae8b935d99e02fb4ebe81eca9eb3033b205117c1b7e920ec51",
                                "value": 50,
                                "ufficio": "Comunicazione & Acquisition",
                                "link": [
                                    "0dcfb7587da75801f1d0f32ca00cf2d33f23c9fd2ccac63e5e3663bc59b7691a",
                                    "150dc7a0f15ffab503fdaa3cd09471f3c6055764f95b1f6cb4ff191bfc4a1532",
                                    "165d192513323e9624c5a4cf2379a90013365677958e51611eb1fc9c39dbedb1",
                                    "21128e9f23759392be2b2b17596d9d7c496d188162fd9ece9f8aa096e189a929",
                                    "36976cfe80dcf459c6a92c1d326df99d4e0c262970320e221f4093becab351c3",
                                    "50125cffec52c899c2da565099f3b084c25f25b17db6facc28758add900ad765",
                                    "50530ca013a6c0cf2eb8037d8032df3ae078924f85236bf5403db4404265bb62",
                                    "52fd640dca47a5008d088dd235189c51fc8495106ae0a39bb021dd809350e29c",
                                    "55ff0b8efbcb37483f73f0ffe87b240272eefce6390e3c3454881cdc8b6b5638",
                                    "6c5a331a8710274d8a04ac9c315eafef0ec060aaeaf649c4322812b900a972d4",
                                    "8a69ecc023e5801658323c6a725b97387bed8cacab1bc8b740205d3534507d07",
                                    "a4e8e3247a94ebc46bb94c8b914971f5ad793847ccf16b1f41b0c88f5a366d80",
                                    "bfdbf363d9c4b0705ccbeecc39fe6db9a81f5145b54a3674b64b0da286429728",
                                    "cb6238988950d6394c11c8b1cb351bdf9c78aa272ad810159bb299ebe5b3da07",
                                    "d3a8ea432eb64faeb9c3661d563d76d6a0b9beec571b803e8a9b68e2f81111c4",
                                    "ec8901cc98702644ab46c54add211d0edfb0f416476369cdd9758afcfbe21609",
                                    "f3eae844e383579728b6d9069cc00301c5b0941b1c10a464c7b9a424618cef2a",
                                    "fbf35a4655c13d8be858814ee9f290bd64fd8712cb3cfa37e890a70515ebbcd5"
                                ]
                            },
                            {
                                "name": "02a0e9c62180eb9c2d8b7fb9e978e4ed45848287660235d5c51e2f110af4e0a9",
                                "value": 50,
                                "ufficio": "Sistemi e Monitoring",
                                "link": [
                                    "1f4ef15b42d71da6eaa84c1666da8f3aae74ebcab35d1321b195f4e6e67b7565",
                                    "370b3c12a845f303bf28657161f8285295c5c8625e7a4daf9c464c6957c03ed9",
                                    "6aa56775705605384583392112cc3a974b7bded505375979500264fb35310747",
                                    "c450b7fdfdcd44454ce327bf0975039d6c7668e23dbc6d91f7edede31875e626",
                                    "f57cdd8423add2b2919fce520a9a2d9a34184b0f716c1e6ae5508ef4ee31056e"
                                ]
                            },
                            {
                                "name": "03570a9667986e67fbc32a36c58c2a83620615f978d54065c0118b9ecf3bb513",
                                "value": 50,
                                "ufficio": "Analisi funzionali",
                                "link": [
                                    "21128e9f23759392be2b2b17596d9d7c496d188162fd9ece9f8aa096e189a929",
                                    "55ff0b8efbcb37483f73f0ffe87b240272eefce6390e3c3454881cdc8b6b5638",
                                    "6c5a331a8710274d8a04ac9c315eafef0ec060aaeaf649c4322812b900a972d4",
                                    "8a69ecc023e5801658323c6a725b97387bed8cacab1bc8b740205d3534507d07",
                                    "8a8d13915198c2121b499efa102f62d5d5ba92ee2c8d5d6659f3f787c722fe59",
                                    "a0e3fd2ed608daf8e1c3a7a51b647f091e813e1185d3761959bfb1bea3b10044",
                                    "b14ceff609c278e1c1891d643b7aa9eb267e80ce5e050863aeeddee9bcb25bd0",
                                    "d718c84701eb431e405971fea04377bfdc1869c17b6cc272ebe8ac8d8bbe74cb",
                                    "ed67329cdf04b5a943a12c63cd602a6d7f06a61d433a960d8c5514f13ca40ca9",
                                    "fb445e49cf6af4fe7c09b1e2965bd032ce19b8ed8d38af0dfb2fcf9340cddd52"
                                ]
                            },
                            {
                                "name": "0495ebf4352ad688fffa84a7e22d1eb1bd29e5c881711d881319d81a626267f7",
                                "value": 50,
                                "ufficio": "Sistemi e Monitoring",
                                "link": [
                                    "370b3c12a845f303bf28657161f8285295c5c8625e7a4daf9c464c6957c03ed9",
                                    "6aa56775705605384583392112cc3a974b7bded505375979500264fb35310747",
                                    "9185e488c18a92b2f4aa28769fc53b5e1cfe84d0b0c13a6b5e98c1f3f5266c6b",
                                    "f57cdd8423add2b2919fce520a9a2d9a34184b0f716c1e6ae5508ef4ee31056e"
                                ]
                            },
                            {
                                "name": "06d38d94f292d05cdb7df23a05ef073febdd7c68931c6a7277f5f60d9e9e9b16",
                                "value": 50,
                                "ufficio": "Analisi funzionali",
                                "link": [
                                    "1bed37d0037f2f6a95cf5d417703ce4a2eda90363991a28e65ea1c788f83008b",
                                    "8464812682363064de4d541a02afca217bb03d68d9409cbb02a96d553d50fde0"
                                ]
                            },
                            {
                                "name": "08031c40fea4b77b2203830bb3dc0aea814fda06cd5a344019eadedbde13c15b",
                                "value": 50,
                                "ufficio": "Amministrazione consulenza finanziaria",
                                "link": [
                                    "3779faf7954bbba479b99fbb0c6543df87cabb3d70654b2486c354a023406372",
                                    "50228aad62253ede25fa405a08929d5bda792f6d07a6554a5f0eae03de60d209",
                                    "c3f57a19f89946b2161a91fb0a3d1f6a4d3e4af39abdea4615a4de8f54beaff7",
                                    "ccb35cb14a71c974c83dae675e7143e1788db7293805206540c8d9df094e4ff4"
                                ]
                            },
                            {
                                "name": "083b96e848f7fe101868dfd1f46eccedc017cbaddf9f49c19c71ccab49536ac0",
                                "value": 50,
                                "ufficio": "Analisi funzionali",
                                "link": [
                                    "66dd598474bc4571b70ff70d1c88d591de4bedebce1edfc074621e71e833b081",
                                    "7ad8ce7b05bf6d2f96c6cf7d8d2c20304a9fe6cf4d7a944608666d8f36b454c9",
                                    "b01e860d623af6128973affb64ac36d84afa30b8089325c6b05134309dfc837a"
                                ]
                            },
                            {
                                "name": "08577f12554924cfe76e64922eb02b81400bb159666c32d6479e5cf7bc8d8702",
                                "value": 50,
                                "ufficio": "Analisi funzionali",
                                "link": [
                                    "03570a9667986e67fbc32a36c58c2a83620615f978d54065c0118b9ecf3bb513",
                                    "0dcfb7587da75801f1d0f32ca00cf2d33f23c9fd2ccac63e5e3663bc59b7691a",
                                    "117484657fdfa49f6f202c4a01f5869cd80e1a3dd60c339d9145c67a9fb03cae",
                                    "150dc7a0f15ffab503fdaa3cd09471f3c6055764f95b1f6cb4ff191bfc4a1532",
                                    "21128e9f23759392be2b2b17596d9d7c496d188162fd9ece9f8aa096e189a929",
                                    "22f2f48afb04cf8f3913a6e67b62209b3d7f920a429a7c3079b00e9e1eb50ae0",
                                    "238ae5b17e2e2c57af472e3f8cfba32aed5cf2f12286424acd7971a63c794c39",
                                    "2d5cdaed96acc015fa15ee3403b327066febef58d4ff9a20e06dfb7b261628ce",
                                    "38ce0ce0122e0bcdd595c12f417196d4acd690cf7b2d6cda61bc4c7208166e04",
                                    "6a03854e415b478f615318d423b54e92dd2077b56f3488690756437e32908be7",
                                    "6d1704d0094ff0d48b3987b3562e082ad66e8ff3b6edf7f57133376917a14a4c",
                                    "780f4a8c11f1780fff99ccb087d5dab5960765259902b28e778be4df8e2ac5af",
                                    "ae4dd003704535e168ebdf1066be077474b7cc1a289ab06837430a972b3799ec",
                                    "bd935ad4c48672c5e4d791aac29afac44ac184d2694524e290563ca81cb2da60",
                                    "ecd3496bb4ed76576e96edd522f6903474e2a27d352d654ae562fb4ad2b2d881",
                                    "f158062016d7c912ba1494b35e8cd64d07656b38a4a0d0992e4dc016800a3788"
                                ]
                            },
                            {
                                "name": "0a79f316c74167ab585e990f9704eccefe51f1fa8c0c57b89499f7a76a1663ed",
                                "value": 50,
                                "ufficio": "Risorse Umane e Progetti Evolutivi",
                                "link": [
                                    "26b9e209584bf3efd59e440e6838fef4d67394ca48f2a9eb7533b7d4b23f015b"
                                ]
                            },
                            {
                                "name": "0b6171902da4f7e74d4d0dfd05097833a95670b853bd7e282f15588a58258e3e",
                                "value": 50,
                                "ufficio": "Comunicazione & Acquisition",
                                "link": [
                                    "4430f196eb4eb2ff7d9806df3fad24bd892c123d66855e0e9ad2f81242892211",
                                    "52fd640dca47a5008d088dd235189c51fc8495106ae0a39bb021dd809350e29c",
                                    "bc369356384d79a8fe050a841aef947bda1114404e1249bb5ac883b4a91ae707",
                                    "be0b7f3457783af8b09e2cf60f10cfdd64903cbda18a9b1c3216ea6a97026370",
                                    "edc927c302d834fd6d1a19493931750c25e5dc949f2b2a8a8d05fb339ea75146"
                                ]
                            },
                            {
                                "name": "0dcfb7587da75801f1d0f32ca00cf2d33f23c9fd2ccac63e5e3663bc59b7691a",
                                "value": 50,
                                "ufficio": "Analisi funzionali",
                                "link": [
                                    "117484657fdfa49f6f202c4a01f5869cd80e1a3dd60c339d9145c67a9fb03cae",
                                    "150dc7a0f15ffab503fdaa3cd09471f3c6055764f95b1f6cb4ff191bfc4a1532",
                                    "15167bd90d8d12b7bed6cfcd192e5306b4e1216c584cc9c50977d8ea7f30e281",
                                    "18f8f073dd89fcf946d09157db5342889d19543390c8cd4c9dd8b05bc65fd518",
                                    "21128e9f23759392be2b2b17596d9d7c496d188162fd9ece9f8aa096e189a929",
                                    "238ae5b17e2e2c57af472e3f8cfba32aed5cf2f12286424acd7971a63c794c39",
                                    "413612c9c7052356812afdabf6504212199907ccb172b3e1e5b4e8ca29eac0a7",
                                    "52fd640dca47a5008d088dd235189c51fc8495106ae0a39bb021dd809350e29c",
                                    "5a44504d34546036745dcb5deeddab47d39a00fb19734ca7145dcc5a9dda9ea7",
                                    "67678ae13f18c6a16575a01c50d4528d22e5e3862d512414f3dd81590aecfbd2",
                                    "6d1704d0094ff0d48b3987b3562e082ad66e8ff3b6edf7f57133376917a14a4c",
                                    "71852c01834ad9f0e850f43c6418b83681f7acb82eb134a23d164ae3db8e7760",
                                    "9f6abb824f8cf4ed7997ff008f64449621aebd1d4bc7af1f46d0f238ebc954a2",
                                    "a69b26529fc2b65bf470c2a2bd22efe41f73763eec20a8a93c05ba720e9fcbd1",
                                    "cb6238988950d6394c11c8b1cb351bdf9c78aa272ad810159bb299ebe5b3da07",
                                    "d46963e985568e7dad629017de5c6f04c3ce4b2b3a584054f7b153812afcff04",
                                    "d5940e1eea800cac78acf3ef1ce311e6d22fc55b076391f5a483b2f25a813855",
                                    "ecd3496bb4ed76576e96edd522f6903474e2a27d352d654ae562fb4ad2b2d881",
                                    "ff12b55ee827fc0697d9f8c059da7ab3b2c16cdcd329686754e8e0ee92c3b5a9"
                                ]
                            },
                            {
                                "name": "0e428ffef64b10335e73fb703497fafde699c4eb8fbc533035b84489c91ec4d6",
                                "value": 50,
                                "ufficio": "Comitato di Direzione",
                                "link": [
                                    "1ca7ba56343a590938e8c410969a3947596d5e121309da4048c66a4d950e67c7",
                                    "2475b9470c6235901e7e3d9cc1201f817a6244a0603a072db7f4cee5785fa448",
                                    "33bbf19881e2d6db89f806f0f3b84552b843e90b78e06b013d145b78113d2b56",
                                    "48805a805841f176eec7131f6f75a461aadf461f846d4e5516facf15350cfaeb",
                                    "50125cffec52c899c2da565099f3b084c25f25b17db6facc28758add900ad765",
                                    "50530ca013a6c0cf2eb8037d8032df3ae078924f85236bf5403db4404265bb62",
                                    "961b69abaab45da0ffccf1bb8be8ea853b617afad66594b8414a574a33f22651",
                                    "e5d566de4dd057aff4327b06c54b22943ad417a5bdbd2a5dee4607646d9b63d9",
                                    "ea0ba1b92d9815a5d808d92bc8b85e97d2c3f3aa6f8c8407ab6add9cd7e5be7a",
                                    "ec8901cc98702644ab46c54add211d0edfb0f416476369cdd9758afcfbe21609",
                                    "edc927c302d834fd6d1a19493931750c25e5dc949f2b2a8a8d05fb339ea75146"
                                ]
                            },
                            {
                                "name": "0ea768b9c21de1396c8517abb6ba0723a8ea84ae7946b78e5f3131954b009d00",
                                "value": 50,
                                "ufficio": "Amministrazione consulenza finanziaria",
                                "link": [
                                    "3779faf7954bbba479b99fbb0c6543df87cabb3d70654b2486c354a023406372",
                                    "ccb35cb14a71c974c83dae675e7143e1788db7293805206540c8d9df094e4ff4"
                                ]
                            },
                            {
                                "name": "112b9a07fc3aac15fee0e4a28221308133e5027c629434bc046b412cf6d4b39b",
                                "value": 50,
                                "ufficio": "Digital Branch",
                                "link": [
                                    "1d3ef700b9d0aba0c20abf40c35422b416c3bba1de5564304df142cef31bca1c"
                                ]
                            },
                            {
                                "name": "1139eb48beb8d6d0ff482002d607a4a5e7ddc0b1da921eb1c759a39652d50650",
                                "value": 50,
                                "ufficio": "Comunicazione & Acquisition",
                                "link": [
                                    "0e428ffef64b10335e73fb703497fafde699c4eb8fbc533035b84489c91ec4d6",
                                    "ea0ba1b92d9815a5d808d92bc8b85e97d2c3f3aa6f8c8407ab6add9cd7e5be7a",
                                    "edc927c302d834fd6d1a19493931750c25e5dc949f2b2a8a8d05fb339ea75146"
                                ]
                            },
                            {
                                "name": "117484657fdfa49f6f202c4a01f5869cd80e1a3dd60c339d9145c67a9fb03cae",
                                "value": 50,
                                "ufficio": "Sviluppo e Architetture",
                                "link": [
                                    "0dcfb7587da75801f1d0f32ca00cf2d33f23c9fd2ccac63e5e3663bc59b7691a",
                                    "1f4ef15b42d71da6eaa84c1666da8f3aae74ebcab35d1321b195f4e6e67b7565",
                                    "38ce0ce0122e0bcdd595c12f417196d4acd690cf7b2d6cda61bc4c7208166e04",
                                    "ae4dd003704535e168ebdf1066be077474b7cc1a289ab06837430a972b3799ec",
                                    "bd935ad4c48672c5e4d791aac29afac44ac184d2694524e290563ca81cb2da60",
                                    "f4f2d477cc383f7885fc3724f0a204025e3ef04f34c7478a185beac0c595ff6c"
                                ]
                            },
                            {
                                "name": "150dc7a0f15ffab503fdaa3cd09471f3c6055764f95b1f6cb4ff191bfc4a1532",
                                "value": 50,
                                "ufficio": "Analisi funzionali",
                                "link": [
                                    "0dcfb7587da75801f1d0f32ca00cf2d33f23c9fd2ccac63e5e3663bc59b7691a",
                                    "1f4ef15b42d71da6eaa84c1666da8f3aae74ebcab35d1321b195f4e6e67b7565",
                                    "21128e9f23759392be2b2b17596d9d7c496d188162fd9ece9f8aa096e189a929",
                                    "36976cfe80dcf459c6a92c1d326df99d4e0c262970320e221f4093becab351c3",
                                    "413612c9c7052356812afdabf6504212199907ccb172b3e1e5b4e8ca29eac0a7",
                                    "52fd640dca47a5008d088dd235189c51fc8495106ae0a39bb021dd809350e29c",
                                    "61d29662a79cdedd32b1e54888938447235caf30c466d7ff090305868dc5919d",
                                    "a4e8e3247a94ebc46bb94c8b914971f5ad793847ccf16b1f41b0c88f5a366d80",
                                    "afad2cd7dd9c3b8c52ac4c46b3e5ee15825047d8f739eea664b6a80a33a44d16",
                                    "bc369356384d79a8fe050a841aef947bda1114404e1249bb5ac883b4a91ae707"
                                ]
                            },
                            {
                                "name": "15167bd90d8d12b7bed6cfcd192e5306b4e1216c584cc9c50977d8ea7f30e281",
                                "value": 50,
                                "ufficio": "User Experience",
                                "link": [
                                    "4430f196eb4eb2ff7d9806df3fad24bd892c123d66855e0e9ad2f81242892211",
                                    "52fd640dca47a5008d088dd235189c51fc8495106ae0a39bb021dd809350e29c",
                                    "650644c665a69dc950d6f6a16d1f31f06f1ed11b2b255f7540987898da9961d0",
                                    "67dac3f288d9e1b5af98c40f069f11ef7eb895d715f2bf7481fc81988c386e6f",
                                    "a4e8e3247a94ebc46bb94c8b914971f5ad793847ccf16b1f41b0c88f5a366d80",
                                    "bc369356384d79a8fe050a841aef947bda1114404e1249bb5ac883b4a91ae707",
                                    "be0b7f3457783af8b09e2cf60f10cfdd64903cbda18a9b1c3216ea6a97026370"
                                ]
                            },
                            {
                                "name": "165d192513323e9624c5a4cf2379a90013365677958e51611eb1fc9c39dbedb1",
                                "value": 50,
                                "ufficio": "Commerciale Banking & Credito",
                                "link": [
                                    "246fa0d14d762dcb827e140c56d3ef32baf59f87dd78b8e27cdecc6670ac8a73",
                                    "29e353b18fae9b63560f47b3f9cbcc291aad0650ad44705c79a9a4abd05ebe9c",
                                    "516c0937b94123241b706f185cbaf5039307313eaf7200e1e0ba64bc880c17f5"
                                ]
                            },
                            {
                                "name": "18f8f073dd89fcf946d09157db5342889d19543390c8cd4c9dd8b05bc65fd518",
                                "value": 50,
                                "ufficio": "Sviluppo e Architetture",
                                "link": [
                                    "00d7df14c3d418e6b9aa1fdbf670004b7bdde6bed851cbdb44d095c5d53633c3",
                                    "02a0e9c62180eb9c2d8b7fb9e978e4ed45848287660235d5c51e2f110af4e0a9",
                                    "0dcfb7587da75801f1d0f32ca00cf2d33f23c9fd2ccac63e5e3663bc59b7691a",
                                    "1f4ef15b42d71da6eaa84c1666da8f3aae74ebcab35d1321b195f4e6e67b7565",
                                    "413612c9c7052356812afdabf6504212199907ccb172b3e1e5b4e8ca29eac0a7",
                                    "a69b26529fc2b65bf470c2a2bd22efe41f73763eec20a8a93c05ba720e9fcbd1",
                                    "c450b7fdfdcd44454ce327bf0975039d6c7668e23dbc6d91f7edede31875e626",
                                    "ce118f98f944cb4b7bf553a6c3361f5e5ffb39bc33050ac457ff5e68c3726eea"
                                ]
                            },
                            {
                                "name": "1918402b88bbadb987f2e2d0b0bc9444f824af4bd707b1abcfe9bee5174fc7fa",
                                "value": 50,
                                "ufficio": "Commerciale Investimenti",
                                "link": [
                                    "249e0a20ac231db9725c70ada8c80912404edc3cd67822a40f5169798f64b8e4",
                                    "5cf2a19a87bbda97652a8134adeaf6aeaaca8d8a42b4a34bca1ff827cd0bc3c2",
                                    "f680ca858e1602fe43773de835e5ead85a16f0a59a88465afbb6badbc99abd8a"
                                ]
                            },
                            {
                                "name": "1bed37d0037f2f6a95cf5d417703ce4a2eda90363991a28e65ea1c788f83008b",
                                "value": 50,
                                "ufficio": "Analisi funzionali",
                                "link": [
                                    "1f4ef15b42d71da6eaa84c1666da8f3aae74ebcab35d1321b195f4e6e67b7565",
                                    "45cdf162e9a98c22f44e9e1259be540e5fc454f582ccb8e98371f00de1c23f04",
                                    "50530ca013a6c0cf2eb8037d8032df3ae078924f85236bf5403db4404265bb62",
                                    "7b138a4913227d7a34f13a5c994fca2a492f6051b242e9eb45d9af1d99bc982e",
                                    "8464812682363064de4d541a02afca217bb03d68d9409cbb02a96d553d50fde0",
                                    "8ddfe2ebab57cffc3af0f950c74433476a320a56aaf55fe5a0e2f74b31526a71",
                                    "c512dc7d82ab25e44b85c3b5d9585c6c3b7045e9340171b6fb0ca43e1eaba9d7",
                                    "d080f6246f89a229aff787012e414f09e420a8f174d2c85a2f342cebd9d54864",
                                    "f158062016d7c912ba1494b35e8cd64d07656b38a4a0d0992e4dc016800a3788"
                                ]
                            },
                            {
                                "name": "1ca7ba56343a590938e8c410969a3947596d5e121309da4048c66a4d950e67c7",
                                "value": 50,
                                "ufficio": "Comunicazione & Acquisition",
                                "link": [
                                    "0e428ffef64b10335e73fb703497fafde699c4eb8fbc533035b84489c91ec4d6",
                                    "5a980b472b83f2974fbb4ecf1061cc8ba78472476347de63d56222b8323f3d18",
                                    "ea0ba1b92d9815a5d808d92bc8b85e97d2c3f3aa6f8c8407ab6add9cd7e5be7a",
                                    "edc927c302d834fd6d1a19493931750c25e5dc949f2b2a8a8d05fb339ea75146"
                                ]
                            },
                            {
                                "name": "1cff424a7b25c230a1c209b630182b4f91fa24bf231d02e19d8f6bc8a86731a1",
                                "value": 50,
                                "ufficio": "Data Intelligence",
                                "link": [
                                    "773ed157955ddce568e45fe6df032c00be2dc8274003ab9a49aee3a472b0dd03",
                                    "915e1d4b27b8f8d1ff4a6f3fd44f14629b0aa665d34f20020877d7d3ac517c40",
                                    "afad2cd7dd9c3b8c52ac4c46b3e5ee15825047d8f739eea664b6a80a33a44d16",
                                    "bf8d8288d88711ebe7141155c39887959509933d7d5a34c34d58c3e2a6d44521"
                                ]
                            },
                            {
                                "name": "1d3ef700b9d0aba0c20abf40c35422b416c3bba1de5564304df142cef31bca1c",
                                "value": 50,
                                "ufficio": "Digital Branch",
                                "link": [
                                    "112b9a07fc3aac15fee0e4a28221308133e5027c629434bc046b412cf6d4b39b"
                                ]
                            },
                            {
                                "name": "1e1408f522fee7c257dc2cc4f59aae8b01bba617cd271e798d0b765900bb7d9b",
                                "value": 50,
                                "ufficio": "Crediti",
                                "link": [
                                    "516c0937b94123241b706f185cbaf5039307313eaf7200e1e0ba64bc880c17f5",
                                    "6f73b50dc0122b97db84a795721a6b625261b938843fcbf467616db0d9811fc5",
                                    "76edfcd09cca7d558ec875aa9aecf369c69b8573cf04142b30cfb7199bbfebc5"
                                ]
                            },
                            {
                                "name": "1f4ef15b42d71da6eaa84c1666da8f3aae74ebcab35d1321b195f4e6e67b7565",
                                "value": 50,
                                "ufficio": "Sviluppo e Architetture",
                                "link": [
                                    "117484657fdfa49f6f202c4a01f5869cd80e1a3dd60c339d9145c67a9fb03cae",
                                    "18f8f073dd89fcf946d09157db5342889d19543390c8cd4c9dd8b05bc65fd518",
                                    "21128e9f23759392be2b2b17596d9d7c496d188162fd9ece9f8aa096e189a929",
                                    "238ae5b17e2e2c57af472e3f8cfba32aed5cf2f12286424acd7971a63c794c39",
                                    "370b3c12a845f303bf28657161f8285295c5c8625e7a4daf9c464c6957c03ed9",
                                    "413612c9c7052356812afdabf6504212199907ccb172b3e1e5b4e8ca29eac0a7",
                                    "50530ca013a6c0cf2eb8037d8032df3ae078924f85236bf5403db4404265bb62",
                                    "5a44504d34546036745dcb5deeddab47d39a00fb19734ca7145dcc5a9dda9ea7",
                                    "67678ae13f18c6a16575a01c50d4528d22e5e3862d512414f3dd81590aecfbd2",
                                    "853ac84efec82736323b7c9a6cdcffe8ae2cf3da4bde5591d1568837b6c96ee9",
                                    "9185e488c18a92b2f4aa28769fc53b5e1cfe84d0b0c13a6b5e98c1f3f5266c6b",
                                    "a4e8e3247a94ebc46bb94c8b914971f5ad793847ccf16b1f41b0c88f5a366d80",
                                    "a88b39bfb012c98803e66f0b018c25d1ffa5766c21260b623d9ddff1b90edc37",
                                    "cd799a97931432b5e176c88142dc61546e826bc2e69a5bc0ec58df54d78c9b74",
                                    "ce118f98f944cb4b7bf553a6c3361f5e5ffb39bc33050ac457ff5e68c3726eea",
                                    "ecd3496bb4ed76576e96edd522f6903474e2a27d352d654ae562fb4ad2b2d881",
                                    "ee64e69e3c67b6db4180164ace85772c427d49160d44da1a27032fe2be2cd16c"
                                ]
                            },
                            {
                                "name": "21128e9f23759392be2b2b17596d9d7c496d188162fd9ece9f8aa096e189a929",
                                "value": 50,
                                "ufficio": "Analisi funzionali",
                                "link": [
                                    "03570a9667986e67fbc32a36c58c2a83620615f978d54065c0118b9ecf3bb513",
                                    "08577f12554924cfe76e64922eb02b81400bb159666c32d6479e5cf7bc8d8702",
                                    "0dcfb7587da75801f1d0f32ca00cf2d33f23c9fd2ccac63e5e3663bc59b7691a",
                                    "150dc7a0f15ffab503fdaa3cd09471f3c6055764f95b1f6cb4ff191bfc4a1532",
                                    "1f4ef15b42d71da6eaa84c1666da8f3aae74ebcab35d1321b195f4e6e67b7565",
                                    "246fa0d14d762dcb827e140c56d3ef32baf59f87dd78b8e27cdecc6670ac8a73",
                                    "2d5cdaed96acc015fa15ee3403b327066febef58d4ff9a20e06dfb7b261628ce",
                                    "36976cfe80dcf459c6a92c1d326df99d4e0c262970320e221f4093becab351c3",
                                    "38ce0ce0122e0bcdd595c12f417196d4acd690cf7b2d6cda61bc4c7208166e04",
                                    "413612c9c7052356812afdabf6504212199907ccb172b3e1e5b4e8ca29eac0a7",
                                    "50530ca013a6c0cf2eb8037d8032df3ae078924f85236bf5403db4404265bb62",
                                    "55ff0b8efbcb37483f73f0ffe87b240272eefce6390e3c3454881cdc8b6b5638",
                                    "6d1704d0094ff0d48b3987b3562e082ad66e8ff3b6edf7f57133376917a14a4c",
                                    "7ad8ce7b05bf6d2f96c6cf7d8d2c20304a9fe6cf4d7a944608666d8f36b454c9",
                                    "7c8496883cb2bea0087ab91f32c798eb9219ff32d0a56db7da8444d821f45d81",
                                    "a4e8e3247a94ebc46bb94c8b914971f5ad793847ccf16b1f41b0c88f5a366d80",
                                    "bd935ad4c48672c5e4d791aac29afac44ac184d2694524e290563ca81cb2da60",
                                    "cd799a97931432b5e176c88142dc61546e826bc2e69a5bc0ec58df54d78c9b74",
                                    "f57cdd8423add2b2919fce520a9a2d9a34184b0f716c1e6ae5508ef4ee31056e"
                                ]
                            },
                            {
                                "name": "213a6c50db2433bd2d9a3300f4508d4c8ab4f102d19763dfb18fcaa06364c2eb",
                                "value": 50,
                                "ufficio": "Pianificazione e Controllo di Gestione",
                                "link": [
                                    "50125cffec52c899c2da565099f3b084c25f25b17db6facc28758add900ad765",
                                    "50228aad62253ede25fa405a08929d5bda792f6d07a6554a5f0eae03de60d209",
                                    "ec8901cc98702644ab46c54add211d0edfb0f416476369cdd9758afcfbe21609",
                                    "f680ca858e1602fe43773de835e5ead85a16f0a59a88465afbb6badbc99abd8a"
                                ]
                            },
                            {
                                "name": "22f2f48afb04cf8f3913a6e67b62209b3d7f920a429a7c3079b00e9e1eb50ae0",
                                "value": 50,
                                "ufficio": "Analisi funzionali",
                                "link": [
                                    "117484657fdfa49f6f202c4a01f5869cd80e1a3dd60c339d9145c67a9fb03cae",
                                    "21128e9f23759392be2b2b17596d9d7c496d188162fd9ece9f8aa096e189a929",
                                    "238ae5b17e2e2c57af472e3f8cfba32aed5cf2f12286424acd7971a63c794c39",
                                    "2d5cdaed96acc015fa15ee3403b327066febef58d4ff9a20e06dfb7b261628ce",
                                    "38ce0ce0122e0bcdd595c12f417196d4acd690cf7b2d6cda61bc4c7208166e04",
                                    "61d29662a79cdedd32b1e54888938447235caf30c466d7ff090305868dc5919d",
                                    "6d1704d0094ff0d48b3987b3562e082ad66e8ff3b6edf7f57133376917a14a4c",
                                    "a85ecef49e3b754918db9058c0e27dd64b37c25ecba47822a608accad8957746",
                                    "bd935ad4c48672c5e4d791aac29afac44ac184d2694524e290563ca81cb2da60",
                                    "d718c84701eb431e405971fea04377bfdc1869c17b6cc272ebe8ac8d8bbe74cb",
                                    "ecd3496bb4ed76576e96edd522f6903474e2a27d352d654ae562fb4ad2b2d881"
                                ]
                            },
                            {
                                "name": "2385eaf1b82db67a6b1f4fa4e968876b08c7e15fd10c6622c584148972c1e0df",
                                "value": 50,
                                "ufficio": "Risorse Umane e Progetti Evolutivi",
                                "link": [
                                    "0a79f316c74167ab585e990f9704eccefe51f1fa8c0c57b89499f7a76a1663ed",
                                    "26b9e209584bf3efd59e440e6838fef4d67394ca48f2a9eb7533b7d4b23f015b",
                                    "50530ca013a6c0cf2eb8037d8032df3ae078924f85236bf5403db4404265bb62",
                                    "d3a8ea432eb64faeb9c3661d563d76d6a0b9beec571b803e8a9b68e2f81111c4",
                                    "fbf35a4655c13d8be858814ee9f290bd64fd8712cb3cfa37e890a70515ebbcd5"
                                ]
                            },
                            {
                                "name": "238ae5b17e2e2c57af472e3f8cfba32aed5cf2f12286424acd7971a63c794c39",
                                "value": 50,
                                "ufficio": "Sviluppo e Architetture",
                                "link": [
                                    "2d5cdaed96acc015fa15ee3403b327066febef58d4ff9a20e06dfb7b261628ce",
                                    "67678ae13f18c6a16575a01c50d4528d22e5e3862d512414f3dd81590aecfbd2",
                                    "71852c01834ad9f0e850f43c6418b83681f7acb82eb134a23d164ae3db8e7760"
                                ]
                            },
                            {
                                "name": "23a9f81805333f7e325484837c8b8874e22105346952d2e500f93a61afa5c928",
                                "value": 50,
                                "ufficio": "Pianificazione e Controllo di Gestione",
                                "link": [
                                    "2475b9470c6235901e7e3d9cc1201f817a6244a0603a072db7f4cee5785fa448",
                                    "393d9a38dc8b3113ef24b6e23d4c1aa82cc8e2371bc18aad4ab32219531554d5",
                                    "50125cffec52c899c2da565099f3b084c25f25b17db6facc28758add900ad765",
                                    "50228aad62253ede25fa405a08929d5bda792f6d07a6554a5f0eae03de60d209",
                                    "50530ca013a6c0cf2eb8037d8032df3ae078924f85236bf5403db4404265bb62",
                                    "5a980b472b83f2974fbb4ecf1061cc8ba78472476347de63d56222b8323f3d18",
                                    "6101c7c61c9aebce7ec0d0ac3ecfbf7be4b2ea92b01a55a3b49e244fdb4af554",
                                    "7c8496883cb2bea0087ab91f32c798eb9219ff32d0a56db7da8444d821f45d81",
                                    "961b69abaab45da0ffccf1bb8be8ea853b617afad66594b8414a574a33f22651",
                                    "b93c96af6004749e2165b74e24a7178092634997794da2c1916a4a41786bd3f4",
                                    "ba153e31ef3d2a4d304a7d61753093336ec2b48414f6a336cef696dbc9e44d94",
                                    "ea0ba1b92d9815a5d808d92bc8b85e97d2c3f3aa6f8c8407ab6add9cd7e5be7a",
                                    "edc927c302d834fd6d1a19493931750c25e5dc949f2b2a8a8d05fb339ea75146",
                                    "f6d9c7262c3bbcc3b8c695a1e98caf93d40ca532dd02620709ece64a72e042ed"
                                ]
                            },
                            {
                                "name": "246fa0d14d762dcb827e140c56d3ef32baf59f87dd78b8e27cdecc6670ac8a73",
                                "value": 50,
                                "ufficio": "Comitato di Direzione",
                                "link": [
                                    "0e428ffef64b10335e73fb703497fafde699c4eb8fbc533035b84489c91ec4d6",
                                    "2475b9470c6235901e7e3d9cc1201f817a6244a0603a072db7f4cee5785fa448",
                                    "2782ff4e895a9d5f2a1edc00d3922d7f7e640c3ee5a6400fb8c4ab3532249c3e",
                                    "50125cffec52c899c2da565099f3b084c25f25b17db6facc28758add900ad765",
                                    "50530ca013a6c0cf2eb8037d8032df3ae078924f85236bf5403db4404265bb62",
                                    "ec8901cc98702644ab46c54add211d0edfb0f416476369cdd9758afcfbe21609",
                                    "f6d9c7262c3bbcc3b8c695a1e98caf93d40ca532dd02620709ece64a72e042ed",
                                    "faad46c5aeb820d0a642a5f11aefea24266c0c23285b78fc7011e9aa672988d6"
                                ]
                            },
                            {
                                "name": "2470fd17d1f1959717120deadaaa3b455dd3c74c8ced88054a8b4fb88e4f8f28",
                                "value": 50,
                                "ufficio": "User Experience",
                                "link": [
                                    "52fd640dca47a5008d088dd235189c51fc8495106ae0a39bb021dd809350e29c",
                                    "be0b7f3457783af8b09e2cf60f10cfdd64903cbda18a9b1c3216ea6a97026370"
                                ]
                            },
                            {
                                "name": "2475b9470c6235901e7e3d9cc1201f817a6244a0603a072db7f4cee5785fa448",
                                "value": 50,
                                "ufficio": "Comitato di Direzione",
                                "link": [
                                    "0e428ffef64b10335e73fb703497fafde699c4eb8fbc533035b84489c91ec4d6",
                                    "48cdde88d13cecd870e6200115cd667de65900c03b57e0e5b7e4cd3da1e1fd21",
                                    "50125cffec52c899c2da565099f3b084c25f25b17db6facc28758add900ad765",
                                    "50530ca013a6c0cf2eb8037d8032df3ae078924f85236bf5403db4404265bb62",
                                    "6120dd083a4c1cf13dd2c8ca1f92591ac48875307ff66fc8da019512f3d7a3df",
                                    "849fba80ec2db6a0b1729e9732c6ccd8874bc00647aa1064c22590b292703dc0",
                                    "cd799a97931432b5e176c88142dc61546e826bc2e69a5bc0ec58df54d78c9b74",
                                    "e118679565fed3549f6e05b398f1275b4cb0c955e46ec0c6bea540974364cd48",
                                    "ec8901cc98702644ab46c54add211d0edfb0f416476369cdd9758afcfbe21609",
                                    "f680ca858e1602fe43773de835e5ead85a16f0a59a88465afbb6badbc99abd8a"
                                ]
                            },
                            {
                                "name": "249e0a20ac231db9725c70ada8c80912404edc3cd67822a40f5169798f64b8e4",
                                "value": 50,
                                "ufficio": "Commerciale Investimenti",
                                "link": [
                                    "1918402b88bbadb987f2e2d0b0bc9444f824af4bd707b1abcfe9bee5174fc7fa"
                                ]
                            },
                            {
                                "name": "25fbc277ed0e153fb6a3f0f720ddf7fe29b22c080091aac35336fad495fcd8f3",
                                "value": 50,
                                "ufficio": "Risorse Umane e Progetti Evolutivi",
                                "link": [
                                    "0e428ffef64b10335e73fb703497fafde699c4eb8fbc533035b84489c91ec4d6",
                                    "4d783066b85f21a7821f82097dae1aed71418fef7af48fc51befffe93af3d9cf",
                                    "50125cffec52c899c2da565099f3b084c25f25b17db6facc28758add900ad765",
                                    "50530ca013a6c0cf2eb8037d8032df3ae078924f85236bf5403db4404265bb62"
                                ]
                            },
                            {
                                "name": "26b9e209584bf3efd59e440e6838fef4d67394ca48f2a9eb7533b7d4b23f015b",
                                "value": 50,
                                "ufficio": "Risorse Umane e Progetti Evolutivi",
                                "link": [
                                    "0a79f316c74167ab585e990f9704eccefe51f1fa8c0c57b89499f7a76a1663ed",
                                    "2385eaf1b82db67a6b1f4fa4e968876b08c7e15fd10c6622c584148972c1e0df",
                                    "d3a8ea432eb64faeb9c3661d563d76d6a0b9beec571b803e8a9b68e2f81111c4",
                                    "fbf35a4655c13d8be858814ee9f290bd64fd8712cb3cfa37e890a70515ebbcd5"
                                ]
                            },
                            {
                                "name": "2b3a1884d7e6e625a7670bab5cf9d5ba47a62ce3d3413da9d7d3998ba89a9084",
                                "value": 50,
                                "ufficio": "Customer care",
                                "link": [
                                    "98ec457cf162a8f703687f934124a5f4df58992b64337f49112b3982b0464666",
                                    "b93c96af6004749e2165b74e24a7178092634997794da2c1916a4a41786bd3f4",
                                    "f43e34018b549d8863e860d611e560ddc05865730aece3e8859a85dbbde6a3d0",
                                    "ff12b55ee827fc0697d9f8c059da7ab3b2c16cdcd329686754e8e0ee92c3b5a9"
                                ]
                            },
                            {
                                "name": "2c0bf0e974b6d546d0f055bc48ff1dac9b792968f17c48e34579ee4e3a5fd421",
                                "value": 50,
                                "ufficio": "Digital Branch",
                                "link": [
                                    "82755a463db01fe7ecad9815a9cfe8bc137a646110a3727d11e338f858f2f59f"
                                ]
                            },
                            {
                                "name": "2cca58c36e9accd51799044e15182df022e053e34c24f13f5577c316ca8db892",
                                "value": 50,
                                "ufficio": "Risk & Fraud",
                                "link": [
                                    "94b0716f2a1b16dc7f4e9a57bc682f54c39daa1c7b6c99569471264755d9484c",
                                    "c3c4106b8583c84e11c12032747f577b4c00c5b84c348d9c586d539f072a7589"
                                ]
                            },
                            {
                                "name": "2d5cdaed96acc015fa15ee3403b327066febef58d4ff9a20e06dfb7b261628ce",
                                "value": 50,
                                "ufficio": "Analisi funzionali",
                                "link": [
                                    "00d7df14c3d418e6b9aa1fdbf670004b7bdde6bed851cbdb44d095c5d53633c3",
                                    "08577f12554924cfe76e64922eb02b81400bb159666c32d6479e5cf7bc8d8702",
                                    "150dc7a0f15ffab503fdaa3cd09471f3c6055764f95b1f6cb4ff191bfc4a1532",
                                    "21128e9f23759392be2b2b17596d9d7c496d188162fd9ece9f8aa096e189a929",
                                    "238ae5b17e2e2c57af472e3f8cfba32aed5cf2f12286424acd7971a63c794c39",
                                    "349a49288d7cc6c6156fa56b7adac7b47db5db8703713ce1deaef5b759f637a3",
                                    "352097edcec7e4cc4332657c589d7506b13fa583c8078c419c1f1e22fc6bb66a",
                                    "38ce0ce0122e0bcdd595c12f417196d4acd690cf7b2d6cda61bc4c7208166e04",
                                    "4430f196eb4eb2ff7d9806df3fad24bd892c123d66855e0e9ad2f81242892211",
                                    "4a1b956ec7c17b2702fc296dc1e39ab99c8277241d40934048f3dc786b8dcb48",
                                    "67678ae13f18c6a16575a01c50d4528d22e5e3862d512414f3dd81590aecfbd2",
                                    "6d1704d0094ff0d48b3987b3562e082ad66e8ff3b6edf7f57133376917a14a4c",
                                    "a85ecef49e3b754918db9058c0e27dd64b37c25ecba47822a608accad8957746",
                                    "b93c96af6004749e2165b74e24a7178092634997794da2c1916a4a41786bd3f4",
                                    "bd935ad4c48672c5e4d791aac29afac44ac184d2694524e290563ca81cb2da60",
                                    "e684fac9c014b3716dea4475452a3460cc3005ff267aaa9ca909228ecd7d91d5",
                                    "f43e34018b549d8863e860d611e560ddc05865730aece3e8859a85dbbde6a3d0",
                                    "fa5deed70dae7bbed11f872ecc4a3cf64ff21e2099602e3dfbe91fdc869493f3",
                                    "ff12b55ee827fc0697d9f8c059da7ab3b2c16cdcd329686754e8e0ee92c3b5a9"
                                ]
                            },
                            {
                                "name": "2e9726ae5dda17566272beb084d6c395b508fd46bb4013dfbc2c1ee042a1edba",
                                "value": 50,
                                "ufficio": "Finance Unit",
                                "link": [
                                    "d737b37cfaabf6ae06942149c5b4ee5d6f51bb83f96c22a97a02dd55aae26797"
                                ]
                            },
                            {
                                "name": "2f02900e1dd4918db6a502619ac506938f4be868a26074349313ed3ab6713f1f",
                                "value": 50,
                                "ufficio": "Sviluppo e Architetture",
                                "link": [
                                    "516c0937b94123241b706f185cbaf5039307313eaf7200e1e0ba64bc880c17f5",
                                    "5f9c999f59aed9c22e6e9f149df9f9ebd5d62d7841b0ea9be721ca9ce7baa094",
                                    "6f73b50dc0122b97db84a795721a6b625261b938843fcbf467616db0d9811fc5",
                                    "969a210a20c074567b7fe306aff7184e0b6fd2340b6952a58423d31aab0e441a"
                                ]
                            },
                            {
                                "name": "33bbf19881e2d6db89f806f0f3b84552b843e90b78e06b013d145b78113d2b56",
                                "value": 50,
                                "ufficio": "Eventi e Brand",
                                "link": [
                                    "0e428ffef64b10335e73fb703497fafde699c4eb8fbc533035b84489c91ec4d6",
                                    "961b69abaab45da0ffccf1bb8be8ea853b617afad66594b8414a574a33f22651"
                                ]
                            },
                            {
                                "name": "349a49288d7cc6c6156fa56b7adac7b47db5db8703713ce1deaef5b759f637a3",
                                "value": 50,
                                "ufficio": "Analisi funzionali",
                                "link": [
                                    "bd935ad4c48672c5e4d791aac29afac44ac184d2694524e290563ca81cb2da60",
                                    "ecd3496bb4ed76576e96edd522f6903474e2a27d352d654ae562fb4ad2b2d881"
                                ]
                            },
                            {
                                "name": "352097edcec7e4cc4332657c589d7506b13fa583c8078c419c1f1e22fc6bb66a",
                                "value": 50,
                                "ufficio": "Analisi funzionali",
                                "link": [
                                    "00d7df14c3d418e6b9aa1fdbf670004b7bdde6bed851cbdb44d095c5d53633c3",
                                    "15167bd90d8d12b7bed6cfcd192e5306b4e1216c584cc9c50977d8ea7f30e281",
                                    "2d5cdaed96acc015fa15ee3403b327066febef58d4ff9a20e06dfb7b261628ce",
                                    "38ce0ce0122e0bcdd595c12f417196d4acd690cf7b2d6cda61bc4c7208166e04",
                                    "413612c9c7052356812afdabf6504212199907ccb172b3e1e5b4e8ca29eac0a7",
                                    "52fd640dca47a5008d088dd235189c51fc8495106ae0a39bb021dd809350e29c",
                                    "5a44504d34546036745dcb5deeddab47d39a00fb19734ca7145dcc5a9dda9ea7",
                                    "61d29662a79cdedd32b1e54888938447235caf30c466d7ff090305868dc5919d",
                                    "655e07f5ed4e15423d43a532542c117b3514eec081633d73af8e542afc4f4b21",
                                    "71852c01834ad9f0e850f43c6418b83681f7acb82eb134a23d164ae3db8e7760",
                                    "8787740c45c655c0511fa208a842b48fd6818c89e1f9a4390482faaa868a027f",
                                    "9f6abb824f8cf4ed7997ff008f64449621aebd1d4bc7af1f46d0f238ebc954a2",
                                    "a85ecef49e3b754918db9058c0e27dd64b37c25ecba47822a608accad8957746",
                                    "b93c96af6004749e2165b74e24a7178092634997794da2c1916a4a41786bd3f4",
                                    "bc369356384d79a8fe050a841aef947bda1114404e1249bb5ac883b4a91ae707",
                                    "bd935ad4c48672c5e4d791aac29afac44ac184d2694524e290563ca81cb2da60",
                                    "ce5d99de22269fea7904a89036f0aef155beabc375707b88bd2d1b73d7eddb11",
                                    "d479e93d42e5581e11ff27c6eaacf71c682fe3914a72e77fdf5702dd9b182c36",
                                    "fa5deed70dae7bbed11f872ecc4a3cf64ff21e2099602e3dfbe91fdc869493f3"
                                ]
                            },
                            {
                                "name": "36976cfe80dcf459c6a92c1d326df99d4e0c262970320e221f4093becab351c3",
                                "value": 50,
                                "ufficio": "Risorse Umane e Progetti Evolutivi",
                                "link": [
                                    "150dc7a0f15ffab503fdaa3cd09471f3c6055764f95b1f6cb4ff191bfc4a1532",
                                    "21128e9f23759392be2b2b17596d9d7c496d188162fd9ece9f8aa096e189a929",
                                    "393d9a38dc8b3113ef24b6e23d4c1aa82cc8e2371bc18aad4ab32219531554d5",
                                    "413612c9c7052356812afdabf6504212199907ccb172b3e1e5b4e8ca29eac0a7",
                                    "50530ca013a6c0cf2eb8037d8032df3ae078924f85236bf5403db4404265bb62",
                                    "814b7e522b4b800335488042e5dad575528905ba0ad796d4ec52b02ad98c5f77",
                                    "9445045a5965f1248b6d49d8030bc56fdf6882ac9ee60a13c0eb87244b8c7060",
                                    "94b0716f2a1b16dc7f4e9a57bc682f54c39daa1c7b6c99569471264755d9484c",
                                    "afad2cd7dd9c3b8c52ac4c46b3e5ee15825047d8f739eea664b6a80a33a44d16",
                                    "d3a8ea432eb64faeb9c3661d563d76d6a0b9beec571b803e8a9b68e2f81111c4",
                                    "e1e1ab4cf8b84afd5499dc456da34147501ef9dec70cb33c3115a7dcc1f9211b",
                                    "f3eae844e383579728b6d9069cc00301c5b0941b1c10a464c7b9a424618cef2a"
                                ]
                            },
                            {
                                "name": "370b3c12a845f303bf28657161f8285295c5c8625e7a4daf9c464c6957c03ed9",
                                "value": 50,
                                "ufficio": "Sistemi e Monitoring",
                                "link": [
                                    "02a0e9c62180eb9c2d8b7fb9e978e4ed45848287660235d5c51e2f110af4e0a9",
                                    "0495ebf4352ad688fffa84a7e22d1eb1bd29e5c881711d881319d81a626267f7",
                                    "1f4ef15b42d71da6eaa84c1666da8f3aae74ebcab35d1321b195f4e6e67b7565",
                                    "5a44504d34546036745dcb5deeddab47d39a00fb19734ca7145dcc5a9dda9ea7",
                                    "6aa56775705605384583392112cc3a974b7bded505375979500264fb35310747",
                                    "853ac84efec82736323b7c9a6cdcffe8ae2cf3da4bde5591d1568837b6c96ee9",
                                    "8c3e02eabd321bb7ae9ba21563338ba3df344ab68b381680bcc3a75a2a8ccdf4",
                                    "9185e488c18a92b2f4aa28769fc53b5e1cfe84d0b0c13a6b5e98c1f3f5266c6b",
                                    "c450b7fdfdcd44454ce327bf0975039d6c7668e23dbc6d91f7edede31875e626",
                                    "ce118f98f944cb4b7bf553a6c3361f5e5ffb39bc33050ac457ff5e68c3726eea",
                                    "f57cdd8423add2b2919fce520a9a2d9a34184b0f716c1e6ae5508ef4ee31056e"
                                ]
                            },
                            {
                                "name": "3779faf7954bbba479b99fbb0c6543df87cabb3d70654b2486c354a023406372",
                                "value": 50,
                                "ufficio": "Amministrazione consulenza finanziaria",
                                "link": [
                                    "08031c40fea4b77b2203830bb3dc0aea814fda06cd5a344019eadedbde13c15b",
                                    "0ea768b9c21de1396c8517abb6ba0723a8ea84ae7946b78e5f3131954b009d00",
                                    "3e96a90c292519dd959a327f2fa19128d2870160698271969dad21652684fdbd",
                                    "50125cffec52c899c2da565099f3b084c25f25b17db6facc28758add900ad765",
                                    "50228aad62253ede25fa405a08929d5bda792f6d07a6554a5f0eae03de60d209",
                                    "64729b37ca40f8417c6bcce885bad4ab76f2054b6619769b4f01533428e6a0f8",
                                    "7db5e7074c95a92b6e3bd1a1cd1ca89bcc8269cdfe18d25a15156a26a0ad538c",
                                    "9344687749ccaab48b3f7d2d5e3b9cf0f71d8b4abb2f99f6b094fc18f64ad3eb",
                                    "c3f57a19f89946b2161a91fb0a3d1f6a4d3e4af39abdea4615a4de8f54beaff7",
                                    "ccb35cb14a71c974c83dae675e7143e1788db7293805206540c8d9df094e4ff4",
                                    "e118679565fed3549f6e05b398f1275b4cb0c955e46ec0c6bea540974364cd48",
                                    "f680ca858e1602fe43773de835e5ead85a16f0a59a88465afbb6badbc99abd8a"
                                ]
                            },
                            {
                                "name": "38ce0ce0122e0bcdd595c12f417196d4acd690cf7b2d6cda61bc4c7208166e04",
                                "value": 50,
                                "ufficio": "Analisi funzionali",
                                "link": [
                                    "21128e9f23759392be2b2b17596d9d7c496d188162fd9ece9f8aa096e189a929",
                                    "238ae5b17e2e2c57af472e3f8cfba32aed5cf2f12286424acd7971a63c794c39",
                                    "2d5cdaed96acc015fa15ee3403b327066febef58d4ff9a20e06dfb7b261628ce",
                                    "a69b26529fc2b65bf470c2a2bd22efe41f73763eec20a8a93c05ba720e9fcbd1",
                                    "bd935ad4c48672c5e4d791aac29afac44ac184d2694524e290563ca81cb2da60",
                                    "f4f2d477cc383f7885fc3724f0a204025e3ef04f34c7478a185beac0c595ff6c"
                                ]
                            },
                            {
                                "name": "393d9a38dc8b3113ef24b6e23d4c1aa82cc8e2371bc18aad4ab32219531554d5",
                                "value": 50,
                                "ufficio": "Media Center Management",
                                "link": [
                                    "4cbe4fc7a7d0bec4e2bf03fcff808f3cb0ec1d93cbe47307c48d219f80c2a6ef"
                                ]
                            },
                            {
                                "name": "3cd667fc0a67da84ca4bc18f8b71d6c422e0745df4d26de6ac42e6ba886730e9",
                                "value": 50,
                                "ufficio": "Finance Unit",
                                "link": [
                                    "d737b37cfaabf6ae06942149c5b4ee5d6f51bb83f96c22a97a02dd55aae26797"
                                ]
                            },
                            {
                                "name": "3e96a90c292519dd959a327f2fa19128d2870160698271969dad21652684fdbd",
                                "value": 50,
                                "ufficio": "Amministrazione consulenza finanziaria",
                                "link": [
                                    "3779faf7954bbba479b99fbb0c6543df87cabb3d70654b2486c354a023406372",
                                    "64729b37ca40f8417c6bcce885bad4ab76f2054b6619769b4f01533428e6a0f8",
                                    "9344687749ccaab48b3f7d2d5e3b9cf0f71d8b4abb2f99f6b094fc18f64ad3eb",
                                    "e118679565fed3549f6e05b398f1275b4cb0c955e46ec0c6bea540974364cd48"
                                ]
                            },
                            {
                                "name": "413612c9c7052356812afdabf6504212199907ccb172b3e1e5b4e8ca29eac0a7",
                                "value": 50,
                                "ufficio": "Chief Technology Officer",
                                "link": [
                                    "00d7df14c3d418e6b9aa1fdbf670004b7bdde6bed851cbdb44d095c5d53633c3",
                                    "083b96e848f7fe101868dfd1f46eccedc017cbaddf9f49c19c71ccab49536ac0",
                                    "117484657fdfa49f6f202c4a01f5869cd80e1a3dd60c339d9145c67a9fb03cae",
                                    "150dc7a0f15ffab503fdaa3cd09471f3c6055764f95b1f6cb4ff191bfc4a1532",
                                    "18f8f073dd89fcf946d09157db5342889d19543390c8cd4c9dd8b05bc65fd518",
                                    "1f4ef15b42d71da6eaa84c1666da8f3aae74ebcab35d1321b195f4e6e67b7565",
                                    "21128e9f23759392be2b2b17596d9d7c496d188162fd9ece9f8aa096e189a929",
                                    "2475b9470c6235901e7e3d9cc1201f817a6244a0603a072db7f4cee5785fa448",
                                    "2d5cdaed96acc015fa15ee3403b327066febef58d4ff9a20e06dfb7b261628ce",
                                    "38ce0ce0122e0bcdd595c12f417196d4acd690cf7b2d6cda61bc4c7208166e04",
                                    "45cdf162e9a98c22f44e9e1259be540e5fc454f582ccb8e98371f00de1c23f04",
                                    "50125cffec52c899c2da565099f3b084c25f25b17db6facc28758add900ad765",
                                    "50530ca013a6c0cf2eb8037d8032df3ae078924f85236bf5403db4404265bb62",
                                    "67678ae13f18c6a16575a01c50d4528d22e5e3862d512414f3dd81590aecfbd2",
                                    "7ad8ce7b05bf6d2f96c6cf7d8d2c20304a9fe6cf4d7a944608666d8f36b454c9",
                                    "7b138a4913227d7a34f13a5c994fca2a492f6051b242e9eb45d9af1d99bc982e",
                                    "7c8496883cb2bea0087ab91f32c798eb9219ff32d0a56db7da8444d821f45d81",
                                    "89581076ad0b28dc87a57bce316f2d6d7a95bbe17220b598b8cfdc5f1685fbb8",
                                    "8ddfe2ebab57cffc3af0f950c74433476a320a56aaf55fe5a0e2f74b31526a71",
                                    "94b0716f2a1b16dc7f4e9a57bc682f54c39daa1c7b6c99569471264755d9484c",
                                    "9d97a74ebd18d7acffed0070ca4bc846160f8159d8efe722f90554cc3d309c3d",
                                    "a4e8e3247a94ebc46bb94c8b914971f5ad793847ccf16b1f41b0c88f5a366d80",
                                    "a88b39bfb012c98803e66f0b018c25d1ffa5766c21260b623d9ddff1b90edc37",
                                    "cd799a97931432b5e176c88142dc61546e826bc2e69a5bc0ec58df54d78c9b74",
                                    "ce118f98f944cb4b7bf553a6c3361f5e5ffb39bc33050ac457ff5e68c3726eea",
                                    "da19ae9bdf22abe2a9cb0962b11f8e355978c0261556c1951bff89a793042d65",
                                    "f57cdd8423add2b2919fce520a9a2d9a34184b0f716c1e6ae5508ef4ee31056e",
                                    "f6d9c7262c3bbcc3b8c695a1e98caf93d40ca532dd02620709ece64a72e042ed",
                                    "fa5deed70dae7bbed11f872ecc4a3cf64ff21e2099602e3dfbe91fdc869493f3"
                                ]
                            },
                            {
                                "name": "42b4616df88565a9134d5620ebc56527d23095866ea78cb1afa74a82cf031f09",
                                "value": 50,
                                "ufficio": "Analisi funzionali",
                                "link": [
                                    "7ad8ce7b05bf6d2f96c6cf7d8d2c20304a9fe6cf4d7a944608666d8f36b454c9"
                                ]
                            },
                            {
                                "name": "42d3f609a82d7d42d4b1fbb92e1fb77c095d318ed908b236393aa914bdbd7acc",
                                "value": 50,
                                "ufficio": "Sviluppo e Architetture",
                                "link": [
                                    "b14ceff609c278e1c1891d643b7aa9eb267e80ce5e050863aeeddee9bcb25bd0",
                                    "d718c84701eb431e405971fea04377bfdc1869c17b6cc272ebe8ac8d8bbe74cb"
                                ]
                            },
                            {
                                "name": "432f4b6475380ae4688eba30a14f2c02c391d3690924ca8134b9d1620af15f28",
                                "value": 50,
                                "ufficio": "Sviluppo e Architetture",
                                "link": [
                                    "18f8f073dd89fcf946d09157db5342889d19543390c8cd4c9dd8b05bc65fd518"
                                ]
                            },
                            {
                                "name": "4391e04c3b3e5bce2eb851c131fee130fa4b50e33ae98dff76d02b5bb94bd5e1",
                                "value": 50,
                                "ufficio": "Commerciale Investimenti",
                                "link": [
                                    "48cdde88d13cecd870e6200115cd667de65900c03b57e0e5b7e4cd3da1e1fd21"
                                ]
                            },
                            {
                                "name": "4430f196eb4eb2ff7d9806df3fad24bd892c123d66855e0e9ad2f81242892211",
                                "value": 50,
                                "ufficio": "User Experience",
                                "link": [
                                    "15167bd90d8d12b7bed6cfcd192e5306b4e1216c584cc9c50977d8ea7f30e281",
                                    "4d9ed21b4e1893c31acbbe057c063c726961bda2b39ce184609662933fbaa733",
                                    "52fd640dca47a5008d088dd235189c51fc8495106ae0a39bb021dd809350e29c",
                                    "655e07f5ed4e15423d43a532542c117b3514eec081633d73af8e542afc4f4b21",
                                    "9a44fd708e36fc4c92d967ede843ab33c6315bf9e8e254006b81bf8b79c889d0",
                                    "a4e8e3247a94ebc46bb94c8b914971f5ad793847ccf16b1f41b0c88f5a366d80",
                                    "b080642b5854215716c65a72781e55050b41240865a8621530419a1348d810f4",
                                    "bfdbf363d9c4b0705ccbeecc39fe6db9a81f5145b54a3674b64b0da286429728",
                                    "d080f6246f89a229aff787012e414f09e420a8f174d2c85a2f342cebd9d54864",
                                    "e43226fe1cb0cb66499b70d618b6067cd5ad450c3ee762586893189caf27c73e",
                                    "ff12b55ee827fc0697d9f8c059da7ab3b2c16cdcd329686754e8e0ee92c3b5a9"
                                ]
                            },
                            {
                                "name": "45cdf162e9a98c22f44e9e1259be540e5fc454f582ccb8e98371f00de1c23f04",
                                "value": 50,
                                "ufficio": "Analisi funzionali",
                                "link": [
                                    "06d38d94f292d05cdb7df23a05ef073febdd7c68931c6a7277f5f60d9e9e9b16",
                                    "1bed37d0037f2f6a95cf5d417703ce4a2eda90363991a28e65ea1c788f83008b",
                                    "67678ae13f18c6a16575a01c50d4528d22e5e3862d512414f3dd81590aecfbd2",
                                    "7184afbf09495f0d5659820f2745c836b9e8f8b2d795e57b3ed9a4bff712cabe",
                                    "9d97a74ebd18d7acffed0070ca4bc846160f8159d8efe722f90554cc3d309c3d",
                                    "a88b39bfb012c98803e66f0b018c25d1ffa5766c21260b623d9ddff1b90edc37",
                                    "b080642b5854215716c65a72781e55050b41240865a8621530419a1348d810f4",
                                    "c7765c7be97958a8b3ca400d000e046e48422cc3733e8019741caa97be3e5d8a",
                                    "cd799a97931432b5e176c88142dc61546e826bc2e69a5bc0ec58df54d78c9b74",
                                    "ce118f98f944cb4b7bf553a6c3361f5e5ffb39bc33050ac457ff5e68c3726eea"
                                ]
                            },
                            {
                                "name": "47bc260e171c6fd87acf10cc371231cd77195394e16c4c2e57c57d1d446aff59",
                                "value": 50,
                                "ufficio": "Digital Branch",
                                "link": [
                                    "62298390c085c1b91e57863bc510085fb2f7579758accb0c081a4930bd5cb533",
                                    "6a03854e415b478f615318d423b54e92dd2077b56f3488690756437e32908be7",
                                    "82755a463db01fe7ecad9815a9cfe8bc137a646110a3727d11e338f858f2f59f",
                                    "dec59a7383f62a4dcf4548eb913834f857598bf81a196fff3414ea39cc5ee32d"
                                ]
                            },
                            {
                                "name": "48805a805841f176eec7131f6f75a461aadf461f846d4e5516facf15350cfaeb",
                                "value": 50,
                                "ufficio": "Eventi e Brand",
                                "link": [
                                    "0e428ffef64b10335e73fb703497fafde699c4eb8fbc533035b84489c91ec4d6",
                                    "15167bd90d8d12b7bed6cfcd192e5306b4e1216c584cc9c50977d8ea7f30e281",
                                    "4430f196eb4eb2ff7d9806df3fad24bd892c123d66855e0e9ad2f81242892211",
                                    "ea0ba1b92d9815a5d808d92bc8b85e97d2c3f3aa6f8c8407ab6add9cd7e5be7a"
                                ]
                            },
                            {
                                "name": "48cdde88d13cecd870e6200115cd667de65900c03b57e0e5b7e4cd3da1e1fd21",
                                "value": 50,
                                "ufficio": "Commerciale Investimenti",
                                "link": [
                                    "2475b9470c6235901e7e3d9cc1201f817a6244a0603a072db7f4cee5785fa448",
                                    "4391e04c3b3e5bce2eb851c131fee130fa4b50e33ae98dff76d02b5bb94bd5e1",
                                    "4b1c31b38e854c1ebd03badab8e535b22acb39c50801740d679f4a4643e76ef8",
                                    "55af1d666e9c133bc765e08f1068057f1d51592ad022affeb421bdf7dbfcdb18",
                                    "6120dd083a4c1cf13dd2c8ca1f92591ac48875307ff66fc8da019512f3d7a3df",
                                    "80335f30f0183fc023e34a72f7c9cddf62808847fbf68a9ae537b4d21588b8f8",
                                    "849fba80ec2db6a0b1729e9732c6ccd8874bc00647aa1064c22590b292703dc0",
                                    "9d97a74ebd18d7acffed0070ca4bc846160f8159d8efe722f90554cc3d309c3d",
                                    "cd799a97931432b5e176c88142dc61546e826bc2e69a5bc0ec58df54d78c9b74"
                                ]
                            },
                            {
                                "name": "4af4cbc00eeadb01960175f8e148342ce43279cc161fd67b1cac2ee1e6ee60dc",
                                "value": 50,
                                "ufficio": "Digital Branch",
                                "link": [
                                    "82755a463db01fe7ecad9815a9cfe8bc137a646110a3727d11e338f858f2f59f",
                                    "bd935ad4c48672c5e4d791aac29afac44ac184d2694524e290563ca81cb2da60"
                                ]
                            },
                            {
                                "name": "4b1c31b38e854c1ebd03badab8e535b22acb39c50801740d679f4a4643e76ef8",
                                "value": 50,
                                "ufficio": "Commerciale Investimenti",
                                "link": [
                                    "6120dd083a4c1cf13dd2c8ca1f92591ac48875307ff66fc8da019512f3d7a3df"
                                ]
                            },
                            {
                                "name": "4d783066b85f21a7821f82097dae1aed71418fef7af48fc51befffe93af3d9cf",
                                "value": 50,
                                "ufficio": "Risorse Umane e Progetti Evolutivi",
                                "link": [
                                    "0e428ffef64b10335e73fb703497fafde699c4eb8fbc533035b84489c91ec4d6",
                                    "246fa0d14d762dcb827e140c56d3ef32baf59f87dd78b8e27cdecc6670ac8a73",
                                    "2475b9470c6235901e7e3d9cc1201f817a6244a0603a072db7f4cee5785fa448",
                                    "25fbc277ed0e153fb6a3f0f720ddf7fe29b22c080091aac35336fad495fcd8f3",
                                    "50125cffec52c899c2da565099f3b084c25f25b17db6facc28758add900ad765",
                                    "50530ca013a6c0cf2eb8037d8032df3ae078924f85236bf5403db4404265bb62",
                                    "ec8901cc98702644ab46c54add211d0edfb0f416476369cdd9758afcfbe21609"
                                ]
                            },
                            {
                                "name": "4d9ed21b4e1893c31acbbe057c063c726961bda2b39ce184609662933fbaa733",
                                "value": 50,
                                "ufficio": "User Experience",
                                "link": [
                                    "bfdbf363d9c4b0705ccbeecc39fe6db9a81f5145b54a3674b64b0da286429728"
                                ]
                            },
                            {
                                "name": "4e12e345bdb2e98fc1ce84b6b9724b164ab5570d3102f6cfd7759a367b58d857",
                                "value": 50,
                                "ufficio": "Sviluppo e Architetture",
                                "link": [
                                    "f4f2d477cc383f7885fc3724f0a204025e3ef04f34c7478a185beac0c595ff6c"
                                ]
                            },
                            {
                                "name": "4e3745d60344cdb6a21b56019ed24a1fbb711b428182ad9a329327786e4d907d",
                                "value": 50,
                                "ufficio": "Controlli, Processi e Immobiliare",
                                "link": [
                                    "82755a463db01fe7ecad9815a9cfe8bc137a646110a3727d11e338f858f2f59f"
                                ]
                            },
                            {
                                "name": "4ec2fcb6eb5b957f8f231afb57994e77f4185482fb2db7601b89259277ed87c6",
                                "value": 50,
                                "ufficio": "Eventi e Brand",
                                "link": [
                                    "5a980b472b83f2974fbb4ecf1061cc8ba78472476347de63d56222b8323f3d18",
                                    "e290b0a48ba64237e04dad91dd9197f081a22d0c60b8bb247f25eeef2334c2ce",
                                    "ea0ba1b92d9815a5d808d92bc8b85e97d2c3f3aa6f8c8407ab6add9cd7e5be7a"
                                ]
                            },
                            {
                                "name": "50125cffec52c899c2da565099f3b084c25f25b17db6facc28758add900ad765",
                                "value": 50,
                                "ufficio": "Comitato di Direzione",
                                "link": [
                                    "0e428ffef64b10335e73fb703497fafde699c4eb8fbc533035b84489c91ec4d6",
                                    "23a9f81805333f7e325484837c8b8874e22105346952d2e500f93a61afa5c928",
                                    "246fa0d14d762dcb827e140c56d3ef32baf59f87dd78b8e27cdecc6670ac8a73",
                                    "2475b9470c6235901e7e3d9cc1201f817a6244a0603a072db7f4cee5785fa448",
                                    "25fbc277ed0e153fb6a3f0f720ddf7fe29b22c080091aac35336fad495fcd8f3",
                                    "3779faf7954bbba479b99fbb0c6543df87cabb3d70654b2486c354a023406372",
                                    "4d783066b85f21a7821f82097dae1aed71418fef7af48fc51befffe93af3d9cf",
                                    "50228aad62253ede25fa405a08929d5bda792f6d07a6554a5f0eae03de60d209",
                                    "50530ca013a6c0cf2eb8037d8032df3ae078924f85236bf5403db4404265bb62",
                                    "55ff0b8efbcb37483f73f0ffe87b240272eefce6390e3c3454881cdc8b6b5638",
                                    "6101c7c61c9aebce7ec0d0ac3ecfbf7be4b2ea92b01a55a3b49e244fdb4af554",
                                    "63f169eba9e68ec4a33ef5cb7cefbcf129975ac567f887dab1718ef65cd9971a",
                                    "7ad8ce7b05bf6d2f96c6cf7d8d2c20304a9fe6cf4d7a944608666d8f36b454c9",
                                    "814b7e522b4b800335488042e5dad575528905ba0ad796d4ec52b02ad98c5f77",
                                    "82755a463db01fe7ecad9815a9cfe8bc137a646110a3727d11e338f858f2f59f",
                                    "8a69ecc023e5801658323c6a725b97387bed8cacab1bc8b740205d3534507d07",
                                    "8a88ee434b3c41377a0fdf64f73927ad4c7580538ec7842be17933bc4a669e6a",
                                    "94b0716f2a1b16dc7f4e9a57bc682f54c39daa1c7b6c99569471264755d9484c",
                                    "b88c5e73043d51af0abb66947a83a98a7efd400e7e55eebac0bd3dc1e5c19374",
                                    "d26aa2e9c00f1acc24a8b3772b51c2558ccf58b0bd1ce98f59f57e041ceb2e63",
                                    "d3a8ea432eb64faeb9c3661d563d76d6a0b9beec571b803e8a9b68e2f81111c4",
                                    "ec8901cc98702644ab46c54add211d0edfb0f416476369cdd9758afcfbe21609",
                                    "f3eae844e383579728b6d9069cc00301c5b0941b1c10a464c7b9a424618cef2a",
                                    "f680ca858e1602fe43773de835e5ead85a16f0a59a88465afbb6badbc99abd8a",
                                    "f6d9c7262c3bbcc3b8c695a1e98caf93d40ca532dd02620709ece64a72e042ed",
                                    "fc0122f6d05c2ec54028830dc458440a2ff21f5a1bd11b664fec0fc9d6b346cc"
                                ]
                            },
                            {
                                "name": "50228aad62253ede25fa405a08929d5bda792f6d07a6554a5f0eae03de60d209",
                                "value": 50,
                                "ufficio": "Pianificazione e Controllo di Gestione",
                                "link": [
                                    "08031c40fea4b77b2203830bb3dc0aea814fda06cd5a344019eadedbde13c15b",
                                    "0e428ffef64b10335e73fb703497fafde699c4eb8fbc533035b84489c91ec4d6",
                                    "213a6c50db2433bd2d9a3300f4508d4c8ab4f102d19763dfb18fcaa06364c2eb",
                                    "23a9f81805333f7e325484837c8b8874e22105346952d2e500f93a61afa5c928",
                                    "2475b9470c6235901e7e3d9cc1201f817a6244a0603a072db7f4cee5785fa448",
                                    "3779faf7954bbba479b99fbb0c6543df87cabb3d70654b2486c354a023406372",
                                    "48cdde88d13cecd870e6200115cd667de65900c03b57e0e5b7e4cd3da1e1fd21",
                                    "50125cffec52c899c2da565099f3b084c25f25b17db6facc28758add900ad765",
                                    "8a88ee434b3c41377a0fdf64f73927ad4c7580538ec7842be17933bc4a669e6a",
                                    "ba153e31ef3d2a4d304a7d61753093336ec2b48414f6a336cef696dbc9e44d94",
                                    "d3a8ea432eb64faeb9c3661d563d76d6a0b9beec571b803e8a9b68e2f81111c4",
                                    "ec8901cc98702644ab46c54add211d0edfb0f416476369cdd9758afcfbe21609",
                                    "f3eae844e383579728b6d9069cc00301c5b0941b1c10a464c7b9a424618cef2a",
                                    "f6d9c7262c3bbcc3b8c695a1e98caf93d40ca532dd02620709ece64a72e042ed"
                                ]
                            },
                            {
                                "name": "50530ca013a6c0cf2eb8037d8032df3ae078924f85236bf5403db4404265bb62",
                                "value": 50,
                                "ufficio": "Comitato di Direzione",
                                "link": [
                                    "0e428ffef64b10335e73fb703497fafde699c4eb8fbc533035b84489c91ec4d6",
                                    "1f4ef15b42d71da6eaa84c1666da8f3aae74ebcab35d1321b195f4e6e67b7565",
                                    "21128e9f23759392be2b2b17596d9d7c496d188162fd9ece9f8aa096e189a929",
                                    "23a9f81805333f7e325484837c8b8874e22105346952d2e500f93a61afa5c928",
                                    "246fa0d14d762dcb827e140c56d3ef32baf59f87dd78b8e27cdecc6670ac8a73",
                                    "2475b9470c6235901e7e3d9cc1201f817a6244a0603a072db7f4cee5785fa448",
                                    "36976cfe80dcf459c6a92c1d326df99d4e0c262970320e221f4093becab351c3",
                                    "413612c9c7052356812afdabf6504212199907ccb172b3e1e5b4e8ca29eac0a7",
                                    "4d783066b85f21a7821f82097dae1aed71418fef7af48fc51befffe93af3d9cf",
                                    "50125cffec52c899c2da565099f3b084c25f25b17db6facc28758add900ad765",
                                    "7ad8ce7b05bf6d2f96c6cf7d8d2c20304a9fe6cf4d7a944608666d8f36b454c9",
                                    "7c8496883cb2bea0087ab91f32c798eb9219ff32d0a56db7da8444d821f45d81",
                                    "94b0716f2a1b16dc7f4e9a57bc682f54c39daa1c7b6c99569471264755d9484c",
                                    "a4e8e3247a94ebc46bb94c8b914971f5ad793847ccf16b1f41b0c88f5a366d80",
                                    "c3c4106b8583c84e11c12032747f577b4c00c5b84c348d9c586d539f072a7589",
                                    "cd799a97931432b5e176c88142dc61546e826bc2e69a5bc0ec58df54d78c9b74",
                                    "d3a8ea432eb64faeb9c3661d563d76d6a0b9beec571b803e8a9b68e2f81111c4",
                                    "ec8901cc98702644ab46c54add211d0edfb0f416476369cdd9758afcfbe21609",
                                    "f57cdd8423add2b2919fce520a9a2d9a34184b0f716c1e6ae5508ef4ee31056e"
                                ]
                            },
                            {
                                "name": "516c0937b94123241b706f185cbaf5039307313eaf7200e1e0ba64bc880c17f5",
                                "value": 50,
                                "ufficio": "Sviluppo e Architetture",
                                "link": [
                                    "55ff0b8efbcb37483f73f0ffe87b240272eefce6390e3c3454881cdc8b6b5638",
                                    "5f9c999f59aed9c22e6e9f149df9f9ebd5d62d7841b0ea9be721ca9ce7baa094",
                                    "63f169eba9e68ec4a33ef5cb7cefbcf129975ac567f887dab1718ef65cd9971a",
                                    "8443c187871034e8ba51d518a5ee3334d252245351ea2dcaec814b7896fd899c",
                                    "b88c5e73043d51af0abb66947a83a98a7efd400e7e55eebac0bd3dc1e5c19374"
                                ]
                            },
                            {
                                "name": "52fd640dca47a5008d088dd235189c51fc8495106ae0a39bb021dd809350e29c",
                                "value": 50,
                                "ufficio": "User Experience",
                                "link": [
                                    "150dc7a0f15ffab503fdaa3cd09471f3c6055764f95b1f6cb4ff191bfc4a1532",
                                    "15167bd90d8d12b7bed6cfcd192e5306b4e1216c584cc9c50977d8ea7f30e281",
                                    "1f4ef15b42d71da6eaa84c1666da8f3aae74ebcab35d1321b195f4e6e67b7565",
                                    "2470fd17d1f1959717120deadaaa3b455dd3c74c8ced88054a8b4fb88e4f8f28",
                                    "4430f196eb4eb2ff7d9806df3fad24bd892c123d66855e0e9ad2f81242892211",
                                    "4d9ed21b4e1893c31acbbe057c063c726961bda2b39ce184609662933fbaa733",
                                    "5a44504d34546036745dcb5deeddab47d39a00fb19734ca7145dcc5a9dda9ea7",
                                    "650644c665a69dc950d6f6a16d1f31f06f1ed11b2b255f7540987898da9961d0",
                                    "655e07f5ed4e15423d43a532542c117b3514eec081633d73af8e542afc4f4b21",
                                    "67dac3f288d9e1b5af98c40f069f11ef7eb895d715f2bf7481fc81988c386e6f",
                                    "6ec9cfba69a90f24827db98d1a905f97613cd4cc592b0e6f0026b5aae81a0118",
                                    "853ac84efec82736323b7c9a6cdcffe8ae2cf3da4bde5591d1568837b6c96ee9",
                                    "8c3e02eabd321bb7ae9ba21563338ba3df344ab68b381680bcc3a75a2a8ccdf4",
                                    "9f6abb824f8cf4ed7997ff008f64449621aebd1d4bc7af1f46d0f238ebc954a2",
                                    "a4e8e3247a94ebc46bb94c8b914971f5ad793847ccf16b1f41b0c88f5a366d80",
                                    "bc369356384d79a8fe050a841aef947bda1114404e1249bb5ac883b4a91ae707",
                                    "be0b7f3457783af8b09e2cf60f10cfdd64903cbda18a9b1c3216ea6a97026370",
                                    "bfdbf363d9c4b0705ccbeecc39fe6db9a81f5145b54a3674b64b0da286429728",
                                    "ce118f98f944cb4b7bf553a6c3361f5e5ffb39bc33050ac457ff5e68c3726eea",
                                    "ce5d99de22269fea7904a89036f0aef155beabc375707b88bd2d1b73d7eddb11",
                                    "d46963e985568e7dad629017de5c6f04c3ce4b2b3a584054f7b153812afcff04",
                                    "d5940e1eea800cac78acf3ef1ce311e6d22fc55b076391f5a483b2f25a813855",
                                    "e290b0a48ba64237e04dad91dd9197f081a22d0c60b8bb247f25eeef2334c2ce",
                                    "ea0ba1b92d9815a5d808d92bc8b85e97d2c3f3aa6f8c8407ab6add9cd7e5be7a",
                                    "edc927c302d834fd6d1a19493931750c25e5dc949f2b2a8a8d05fb339ea75146"
                                ]
                            },
                            {
                                "name": "551c9be035e6c1b2ddd26424b7dcb7e62f8c70c58dfa9ff66ca4e9e025840353",
                                "value": 50,
                                "ufficio": "Controlli, Processi e Immobiliare",
                                "link": [
                                    "6101c7c61c9aebce7ec0d0ac3ecfbf7be4b2ea92b01a55a3b49e244fdb4af554",
                                    "bd3a639dfc2b875db05bd23d32b6271b9615b79b2dc08557c3836a18aa2c8da3"
                                ]
                            },
                            {
                                "name": "55af1d666e9c133bc765e08f1068057f1d51592ad022affeb421bdf7dbfcdb18",
                                "value": 50,
                                "ufficio": "Commerciale Investimenti",
                                "link": [
                                    "48cdde88d13cecd870e6200115cd667de65900c03b57e0e5b7e4cd3da1e1fd21",
                                    "6120dd083a4c1cf13dd2c8ca1f92591ac48875307ff66fc8da019512f3d7a3df",
                                    "849fba80ec2db6a0b1729e9732c6ccd8874bc00647aa1064c22590b292703dc0"
                                ]
                            },
                            {
                                "name": "55ff0b8efbcb37483f73f0ffe87b240272eefce6390e3c3454881cdc8b6b5638",
                                "value": 50,
                                "ufficio": "Crediti",
                                "link": [
                                    "03570a9667986e67fbc32a36c58c2a83620615f978d54065c0118b9ecf3bb513",
                                    "50125cffec52c899c2da565099f3b084c25f25b17db6facc28758add900ad765",
                                    "50530ca013a6c0cf2eb8037d8032df3ae078924f85236bf5403db4404265bb62",
                                    "516c0937b94123241b706f185cbaf5039307313eaf7200e1e0ba64bc880c17f5",
                                    "5f9c999f59aed9c22e6e9f149df9f9ebd5d62d7841b0ea9be721ca9ce7baa094",
                                    "6101c7c61c9aebce7ec0d0ac3ecfbf7be4b2ea92b01a55a3b49e244fdb4af554",
                                    "63f169eba9e68ec4a33ef5cb7cefbcf129975ac567f887dab1718ef65cd9971a",
                                    "6c5a331a8710274d8a04ac9c315eafef0ec060aaeaf649c4322812b900a972d4",
                                    "6d4f4fcc24d0ccfb24d64be9bf4af436a091122c5602e0a90b03c0cb2515f6ae",
                                    "6f73b50dc0122b97db84a795721a6b625261b938843fcbf467616db0d9811fc5",
                                    "7ad8ce7b05bf6d2f96c6cf7d8d2c20304a9fe6cf4d7a944608666d8f36b454c9",
                                    "7bd334b88615bc7016ee5e1fcc0e2860fbc90f187d9b2f37150b9da59cb21610",
                                    "82755a463db01fe7ecad9815a9cfe8bc137a646110a3727d11e338f858f2f59f",
                                    "8a69ecc023e5801658323c6a725b97387bed8cacab1bc8b740205d3534507d07",
                                    "b88c5e73043d51af0abb66947a83a98a7efd400e7e55eebac0bd3dc1e5c19374",
                                    "d737b37cfaabf6ae06942149c5b4ee5d6f51bb83f96c22a97a02dd55aae26797",
                                    "ffdd2a8bbeedc5f07cf40d7708d90c44d18260c146641e835b194e0f8c0a0490"
                                ]
                            },
                            {
                                "name": "562b355139df6b1823ec7eacdb3c4726d0a68bdf9206476d8a18f3680957078c",
                                "value": 50,
                                "ufficio": "Comunicazione & Acquisition",
                                "link": [
                                    "a85ecef49e3b754918db9058c0e27dd64b37c25ecba47822a608accad8957746"
                                ]
                            },
                            {
                                "name": "59294899f0e52923d6f08c1a86900c906187345c14c72a70fb51510e7fa00aec",
                                "value": 50,
                                "ufficio": "Sviluppo e Architetture",
                                "link": [
                                    "853ac84efec82736323b7c9a6cdcffe8ae2cf3da4bde5591d1568837b6c96ee9",
                                    "8c3e02eabd321bb7ae9ba21563338ba3df344ab68b381680bcc3a75a2a8ccdf4",
                                    "a85ecef49e3b754918db9058c0e27dd64b37c25ecba47822a608accad8957746"
                                ]
                            },
                            {
                                "name": "5a44504d34546036745dcb5deeddab47d39a00fb19734ca7145dcc5a9dda9ea7",
                                "value": 50,
                                "ufficio": "Sviluppo e Architetture",
                                "link": [
                                    "0dcfb7587da75801f1d0f32ca00cf2d33f23c9fd2ccac63e5e3663bc59b7691a",
                                    "15167bd90d8d12b7bed6cfcd192e5306b4e1216c584cc9c50977d8ea7f30e281",
                                    "1f4ef15b42d71da6eaa84c1666da8f3aae74ebcab35d1321b195f4e6e67b7565",
                                    "413612c9c7052356812afdabf6504212199907ccb172b3e1e5b4e8ca29eac0a7",
                                    "52fd640dca47a5008d088dd235189c51fc8495106ae0a39bb021dd809350e29c",
                                    "853ac84efec82736323b7c9a6cdcffe8ae2cf3da4bde5591d1568837b6c96ee9",
                                    "8c3e02eabd321bb7ae9ba21563338ba3df344ab68b381680bcc3a75a2a8ccdf4",
                                    "9f6abb824f8cf4ed7997ff008f64449621aebd1d4bc7af1f46d0f238ebc954a2",
                                    "a4e8e3247a94ebc46bb94c8b914971f5ad793847ccf16b1f41b0c88f5a366d80",
                                    "a85ecef49e3b754918db9058c0e27dd64b37c25ecba47822a608accad8957746",
                                    "bc369356384d79a8fe050a841aef947bda1114404e1249bb5ac883b4a91ae707",
                                    "be0b7f3457783af8b09e2cf60f10cfdd64903cbda18a9b1c3216ea6a97026370",
                                    "ce118f98f944cb4b7bf553a6c3361f5e5ffb39bc33050ac457ff5e68c3726eea",
                                    "edc927c302d834fd6d1a19493931750c25e5dc949f2b2a8a8d05fb339ea75146",
                                    "ee64e69e3c67b6db4180164ace85772c427d49160d44da1a27032fe2be2cd16c",
                                    "ff12b55ee827fc0697d9f8c059da7ab3b2c16cdcd329686754e8e0ee92c3b5a9"
                                ]
                            },
                            {
                                "name": "5a980b472b83f2974fbb4ecf1061cc8ba78472476347de63d56222b8323f3d18",
                                "value": 50,
                                "ufficio": "Comunicazione & Acquisition",
                                "link": [
                                    "1139eb48beb8d6d0ff482002d607a4a5e7ddc0b1da921eb1c759a39652d50650",
                                    "1ca7ba56343a590938e8c410969a3947596d5e121309da4048c66a4d950e67c7",
                                    "e290b0a48ba64237e04dad91dd9197f081a22d0c60b8bb247f25eeef2334c2ce",
                                    "edc927c302d834fd6d1a19493931750c25e5dc949f2b2a8a8d05fb339ea75146"
                                ]
                            },
                            {
                                "name": "5e1084862fb3dc2334e3b24edef9e94744739cf28be459c808c84d5f7f0f6f70",
                                "value": 50,
                                "ufficio": "Commerciale Banking & Credito",
                                "link": [
                                    "29e353b18fae9b63560f47b3f9cbcc291aad0650ad44705c79a9a4abd05ebe9c",
                                    "8f9707cd5ae227dc0ea5e7ddb76fe9ab62e93c62829debd8ba5f599ef62b7502",
                                    "ed67329cdf04b5a943a12c63cd602a6d7f06a61d433a960d8c5514f13ca40ca9"
                                ]
                            },
                            {
                                "name": "5f9c999f59aed9c22e6e9f149df9f9ebd5d62d7841b0ea9be721ca9ce7baa094",
                                "value": 50,
                                "ufficio": "Crediti",
                                "link": [
                                    "516c0937b94123241b706f185cbaf5039307313eaf7200e1e0ba64bc880c17f5",
                                    "55ff0b8efbcb37483f73f0ffe87b240272eefce6390e3c3454881cdc8b6b5638",
                                    "63f169eba9e68ec4a33ef5cb7cefbcf129975ac567f887dab1718ef65cd9971a",
                                    "6f73b50dc0122b97db84a795721a6b625261b938843fcbf467616db0d9811fc5",
                                    "b88c5e73043d51af0abb66947a83a98a7efd400e7e55eebac0bd3dc1e5c19374"
                                ]
                            },
                            {
                                "name": "6101c7c61c9aebce7ec0d0ac3ecfbf7be4b2ea92b01a55a3b49e244fdb4af554",
                                "value": 50,
                                "ufficio": "Controlli, Processi e Immobiliare",
                                "link": [
                                    "50125cffec52c899c2da565099f3b084c25f25b17db6facc28758add900ad765",
                                    "551c9be035e6c1b2ddd26424b7dcb7e62f8c70c58dfa9ff66ca4e9e025840353",
                                    "55ff0b8efbcb37483f73f0ffe87b240272eefce6390e3c3454881cdc8b6b5638",
                                    "63f169eba9e68ec4a33ef5cb7cefbcf129975ac567f887dab1718ef65cd9971a",
                                    "6d4f4fcc24d0ccfb24d64be9bf4af436a091122c5602e0a90b03c0cb2515f6ae",
                                    "6f73b50dc0122b97db84a795721a6b625261b938843fcbf467616db0d9811fc5",
                                    "7bd334b88615bc7016ee5e1fcc0e2860fbc90f187d9b2f37150b9da59cb21610",
                                    "82755a463db01fe7ecad9815a9cfe8bc137a646110a3727d11e338f858f2f59f",
                                    "bd3a639dfc2b875db05bd23d32b6271b9615b79b2dc08557c3836a18aa2c8da3",
                                    "c682a14b4b7b3ae8c6d204234a700baec5e56df8e2b2ff60ba668e74b493e274",
                                    "ffdd2a8bbeedc5f07cf40d7708d90c44d18260c146641e835b194e0f8c0a0490"
                                ]
                            },
                            {
                                "name": "6120dd083a4c1cf13dd2c8ca1f92591ac48875307ff66fc8da019512f3d7a3df",
                                "value": 50,
                                "ufficio": "Commerciale Investimenti",
                                "link": [
                                    "2475b9470c6235901e7e3d9cc1201f817a6244a0603a072db7f4cee5785fa448",
                                    "48cdde88d13cecd870e6200115cd667de65900c03b57e0e5b7e4cd3da1e1fd21",
                                    "4b1c31b38e854c1ebd03badab8e535b22acb39c50801740d679f4a4643e76ef8",
                                    "7b138a4913227d7a34f13a5c994fca2a492f6051b242e9eb45d9af1d99bc982e",
                                    "9c0a0f6c096827cf6fe5d3f87a6312ebd04e8ad3657fad79c4a8d967c445b9f2",
                                    "cd799a97931432b5e176c88142dc61546e826bc2e69a5bc0ec58df54d78c9b74",
                                    "d080f6246f89a229aff787012e414f09e420a8f174d2c85a2f342cebd9d54864",
                                    "f158062016d7c912ba1494b35e8cd64d07656b38a4a0d0992e4dc016800a3788"
                                ]
                            },
                            {
                                "name": "61d29662a79cdedd32b1e54888938447235caf30c466d7ff090305868dc5919d",
                                "value": 50,
                                "ufficio": "Analisi funzionali",
                                "link": [
                                    "150dc7a0f15ffab503fdaa3cd09471f3c6055764f95b1f6cb4ff191bfc4a1532",
                                    "6ec9cfba69a90f24827db98d1a905f97613cd4cc592b0e6f0026b5aae81a0118"
                                ]
                            },
                            {
                                "name": "62298390c085c1b91e57863bc510085fb2f7579758accb0c081a4930bd5cb533",
                                "value": 50,
                                "ufficio": "Digital Branch",
                                "link": [
                                    "47bc260e171c6fd87acf10cc371231cd77195394e16c4c2e57c57d1d446aff59",
                                    "6a03854e415b478f615318d423b54e92dd2077b56f3488690756437e32908be7",
                                    "82755a463db01fe7ecad9815a9cfe8bc137a646110a3727d11e338f858f2f59f",
                                    "a3a8e4cae33ce7eb7d328d5b74fff1b86cf0207cb63f27cc518615de467ea2c6",
                                    "dec59a7383f62a4dcf4548eb913834f857598bf81a196fff3414ea39cc5ee32d"
                                ]
                            },
                            {
                                "name": "63f169eba9e68ec4a33ef5cb7cefbcf129975ac567f887dab1718ef65cd9971a",
                                "value": 50,
                                "ufficio": "Crediti",
                                "link": [
                                    "2f02900e1dd4918db6a502619ac506938f4be868a26074349313ed3ab6713f1f",
                                    "50125cffec52c899c2da565099f3b084c25f25b17db6facc28758add900ad765",
                                    "516c0937b94123241b706f185cbaf5039307313eaf7200e1e0ba64bc880c17f5",
                                    "55ff0b8efbcb37483f73f0ffe87b240272eefce6390e3c3454881cdc8b6b5638",
                                    "5f9c999f59aed9c22e6e9f149df9f9ebd5d62d7841b0ea9be721ca9ce7baa094",
                                    "6101c7c61c9aebce7ec0d0ac3ecfbf7be4b2ea92b01a55a3b49e244fdb4af554",
                                    "6d4f4fcc24d0ccfb24d64be9bf4af436a091122c5602e0a90b03c0cb2515f6ae",
                                    "6f73b50dc0122b97db84a795721a6b625261b938843fcbf467616db0d9811fc5",
                                    "76edfcd09cca7d558ec875aa9aecf369c69b8573cf04142b30cfb7199bbfebc5",
                                    "8443c187871034e8ba51d518a5ee3334d252245351ea2dcaec814b7896fd899c",
                                    "8a69ecc023e5801658323c6a725b97387bed8cacab1bc8b740205d3534507d07",
                                    "969a210a20c074567b7fe306aff7184e0b6fd2340b6952a58423d31aab0e441a",
                                    "a91e77c4bfff24379c25a1ee6b866d3ab57df33661aed16539bb346b7e92cc1d",
                                    "b88c5e73043d51af0abb66947a83a98a7efd400e7e55eebac0bd3dc1e5c19374",
                                    "d1692f6821e7745b042c2d05d65937e004b338bd4078d1729df09cbe4ddd0e48",
                                    "d718c84701eb431e405971fea04377bfdc1869c17b6cc272ebe8ac8d8bbe74cb",
                                    "f3280920c064eb900b091aa992d081b27c1f9f3c2e8aae488b69be093a4932e4"
                                ]
                            },
                            {
                                "name": "64729b37ca40f8417c6bcce885bad4ab76f2054b6619769b4f01533428e6a0f8",
                                "value": 50,
                                "ufficio": "Amministrazione consulenza finanziaria",
                                "link": [
                                    "3779faf7954bbba479b99fbb0c6543df87cabb3d70654b2486c354a023406372",
                                    "3e96a90c292519dd959a327f2fa19128d2870160698271969dad21652684fdbd",
                                    "9344687749ccaab48b3f7d2d5e3b9cf0f71d8b4abb2f99f6b094fc18f64ad3eb",
                                    "ccb35cb14a71c974c83dae675e7143e1788db7293805206540c8d9df094e4ff4"
                                ]
                            },
                            {
                                "name": "650644c665a69dc950d6f6a16d1f31f06f1ed11b2b255f7540987898da9961d0",
                                "value": 50,
                                "ufficio": "User Experience",
                                "link": [
                                    "15167bd90d8d12b7bed6cfcd192e5306b4e1216c584cc9c50977d8ea7f30e281"
                                ]
                            },
                            {
                                "name": "655e07f5ed4e15423d43a532542c117b3514eec081633d73af8e542afc4f4b21",
                                "value": 50,
                                "ufficio": "User Experience",
                                "link": [
                                    "52fd640dca47a5008d088dd235189c51fc8495106ae0a39bb021dd809350e29c"
                                ]
                            },
                            {
                                "name": "659a5f5d597cd6834db5df465fcd21c9eaf134b14d21dbfdef9b2d0060133573",
                                "value": 50,
                                "ufficio": "Sviluppo e Architetture",
                                "link": [
                                    "d479e93d42e5581e11ff27c6eaacf71c682fe3914a72e77fdf5702dd9b182c36"
                                ]
                            },
                            {
                                "name": "66dd598474bc4571b70ff70d1c88d591de4bedebce1edfc074621e71e833b081",
                                "value": 50,
                                "ufficio": "Analisi funzionali",
                                "link": [
                                    "083b96e848f7fe101868dfd1f46eccedc017cbaddf9f49c19c71ccab49536ac0",
                                    "48cdde88d13cecd870e6200115cd667de65900c03b57e0e5b7e4cd3da1e1fd21",
                                    "7ad8ce7b05bf6d2f96c6cf7d8d2c20304a9fe6cf4d7a944608666d8f36b454c9"
                                ]
                            },
                            {
                                "name": "67678ae13f18c6a16575a01c50d4528d22e5e3862d512414f3dd81590aecfbd2",
                                "value": 50,
                                "ufficio": "Sviluppo e Architetture",
                                "link": [
                                    "1f4ef15b42d71da6eaa84c1666da8f3aae74ebcab35d1321b195f4e6e67b7565",
                                    "2d5cdaed96acc015fa15ee3403b327066febef58d4ff9a20e06dfb7b261628ce",
                                    "413612c9c7052356812afdabf6504212199907ccb172b3e1e5b4e8ca29eac0a7",
                                    "45cdf162e9a98c22f44e9e1259be540e5fc454f582ccb8e98371f00de1c23f04",
                                    "7b138a4913227d7a34f13a5c994fca2a492f6051b242e9eb45d9af1d99bc982e",
                                    "8ddfe2ebab57cffc3af0f950c74433476a320a56aaf55fe5a0e2f74b31526a71",
                                    "9d97a74ebd18d7acffed0070ca4bc846160f8159d8efe722f90554cc3d309c3d",
                                    "b080642b5854215716c65a72781e55050b41240865a8621530419a1348d810f4",
                                    "d080f6246f89a229aff787012e414f09e420a8f174d2c85a2f342cebd9d54864"
                                ]
                            },
                            {
                                "name": "67dac3f288d9e1b5af98c40f069f11ef7eb895d715f2bf7481fc81988c386e6f",
                                "value": 50,
                                "ufficio": "User Experience",
                                "link": [
                                    "15167bd90d8d12b7bed6cfcd192e5306b4e1216c584cc9c50977d8ea7f30e281",
                                    "4430f196eb4eb2ff7d9806df3fad24bd892c123d66855e0e9ad2f81242892211",
                                    "52fd640dca47a5008d088dd235189c51fc8495106ae0a39bb021dd809350e29c",
                                    "be0b7f3457783af8b09e2cf60f10cfdd64903cbda18a9b1c3216ea6a97026370"
                                ]
                            },
                            {
                                "name": "67fd6c0efd48daf4780f975393ab5252432b35da210995dc6845d95261be3f3e",
                                "value": 50,
                                "ufficio": "Sistemi e Monitoring",
                                "link": [
                                    "6f6b05938c3222e719d57701ea4f34dcfcebf231111d6b60aa693bd2e0169ca3",
                                    "c450b7fdfdcd44454ce327bf0975039d6c7668e23dbc6d91f7edede31875e626"
                                ]
                            },
                            {
                                "name": "684154a13c4aba0b25fe1a0bdd4667ee40d5ed056108566cb09c6f8614bd27aa",
                                "value": 50,
                                "ufficio": "Data Intelligence",
                                "link": [
                                    "50125cffec52c899c2da565099f3b084c25f25b17db6facc28758add900ad765",
                                    "915e1d4b27b8f8d1ff4a6f3fd44f14629b0aa665d34f20020877d7d3ac517c40",
                                    "d8b2ba48d1bc4fe02ccf2283c833bf8724bf4e48bfbdbc20ee240d0db536e5f7",
                                    "f6d9c7262c3bbcc3b8c695a1e98caf93d40ca532dd02620709ece64a72e042ed"
                                ]
                            },
                            {
                                "name": "6a03854e415b478f615318d423b54e92dd2077b56f3488690756437e32908be7",
                                "value": 50,
                                "ufficio": "Digital Branch",
                                "link": [
                                    "0091293c6a46ce890479b100e32455b80099b84756edafe00749523e9ec46b75",
                                    "42bf2a83707348b01f00fda5e2fe43f81de5f6bce87b45c9af1a12c803bedd12",
                                    "47bc260e171c6fd87acf10cc371231cd77195394e16c4c2e57c57d1d446aff59",
                                    "62298390c085c1b91e57863bc510085fb2f7579758accb0c081a4930bd5cb533",
                                    "82755a463db01fe7ecad9815a9cfe8bc137a646110a3727d11e338f858f2f59f",
                                    "9f11d1d7bc36b59ec4d41aeaac8621a878b2fe671ceb1cca3e67133dde371f84",
                                    "cbbf3604801467bb1ac7222a9bf7555bb21784ffa8c00abf7f5ffae7bc4e2da6",
                                    "dec59a7383f62a4dcf4548eb913834f857598bf81a196fff3414ea39cc5ee32d"
                                ]
                            },
                            {
                                "name": "6aa56775705605384583392112cc3a974b7bded505375979500264fb35310747",
                                "value": 50,
                                "ufficio": "Sistemi e Monitoring",
                                "link": [
                                    "02a0e9c62180eb9c2d8b7fb9e978e4ed45848287660235d5c51e2f110af4e0a9",
                                    "0495ebf4352ad688fffa84a7e22d1eb1bd29e5c881711d881319d81a626267f7",
                                    "1f4ef15b42d71da6eaa84c1666da8f3aae74ebcab35d1321b195f4e6e67b7565",
                                    "26b9e209584bf3efd59e440e6838fef4d67394ca48f2a9eb7533b7d4b23f015b",
                                    "370b3c12a845f303bf28657161f8285295c5c8625e7a4daf9c464c6957c03ed9",
                                    "413612c9c7052356812afdabf6504212199907ccb172b3e1e5b4e8ca29eac0a7",
                                    "7d82b46491ea76531341ae71f72e7a616761d08e10c9cc71afb7bc8b22dfbec1",
                                    "853ac84efec82736323b7c9a6cdcffe8ae2cf3da4bde5591d1568837b6c96ee9",
                                    "8c3e02eabd321bb7ae9ba21563338ba3df344ab68b381680bcc3a75a2a8ccdf4",
                                    "9185e488c18a92b2f4aa28769fc53b5e1cfe84d0b0c13a6b5e98c1f3f5266c6b",
                                    "c450b7fdfdcd44454ce327bf0975039d6c7668e23dbc6d91f7edede31875e626",
                                    "ce118f98f944cb4b7bf553a6c3361f5e5ffb39bc33050ac457ff5e68c3726eea",
                                    "d8b2ba48d1bc4fe02ccf2283c833bf8724bf4e48bfbdbc20ee240d0db536e5f7",
                                    "f57cdd8423add2b2919fce520a9a2d9a34184b0f716c1e6ae5508ef4ee31056e"
                                ]
                            },
                            {
                                "name": "6c5a331a8710274d8a04ac9c315eafef0ec060aaeaf649c4322812b900a972d4",
                                "value": 50,
                                "ufficio": "Commerciale Banking & Credito",
                                "link": [
                                    "03570a9667986e67fbc32a36c58c2a83620615f978d54065c0118b9ecf3bb513",
                                    "165d192513323e9624c5a4cf2379a90013365677958e51611eb1fc9c39dbedb1",
                                    "246fa0d14d762dcb827e140c56d3ef32baf59f87dd78b8e27cdecc6670ac8a73",
                                    "29e353b18fae9b63560f47b3f9cbcc291aad0650ad44705c79a9a4abd05ebe9c",
                                    "516c0937b94123241b706f185cbaf5039307313eaf7200e1e0ba64bc880c17f5",
                                    "55ff0b8efbcb37483f73f0ffe87b240272eefce6390e3c3454881cdc8b6b5638",
                                    "5e1084862fb3dc2334e3b24edef9e94744739cf28be459c808c84d5f7f0f6f70",
                                    "63f169eba9e68ec4a33ef5cb7cefbcf129975ac567f887dab1718ef65cd9971a",
                                    "8a69ecc023e5801658323c6a725b97387bed8cacab1bc8b740205d3534507d07",
                                    "b88c5e73043d51af0abb66947a83a98a7efd400e7e55eebac0bd3dc1e5c19374",
                                    "ed67329cdf04b5a943a12c63cd602a6d7f06a61d433a960d8c5514f13ca40ca9"
                                ]
                            },
                            {
                                "name": "6d1704d0094ff0d48b3987b3562e082ad66e8ff3b6edf7f57133376917a14a4c",
                                "value": 50,
                                "ufficio": "Analisi funzionali",
                                "link": [
                                    "117484657fdfa49f6f202c4a01f5869cd80e1a3dd60c339d9145c67a9fb03cae",
                                    "21128e9f23759392be2b2b17596d9d7c496d188162fd9ece9f8aa096e189a929",
                                    "2d5cdaed96acc015fa15ee3403b327066febef58d4ff9a20e06dfb7b261628ce",
                                    "a85ecef49e3b754918db9058c0e27dd64b37c25ecba47822a608accad8957746",
                                    "e684fac9c014b3716dea4475452a3460cc3005ff267aaa9ca909228ecd7d91d5"
                                ]
                            },
                            {
                                "name": "6d4f4fcc24d0ccfb24d64be9bf4af436a091122c5602e0a90b03c0cb2515f6ae",
                                "value": 50,
                                "ufficio": "Crediti",
                                "link": [
                                    "55ff0b8efbcb37483f73f0ffe87b240272eefce6390e3c3454881cdc8b6b5638",
                                    "63f169eba9e68ec4a33ef5cb7cefbcf129975ac567f887dab1718ef65cd9971a",
                                    "cf5df46f41de37bb03a4c96ba027720e172f4abef43760298fc7893ad58af09d",
                                    "d1692f6821e7745b042c2d05d65937e004b338bd4078d1729df09cbe4ddd0e48"
                                ]
                            },
                            {
                                "name": "6ec9cfba69a90f24827db98d1a905f97613cd4cc592b0e6f0026b5aae81a0118",
                                "value": 50,
                                "ufficio": "Sviluppo e Architetture",
                                "link": [
                                    "150dc7a0f15ffab503fdaa3cd09471f3c6055764f95b1f6cb4ff191bfc4a1532",
                                    "1f4ef15b42d71da6eaa84c1666da8f3aae74ebcab35d1321b195f4e6e67b7565",
                                    "413612c9c7052356812afdabf6504212199907ccb172b3e1e5b4e8ca29eac0a7",
                                    "52fd640dca47a5008d088dd235189c51fc8495106ae0a39bb021dd809350e29c",
                                    "5a44504d34546036745dcb5deeddab47d39a00fb19734ca7145dcc5a9dda9ea7",
                                    "61d29662a79cdedd32b1e54888938447235caf30c466d7ff090305868dc5919d",
                                    "853ac84efec82736323b7c9a6cdcffe8ae2cf3da4bde5591d1568837b6c96ee9",
                                    "8c3e02eabd321bb7ae9ba21563338ba3df344ab68b381680bcc3a75a2a8ccdf4",
                                    "8ddfe2ebab57cffc3af0f950c74433476a320a56aaf55fe5a0e2f74b31526a71",
                                    "a4e8e3247a94ebc46bb94c8b914971f5ad793847ccf16b1f41b0c88f5a366d80",
                                    "bfdbf363d9c4b0705ccbeecc39fe6db9a81f5145b54a3674b64b0da286429728",
                                    "ce118f98f944cb4b7bf553a6c3361f5e5ffb39bc33050ac457ff5e68c3726eea",
                                    "e3dc6f0b0a929bfbadca9a0b3b38180530b1656738bf7f89eb91c9be4c74975e"
                                ]
                            },
                            {
                                "name": "6f6b05938c3222e719d57701ea4f34dcfcebf231111d6b60aa693bd2e0169ca3",
                                "value": 50,
                                "ufficio": "Sistemi e Monitoring",
                                "link": [
                                    "67fd6c0efd48daf4780f975393ab5252432b35da210995dc6845d95261be3f3e"
                                ]
                            },
                            {
                                "name": "6f73b50dc0122b97db84a795721a6b625261b938843fcbf467616db0d9811fc5",
                                "value": 50,
                                "ufficio": "Controlli, Processi e Immobiliare",
                                "link": [
                                    "2f02900e1dd4918db6a502619ac506938f4be868a26074349313ed3ab6713f1f",
                                    "516c0937b94123241b706f185cbaf5039307313eaf7200e1e0ba64bc880c17f5",
                                    "55ff0b8efbcb37483f73f0ffe87b240272eefce6390e3c3454881cdc8b6b5638",
                                    "5f9c999f59aed9c22e6e9f149df9f9ebd5d62d7841b0ea9be721ca9ce7baa094",
                                    "6101c7c61c9aebce7ec0d0ac3ecfbf7be4b2ea92b01a55a3b49e244fdb4af554",
                                    "63f169eba9e68ec4a33ef5cb7cefbcf129975ac567f887dab1718ef65cd9971a",
                                    "969a210a20c074567b7fe306aff7184e0b6fd2340b6952a58423d31aab0e441a",
                                    "a91e77c4bfff24379c25a1ee6b866d3ab57df33661aed16539bb346b7e92cc1d",
                                    "c682a14b4b7b3ae8c6d204234a700baec5e56df8e2b2ff60ba668e74b493e274",
                                    "d1692f6821e7745b042c2d05d65937e004b338bd4078d1729df09cbe4ddd0e48",
                                    "f3280920c064eb900b091aa992d081b27c1f9f3c2e8aae488b69be093a4932e4"
                                ]
                            },
                            {
                                "name": "71852c01834ad9f0e850f43c6418b83681f7acb82eb134a23d164ae3db8e7760",
                                "value": 50,
                                "ufficio": "Sviluppo e Architetture",
                                "link": [
                                    "238ae5b17e2e2c57af472e3f8cfba32aed5cf2f12286424acd7971a63c794c39",
                                    "67678ae13f18c6a16575a01c50d4528d22e5e3862d512414f3dd81590aecfbd2"
                                ]
                            },
                            {
                                "name": "73cbc0bd39e2e5b7ddabdcd8e3ce3e32df2057dd17cea502a971e71e705b0cea",
                                "value": 50,
                                "ufficio": "Sviluppo e Architetture",
                                "link": [
                                    "8464812682363064de4d541a02afca217bb03d68d9409cbb02a96d553d50fde0",
                                    "8ddfe2ebab57cffc3af0f950c74433476a320a56aaf55fe5a0e2f74b31526a71",
                                    "f158062016d7c912ba1494b35e8cd64d07656b38a4a0d0992e4dc016800a3788"
                                ]
                            },
                            {
                                "name": "74910197266fef7d298dd12740f389762cac70dc2e07f05868b98db34241dc1b",
                                "value": 50,
                                "ufficio": "Data Intelligence",
                                "link": [
                                    "684154a13c4aba0b25fe1a0bdd4667ee40d5ed056108566cb09c6f8614bd27aa",
                                    "7d82b46491ea76531341ae71f72e7a616761d08e10c9cc71afb7bc8b22dfbec1",
                                    "9185e488c18a92b2f4aa28769fc53b5e1cfe84d0b0c13a6b5e98c1f3f5266c6b",
                                    "da19ae9bdf22abe2a9cb0962b11f8e355978c0261556c1951bff89a793042d65",
                                    "f6d9c7262c3bbcc3b8c695a1e98caf93d40ca532dd02620709ece64a72e042ed"
                                ]
                            },
                            {
                                "name": "76edfcd09cca7d558ec875aa9aecf369c69b8573cf04142b30cfb7199bbfebc5",
                                "value": 50,
                                "ufficio": "Crediti",
                                "link": [
                                    "516c0937b94123241b706f185cbaf5039307313eaf7200e1e0ba64bc880c17f5",
                                    "63f169eba9e68ec4a33ef5cb7cefbcf129975ac567f887dab1718ef65cd9971a"
                                ]
                            },
                            {
                                "name": "773ed157955ddce568e45fe6df032c00be2dc8274003ab9a49aee3a472b0dd03",
                                "value": 50,
                                "ufficio": "Customer care",
                                "link": [
                                    "1cff424a7b25c230a1c209b630182b4f91fa24bf231d02e19d8f6bc8a86731a1",
                                    "ff12b55ee827fc0697d9f8c059da7ab3b2c16cdcd329686754e8e0ee92c3b5a9"
                                ]
                            },
                            {
                                "name": "780f4a8c11f1780fff99ccb087d5dab5960765259902b28e778be4df8e2ac5af",
                                "value": 50,
                                "ufficio": "Analisi funzionali",
                                "link": [
                                    "00d7df14c3d418e6b9aa1fdbf670004b7bdde6bed851cbdb44d095c5d53633c3",
                                    "08577f12554924cfe76e64922eb02b81400bb159666c32d6479e5cf7bc8d8702",
                                    "2d5cdaed96acc015fa15ee3403b327066febef58d4ff9a20e06dfb7b261628ce",
                                    "50530ca013a6c0cf2eb8037d8032df3ae078924f85236bf5403db4404265bb62",
                                    "61d29662a79cdedd32b1e54888938447235caf30c466d7ff090305868dc5919d",
                                    "6d1704d0094ff0d48b3987b3562e082ad66e8ff3b6edf7f57133376917a14a4c",
                                    "8787740c45c655c0511fa208a842b48fd6818c89e1f9a4390482faaa868a027f",
                                    "ae4dd003704535e168ebdf1066be077474b7cc1a289ab06837430a972b3799ec",
                                    "bd935ad4c48672c5e4d791aac29afac44ac184d2694524e290563ca81cb2da60",
                                    "ecd3496bb4ed76576e96edd522f6903474e2a27d352d654ae562fb4ad2b2d881"
                                ]
                            },
                            {
                                "name": "7ad8ce7b05bf6d2f96c6cf7d8d2c20304a9fe6cf4d7a944608666d8f36b454c9",
                                "value": 50,
                                "ufficio": "Analisi funzionali",
                                "link": [
                                    "083b96e848f7fe101868dfd1f46eccedc017cbaddf9f49c19c71ccab49536ac0",
                                    "21128e9f23759392be2b2b17596d9d7c496d188162fd9ece9f8aa096e189a929",
                                    "246fa0d14d762dcb827e140c56d3ef32baf59f87dd78b8e27cdecc6670ac8a73",
                                    "2475b9470c6235901e7e3d9cc1201f817a6244a0603a072db7f4cee5785fa448",
                                    "2782ff4e895a9d5f2a1edc00d3922d7f7e640c3ee5a6400fb8c4ab3532249c3e",
                                    "2d5cdaed96acc015fa15ee3403b327066febef58d4ff9a20e06dfb7b261628ce",
                                    "38ce0ce0122e0bcdd595c12f417196d4acd690cf7b2d6cda61bc4c7208166e04",
                                    "413612c9c7052356812afdabf6504212199907ccb172b3e1e5b4e8ca29eac0a7",
                                    "42b4616df88565a9134d5620ebc56527d23095866ea78cb1afa74a82cf031f09",
                                    "50125cffec52c899c2da565099f3b084c25f25b17db6facc28758add900ad765",
                                    "50530ca013a6c0cf2eb8037d8032df3ae078924f85236bf5403db4404265bb62",
                                    "55ff0b8efbcb37483f73f0ffe87b240272eefce6390e3c3454881cdc8b6b5638",
                                    "6101c7c61c9aebce7ec0d0ac3ecfbf7be4b2ea92b01a55a3b49e244fdb4af554",
                                    "66dd598474bc4571b70ff70d1c88d591de4bedebce1edfc074621e71e833b081",
                                    "7c8496883cb2bea0087ab91f32c798eb9219ff32d0a56db7da8444d821f45d81",
                                    "7effcfa88296e6bafea01f9674e058de82c343a20d1b50710cb029eadb536339",
                                    "82755a463db01fe7ecad9815a9cfe8bc137a646110a3727d11e338f858f2f59f",
                                    "92ffbaa57f70f365dddf8d3c88b18849f252e0836f434d197ee7a185cdb79734",
                                    "94b0716f2a1b16dc7f4e9a57bc682f54c39daa1c7b6c99569471264755d9484c",
                                    "b01e860d623af6128973affb64ac36d84afa30b8089325c6b05134309dfc837a",
                                    "cd799a97931432b5e176c88142dc61546e826bc2e69a5bc0ec58df54d78c9b74",
                                    "f43e34018b549d8863e860d611e560ddc05865730aece3e8859a85dbbde6a3d0",
                                    "f680ca858e1602fe43773de835e5ead85a16f0a59a88465afbb6badbc99abd8a",
                                    "ff12b55ee827fc0697d9f8c059da7ab3b2c16cdcd329686754e8e0ee92c3b5a9"
                                ]
                            },
                            {
                                "name": "7b138a4913227d7a34f13a5c994fca2a492f6051b242e9eb45d9af1d99bc982e",
                                "value": 50,
                                "ufficio": "Analisi funzionali",
                                "link": [
                                    "01bc86e78cd69cc938888e5d58529827da10d3afacc31a4e746b1b235c362cb0",
                                    "06d38d94f292d05cdb7df23a05ef073febdd7c68931c6a7277f5f60d9e9e9b16",
                                    "117484657fdfa49f6f202c4a01f5869cd80e1a3dd60c339d9145c67a9fb03cae",
                                    "1bed37d0037f2f6a95cf5d417703ce4a2eda90363991a28e65ea1c788f83008b",
                                    "413612c9c7052356812afdabf6504212199907ccb172b3e1e5b4e8ca29eac0a7",
                                    "45cdf162e9a98c22f44e9e1259be540e5fc454f582ccb8e98371f00de1c23f04",
                                    "59294899f0e52923d6f08c1a86900c906187345c14c72a70fb51510e7fa00aec",
                                    "6120dd083a4c1cf13dd2c8ca1f92591ac48875307ff66fc8da019512f3d7a3df",
                                    "67678ae13f18c6a16575a01c50d4528d22e5e3862d512414f3dd81590aecfbd2",
                                    "73cbc0bd39e2e5b7ddabdcd8e3ce3e32df2057dd17cea502a971e71e705b0cea",
                                    "8ddfe2ebab57cffc3af0f950c74433476a320a56aaf55fe5a0e2f74b31526a71",
                                    "9c0a0f6c096827cf6fe5d3f87a6312ebd04e8ad3657fad79c4a8d967c445b9f2",
                                    "9d97a74ebd18d7acffed0070ca4bc846160f8159d8efe722f90554cc3d309c3d",
                                    "a88b39bfb012c98803e66f0b018c25d1ffa5766c21260b623d9ddff1b90edc37",
                                    "b080642b5854215716c65a72781e55050b41240865a8621530419a1348d810f4",
                                    "b14ceff609c278e1c1891d643b7aa9eb267e80ce5e050863aeeddee9bcb25bd0",
                                    "ccb35cb14a71c974c83dae675e7143e1788db7293805206540c8d9df094e4ff4",
                                    "cd799a97931432b5e176c88142dc61546e826bc2e69a5bc0ec58df54d78c9b74",
                                    "ce118f98f944cb4b7bf553a6c3361f5e5ffb39bc33050ac457ff5e68c3726eea",
                                    "d080f6246f89a229aff787012e414f09e420a8f174d2c85a2f342cebd9d54864",
                                    "d9b07283f475aeb6d05e04c9acb0f0bd609e9b5220b21f7a99a5c342a64139dc",
                                    "db8d910dfd966f6af426d67ef70da670fccc08932340c385b8d340151e7e7ac8",
                                    "f0c2bb337febad5eab9639010c46aa5a111eff6a15b323eec978c6aa946c36e5",
                                    "f158062016d7c912ba1494b35e8cd64d07656b38a4a0d0992e4dc016800a3788",
                                    "f4f2d477cc383f7885fc3724f0a204025e3ef04f34c7478a185beac0c595ff6c"
                                ]
                            },
                            {
                                "name": "7bd334b88615bc7016ee5e1fcc0e2860fbc90f187d9b2f37150b9da59cb21610",
                                "value": 50,
                                "ufficio": "Controlli, Processi e Immobiliare",
                                "link": [
                                    "50125cffec52c899c2da565099f3b084c25f25b17db6facc28758add900ad765",
                                    "55ff0b8efbcb37483f73f0ffe87b240272eefce6390e3c3454881cdc8b6b5638",
                                    "6101c7c61c9aebce7ec0d0ac3ecfbf7be4b2ea92b01a55a3b49e244fdb4af554",
                                    "edc927c302d834fd6d1a19493931750c25e5dc949f2b2a8a8d05fb339ea75146",
                                    "ffdd2a8bbeedc5f07cf40d7708d90c44d18260c146641e835b194e0f8c0a0490"
                                ]
                            },
                            {
                                "name": "7c8496883cb2bea0087ab91f32c798eb9219ff32d0a56db7da8444d821f45d81",
                                "value": 50,
                                "ufficio": "Chief Technology Officer",
                                "link": [
                                    "21128e9f23759392be2b2b17596d9d7c496d188162fd9ece9f8aa096e189a929",
                                    "23a9f81805333f7e325484837c8b8874e22105346952d2e500f93a61afa5c928",
                                    "413612c9c7052356812afdabf6504212199907ccb172b3e1e5b4e8ca29eac0a7",
                                    "50530ca013a6c0cf2eb8037d8032df3ae078924f85236bf5403db4404265bb62",
                                    "cd799a97931432b5e176c88142dc61546e826bc2e69a5bc0ec58df54d78c9b74",
                                    "f57cdd8423add2b2919fce520a9a2d9a34184b0f716c1e6ae5508ef4ee31056e"
                                ]
                            },
                            {
                                "name": "7d82b46491ea76531341ae71f72e7a616761d08e10c9cc71afb7bc8b22dfbec1",
                                "value": 50,
                                "ufficio": "Data Intelligence",
                                "link": [
                                    "da19ae9bdf22abe2a9cb0962b11f8e355978c0261556c1951bff89a793042d65",
                                    "f6d9c7262c3bbcc3b8c695a1e98caf93d40ca532dd02620709ece64a72e042ed"
                                ]
                            },
                            {
                                "name": "7db5e7074c95a92b6e3bd1a1cd1ca89bcc8269cdfe18d25a15156a26a0ad538c",
                                "value": 50,
                                "ufficio": "Commerciale Investimenti",
                                "link": [
                                    "1918402b88bbadb987f2e2d0b0bc9444f824af4bd707b1abcfe9bee5174fc7fa",
                                    "2475b9470c6235901e7e3d9cc1201f817a6244a0603a072db7f4cee5785fa448",
                                    "3779faf7954bbba479b99fbb0c6543df87cabb3d70654b2486c354a023406372",
                                    "8c065cfe40a3279cd689a512a27dfcd0afd5c725384b0bf22eaa22308bba5302",
                                    "e118679565fed3549f6e05b398f1275b4cb0c955e46ec0c6bea540974364cd48",
                                    "f680ca858e1602fe43773de835e5ead85a16f0a59a88465afbb6badbc99abd8a"
                                ]
                            },
                            {
                                "name": "7effcfa88296e6bafea01f9674e058de82c343a20d1b50710cb029eadb536339",
                                "value": 50,
                                "ufficio": "Analisi funzionali",
                                "link": [
                                    "38ce0ce0122e0bcdd595c12f417196d4acd690cf7b2d6cda61bc4c7208166e04",
                                    "4e3745d60344cdb6a21b56019ed24a1fbb711b428182ad9a329327786e4d907d",
                                    "55ff0b8efbcb37483f73f0ffe87b240272eefce6390e3c3454881cdc8b6b5638",
                                    "7ad8ce7b05bf6d2f96c6cf7d8d2c20304a9fe6cf4d7a944608666d8f36b454c9",
                                    "82755a463db01fe7ecad9815a9cfe8bc137a646110a3727d11e338f858f2f59f",
                                    "bd935ad4c48672c5e4d791aac29afac44ac184d2694524e290563ca81cb2da60",
                                    "f43e34018b549d8863e860d611e560ddc05865730aece3e8859a85dbbde6a3d0"
                                ]
                            },
                            {
                                "name": "814b7e522b4b800335488042e5dad575528905ba0ad796d4ec52b02ad98c5f77",
                                "value": 50,
                                "ufficio": "Legale",
                                "link": [
                                    "50125cffec52c899c2da565099f3b084c25f25b17db6facc28758add900ad765",
                                    "fc0122f6d05c2ec54028830dc458440a2ff21f5a1bd11b664fec0fc9d6b346cc"
                                ]
                            },
                            {
                                "name": "82755a463db01fe7ecad9815a9cfe8bc137a646110a3727d11e338f858f2f59f",
                                "value": 50,
                                "ufficio": "Digital Branch",
                                "link": [
                                    "112b9a07fc3aac15fee0e4a28221308133e5027c629434bc046b412cf6d4b39b",
                                    "1d3ef700b9d0aba0c20abf40c35422b416c3bba1de5564304df142cef31bca1c",
                                    "47bc260e171c6fd87acf10cc371231cd77195394e16c4c2e57c57d1d446aff59",
                                    "4af4cbc00eeadb01960175f8e148342ce43279cc161fd67b1cac2ee1e6ee60dc",
                                    "55ff0b8efbcb37483f73f0ffe87b240272eefce6390e3c3454881cdc8b6b5638",
                                    "62298390c085c1b91e57863bc510085fb2f7579758accb0c081a4930bd5cb533",
                                    "6a03854e415b478f615318d423b54e92dd2077b56f3488690756437e32908be7",
                                    "dec59a7383f62a4dcf4548eb913834f857598bf81a196fff3414ea39cc5ee32d"
                                ]
                            },
                            {
                                "name": "8443c187871034e8ba51d518a5ee3334d252245351ea2dcaec814b7896fd899c",
                                "value": 50,
                                "ufficio": "Crediti",
                                "link": [
                                    "516c0937b94123241b706f185cbaf5039307313eaf7200e1e0ba64bc880c17f5"
                                ]
                            },
                            {
                                "name": "8464812682363064de4d541a02afca217bb03d68d9409cbb02a96d553d50fde0",
                                "value": 50,
                                "ufficio": "Analisi funzionali",
                                "link": [
                                    "06d38d94f292d05cdb7df23a05ef073febdd7c68931c6a7277f5f60d9e9e9b16",
                                    "1bed37d0037f2f6a95cf5d417703ce4a2eda90363991a28e65ea1c788f83008b",
                                    "67678ae13f18c6a16575a01c50d4528d22e5e3862d512414f3dd81590aecfbd2",
                                    "73cbc0bd39e2e5b7ddabdcd8e3ce3e32df2057dd17cea502a971e71e705b0cea",
                                    "db8d910dfd966f6af426d67ef70da670fccc08932340c385b8d340151e7e7ac8",
                                    "f0c2bb337febad5eab9639010c46aa5a111eff6a15b323eec978c6aa946c36e5",
                                    "f158062016d7c912ba1494b35e8cd64d07656b38a4a0d0992e4dc016800a3788"
                                ]
                            },
                            {
                                "name": "849fba80ec2db6a0b1729e9732c6ccd8874bc00647aa1064c22590b292703dc0",
                                "value": 50,
                                "ufficio": "Commerciale Investimenti",
                                "link": [
                                    "2475b9470c6235901e7e3d9cc1201f817a6244a0603a072db7f4cee5785fa448",
                                    "4391e04c3b3e5bce2eb851c131fee130fa4b50e33ae98dff76d02b5bb94bd5e1",
                                    "48cdde88d13cecd870e6200115cd667de65900c03b57e0e5b7e4cd3da1e1fd21",
                                    "55af1d666e9c133bc765e08f1068057f1d51592ad022affeb421bdf7dbfcdb18",
                                    "6120dd083a4c1cf13dd2c8ca1f92591ac48875307ff66fc8da019512f3d7a3df",
                                    "80335f30f0183fc023e34a72f7c9cddf62808847fbf68a9ae537b4d21588b8f8",
                                    "8ddfe2ebab57cffc3af0f950c74433476a320a56aaf55fe5a0e2f74b31526a71"
                                ]
                            },
                            {
                                "name": "853ac84efec82736323b7c9a6cdcffe8ae2cf3da4bde5591d1568837b6c96ee9",
                                "value": 50,
                                "ufficio": "Sviluppo e Architetture",
                                "link": [
                                    "01bc86e78cd69cc938888e5d58529827da10d3afacc31a4e746b1b235c362cb0",
                                    "117484657fdfa49f6f202c4a01f5869cd80e1a3dd60c339d9145c67a9fb03cae",
                                    "18f8f073dd89fcf946d09157db5342889d19543390c8cd4c9dd8b05bc65fd518",
                                    "1c3b601500525bebe692d29a852e12a4dddb9cf2c45a101e17b61a0098a2cf59",
                                    "1f4ef15b42d71da6eaa84c1666da8f3aae74ebcab35d1321b195f4e6e67b7565",
                                    "238ae5b17e2e2c57af472e3f8cfba32aed5cf2f12286424acd7971a63c794c39",
                                    "2470fd17d1f1959717120deadaaa3b455dd3c74c8ced88054a8b4fb88e4f8f28",
                                    "370b3c12a845f303bf28657161f8285295c5c8625e7a4daf9c464c6957c03ed9",
                                    "39279f0ae2acd0ac0500f39f1d2f51fba54849e4c930cc7275b3f2c82c3883d1",
                                    "413612c9c7052356812afdabf6504212199907ccb172b3e1e5b4e8ca29eac0a7",
                                    "42d3f609a82d7d42d4b1fbb92e1fb77c095d318ed908b236393aa914bdbd7acc",
                                    "432f4b6475380ae4688eba30a14f2c02c391d3690924ca8134b9d1620af15f28",
                                    "4d9ed21b4e1893c31acbbe057c063c726961bda2b39ce184609662933fbaa733",
                                    "4e12e345bdb2e98fc1ce84b6b9724b164ab5570d3102f6cfd7759a367b58d857",
                                    "4f188222e99c82078ee2bacc682ce5f86fefe34c32b4b7c6f1e6699a213eba98",
                                    "52fd640dca47a5008d088dd235189c51fc8495106ae0a39bb021dd809350e29c",
                                    "59294899f0e52923d6f08c1a86900c906187345c14c72a70fb51510e7fa00aec",
                                    "5a44504d34546036745dcb5deeddab47d39a00fb19734ca7145dcc5a9dda9ea7",
                                    "5cdffd0bb86164a019995466d08eee034547095aedef6ebe0f9f42aa8017698c",
                                    "655e07f5ed4e15423d43a532542c117b3514eec081633d73af8e542afc4f4b21",
                                    "67678ae13f18c6a16575a01c50d4528d22e5e3862d512414f3dd81590aecfbd2",
                                    "6ec9cfba69a90f24827db98d1a905f97613cd4cc592b0e6f0026b5aae81a0118",
                                    "6f5f1042d58240dc0cc7eb8522f1d40d6d0dc7a1e1c1c94815a0d1f1fb41bf51",
                                    "7184afbf09495f0d5659820f2745c836b9e8f8b2d795e57b3ed9a4bff712cabe",
                                    "71852c01834ad9f0e850f43c6418b83681f7acb82eb134a23d164ae3db8e7760",
                                    "73cbc0bd39e2e5b7ddabdcd8e3ce3e32df2057dd17cea502a971e71e705b0cea",
                                    "7c8496883cb2bea0087ab91f32c798eb9219ff32d0a56db7da8444d821f45d81",
                                    "807ce620e70113b5af04dbfcdf1dd7ca41f5e64fa8199b67ffedb5352481cb7f",
                                    "8787740c45c655c0511fa208a842b48fd6818c89e1f9a4390482faaa868a027f",
                                    "8c3e02eabd321bb7ae9ba21563338ba3df344ab68b381680bcc3a75a2a8ccdf4",
                                    "913cdf0ec1a4b8694a0f8677e310fc322b3422724102df7d458fa097b25fa795",
                                    "9949bc108015faaf4b49124f1164a7bc6d8db0c7ea9d32d634a4e89d74134ad9",
                                    "9f6abb824f8cf4ed7997ff008f64449621aebd1d4bc7af1f46d0f238ebc954a2",
                                    "a69b26529fc2b65bf470c2a2bd22efe41f73763eec20a8a93c05ba720e9fcbd1",
                                    "a88b39bfb012c98803e66f0b018c25d1ffa5766c21260b623d9ddff1b90edc37",
                                    "abc7401e3265278becd57c05a0f1675fbb4b94f39845020e6ddb03f448020e02",
                                    "b04e2eca334e777155ad38dcf329026d75656094a8665ff4448e4d1bba89eed1",
                                    "b080642b5854215716c65a72781e55050b41240865a8621530419a1348d810f4",
                                    "b14ceff609c278e1c1891d643b7aa9eb267e80ce5e050863aeeddee9bcb25bd0",
                                    "b94f1f031fb821f81bd0134767c6b12687da03f5eca73b58a127569a21ed1dae",
                                    "be0b7f3457783af8b09e2cf60f10cfdd64903cbda18a9b1c3216ea6a97026370",
                                    "bf2a52af189f34bcf54851fcdd6a8deb89d31ec18c9e53273f05739ddd5badc1",
                                    "bfdbf363d9c4b0705ccbeecc39fe6db9a81f5145b54a3674b64b0da286429728",
                                    "c7765c7be97958a8b3ca400d000e046e48422cc3733e8019741caa97be3e5d8a",
                                    "ce118f98f944cb4b7bf553a6c3361f5e5ffb39bc33050ac457ff5e68c3726eea",
                                    "ce5d99de22269fea7904a89036f0aef155beabc375707b88bd2d1b73d7eddb11",
                                    "d46963e985568e7dad629017de5c6f04c3ce4b2b3a584054f7b153812afcff04",
                                    "d5940e1eea800cac78acf3ef1ce311e6d22fc55b076391f5a483b2f25a813855",
                                    "d9b07283f475aeb6d05e04c9acb0f0bd609e9b5220b21f7a99a5c342a64139dc",
                                    "db8d910dfd966f6af426d67ef70da670fccc08932340c385b8d340151e7e7ac8",
                                    "e3dc6f0b0a929bfbadca9a0b3b38180530b1656738bf7f89eb91c9be4c74975e",
                                    "ecd3496bb4ed76576e96edd522f6903474e2a27d352d654ae562fb4ad2b2d881",
                                    "ee64e69e3c67b6db4180164ace85772c427d49160d44da1a27032fe2be2cd16c",
                                    "f0c2bb337febad5eab9639010c46aa5a111eff6a15b323eec978c6aa946c36e5",
                                    "f3f85e565e5ac1094c984e1a8434c23af875b724c4a35203024759374686141e",
                                    "f4f2d477cc383f7885fc3724f0a204025e3ef04f34c7478a185beac0c595ff6c",
                                    "fa5deed70dae7bbed11f872ecc4a3cf64ff21e2099602e3dfbe91fdc869493f3"
                                ]
                            },
                            {
                                "name": "86f7c464af2a1b6fd14c3919260cf5bcbecb7f78b17d577c77c256599e25dcc0",
                                "value": 50,
                                "ufficio": "Chief Technology Officer",
                                "link": [
                                    "89581076ad0b28dc87a57bce316f2d6d7a95bbe17220b598b8cfdc5f1685fbb8"
                                ]
                            },
                            {
                                "name": "8787740c45c655c0511fa208a842b48fd6818c89e1f9a4390482faaa868a027f",
                                "value": 50,
                                "ufficio": "Sviluppo e Architetture",
                                "link": [
                                    "00d7df14c3d418e6b9aa1fdbf670004b7bdde6bed851cbdb44d095c5d53633c3",
                                    "352097edcec7e4cc4332657c589d7506b13fa583c8078c419c1f1e22fc6bb66a"
                                ]
                            },
                            {
                                "name": "89581076ad0b28dc87a57bce316f2d6d7a95bbe17220b598b8cfdc5f1685fbb8",
                                "value": 50,
                                "ufficio": "Chief Technology Officer",
                                "link": [
                                    "413612c9c7052356812afdabf6504212199907ccb172b3e1e5b4e8ca29eac0a7",
                                    "86f7c464af2a1b6fd14c3919260cf5bcbecb7f78b17d577c77c256599e25dcc0"
                                ]
                            },
                            {
                                "name": "8a69ecc023e5801658323c6a725b97387bed8cacab1bc8b740205d3534507d07",
                                "value": 50,
                                "ufficio": "Commerciale Banking & Credito",
                                "link": [
                                    "165d192513323e9624c5a4cf2379a90013365677958e51611eb1fc9c39dbedb1",
                                    "29e353b18fae9b63560f47b3f9cbcc291aad0650ad44705c79a9a4abd05ebe9c",
                                    "55ff0b8efbcb37483f73f0ffe87b240272eefce6390e3c3454881cdc8b6b5638",
                                    "5e1084862fb3dc2334e3b24edef9e94744739cf28be459c808c84d5f7f0f6f70",
                                    "63f169eba9e68ec4a33ef5cb7cefbcf129975ac567f887dab1718ef65cd9971a",
                                    "6c5a331a8710274d8a04ac9c315eafef0ec060aaeaf649c4322812b900a972d4",
                                    "8f9707cd5ae227dc0ea5e7ddb76fe9ab62e93c62829debd8ba5f599ef62b7502",
                                    "b88c5e73043d51af0abb66947a83a98a7efd400e7e55eebac0bd3dc1e5c19374",
                                    "ed67329cdf04b5a943a12c63cd602a6d7f06a61d433a960d8c5514f13ca40ca9"
                                ]
                            },
                            {
                                "name": "8a88ee434b3c41377a0fdf64f73927ad4c7580538ec7842be17933bc4a669e6a",
                                "value": 50,
                                "ufficio": "Pianificazione e Controllo di Gestione",
                                "link": [
                                    "50125cffec52c899c2da565099f3b084c25f25b17db6facc28758add900ad765"
                                ]
                            },
                            {
                                "name": "8a8d13915198c2121b499efa102f62d5d5ba92ee2c8d5d6659f3f787c722fe59",
                                "value": 50,
                                "ufficio": "Analisi funzionali",
                                "link": [
                                    "03570a9667986e67fbc32a36c58c2a83620615f978d54065c0118b9ecf3bb513",
                                    "29e353b18fae9b63560f47b3f9cbcc291aad0650ad44705c79a9a4abd05ebe9c",
                                    "42d3f609a82d7d42d4b1fbb92e1fb77c095d318ed908b236393aa914bdbd7acc",
                                    "9949bc108015faaf4b49124f1164a7bc6d8db0c7ea9d32d634a4e89d74134ad9",
                                    "b04e2eca334e777155ad38dcf329026d75656094a8665ff4448e4d1bba89eed1",
                                    "b14ceff609c278e1c1891d643b7aa9eb267e80ce5e050863aeeddee9bcb25bd0",
                                    "d718c84701eb431e405971fea04377bfdc1869c17b6cc272ebe8ac8d8bbe74cb"
                                ]
                            },
                            {
                                "name": "8c065cfe40a3279cd689a512a27dfcd0afd5c725384b0bf22eaa22308bba5302",
                                "value": 50,
                                "ufficio": "Analisi funzionali",
                                "link": [
                                    "01383b73d276c1b3293acb0f6f25ea1f284cf1de900cfae83e1d9b15a4175a9f",
                                    "cd799a97931432b5e176c88142dc61546e826bc2e69a5bc0ec58df54d78c9b74"
                                ]
                            },
                            {
                                "name": "8c3e02eabd321bb7ae9ba21563338ba3df344ab68b381680bcc3a75a2a8ccdf4",
                                "value": 50,
                                "ufficio": "Sviluppo e Architetture",
                                "link": [
                                    "1f4ef15b42d71da6eaa84c1666da8f3aae74ebcab35d1321b195f4e6e67b7565",
                                    "413612c9c7052356812afdabf6504212199907ccb172b3e1e5b4e8ca29eac0a7",
                                    "853ac84efec82736323b7c9a6cdcffe8ae2cf3da4bde5591d1568837b6c96ee9",
                                    "ce118f98f944cb4b7bf553a6c3361f5e5ffb39bc33050ac457ff5e68c3726eea"
                                ]
                            },
                            {
                                "name": "8ddfe2ebab57cffc3af0f950c74433476a320a56aaf55fe5a0e2f74b31526a71",
                                "value": 50,
                                "ufficio": "Analisi funzionali",
                                "link": [
                                    "06d38d94f292d05cdb7df23a05ef073febdd7c68931c6a7277f5f60d9e9e9b16",
                                    "117484657fdfa49f6f202c4a01f5869cd80e1a3dd60c339d9145c67a9fb03cae",
                                    "1bed37d0037f2f6a95cf5d417703ce4a2eda90363991a28e65ea1c788f83008b",
                                    "1f4ef15b42d71da6eaa84c1666da8f3aae74ebcab35d1321b195f4e6e67b7565",
                                    "413612c9c7052356812afdabf6504212199907ccb172b3e1e5b4e8ca29eac0a7",
                                    "67678ae13f18c6a16575a01c50d4528d22e5e3862d512414f3dd81590aecfbd2",
                                    "73cbc0bd39e2e5b7ddabdcd8e3ce3e32df2057dd17cea502a971e71e705b0cea",
                                    "7b138a4913227d7a34f13a5c994fca2a492f6051b242e9eb45d9af1d99bc982e",
                                    "8464812682363064de4d541a02afca217bb03d68d9409cbb02a96d553d50fde0",
                                    "88237b6a9bdc01eacd137201074f44ffd2e4c2f0192ed388bef9e180d4b6fdc6",
                                    "9d97a74ebd18d7acffed0070ca4bc846160f8159d8efe722f90554cc3d309c3d",
                                    "a88b39bfb012c98803e66f0b018c25d1ffa5766c21260b623d9ddff1b90edc37",
                                    "b080642b5854215716c65a72781e55050b41240865a8621530419a1348d810f4",
                                    "cd799a97931432b5e176c88142dc61546e826bc2e69a5bc0ec58df54d78c9b74",
                                    "d080f6246f89a229aff787012e414f09e420a8f174d2c85a2f342cebd9d54864",
                                    "f158062016d7c912ba1494b35e8cd64d07656b38a4a0d0992e4dc016800a3788"
                                ]
                            },
                            {
                                "name": "8f9707cd5ae227dc0ea5e7ddb76fe9ab62e93c62829debd8ba5f599ef62b7502",
                                "value": 50,
                                "ufficio": "Commerciale Banking & Credito",
                                "link": [
                                    "29e353b18fae9b63560f47b3f9cbcc291aad0650ad44705c79a9a4abd05ebe9c",
                                    "8a69ecc023e5801658323c6a725b97387bed8cacab1bc8b740205d3534507d07"
                                ]
                            },
                            {
                                "name": "915e1d4b27b8f8d1ff4a6f3fd44f14629b0aa665d34f20020877d7d3ac517c40",
                                "value": 50,
                                "ufficio": "Data Intelligence",
                                "link": [
                                    "1cff424a7b25c230a1c209b630182b4f91fa24bf231d02e19d8f6bc8a86731a1",
                                    "684154a13c4aba0b25fe1a0bdd4667ee40d5ed056108566cb09c6f8614bd27aa",
                                    "bc369356384d79a8fe050a841aef947bda1114404e1249bb5ac883b4a91ae707",
                                    "da19ae9bdf22abe2a9cb0962b11f8e355978c0261556c1951bff89a793042d65",
                                    "f6d9c7262c3bbcc3b8c695a1e98caf93d40ca532dd02620709ece64a72e042ed"
                                ]
                            },
                            {
                                "name": "9185e488c18a92b2f4aa28769fc53b5e1cfe84d0b0c13a6b5e98c1f3f5266c6b",
                                "value": 50,
                                "ufficio": "Sistemi e Monitoring",
                                "link": [
                                    "1f4ef15b42d71da6eaa84c1666da8f3aae74ebcab35d1321b195f4e6e67b7565",
                                    "413612c9c7052356812afdabf6504212199907ccb172b3e1e5b4e8ca29eac0a7",
                                    "6aa56775705605384583392112cc3a974b7bded505375979500264fb35310747",
                                    "7d82b46491ea76531341ae71f72e7a616761d08e10c9cc71afb7bc8b22dfbec1",
                                    "ce118f98f944cb4b7bf553a6c3361f5e5ffb39bc33050ac457ff5e68c3726eea"
                                ]
                            },
                            {
                                "name": "92ffbaa57f70f365dddf8d3c88b18849f252e0836f434d197ee7a185cdb79734",
                                "value": 50,
                                "ufficio": "Analisi funzionali",
                                "link": [
                                    "7ad8ce7b05bf6d2f96c6cf7d8d2c20304a9fe6cf4d7a944608666d8f36b454c9",
                                    "b01e860d623af6128973affb64ac36d84afa30b8089325c6b05134309dfc837a"
                                ]
                            },
                            {
                                "name": "9344687749ccaab48b3f7d2d5e3b9cf0f71d8b4abb2f99f6b094fc18f64ad3eb",
                                "value": 50,
                                "ufficio": "Amministrazione consulenza finanziaria",
                                "link": [
                                    "08031c40fea4b77b2203830bb3dc0aea814fda06cd5a344019eadedbde13c15b",
                                    "3779faf7954bbba479b99fbb0c6543df87cabb3d70654b2486c354a023406372",
                                    "3e96a90c292519dd959a327f2fa19128d2870160698271969dad21652684fdbd",
                                    "64729b37ca40f8417c6bcce885bad4ab76f2054b6619769b4f01533428e6a0f8",
                                    "ccb35cb14a71c974c83dae675e7143e1788db7293805206540c8d9df094e4ff4",
                                    "d26aa2e9c00f1acc24a8b3772b51c2558ccf58b0bd1ce98f59f57e041ceb2e63",
                                    "f680ca858e1602fe43773de835e5ead85a16f0a59a88465afbb6badbc99abd8a"
                                ]
                            },
                            {
                                "name": "9445045a5965f1248b6d49d8030bc56fdf6882ac9ee60a13c0eb87244b8c7060",
                                "value": 50,
                                "ufficio": "Risorse Umane e Progetti Evolutivi",
                                "link": [
                                    "36976cfe80dcf459c6a92c1d326df99d4e0c262970320e221f4093becab351c3",
                                    "f3eae844e383579728b6d9069cc00301c5b0941b1c10a464c7b9a424618cef2a"
                                ]
                            },
                            {
                                "name": "94b0716f2a1b16dc7f4e9a57bc682f54c39daa1c7b6c99569471264755d9484c",
                                "value": 50,
                                "ufficio": "Risk & Fraud",
                                "link": [
                                    "0e428ffef64b10335e73fb703497fafde699c4eb8fbc533035b84489c91ec4d6",
                                    "21128e9f23759392be2b2b17596d9d7c496d188162fd9ece9f8aa096e189a929",
                                    "246fa0d14d762dcb827e140c56d3ef32baf59f87dd78b8e27cdecc6670ac8a73",
                                    "2475b9470c6235901e7e3d9cc1201f817a6244a0603a072db7f4cee5785fa448",
                                    "2782ff4e895a9d5f2a1edc00d3922d7f7e640c3ee5a6400fb8c4ab3532249c3e",
                                    "2cca58c36e9accd51799044e15182df022e053e34c24f13f5577c316ca8db892",
                                    "413612c9c7052356812afdabf6504212199907ccb172b3e1e5b4e8ca29eac0a7",
                                    "43e65699d010731a4bdfabbf9389a15271b186c95a5935d84950c4f856a80986",
                                    "48cdde88d13cecd870e6200115cd667de65900c03b57e0e5b7e4cd3da1e1fd21",
                                    "4d783066b85f21a7821f82097dae1aed71418fef7af48fc51befffe93af3d9cf",
                                    "50125cffec52c899c2da565099f3b084c25f25b17db6facc28758add900ad765",
                                    "50530ca013a6c0cf2eb8037d8032df3ae078924f85236bf5403db4404265bb62",
                                    "7ad8ce7b05bf6d2f96c6cf7d8d2c20304a9fe6cf4d7a944608666d8f36b454c9",
                                    "c3c4106b8583c84e11c12032747f577b4c00c5b84c348d9c586d539f072a7589",
                                    "cd799a97931432b5e176c88142dc61546e826bc2e69a5bc0ec58df54d78c9b74",
                                    "e6bd9b91c6b370580e55f4dca3a3241027babbf65d92298a5c2804b26167fc07",
                                    "ec8901cc98702644ab46c54add211d0edfb0f416476369cdd9758afcfbe21609",
                                    "f680ca858e1602fe43773de835e5ead85a16f0a59a88465afbb6badbc99abd8a"
                                ]
                            },
                            {
                                "name": "959f1d51b386cac9dba6dcfed0bbceac0d98a8e30e284abf4108fdcd111f14ac",
                                "value": 50,
                                "ufficio": "Customer care",
                                "link": [
                                    "e5b879512a1674dc7b5652370e4b79117acfb9c47cfd932a905f7c2dcee40803"
                                ]
                            },
                            {
                                "name": "961b69abaab45da0ffccf1bb8be8ea853b617afad66594b8414a574a33f22651",
                                "value": 50,
                                "ufficio": "Eventi e Brand",
                                "link": [
                                    "0e428ffef64b10335e73fb703497fafde699c4eb8fbc533035b84489c91ec4d6",
                                    "23a9f81805333f7e325484837c8b8874e22105346952d2e500f93a61afa5c928",
                                    "2475b9470c6235901e7e3d9cc1201f817a6244a0603a072db7f4cee5785fa448",
                                    "33bbf19881e2d6db89f806f0f3b84552b843e90b78e06b013d145b78113d2b56",
                                    "ea0ba1b92d9815a5d808d92bc8b85e97d2c3f3aa6f8c8407ab6add9cd7e5be7a",
                                    "edc927c302d834fd6d1a19493931750c25e5dc949f2b2a8a8d05fb339ea75146"
                                ]
                            },
                            {
                                "name": "969a210a20c074567b7fe306aff7184e0b6fd2340b6952a58423d31aab0e441a",
                                "value": 50,
                                "ufficio": "Crediti",
                                "link": [
                                    "63f169eba9e68ec4a33ef5cb7cefbcf129975ac567f887dab1718ef65cd9971a",
                                    "6f73b50dc0122b97db84a795721a6b625261b938843fcbf467616db0d9811fc5"
                                ]
                            },
                            {
                                "name": "9949bc108015faaf4b49124f1164a7bc6d8db0c7ea9d32d634a4e89d74134ad9",
                                "value": 50,
                                "ufficio": "Sviluppo e Architetture",
                                "link": [
                                    "8a8d13915198c2121b499efa102f62d5d5ba92ee2c8d5d6659f3f787c722fe59",
                                    "d718c84701eb431e405971fea04377bfdc1869c17b6cc272ebe8ac8d8bbe74cb"
                                ]
                            },
                            {
                                "name": "9a44fd708e36fc4c92d967ede843ab33c6315bf9e8e254006b81bf8b79c889d0",
                                "value": 50,
                                "ufficio": "User Experience",
                                "link": [
                                    "15167bd90d8d12b7bed6cfcd192e5306b4e1216c584cc9c50977d8ea7f30e281",
                                    "4430f196eb4eb2ff7d9806df3fad24bd892c123d66855e0e9ad2f81242892211",
                                    "48805a805841f176eec7131f6f75a461aadf461f846d4e5516facf15350cfaeb",
                                    "a4e8e3247a94ebc46bb94c8b914971f5ad793847ccf16b1f41b0c88f5a366d80",
                                    "d479e93d42e5581e11ff27c6eaacf71c682fe3914a72e77fdf5702dd9b182c36",
                                    "e290b0a48ba64237e04dad91dd9197f081a22d0c60b8bb247f25eeef2334c2ce"
                                ]
                            },
                            {
                                "name": "9c0a0f6c096827cf6fe5d3f87a6312ebd04e8ad3657fad79c4a8d967c445b9f2",
                                "value": 50,
                                "ufficio": "Analisi funzionali",
                                "link": [
                                    "db8d910dfd966f6af426d67ef70da670fccc08932340c385b8d340151e7e7ac8",
                                    "f0c2bb337febad5eab9639010c46aa5a111eff6a15b323eec978c6aa946c36e5"
                                ]
                            },
                            {
                                "name": "9d97a74ebd18d7acffed0070ca4bc846160f8159d8efe722f90554cc3d309c3d",
                                "value": 50,
                                "ufficio": "Analisi funzionali",
                                "link": [
                                    "1f4ef15b42d71da6eaa84c1666da8f3aae74ebcab35d1321b195f4e6e67b7565",
                                    "45cdf162e9a98c22f44e9e1259be540e5fc454f582ccb8e98371f00de1c23f04",
                                    "67678ae13f18c6a16575a01c50d4528d22e5e3862d512414f3dd81590aecfbd2",
                                    "8ddfe2ebab57cffc3af0f950c74433476a320a56aaf55fe5a0e2f74b31526a71",
                                    "cd799a97931432b5e176c88142dc61546e826bc2e69a5bc0ec58df54d78c9b74",
                                    "ce118f98f944cb4b7bf553a6c3361f5e5ffb39bc33050ac457ff5e68c3726eea",
                                    "f158062016d7c912ba1494b35e8cd64d07656b38a4a0d0992e4dc016800a3788"
                                ]
                            },
                            {
                                "name": "9ddbcb303660a8ac85ea9a9427f0554c93f6f8a45e18e5606dfccb1f838266d1",
                                "value": 50,
                                "ufficio": "Chief Technology Officer",
                                "link": [
                                    "89581076ad0b28dc87a57bce316f2d6d7a95bbe17220b598b8cfdc5f1685fbb8"
                                ]
                            },
                            {
                                "name": "9f61ca7780728c008f09ffcb6fc3336b3b07ab815aae62c00d84b4a49859b01d",
                                "value": 50,
                                "ufficio": "Legale",
                                "link": [
                                    "814b7e522b4b800335488042e5dad575528905ba0ad796d4ec52b02ad98c5f77"
                                ]
                            },
                            {
                                "name": "9f6abb824f8cf4ed7997ff008f64449621aebd1d4bc7af1f46d0f238ebc954a2",
                                "value": 50,
                                "ufficio": "User Experience",
                                "link": [
                                    "0dcfb7587da75801f1d0f32ca00cf2d33f23c9fd2ccac63e5e3663bc59b7691a",
                                    "52fd640dca47a5008d088dd235189c51fc8495106ae0a39bb021dd809350e29c",
                                    "5a44504d34546036745dcb5deeddab47d39a00fb19734ca7145dcc5a9dda9ea7",
                                    "853ac84efec82736323b7c9a6cdcffe8ae2cf3da4bde5591d1568837b6c96ee9",
                                    "d479e93d42e5581e11ff27c6eaacf71c682fe3914a72e77fdf5702dd9b182c36"
                                ]
                            },
                            {
                                "name": "a0e3fd2ed608daf8e1c3a7a51b647f091e813e1185d3761959bfb1bea3b10044",
                                "value": 50,
                                "ufficio": "Analisi funzionali",
                                "link": [
                                    "03570a9667986e67fbc32a36c58c2a83620615f978d54065c0118b9ecf3bb513",
                                    "4f188222e99c82078ee2bacc682ce5f86fefe34c32b4b7c6f1e6699a213eba98",
                                    "b04e2eca334e777155ad38dcf329026d75656094a8665ff4448e4d1bba89eed1"
                                ]
                            },
                            {
                                "name": "a4e8e3247a94ebc46bb94c8b914971f5ad793847ccf16b1f41b0c88f5a366d80",
                                "value": 50,
                                "ufficio": "User Experience",
                                "link": [
                                    "0e428ffef64b10335e73fb703497fafde699c4eb8fbc533035b84489c91ec4d6",
                                    "150dc7a0f15ffab503fdaa3cd09471f3c6055764f95b1f6cb4ff191bfc4a1532",
                                    "15167bd90d8d12b7bed6cfcd192e5306b4e1216c584cc9c50977d8ea7f30e281",
                                    "1f4ef15b42d71da6eaa84c1666da8f3aae74ebcab35d1321b195f4e6e67b7565",
                                    "21128e9f23759392be2b2b17596d9d7c496d188162fd9ece9f8aa096e189a929",
                                    "413612c9c7052356812afdabf6504212199907ccb172b3e1e5b4e8ca29eac0a7",
                                    "4430f196eb4eb2ff7d9806df3fad24bd892c123d66855e0e9ad2f81242892211",
                                    "50530ca013a6c0cf2eb8037d8032df3ae078924f85236bf5403db4404265bb62",
                                    "52fd640dca47a5008d088dd235189c51fc8495106ae0a39bb021dd809350e29c",
                                    "5a44504d34546036745dcb5deeddab47d39a00fb19734ca7145dcc5a9dda9ea7",
                                    "9f6abb824f8cf4ed7997ff008f64449621aebd1d4bc7af1f46d0f238ebc954a2",
                                    "b93c96af6004749e2165b74e24a7178092634997794da2c1916a4a41786bd3f4",
                                    "bc369356384d79a8fe050a841aef947bda1114404e1249bb5ac883b4a91ae707",
                                    "bfdbf363d9c4b0705ccbeecc39fe6db9a81f5145b54a3674b64b0da286429728",
                                    "cd799a97931432b5e176c88142dc61546e826bc2e69a5bc0ec58df54d78c9b74",
                                    "ea0ba1b92d9815a5d808d92bc8b85e97d2c3f3aa6f8c8407ab6add9cd7e5be7a",
                                    "edc927c302d834fd6d1a19493931750c25e5dc949f2b2a8a8d05fb339ea75146",
                                    "ff12b55ee827fc0697d9f8c059da7ab3b2c16cdcd329686754e8e0ee92c3b5a9"
                                ]
                            },
                            {
                                "name": "a806519c60e74c17968ddcdf8c1b32e3ad883c7866081b61f555be3d952043af",
                                "value": 50,
                                "ufficio": "Media Center Management",
                                "link": [
                                    "29e353b18fae9b63560f47b3f9cbcc291aad0650ad44705c79a9a4abd05ebe9c"
                                ]
                            },
                            {
                                "name": "a85ecef49e3b754918db9058c0e27dd64b37c25ecba47822a608accad8957746",
                                "value": 50,
                                "ufficio": "Analisi funzionali",
                                "link": [
                                    "117484657fdfa49f6f202c4a01f5869cd80e1a3dd60c339d9145c67a9fb03cae",
                                    "18f8f073dd89fcf946d09157db5342889d19543390c8cd4c9dd8b05bc65fd518",
                                    "2d5cdaed96acc015fa15ee3403b327066febef58d4ff9a20e06dfb7b261628ce",
                                    "4430f196eb4eb2ff7d9806df3fad24bd892c123d66855e0e9ad2f81242892211",
                                    "52fd640dca47a5008d088dd235189c51fc8495106ae0a39bb021dd809350e29c",
                                    "562b355139df6b1823ec7eacdb3c4726d0a68bdf9206476d8a18f3680957078c",
                                    "59294899f0e52923d6f08c1a86900c906187345c14c72a70fb51510e7fa00aec",
                                    "5a44504d34546036745dcb5deeddab47d39a00fb19734ca7145dcc5a9dda9ea7",
                                    "9f6abb824f8cf4ed7997ff008f64449621aebd1d4bc7af1f46d0f238ebc954a2",
                                    "a69b26529fc2b65bf470c2a2bd22efe41f73763eec20a8a93c05ba720e9fcbd1",
                                    "bc369356384d79a8fe050a841aef947bda1114404e1249bb5ac883b4a91ae707",
                                    "d46963e985568e7dad629017de5c6f04c3ce4b2b3a584054f7b153812afcff04",
                                    "ecd3496bb4ed76576e96edd522f6903474e2a27d352d654ae562fb4ad2b2d881",
                                    "f4f2d477cc383f7885fc3724f0a204025e3ef04f34c7478a185beac0c595ff6c"
                                ]
                            },
                            {
                                "name": "a88b39bfb012c98803e66f0b018c25d1ffa5766c21260b623d9ddff1b90edc37",
                                "value": 50,
                                "ufficio": "Sviluppo e Architetture",
                                "link": [
                                    "1f4ef15b42d71da6eaa84c1666da8f3aae74ebcab35d1321b195f4e6e67b7565",
                                    "413612c9c7052356812afdabf6504212199907ccb172b3e1e5b4e8ca29eac0a7",
                                    "45cdf162e9a98c22f44e9e1259be540e5fc454f582ccb8e98371f00de1c23f04",
                                    "67678ae13f18c6a16575a01c50d4528d22e5e3862d512414f3dd81590aecfbd2",
                                    "7b138a4913227d7a34f13a5c994fca2a492f6051b242e9eb45d9af1d99bc982e",
                                    "9d97a74ebd18d7acffed0070ca4bc846160f8159d8efe722f90554cc3d309c3d",
                                    "abc7401e3265278becd57c05a0f1675fbb4b94f39845020e6ddb03f448020e02",
                                    "bc369356384d79a8fe050a841aef947bda1114404e1249bb5ac883b4a91ae707",
                                    "ce118f98f944cb4b7bf553a6c3361f5e5ffb39bc33050ac457ff5e68c3726eea",
                                    "d9b07283f475aeb6d05e04c9acb0f0bd609e9b5220b21f7a99a5c342a64139dc",
                                    "e684fac9c014b3716dea4475452a3460cc3005ff267aaa9ca909228ecd7d91d5",
                                    "f158062016d7c912ba1494b35e8cd64d07656b38a4a0d0992e4dc016800a3788"
                                ]
                            },
                            {
                                "name": "a91e77c4bfff24379c25a1ee6b866d3ab57df33661aed16539bb346b7e92cc1d",
                                "value": 50,
                                "ufficio": "Crediti",
                                "link": [
                                    "63f169eba9e68ec4a33ef5cb7cefbcf129975ac567f887dab1718ef65cd9971a",
                                    "6f73b50dc0122b97db84a795721a6b625261b938843fcbf467616db0d9811fc5"
                                ]
                            },
                            {
                                "name": "a9da7e6b3d82d45f8181786d5e75e390a35fbb4485bb07117c1563294cfa0b2c",
                                "value": 50,
                                "ufficio": "Crediti",
                                "link": [
                                    "516c0937b94123241b706f185cbaf5039307313eaf7200e1e0ba64bc880c17f5",
                                    "8443c187871034e8ba51d518a5ee3334d252245351ea2dcaec814b7896fd899c"
                                ]
                            },
                            {
                                "name": "abc7401e3265278becd57c05a0f1675fbb4b94f39845020e6ddb03f448020e02",
                                "value": 50,
                                "ufficio": "Sviluppo e Architetture",
                                "link": [
                                    "bf2a52af189f34bcf54851fcdd6a8deb89d31ec18c9e53273f05739ddd5badc1"
                                ]
                            },
                            {
                                "name": "ae4dd003704535e168ebdf1066be077474b7cc1a289ab06837430a972b3799ec",
                                "value": 50,
                                "ufficio": "Analisi funzionali",
                                "link": [
                                    "117484657fdfa49f6f202c4a01f5869cd80e1a3dd60c339d9145c67a9fb03cae",
                                    "50530ca013a6c0cf2eb8037d8032df3ae078924f85236bf5403db4404265bb62",
                                    "6d1704d0094ff0d48b3987b3562e082ad66e8ff3b6edf7f57133376917a14a4c",
                                    "bd935ad4c48672c5e4d791aac29afac44ac184d2694524e290563ca81cb2da60",
                                    "ecd3496bb4ed76576e96edd522f6903474e2a27d352d654ae562fb4ad2b2d881",
                                    "fcf8d1bdf0ae685599e1f7c47937032b9f521aa92c40619adc904c705c653846"
                                ]
                            },
                            {
                                "name": "afad2cd7dd9c3b8c52ac4c46b3e5ee15825047d8f739eea664b6a80a33a44d16",
                                "value": 50,
                                "ufficio": "Media Center Management",
                                "link": [
                                    "150dc7a0f15ffab503fdaa3cd09471f3c6055764f95b1f6cb4ff191bfc4a1532",
                                    "1cff424a7b25c230a1c209b630182b4f91fa24bf231d02e19d8f6bc8a86731a1",
                                    "36976cfe80dcf459c6a92c1d326df99d4e0c262970320e221f4093becab351c3",
                                    "61d29662a79cdedd32b1e54888938447235caf30c466d7ff090305868dc5919d",
                                    "915e1d4b27b8f8d1ff4a6f3fd44f14629b0aa665d34f20020877d7d3ac517c40"
                                ]
                            },
                            {
                                "name": "b01e860d623af6128973affb64ac36d84afa30b8089325c6b05134309dfc837a",
                                "value": 50,
                                "ufficio": "Analisi funzionali",
                                "link": [
                                    "7ad8ce7b05bf6d2f96c6cf7d8d2c20304a9fe6cf4d7a944608666d8f36b454c9"
                                ]
                            },
                            {
                                "name": "b0298f17afb1ed5c68b4f757fa10546b537cca65b13c8fd60ef1d439183ad7d7",
                                "value": 50,
                                "ufficio": "Customer care",
                                "link": [
                                    "e5b879512a1674dc7b5652370e4b79117acfb9c47cfd932a905f7c2dcee40803"
                                ]
                            },
                            {
                                "name": "b04e2eca334e777155ad38dcf329026d75656094a8665ff4448e4d1bba89eed1",
                                "value": 50,
                                "ufficio": "Sviluppo e Architetture",
                                "link": [
                                    "d718c84701eb431e405971fea04377bfdc1869c17b6cc272ebe8ac8d8bbe74cb"
                                ]
                            },
                            {
                                "name": "b080642b5854215716c65a72781e55050b41240865a8621530419a1348d810f4",
                                "value": 50,
                                "ufficio": "Sviluppo e Architetture",
                                "link": [
                                    "8ddfe2ebab57cffc3af0f950c74433476a320a56aaf55fe5a0e2f74b31526a71"
                                ]
                            },
                            {
                                "name": "b14ceff609c278e1c1891d643b7aa9eb267e80ce5e050863aeeddee9bcb25bd0",
                                "value": 50,
                                "ufficio": "Sviluppo e Architetture",
                                "link": [
                                    "03570a9667986e67fbc32a36c58c2a83620615f978d54065c0118b9ecf3bb513",
                                    "42d3f609a82d7d42d4b1fbb92e1fb77c095d318ed908b236393aa914bdbd7acc",
                                    "853ac84efec82736323b7c9a6cdcffe8ae2cf3da4bde5591d1568837b6c96ee9",
                                    "8a8d13915198c2121b499efa102f62d5d5ba92ee2c8d5d6659f3f787c722fe59",
                                    "8c3e02eabd321bb7ae9ba21563338ba3df344ab68b381680bcc3a75a2a8ccdf4",
                                    "d718c84701eb431e405971fea04377bfdc1869c17b6cc272ebe8ac8d8bbe74cb"
                                ]
                            },
                            {
                                "name": "b1a56a5123fc5d0e14ba70d4201867e7977e172025e208835623c3dfd3609f32",
                                "value": 50,
                                "ufficio": "Customer care",
                                "link": [
                                    "47bc260e171c6fd87acf10cc371231cd77195394e16c4c2e57c57d1d446aff59",
                                    "62298390c085c1b91e57863bc510085fb2f7579758accb0c081a4930bd5cb533",
                                    "6a03854e415b478f615318d423b54e92dd2077b56f3488690756437e32908be7",
                                    "dec59a7383f62a4dcf4548eb913834f857598bf81a196fff3414ea39cc5ee32d"
                                ]
                            },
                            {
                                "name": "b88c5e73043d51af0abb66947a83a98a7efd400e7e55eebac0bd3dc1e5c19374",
                                "value": 50,
                                "ufficio": "Crediti",
                                "link": [
                                    "516c0937b94123241b706f185cbaf5039307313eaf7200e1e0ba64bc880c17f5",
                                    "55ff0b8efbcb37483f73f0ffe87b240272eefce6390e3c3454881cdc8b6b5638",
                                    "5f9c999f59aed9c22e6e9f149df9f9ebd5d62d7841b0ea9be721ca9ce7baa094",
                                    "63f169eba9e68ec4a33ef5cb7cefbcf129975ac567f887dab1718ef65cd9971a",
                                    "6d4f4fcc24d0ccfb24d64be9bf4af436a091122c5602e0a90b03c0cb2515f6ae",
                                    "f3280920c064eb900b091aa992d081b27c1f9f3c2e8aae488b69be093a4932e4"
                                ]
                            },
                            {
                                "name": "b93c96af6004749e2165b74e24a7178092634997794da2c1916a4a41786bd3f4",
                                "value": 50,
                                "ufficio": "Commerciale Banking & Credito",
                                "link": [
                                    "00d7df14c3d418e6b9aa1fdbf670004b7bdde6bed851cbdb44d095c5d53633c3",
                                    "15167bd90d8d12b7bed6cfcd192e5306b4e1216c584cc9c50977d8ea7f30e281",
                                    "2782ff4e895a9d5f2a1edc00d3922d7f7e640c3ee5a6400fb8c4ab3532249c3e",
                                    "2b3a1884d7e6e625a7670bab5cf9d5ba47a62ce3d3413da9d7d3998ba89a9084",
                                    "2d5cdaed96acc015fa15ee3403b327066febef58d4ff9a20e06dfb7b261628ce",
                                    "352097edcec7e4cc4332657c589d7506b13fa583c8078c419c1f1e22fc6bb66a",
                                    "4430f196eb4eb2ff7d9806df3fad24bd892c123d66855e0e9ad2f81242892211",
                                    "a4e8e3247a94ebc46bb94c8b914971f5ad793847ccf16b1f41b0c88f5a366d80",
                                    "bc369356384d79a8fe050a841aef947bda1114404e1249bb5ac883b4a91ae707",
                                    "f43e34018b549d8863e860d611e560ddc05865730aece3e8859a85dbbde6a3d0",
                                    "f6d9c7262c3bbcc3b8c695a1e98caf93d40ca532dd02620709ece64a72e042ed",
                                    "faad46c5aeb820d0a642a5f11aefea24266c0c23285b78fc7011e9aa672988d6",
                                    "ff12b55ee827fc0697d9f8c059da7ab3b2c16cdcd329686754e8e0ee92c3b5a9"
                                ]
                            },
                            {
                                "name": "bc369356384d79a8fe050a841aef947bda1114404e1249bb5ac883b4a91ae707",
                                "value": 50,
                                "ufficio": "Comunicazione & Acquisition",
                                "link": [
                                    "00d7df14c3d418e6b9aa1fdbf670004b7bdde6bed851cbdb44d095c5d53633c3",
                                    "0b6171902da4f7e74d4d0dfd05097833a95670b853bd7e282f15588a58258e3e",
                                    "0dcfb7587da75801f1d0f32ca00cf2d33f23c9fd2ccac63e5e3663bc59b7691a",
                                    "0e428ffef64b10335e73fb703497fafde699c4eb8fbc533035b84489c91ec4d6",
                                    "1139eb48beb8d6d0ff482002d607a4a5e7ddc0b1da921eb1c759a39652d50650",
                                    "117484657fdfa49f6f202c4a01f5869cd80e1a3dd60c339d9145c67a9fb03cae",
                                    "150dc7a0f15ffab503fdaa3cd09471f3c6055764f95b1f6cb4ff191bfc4a1532",
                                    "15167bd90d8d12b7bed6cfcd192e5306b4e1216c584cc9c50977d8ea7f30e281",
                                    "1f4ef15b42d71da6eaa84c1666da8f3aae74ebcab35d1321b195f4e6e67b7565",
                                    "2385eaf1b82db67a6b1f4fa4e968876b08c7e15fd10c6622c584148972c1e0df",
                                    "26b9e209584bf3efd59e440e6838fef4d67394ca48f2a9eb7533b7d4b23f015b",
                                    "29e353b18fae9b63560f47b3f9cbcc291aad0650ad44705c79a9a4abd05ebe9c",
                                    "2d5cdaed96acc015fa15ee3403b327066febef58d4ff9a20e06dfb7b261628ce",
                                    "349a49288d7cc6c6156fa56b7adac7b47db5db8703713ce1deaef5b759f637a3",
                                    "352097edcec7e4cc4332657c589d7506b13fa583c8078c419c1f1e22fc6bb66a",
                                    "38ce0ce0122e0bcdd595c12f417196d4acd690cf7b2d6cda61bc4c7208166e04",
                                    "413612c9c7052356812afdabf6504212199907ccb172b3e1e5b4e8ca29eac0a7",
                                    "4430f196eb4eb2ff7d9806df3fad24bd892c123d66855e0e9ad2f81242892211",
                                    "48805a805841f176eec7131f6f75a461aadf461f846d4e5516facf15350cfaeb",
                                    "52fd640dca47a5008d088dd235189c51fc8495106ae0a39bb021dd809350e29c",
                                    "562b355139df6b1823ec7eacdb3c4726d0a68bdf9206476d8a18f3680957078c",
                                    "5a44504d34546036745dcb5deeddab47d39a00fb19734ca7145dcc5a9dda9ea7",
                                    "5a980b472b83f2974fbb4ecf1061cc8ba78472476347de63d56222b8323f3d18",
                                    "61d29662a79cdedd32b1e54888938447235caf30c466d7ff090305868dc5919d",
                                    "67dac3f288d9e1b5af98c40f069f11ef7eb895d715f2bf7481fc81988c386e6f",
                                    "6ec9cfba69a90f24827db98d1a905f97613cd4cc592b0e6f0026b5aae81a0118",
                                    "80335f30f0183fc023e34a72f7c9cddf62808847fbf68a9ae537b4d21588b8f8",
                                    "853ac84efec82736323b7c9a6cdcffe8ae2cf3da4bde5591d1568837b6c96ee9",
                                    "8787740c45c655c0511fa208a842b48fd6818c89e1f9a4390482faaa868a027f",
                                    "89581076ad0b28dc87a57bce316f2d6d7a95bbe17220b598b8cfdc5f1685fbb8",
                                    "8c3e02eabd321bb7ae9ba21563338ba3df344ab68b381680bcc3a75a2a8ccdf4",
                                    "915e1d4b27b8f8d1ff4a6f3fd44f14629b0aa665d34f20020877d7d3ac517c40",
                                    "9587785cab847871808c30ef9ec14d1d41c673ea28ac289d2e33079f388c4d5d",
                                    "9a44fd708e36fc4c92d967ede843ab33c6315bf9e8e254006b81bf8b79c889d0",
                                    "9f6abb824f8cf4ed7997ff008f64449621aebd1d4bc7af1f46d0f238ebc954a2",
                                    "a4e8e3247a94ebc46bb94c8b914971f5ad793847ccf16b1f41b0c88f5a366d80",
                                    "a85ecef49e3b754918db9058c0e27dd64b37c25ecba47822a608accad8957746",
                                    "a88b39bfb012c98803e66f0b018c25d1ffa5766c21260b623d9ddff1b90edc37",
                                    "abc7401e3265278becd57c05a0f1675fbb4b94f39845020e6ddb03f448020e02",
                                    "afad2cd7dd9c3b8c52ac4c46b3e5ee15825047d8f739eea664b6a80a33a44d16",
                                    "b14ceff609c278e1c1891d643b7aa9eb267e80ce5e050863aeeddee9bcb25bd0",
                                    "b93c96af6004749e2165b74e24a7178092634997794da2c1916a4a41786bd3f4",
                                    "be0b7f3457783af8b09e2cf60f10cfdd64903cbda18a9b1c3216ea6a97026370",
                                    "cb6238988950d6394c11c8b1cb351bdf9c78aa272ad810159bb299ebe5b3da07",
                                    "cd3ef604909c834a79d3287fa7fa7d5cf8d30d07d7b3999ed4f592226c30d3fd",
                                    "ce118f98f944cb4b7bf553a6c3361f5e5ffb39bc33050ac457ff5e68c3726eea",
                                    "ce5d99de22269fea7904a89036f0aef155beabc375707b88bd2d1b73d7eddb11",
                                    "d479e93d42e5581e11ff27c6eaacf71c682fe3914a72e77fdf5702dd9b182c36",
                                    "e290b0a48ba64237e04dad91dd9197f081a22d0c60b8bb247f25eeef2334c2ce",
                                    "e43226fe1cb0cb66499b70d618b6067cd5ad450c3ee762586893189caf27c73e",
                                    "ea0ba1b92d9815a5d808d92bc8b85e97d2c3f3aa6f8c8407ab6add9cd7e5be7a",
                                    "ed67329cdf04b5a943a12c63cd602a6d7f06a61d433a960d8c5514f13ca40ca9",
                                    "edc927c302d834fd6d1a19493931750c25e5dc949f2b2a8a8d05fb339ea75146",
                                    "ee64e69e3c67b6db4180164ace85772c427d49160d44da1a27032fe2be2cd16c",
                                    "f43e34018b549d8863e860d611e560ddc05865730aece3e8859a85dbbde6a3d0",
                                    "f4f2d477cc383f7885fc3724f0a204025e3ef04f34c7478a185beac0c595ff6c",
                                    "f6d9c7262c3bbcc3b8c695a1e98caf93d40ca532dd02620709ece64a72e042ed",
                                    "faad46c5aeb820d0a642a5f11aefea24266c0c23285b78fc7011e9aa672988d6",
                                    "ff12b55ee827fc0697d9f8c059da7ab3b2c16cdcd329686754e8e0ee92c3b5a9"
                                ]
                            },
                            {
                                "name": "bd3a639dfc2b875db05bd23d32b6271b9615b79b2dc08557c3836a18aa2c8da3",
                                "value": 50,
                                "ufficio": "Controlli, Processi e Immobiliare",
                                "link": [
                                    "551c9be035e6c1b2ddd26424b7dcb7e62f8c70c58dfa9ff66ca4e9e025840353",
                                    "6101c7c61c9aebce7ec0d0ac3ecfbf7be4b2ea92b01a55a3b49e244fdb4af554",
                                    "6f73b50dc0122b97db84a795721a6b625261b938843fcbf467616db0d9811fc5",
                                    "82755a463db01fe7ecad9815a9cfe8bc137a646110a3727d11e338f858f2f59f",
                                    "c682a14b4b7b3ae8c6d204234a700baec5e56df8e2b2ff60ba668e74b493e274"
                                ]
                            },
                            {
                                "name": "bd935ad4c48672c5e4d791aac29afac44ac184d2694524e290563ca81cb2da60",
                                "value": 50,
                                "ufficio": "Analisi funzionali",
                                "link": [
                                    "117484657fdfa49f6f202c4a01f5869cd80e1a3dd60c339d9145c67a9fb03cae",
                                    "21128e9f23759392be2b2b17596d9d7c496d188162fd9ece9f8aa096e189a929",
                                    "2d5cdaed96acc015fa15ee3403b327066febef58d4ff9a20e06dfb7b261628ce",
                                    "349a49288d7cc6c6156fa56b7adac7b47db5db8703713ce1deaef5b759f637a3",
                                    "38ce0ce0122e0bcdd595c12f417196d4acd690cf7b2d6cda61bc4c7208166e04",
                                    "4a1b956ec7c17b2702fc296dc1e39ab99c8277241d40934048f3dc786b8dcb48",
                                    "4af4cbc00eeadb01960175f8e148342ce43279cc161fd67b1cac2ee1e6ee60dc",
                                    "ecd3496bb4ed76576e96edd522f6903474e2a27d352d654ae562fb4ad2b2d881",
                                    "f4f2d477cc383f7885fc3724f0a204025e3ef04f34c7478a185beac0c595ff6c",
                                    "ff12b55ee827fc0697d9f8c059da7ab3b2c16cdcd329686754e8e0ee92c3b5a9"
                                ]
                            },
                            {
                                "name": "be0b7f3457783af8b09e2cf60f10cfdd64903cbda18a9b1c3216ea6a97026370",
                                "value": 50,
                                "ufficio": "User Experience",
                                "link": [
                                    "0b6171902da4f7e74d4d0dfd05097833a95670b853bd7e282f15588a58258e3e",
                                    "15167bd90d8d12b7bed6cfcd192e5306b4e1216c584cc9c50977d8ea7f30e281",
                                    "2470fd17d1f1959717120deadaaa3b455dd3c74c8ced88054a8b4fb88e4f8f28",
                                    "52fd640dca47a5008d088dd235189c51fc8495106ae0a39bb021dd809350e29c",
                                    "5a44504d34546036745dcb5deeddab47d39a00fb19734ca7145dcc5a9dda9ea7",
                                    "5a980b472b83f2974fbb4ecf1061cc8ba78472476347de63d56222b8323f3d18",
                                    "9f6abb824f8cf4ed7997ff008f64449621aebd1d4bc7af1f46d0f238ebc954a2",
                                    "a4e8e3247a94ebc46bb94c8b914971f5ad793847ccf16b1f41b0c88f5a366d80",
                                    "bc369356384d79a8fe050a841aef947bda1114404e1249bb5ac883b4a91ae707"
                                ]
                            },
                            {
                                "name": "bf8d8288d88711ebe7141155c39887959509933d7d5a34c34d58c3e2a6d44521",
                                "value": 50,
                                "ufficio": "Media Center Management",
                                "link": [
                                    "1cff424a7b25c230a1c209b630182b4f91fa24bf231d02e19d8f6bc8a86731a1"
                                ]
                            },
                            {
                                "name": "bfdbf363d9c4b0705ccbeecc39fe6db9a81f5145b54a3674b64b0da286429728",
                                "value": 50,
                                "ufficio": "User Experience",
                                "link": [
                                    "15167bd90d8d12b7bed6cfcd192e5306b4e1216c584cc9c50977d8ea7f30e281",
                                    "4d9ed21b4e1893c31acbbe057c063c726961bda2b39ce184609662933fbaa733",
                                    "52fd640dca47a5008d088dd235189c51fc8495106ae0a39bb021dd809350e29c",
                                    "a4e8e3247a94ebc46bb94c8b914971f5ad793847ccf16b1f41b0c88f5a366d80"
                                ]
                            },
                            {
                                "name": "c3c4106b8583c84e11c12032747f577b4c00c5b84c348d9c586d539f072a7589",
                                "value": 50,
                                "ufficio": "Risk & Fraud",
                                "link": [
                                    "2cca58c36e9accd51799044e15182df022e053e34c24f13f5577c316ca8db892",
                                    "50530ca013a6c0cf2eb8037d8032df3ae078924f85236bf5403db4404265bb62",
                                    "94b0716f2a1b16dc7f4e9a57bc682f54c39daa1c7b6c99569471264755d9484c"
                                ]
                            },
                            {
                                "name": "c450b7fdfdcd44454ce327bf0975039d6c7668e23dbc6d91f7edede31875e626",
                                "value": 50,
                                "ufficio": "Sistemi e Monitoring",
                                "link": [
                                    "02a0e9c62180eb9c2d8b7fb9e978e4ed45848287660235d5c51e2f110af4e0a9",
                                    "370b3c12a845f303bf28657161f8285295c5c8625e7a4daf9c464c6957c03ed9",
                                    "67fd6c0efd48daf4780f975393ab5252432b35da210995dc6845d95261be3f3e",
                                    "f57cdd8423add2b2919fce520a9a2d9a34184b0f716c1e6ae5508ef4ee31056e"
                                ]
                            },
                            {
                                "name": "c512dc7d82ab25e44b85c3b5d9585c6c3b7045e9340171b6fb0ca43e1eaba9d7",
                                "value": 50,
                                "ufficio": "Analisi funzionali",
                                "link": [
                                    "1f4ef15b42d71da6eaa84c1666da8f3aae74ebcab35d1321b195f4e6e67b7565",
                                    "59294899f0e52923d6f08c1a86900c906187345c14c72a70fb51510e7fa00aec",
                                    "73cbc0bd39e2e5b7ddabdcd8e3ce3e32df2057dd17cea502a971e71e705b0cea",
                                    "9d97a74ebd18d7acffed0070ca4bc846160f8159d8efe722f90554cc3d309c3d",
                                    "ecd3496bb4ed76576e96edd522f6903474e2a27d352d654ae562fb4ad2b2d881"
                                ]
                            },
                            {
                                "name": "c682a14b4b7b3ae8c6d204234a700baec5e56df8e2b2ff60ba668e74b493e274",
                                "value": 50,
                                "ufficio": "Risk & Fraud",
                                "link": [
                                    "6101c7c61c9aebce7ec0d0ac3ecfbf7be4b2ea92b01a55a3b49e244fdb4af554"
                                ]
                            },
                            {
                                "name": "c819102ab18c845283fce6feec8f4200afceb464911a1b39be02658303f9e69a",
                                "value": 50,
                                "ufficio": "Media Center Management",
                                "link": [
                                    "ce118f98f944cb4b7bf553a6c3361f5e5ffb39bc33050ac457ff5e68c3726eea"
                                ]
                            },
                            {
                                "name": "c9d32c28ab63c1458b9375ec3d54241cb7b117c6e116ab51d041490ff69c3cd0",
                                "value": 50,
                                "ufficio": "Customer care",
                                "link": [
                                    "47bc260e171c6fd87acf10cc371231cd77195394e16c4c2e57c57d1d446aff59",
                                    "62298390c085c1b91e57863bc510085fb2f7579758accb0c081a4930bd5cb533",
                                    "dec59a7383f62a4dcf4548eb913834f857598bf81a196fff3414ea39cc5ee32d"
                                ]
                            },
                            {
                                "name": "cb6238988950d6394c11c8b1cb351bdf9c78aa272ad810159bb299ebe5b3da07",
                                "value": 50,
                                "ufficio": "Analisi funzionali",
                                "link": [
                                    "0dcfb7587da75801f1d0f32ca00cf2d33f23c9fd2ccac63e5e3663bc59b7691a",
                                    "117484657fdfa49f6f202c4a01f5869cd80e1a3dd60c339d9145c67a9fb03cae",
                                    "150dc7a0f15ffab503fdaa3cd09471f3c6055764f95b1f6cb4ff191bfc4a1532",
                                    "52fd640dca47a5008d088dd235189c51fc8495106ae0a39bb021dd809350e29c",
                                    "5a980b472b83f2974fbb4ecf1061cc8ba78472476347de63d56222b8323f3d18",
                                    "bc369356384d79a8fe050a841aef947bda1114404e1249bb5ac883b4a91ae707",
                                    "d99dc1f4e0881e5d348869f26afef39bec730a7dbd5585548ee85f6bb02bfe6a",
                                    "ecd3496bb4ed76576e96edd522f6903474e2a27d352d654ae562fb4ad2b2d881",
                                    "edc927c302d834fd6d1a19493931750c25e5dc949f2b2a8a8d05fb339ea75146",
                                    "ee64e69e3c67b6db4180164ace85772c427d49160d44da1a27032fe2be2cd16c",
                                    "f4f2d477cc383f7885fc3724f0a204025e3ef04f34c7478a185beac0c595ff6c"
                                ]
                            },
                            {
                                "name": "cbbf3604801467bb1ac7222a9bf7555bb21784ffa8c00abf7f5ffae7bc4e2da6",
                                "value": 50,
                                "ufficio": "Digital Branch",
                                "link": [
                                    "9f11d1d7bc36b59ec4d41aeaac8621a878b2fe671ceb1cca3e67133dde371f84"
                                ]
                            },
                            {
                                "name": "ccb35cb14a71c974c83dae675e7143e1788db7293805206540c8d9df094e4ff4",
                                "value": 50,
                                "ufficio": "Amministrazione consulenza finanziaria",
                                "link": [
                                    "08031c40fea4b77b2203830bb3dc0aea814fda06cd5a344019eadedbde13c15b",
                                    "0ea768b9c21de1396c8517abb6ba0723a8ea84ae7946b78e5f3131954b009d00",
                                    "29e353b18fae9b63560f47b3f9cbcc291aad0650ad44705c79a9a4abd05ebe9c",
                                    "33bbf19881e2d6db89f806f0f3b84552b843e90b78e06b013d145b78113d2b56",
                                    "3779faf7954bbba479b99fbb0c6543df87cabb3d70654b2486c354a023406372",
                                    "3e96a90c292519dd959a327f2fa19128d2870160698271969dad21652684fdbd",
                                    "50228aad62253ede25fa405a08929d5bda792f6d07a6554a5f0eae03de60d209",
                                    "55af1d666e9c133bc765e08f1068057f1d51592ad022affeb421bdf7dbfcdb18",
                                    "64729b37ca40f8417c6bcce885bad4ab76f2054b6619769b4f01533428e6a0f8",
                                    "9344687749ccaab48b3f7d2d5e3b9cf0f71d8b4abb2f99f6b094fc18f64ad3eb",
                                    "da19ae9bdf22abe2a9cb0962b11f8e355978c0261556c1951bff89a793042d65",
                                    "e118679565fed3549f6e05b398f1275b4cb0c955e46ec0c6bea540974364cd48",
                                    "ffd5bbb942400ed12a334991d5f08a56b853c94044196cf6721fcbeedefc2c78"
                                ]
                            },
                            {
                                "name": "cd799a97931432b5e176c88142dc61546e826bc2e69a5bc0ec58df54d78c9b74",
                                "value": 50,
                                "ufficio": "Analisi funzionali",
                                "link": [
                                    "1f4ef15b42d71da6eaa84c1666da8f3aae74ebcab35d1321b195f4e6e67b7565",
                                    "21128e9f23759392be2b2b17596d9d7c496d188162fd9ece9f8aa096e189a929",
                                    "2475b9470c6235901e7e3d9cc1201f817a6244a0603a072db7f4cee5785fa448",
                                    "413612c9c7052356812afdabf6504212199907ccb172b3e1e5b4e8ca29eac0a7",
                                    "45cdf162e9a98c22f44e9e1259be540e5fc454f582ccb8e98371f00de1c23f04",
                                    "48cdde88d13cecd870e6200115cd667de65900c03b57e0e5b7e4cd3da1e1fd21",
                                    "50530ca013a6c0cf2eb8037d8032df3ae078924f85236bf5403db4404265bb62",
                                    "6120dd083a4c1cf13dd2c8ca1f92591ac48875307ff66fc8da019512f3d7a3df",
                                    "7ad8ce7b05bf6d2f96c6cf7d8d2c20304a9fe6cf4d7a944608666d8f36b454c9",
                                    "7b138a4913227d7a34f13a5c994fca2a492f6051b242e9eb45d9af1d99bc982e",
                                    "7c8496883cb2bea0087ab91f32c798eb9219ff32d0a56db7da8444d821f45d81",
                                    "8c065cfe40a3279cd689a512a27dfcd0afd5c725384b0bf22eaa22308bba5302",
                                    "8ddfe2ebab57cffc3af0f950c74433476a320a56aaf55fe5a0e2f74b31526a71",
                                    "9d97a74ebd18d7acffed0070ca4bc846160f8159d8efe722f90554cc3d309c3d",
                                    "a4e8e3247a94ebc46bb94c8b914971f5ad793847ccf16b1f41b0c88f5a366d80",
                                    "a88b39bfb012c98803e66f0b018c25d1ffa5766c21260b623d9ddff1b90edc37",
                                    "c512dc7d82ab25e44b85c3b5d9585c6c3b7045e9340171b6fb0ca43e1eaba9d7",
                                    "ce118f98f944cb4b7bf553a6c3361f5e5ffb39bc33050ac457ff5e68c3726eea",
                                    "d080f6246f89a229aff787012e414f09e420a8f174d2c85a2f342cebd9d54864",
                                    "f158062016d7c912ba1494b35e8cd64d07656b38a4a0d0992e4dc016800a3788"
                                ]
                            },
                            {
                                "name": "ce118f98f944cb4b7bf553a6c3361f5e5ffb39bc33050ac457ff5e68c3726eea",
                                "value": 50,
                                "ufficio": "Sviluppo e Architetture",
                                "link": [
                                    "00d7df14c3d418e6b9aa1fdbf670004b7bdde6bed851cbdb44d095c5d53633c3",
                                    "02a0e9c62180eb9c2d8b7fb9e978e4ed45848287660235d5c51e2f110af4e0a9",
                                    "117484657fdfa49f6f202c4a01f5869cd80e1a3dd60c339d9145c67a9fb03cae",
                                    "150dc7a0f15ffab503fdaa3cd09471f3c6055764f95b1f6cb4ff191bfc4a1532",
                                    "18f8f073dd89fcf946d09157db5342889d19543390c8cd4c9dd8b05bc65fd518",
                                    "1bed37d0037f2f6a95cf5d417703ce4a2eda90363991a28e65ea1c788f83008b",
                                    "1f4ef15b42d71da6eaa84c1666da8f3aae74ebcab35d1321b195f4e6e67b7565",
                                    "21128e9f23759392be2b2b17596d9d7c496d188162fd9ece9f8aa096e189a929",
                                    "238ae5b17e2e2c57af472e3f8cfba32aed5cf2f12286424acd7971a63c794c39",
                                    "352097edcec7e4cc4332657c589d7506b13fa583c8078c419c1f1e22fc6bb66a",
                                    "370b3c12a845f303bf28657161f8285295c5c8625e7a4daf9c464c6957c03ed9",
                                    "38ce0ce0122e0bcdd595c12f417196d4acd690cf7b2d6cda61bc4c7208166e04",
                                    "413612c9c7052356812afdabf6504212199907ccb172b3e1e5b4e8ca29eac0a7",
                                    "45cdf162e9a98c22f44e9e1259be540e5fc454f582ccb8e98371f00de1c23f04",
                                    "4e12e345bdb2e98fc1ce84b6b9724b164ab5570d3102f6cfd7759a367b58d857",
                                    "50530ca013a6c0cf2eb8037d8032df3ae078924f85236bf5403db4404265bb62",
                                    "52fd640dca47a5008d088dd235189c51fc8495106ae0a39bb021dd809350e29c",
                                    "59294899f0e52923d6f08c1a86900c906187345c14c72a70fb51510e7fa00aec",
                                    "5a44504d34546036745dcb5deeddab47d39a00fb19734ca7145dcc5a9dda9ea7",
                                    "61d29662a79cdedd32b1e54888938447235caf30c466d7ff090305868dc5919d",
                                    "655e07f5ed4e15423d43a532542c117b3514eec081633d73af8e542afc4f4b21",
                                    "67678ae13f18c6a16575a01c50d4528d22e5e3862d512414f3dd81590aecfbd2",
                                    "6aa56775705605384583392112cc3a974b7bded505375979500264fb35310747",
                                    "6ec9cfba69a90f24827db98d1a905f97613cd4cc592b0e6f0026b5aae81a0118",
                                    "71852c01834ad9f0e850f43c6418b83681f7acb82eb134a23d164ae3db8e7760",
                                    "73cbc0bd39e2e5b7ddabdcd8e3ce3e32df2057dd17cea502a971e71e705b0cea",
                                    "7b138a4913227d7a34f13a5c994fca2a492f6051b242e9eb45d9af1d99bc982e",
                                    "7c8496883cb2bea0087ab91f32c798eb9219ff32d0a56db7da8444d821f45d81",
                                    "853ac84efec82736323b7c9a6cdcffe8ae2cf3da4bde5591d1568837b6c96ee9",
                                    "8787740c45c655c0511fa208a842b48fd6818c89e1f9a4390482faaa868a027f",
                                    "8c3e02eabd321bb7ae9ba21563338ba3df344ab68b381680bcc3a75a2a8ccdf4",
                                    "8ddfe2ebab57cffc3af0f950c74433476a320a56aaf55fe5a0e2f74b31526a71",
                                    "9185e488c18a92b2f4aa28769fc53b5e1cfe84d0b0c13a6b5e98c1f3f5266c6b",
                                    "9949bc108015faaf4b49124f1164a7bc6d8db0c7ea9d32d634a4e89d74134ad9",
                                    "9d97a74ebd18d7acffed0070ca4bc846160f8159d8efe722f90554cc3d309c3d",
                                    "9f6abb824f8cf4ed7997ff008f64449621aebd1d4bc7af1f46d0f238ebc954a2",
                                    "a69b26529fc2b65bf470c2a2bd22efe41f73763eec20a8a93c05ba720e9fcbd1",
                                    "a88b39bfb012c98803e66f0b018c25d1ffa5766c21260b623d9ddff1b90edc37",
                                    "abc7401e3265278becd57c05a0f1675fbb4b94f39845020e6ddb03f448020e02",
                                    "b04e2eca334e777155ad38dcf329026d75656094a8665ff4448e4d1bba89eed1",
                                    "b080642b5854215716c65a72781e55050b41240865a8621530419a1348d810f4",
                                    "b14ceff609c278e1c1891d643b7aa9eb267e80ce5e050863aeeddee9bcb25bd0",
                                    "be0b7f3457783af8b09e2cf60f10cfdd64903cbda18a9b1c3216ea6a97026370",
                                    "bfdbf363d9c4b0705ccbeecc39fe6db9a81f5145b54a3674b64b0da286429728",
                                    "c512dc7d82ab25e44b85c3b5d9585c6c3b7045e9340171b6fb0ca43e1eaba9d7",
                                    "c819102ab18c845283fce6feec8f4200afceb464911a1b39be02658303f9e69a",
                                    "cd799a97931432b5e176c88142dc61546e826bc2e69a5bc0ec58df54d78c9b74",
                                    "ce5d99de22269fea7904a89036f0aef155beabc375707b88bd2d1b73d7eddb11",
                                    "d080f6246f89a229aff787012e414f09e420a8f174d2c85a2f342cebd9d54864",
                                    "d46963e985568e7dad629017de5c6f04c3ce4b2b3a584054f7b153812afcff04",
                                    "d5940e1eea800cac78acf3ef1ce311e6d22fc55b076391f5a483b2f25a813855",
                                    "db8d910dfd966f6af426d67ef70da670fccc08932340c385b8d340151e7e7ac8",
                                    "ecd3496bb4ed76576e96edd522f6903474e2a27d352d654ae562fb4ad2b2d881",
                                    "ee64e69e3c67b6db4180164ace85772c427d49160d44da1a27032fe2be2cd16c",
                                    "f0c2bb337febad5eab9639010c46aa5a111eff6a15b323eec978c6aa946c36e5",
                                    "f158062016d7c912ba1494b35e8cd64d07656b38a4a0d0992e4dc016800a3788",
                                    "f4f2d477cc383f7885fc3724f0a204025e3ef04f34c7478a185beac0c595ff6c",
                                    "f57cdd8423add2b2919fce520a9a2d9a34184b0f716c1e6ae5508ef4ee31056e",
                                    "fa5deed70dae7bbed11f872ecc4a3cf64ff21e2099602e3dfbe91fdc869493f3"
                                ]
                            },
                            {
                                "name": "ce5d99de22269fea7904a89036f0aef155beabc375707b88bd2d1b73d7eddb11",
                                "value": 50,
                                "ufficio": "User Experience",
                                "link": [
                                    "52fd640dca47a5008d088dd235189c51fc8495106ae0a39bb021dd809350e29c"
                                ]
                            },
                            {
                                "name": "cf5df46f41de37bb03a4c96ba027720e172f4abef43760298fc7893ad58af09d",
                                "value": 50,
                                "ufficio": "Crediti",
                                "link": [
                                    "6d4f4fcc24d0ccfb24d64be9bf4af436a091122c5602e0a90b03c0cb2515f6ae",
                                    "d1692f6821e7745b042c2d05d65937e004b338bd4078d1729df09cbe4ddd0e48"
                                ]
                            },
                            {
                                "name": "d080f6246f89a229aff787012e414f09e420a8f174d2c85a2f342cebd9d54864",
                                "value": 50,
                                "ufficio": "Analisi funzionali",
                                "link": [
                                    "01bc86e78cd69cc938888e5d58529827da10d3afacc31a4e746b1b235c362cb0",
                                    "06d38d94f292d05cdb7df23a05ef073febdd7c68931c6a7277f5f60d9e9e9b16",
                                    "1bed37d0037f2f6a95cf5d417703ce4a2eda90363991a28e65ea1c788f83008b",
                                    "4430f196eb4eb2ff7d9806df3fad24bd892c123d66855e0e9ad2f81242892211",
                                    "4b1c31b38e854c1ebd03badab8e535b22acb39c50801740d679f4a4643e76ef8",
                                    "59294899f0e52923d6f08c1a86900c906187345c14c72a70fb51510e7fa00aec",
                                    "6120dd083a4c1cf13dd2c8ca1f92591ac48875307ff66fc8da019512f3d7a3df",
                                    "67678ae13f18c6a16575a01c50d4528d22e5e3862d512414f3dd81590aecfbd2",
                                    "7b138a4913227d7a34f13a5c994fca2a492f6051b242e9eb45d9af1d99bc982e",
                                    "8464812682363064de4d541a02afca217bb03d68d9409cbb02a96d553d50fde0",
                                    "9c0a0f6c096827cf6fe5d3f87a6312ebd04e8ad3657fad79c4a8d967c445b9f2",
                                    "cd799a97931432b5e176c88142dc61546e826bc2e69a5bc0ec58df54d78c9b74",
                                    "db8d910dfd966f6af426d67ef70da670fccc08932340c385b8d340151e7e7ac8",
                                    "e43226fe1cb0cb66499b70d618b6067cd5ad450c3ee762586893189caf27c73e",
                                    "f0c2bb337febad5eab9639010c46aa5a111eff6a15b323eec978c6aa946c36e5",
                                    "f158062016d7c912ba1494b35e8cd64d07656b38a4a0d0992e4dc016800a3788"
                                ]
                            },
                            {
                                "name": "d1692f6821e7745b042c2d05d65937e004b338bd4078d1729df09cbe4ddd0e48",
                                "value": 50,
                                "ufficio": "Crediti",
                                "link": [
                                    "63f169eba9e68ec4a33ef5cb7cefbcf129975ac567f887dab1718ef65cd9971a"
                                ]
                            },
                            {
                                "name": "d26aa2e9c00f1acc24a8b3772b51c2558ccf58b0bd1ce98f59f57e041ceb2e63",
                                "value": 50,
                                "ufficio": "Legale",
                                "link": [
                                    "3779faf7954bbba479b99fbb0c6543df87cabb3d70654b2486c354a023406372",
                                    "50125cffec52c899c2da565099f3b084c25f25b17db6facc28758add900ad765",
                                    "74157b896e28ac56897470601f3db0a913b32c43ca44ec724ee6ce31cc493a16",
                                    "82755a463db01fe7ecad9815a9cfe8bc137a646110a3727d11e338f858f2f59f",
                                    "9344687749ccaab48b3f7d2d5e3b9cf0f71d8b4abb2f99f6b094fc18f64ad3eb",
                                    "9af3bc0cf7213f769313d3804c981b62bde5f0cd89f3223109e6d1f412747549",
                                    "fc0122f6d05c2ec54028830dc458440a2ff21f5a1bd11b664fec0fc9d6b346cc"
                                ]
                            },
                            {
                                "name": "d3a8ea432eb64faeb9c3661d563d76d6a0b9beec571b803e8a9b68e2f81111c4",
                                "value": 50,
                                "ufficio": "Risorse Umane e Progetti Evolutivi",
                                "link": [
                                    "0e428ffef64b10335e73fb703497fafde699c4eb8fbc533035b84489c91ec4d6",
                                    "2385eaf1b82db67a6b1f4fa4e968876b08c7e15fd10c6622c584148972c1e0df",
                                    "26b9e209584bf3efd59e440e6838fef4d67394ca48f2a9eb7533b7d4b23f015b",
                                    "36976cfe80dcf459c6a92c1d326df99d4e0c262970320e221f4093becab351c3",
                                    "50125cffec52c899c2da565099f3b084c25f25b17db6facc28758add900ad765",
                                    "50530ca013a6c0cf2eb8037d8032df3ae078924f85236bf5403db4404265bb62",
                                    "f3eae844e383579728b6d9069cc00301c5b0941b1c10a464c7b9a424618cef2a",
                                    "fbf35a4655c13d8be858814ee9f290bd64fd8712cb3cfa37e890a70515ebbcd5"
                                ]
                            },
                            {
                                "name": "d46963e985568e7dad629017de5c6f04c3ce4b2b3a584054f7b153812afcff04",
                                "value": 50,
                                "ufficio": "User Experience",
                                "link": [
                                    "15167bd90d8d12b7bed6cfcd192e5306b4e1216c584cc9c50977d8ea7f30e281",
                                    "52fd640dca47a5008d088dd235189c51fc8495106ae0a39bb021dd809350e29c"
                                ]
                            },
                            {
                                "name": "d479e93d42e5581e11ff27c6eaacf71c682fe3914a72e77fdf5702dd9b182c36",
                                "value": 50,
                                "ufficio": "Commerciale Investimenti",
                                "link": [
                                    "2475b9470c6235901e7e3d9cc1201f817a6244a0603a072db7f4cee5785fa448",
                                    "29e353b18fae9b63560f47b3f9cbcc291aad0650ad44705c79a9a4abd05ebe9c",
                                    "9f6abb824f8cf4ed7997ff008f64449621aebd1d4bc7af1f46d0f238ebc954a2"
                                ]
                            },
                            {
                                "name": "d499a998e542bcb1baf5189c1cd28f4ca585e9b74de5eb8bd317cb1869296794",
                                "value": 50,
                                "ufficio": "Commerciale Investimenti",
                                "link": [
                                    "f680ca858e1602fe43773de835e5ead85a16f0a59a88465afbb6badbc99abd8a"
                                ]
                            },
                            {
                                "name": "d5940e1eea800cac78acf3ef1ce311e6d22fc55b076391f5a483b2f25a813855",
                                "value": 50,
                                "ufficio": "User Experience",
                                "link": [
                                    "52fd640dca47a5008d088dd235189c51fc8495106ae0a39bb021dd809350e29c",
                                    "9f6abb824f8cf4ed7997ff008f64449621aebd1d4bc7af1f46d0f238ebc954a2"
                                ]
                            },
                            {
                                "name": "d718c84701eb431e405971fea04377bfdc1869c17b6cc272ebe8ac8d8bbe74cb",
                                "value": 50,
                                "ufficio": "Analisi funzionali",
                                "link": [
                                    "03570a9667986e67fbc32a36c58c2a83620615f978d54065c0118b9ecf3bb513",
                                    "42d3f609a82d7d42d4b1fbb92e1fb77c095d318ed908b236393aa914bdbd7acc",
                                    "8a8d13915198c2121b499efa102f62d5d5ba92ee2c8d5d6659f3f787c722fe59",
                                    "9949bc108015faaf4b49124f1164a7bc6d8db0c7ea9d32d634a4e89d74134ad9",
                                    "b04e2eca334e777155ad38dcf329026d75656094a8665ff4448e4d1bba89eed1",
                                    "b14ceff609c278e1c1891d643b7aa9eb267e80ce5e050863aeeddee9bcb25bd0"
                                ]
                            },
                            {
                                "name": "d737b37cfaabf6ae06942149c5b4ee5d6f51bb83f96c22a97a02dd55aae26797",
                                "value": 50,
                                "ufficio": "Sviluppo e Architetture",
                                "link": [
                                    "2e9726ae5dda17566272beb084d6c395b508fd46bb4013dfbc2c1ee042a1edba",
                                    "3cd667fc0a67da84ca4bc18f8b71d6c422e0745df4d26de6ac42e6ba886730e9",
                                    "4241fb0439bbde55a8fc18fc081d2775bc07d4ab8f08dbac79edba568e937207",
                                    "d0e7f46ccbde7c18b4c705dafd75eea36a8e218ccb84da1451d7402b260baa5f",
                                    "df35146ff5a9e86dd6ca1a536384dcb32c978ce1c2a53899a97cc0d525d4c32e",
                                    "e5a8d5c90db2aa9d5952663960c85af1f4cda6964bf6c8609a348d812e4f6bb5",
                                    "f20b967e2711621b8201ec35a510f0f042051a84d29d6878dd2962fab17513d6",
                                    "f49fba695f17ba776fa17a9385106dd46293066ff88682e28e3939c77f202e2b",
                                    "fa225faeed4228a77a50974305de27453cc15565991105d05fa313720f58ae84"
                                ]
                            },
                            {
                                "name": "d8b2ba48d1bc4fe02ccf2283c833bf8724bf4e48bfbdbc20ee240d0db536e5f7",
                                "value": 50,
                                "ufficio": "Data Intelligence",
                                "link": [
                                    "684154a13c4aba0b25fe1a0bdd4667ee40d5ed056108566cb09c6f8614bd27aa",
                                    "915e1d4b27b8f8d1ff4a6f3fd44f14629b0aa665d34f20020877d7d3ac517c40"
                                ]
                            },
                            {
                                "name": "d9b07283f475aeb6d05e04c9acb0f0bd609e9b5220b21f7a99a5c342a64139dc",
                                "value": 50,
                                "ufficio": "Sviluppo e Architetture",
                                "link": [
                                    "7b138a4913227d7a34f13a5c994fca2a492f6051b242e9eb45d9af1d99bc982e",
                                    "a88b39bfb012c98803e66f0b018c25d1ffa5766c21260b623d9ddff1b90edc37"
                                ]
                            },
                            {
                                "name": "da19ae9bdf22abe2a9cb0962b11f8e355978c0261556c1951bff89a793042d65",
                                "value": 50,
                                "ufficio": "Data Intelligence",
                                "link": [
                                    "74910197266fef7d298dd12740f389762cac70dc2e07f05868b98db34241dc1b",
                                    "79ed5305dcb6cb527389fad825a4f78c1b8463205901fd4c01d2d4b1b96527e7",
                                    "7d82b46491ea76531341ae71f72e7a616761d08e10c9cc71afb7bc8b22dfbec1",
                                    "915e1d4b27b8f8d1ff4a6f3fd44f14629b0aa665d34f20020877d7d3ac517c40"
                                ]
                            },
                            {
                                "name": "db8d910dfd966f6af426d67ef70da670fccc08932340c385b8d340151e7e7ac8",
                                "value": 50,
                                "ufficio": "Sviluppo e Architetture",
                                "link": [
                                    "01bc86e78cd69cc938888e5d58529827da10d3afacc31a4e746b1b235c362cb0",
                                    "59294899f0e52923d6f08c1a86900c906187345c14c72a70fb51510e7fa00aec",
                                    "67678ae13f18c6a16575a01c50d4528d22e5e3862d512414f3dd81590aecfbd2",
                                    "73cbc0bd39e2e5b7ddabdcd8e3ce3e32df2057dd17cea502a971e71e705b0cea",
                                    "7b138a4913227d7a34f13a5c994fca2a492f6051b242e9eb45d9af1d99bc982e",
                                    "8464812682363064de4d541a02afca217bb03d68d9409cbb02a96d553d50fde0",
                                    "9c0a0f6c096827cf6fe5d3f87a6312ebd04e8ad3657fad79c4a8d967c445b9f2",
                                    "d080f6246f89a229aff787012e414f09e420a8f174d2c85a2f342cebd9d54864",
                                    "f0c2bb337febad5eab9639010c46aa5a111eff6a15b323eec978c6aa946c36e5",
                                    "f158062016d7c912ba1494b35e8cd64d07656b38a4a0d0992e4dc016800a3788"
                                ]
                            },
                            {
                                "name": "dd6f95bbbe43ac35f6b9ecadc200574172031010fb3e3cb949878bc2b37065df",
                                "value": 50,
                                "ufficio": "Commerciale Investimenti",
                                "link": [
                                    "2475b9470c6235901e7e3d9cc1201f817a6244a0603a072db7f4cee5785fa448",
                                    "48cdde88d13cecd870e6200115cd667de65900c03b57e0e5b7e4cd3da1e1fd21",
                                    "659a5f5d597cd6834db5df465fcd21c9eaf134b14d21dbfdef9b2d0060133573",
                                    "d479e93d42e5581e11ff27c6eaacf71c682fe3914a72e77fdf5702dd9b182c36",
                                    "f680ca858e1602fe43773de835e5ead85a16f0a59a88465afbb6badbc99abd8a"
                                ]
                            },
                            {
                                "name": "dec59a7383f62a4dcf4548eb913834f857598bf81a196fff3414ea39cc5ee32d",
                                "value": 50,
                                "ufficio": "Digital Branch",
                                "link": [
                                    "47bc260e171c6fd87acf10cc371231cd77195394e16c4c2e57c57d1d446aff59",
                                    "62298390c085c1b91e57863bc510085fb2f7579758accb0c081a4930bd5cb533",
                                    "6a03854e415b478f615318d423b54e92dd2077b56f3488690756437e32908be7",
                                    "a3a8e4cae33ce7eb7d328d5b74fff1b86cf0207cb63f27cc518615de467ea2c6",
                                    "b1a56a5123fc5d0e14ba70d4201867e7977e172025e208835623c3dfd3609f32",
                                    "c9d32c28ab63c1458b9375ec3d54241cb7b117c6e116ab51d041490ff69c3cd0"
                                ]
                            },
                            {
                                "name": "e118679565fed3549f6e05b398f1275b4cb0c955e46ec0c6bea540974364cd48",
                                "value": 50,
                                "ufficio": "Commerciale Investimenti",
                                "link": [
                                    "08031c40fea4b77b2203830bb3dc0aea814fda06cd5a344019eadedbde13c15b",
                                    "1918402b88bbadb987f2e2d0b0bc9444f824af4bd707b1abcfe9bee5174fc7fa",
                                    "2475b9470c6235901e7e3d9cc1201f817a6244a0603a072db7f4cee5785fa448",
                                    "3779faf7954bbba479b99fbb0c6543df87cabb3d70654b2486c354a023406372",
                                    "3e96a90c292519dd959a327f2fa19128d2870160698271969dad21652684fdbd",
                                    "7db5e7074c95a92b6e3bd1a1cd1ca89bcc8269cdfe18d25a15156a26a0ad538c",
                                    "814b7e522b4b800335488042e5dad575528905ba0ad796d4ec52b02ad98c5f77",
                                    "ccb35cb14a71c974c83dae675e7143e1788db7293805206540c8d9df094e4ff4",
                                    "f680ca858e1602fe43773de835e5ead85a16f0a59a88465afbb6badbc99abd8a"
                                ]
                            },
                            {
                                "name": "e1e1ab4cf8b84afd5499dc456da34147501ef9dec70cb33c3115a7dcc1f9211b",
                                "value": 50,
                                "ufficio": "Risorse Umane e Progetti Evolutivi",
                                "link": [
                                    "36976cfe80dcf459c6a92c1d326df99d4e0c262970320e221f4093becab351c3",
                                    "f3eae844e383579728b6d9069cc00301c5b0941b1c10a464c7b9a424618cef2a"
                                ]
                            },
                            {
                                "name": "e290b0a48ba64237e04dad91dd9197f081a22d0c60b8bb247f25eeef2334c2ce",
                                "value": 50,
                                "ufficio": "Eventi e Brand",
                                "link": [
                                    "0e428ffef64b10335e73fb703497fafde699c4eb8fbc533035b84489c91ec4d6",
                                    "15167bd90d8d12b7bed6cfcd192e5306b4e1216c584cc9c50977d8ea7f30e281",
                                    "4ec2fcb6eb5b957f8f231afb57994e77f4185482fb2db7601b89259277ed87c6",
                                    "52fd640dca47a5008d088dd235189c51fc8495106ae0a39bb021dd809350e29c",
                                    "bc369356384d79a8fe050a841aef947bda1114404e1249bb5ac883b4a91ae707",
                                    "be0b7f3457783af8b09e2cf60f10cfdd64903cbda18a9b1c3216ea6a97026370",
                                    "ea0ba1b92d9815a5d808d92bc8b85e97d2c3f3aa6f8c8407ab6add9cd7e5be7a",
                                    "edc927c302d834fd6d1a19493931750c25e5dc949f2b2a8a8d05fb339ea75146"
                                ]
                            },
                            {
                                "name": "e43226fe1cb0cb66499b70d618b6067cd5ad450c3ee762586893189caf27c73e",
                                "value": 50,
                                "ufficio": "User Experience",
                                "link": [
                                    "15167bd90d8d12b7bed6cfcd192e5306b4e1216c584cc9c50977d8ea7f30e281",
                                    "4430f196eb4eb2ff7d9806df3fad24bd892c123d66855e0e9ad2f81242892211",
                                    "6120dd083a4c1cf13dd2c8ca1f92591ac48875307ff66fc8da019512f3d7a3df",
                                    "6c5a331a8710274d8a04ac9c315eafef0ec060aaeaf649c4322812b900a972d4",
                                    "8ddfe2ebab57cffc3af0f950c74433476a320a56aaf55fe5a0e2f74b31526a71",
                                    "9a44fd708e36fc4c92d967ede843ab33c6315bf9e8e254006b81bf8b79c889d0",
                                    "b93c96af6004749e2165b74e24a7178092634997794da2c1916a4a41786bd3f4",
                                    "bc369356384d79a8fe050a841aef947bda1114404e1249bb5ac883b4a91ae707",
                                    "d080f6246f89a229aff787012e414f09e420a8f174d2c85a2f342cebd9d54864",
                                    "ff12b55ee827fc0697d9f8c059da7ab3b2c16cdcd329686754e8e0ee92c3b5a9"
                                ]
                            },
                            {
                                "name": "e5a8d5c90db2aa9d5952663960c85af1f4cda6964bf6c8609a348d812e4f6bb5",
                                "value": 50,
                                "ufficio": "Finance Unit",
                                "link": [
                                    "3cd667fc0a67da84ca4bc18f8b71d6c422e0745df4d26de6ac42e6ba886730e9",
                                    "d737b37cfaabf6ae06942149c5b4ee5d6f51bb83f96c22a97a02dd55aae26797"
                                ]
                            },
                            {
                                "name": "e5b879512a1674dc7b5652370e4b79117acfb9c47cfd932a905f7c2dcee40803",
                                "value": 50,
                                "ufficio": "Customer care",
                                "link": [
                                    "15b1cc14db0ab645de4add03c990a9870ae632200c5344a48b97bb3e4bb11e13",
                                    "2b3a1884d7e6e625a7670bab5cf9d5ba47a62ce3d3413da9d7d3998ba89a9084",
                                    "4864fe7def1d6901b0b2cc8fc99d46c7990f929a39df1a12b552d7f11925a12e",
                                    "75bd954d523e6b4abc9af205110c94378e6e42519754106ae939adfbf3964529",
                                    "959f1d51b386cac9dba6dcfed0bbceac0d98a8e30e284abf4108fdcd111f14ac",
                                    "b0298f17afb1ed5c68b4f757fa10546b537cca65b13c8fd60ef1d439183ad7d7",
                                    "c7d09eed4cc9db53457fc09041d57ed674b0fd80ca07e9c15dede6af3ee4073d",
                                    "d99dc1f4e0881e5d348869f26afef39bec730a7dbd5585548ee85f6bb02bfe6a",
                                    "fcf8d1bdf0ae685599e1f7c47937032b9f521aa92c40619adc904c705c653846"
                                ]
                            },
                            {
                                "name": "e5d566de4dd057aff4327b06c54b22943ad417a5bdbd2a5dee4607646d9b63d9",
                                "value": 50,
                                "ufficio": "Eventi e Brand",
                                "link": [
                                    "0e428ffef64b10335e73fb703497fafde699c4eb8fbc533035b84489c91ec4d6",
                                    "50530ca013a6c0cf2eb8037d8032df3ae078924f85236bf5403db4404265bb62",
                                    "ea0ba1b92d9815a5d808d92bc8b85e97d2c3f3aa6f8c8407ab6add9cd7e5be7a"
                                ]
                            },
                            {
                                "name": "e6bd9b91c6b370580e55f4dca3a3241027babbf65d92298a5c2804b26167fc07",
                                "value": 50,
                                "ufficio": "Risk & Fraud",
                                "link": [
                                    "fabb5db77ad8282ec0c282f5d954afed22a64202a4a5436b78366c670981fdb8"
                                ]
                            },
                            {
                                "name": "ea0ba1b92d9815a5d808d92bc8b85e97d2c3f3aa6f8c8407ab6add9cd7e5be7a",
                                "value": 50,
                                "ufficio": "Eventi e Brand",
                                "link": [
                                    "0e428ffef64b10335e73fb703497fafde699c4eb8fbc533035b84489c91ec4d6",
                                    "1139eb48beb8d6d0ff482002d607a4a5e7ddc0b1da921eb1c759a39652d50650",
                                    "48805a805841f176eec7131f6f75a461aadf461f846d4e5516facf15350cfaeb",
                                    "50530ca013a6c0cf2eb8037d8032df3ae078924f85236bf5403db4404265bb62",
                                    "52fd640dca47a5008d088dd235189c51fc8495106ae0a39bb021dd809350e29c",
                                    "961b69abaab45da0ffccf1bb8be8ea853b617afad66594b8414a574a33f22651",
                                    "a4e8e3247a94ebc46bb94c8b914971f5ad793847ccf16b1f41b0c88f5a366d80",
                                    "e290b0a48ba64237e04dad91dd9197f081a22d0c60b8bb247f25eeef2334c2ce",
                                    "e5d566de4dd057aff4327b06c54b22943ad417a5bdbd2a5dee4607646d9b63d9",
                                    "ec8901cc98702644ab46c54add211d0edfb0f416476369cdd9758afcfbe21609",
                                    "edc927c302d834fd6d1a19493931750c25e5dc949f2b2a8a8d05fb339ea75146"
                                ]
                            },
                            {
                                "name": "ec8901cc98702644ab46c54add211d0edfb0f416476369cdd9758afcfbe21609",
                                "value": 50,
                                "ufficio": "Comitato di Direzione",
                                "link": [
                                    "0e428ffef64b10335e73fb703497fafde699c4eb8fbc533035b84489c91ec4d6",
                                    "246fa0d14d762dcb827e140c56d3ef32baf59f87dd78b8e27cdecc6670ac8a73",
                                    "2475b9470c6235901e7e3d9cc1201f817a6244a0603a072db7f4cee5785fa448",
                                    "4d783066b85f21a7821f82097dae1aed71418fef7af48fc51befffe93af3d9cf",
                                    "50125cffec52c899c2da565099f3b084c25f25b17db6facc28758add900ad765",
                                    "50530ca013a6c0cf2eb8037d8032df3ae078924f85236bf5403db4404265bb62",
                                    "e5d566de4dd057aff4327b06c54b22943ad417a5bdbd2a5dee4607646d9b63d9",
                                    "ea0ba1b92d9815a5d808d92bc8b85e97d2c3f3aa6f8c8407ab6add9cd7e5be7a",
                                    "f6d9c7262c3bbcc3b8c695a1e98caf93d40ca532dd02620709ece64a72e042ed"
                                ]
                            },
                            {
                                "name": "ecd3496bb4ed76576e96edd522f6903474e2a27d352d654ae562fb4ad2b2d881",
                                "value": 50,
                                "ufficio": "Sviluppo e Architetture",
                                "link": [
                                    "1f4ef15b42d71da6eaa84c1666da8f3aae74ebcab35d1321b195f4e6e67b7565",
                                    "ae4dd003704535e168ebdf1066be077474b7cc1a289ab06837430a972b3799ec",
                                    "bd935ad4c48672c5e4d791aac29afac44ac184d2694524e290563ca81cb2da60",
                                    "c512dc7d82ab25e44b85c3b5d9585c6c3b7045e9340171b6fb0ca43e1eaba9d7"
                                ]
                            },
                            {
                                "name": "ed67329cdf04b5a943a12c63cd602a6d7f06a61d433a960d8c5514f13ca40ca9",
                                "value": 50,
                                "ufficio": "Sviluppo e Architetture",
                                "link": [
                                    "03570a9667986e67fbc32a36c58c2a83620615f978d54065c0118b9ecf3bb513",
                                    "165d192513323e9624c5a4cf2379a90013365677958e51611eb1fc9c39dbedb1",
                                    "29e353b18fae9b63560f47b3f9cbcc291aad0650ad44705c79a9a4abd05ebe9c",
                                    "5e1084862fb3dc2334e3b24edef9e94744739cf28be459c808c84d5f7f0f6f70",
                                    "6c5a331a8710274d8a04ac9c315eafef0ec060aaeaf649c4322812b900a972d4",
                                    "8a69ecc023e5801658323c6a725b97387bed8cacab1bc8b740205d3534507d07",
                                    "faad46c5aeb820d0a642a5f11aefea24266c0c23285b78fc7011e9aa672988d6",
                                    "fb445e49cf6af4fe7c09b1e2965bd032ce19b8ed8d38af0dfb2fcf9340cddd52"
                                ]
                            },
                            {
                                "name": "edc927c302d834fd6d1a19493931750c25e5dc949f2b2a8a8d05fb339ea75146",
                                "value": 50,
                                "ufficio": "Comunicazione & Acquisition",
                                "link": [
                                    "0b6171902da4f7e74d4d0dfd05097833a95670b853bd7e282f15588a58258e3e",
                                    "0e428ffef64b10335e73fb703497fafde699c4eb8fbc533035b84489c91ec4d6",
                                    "1139eb48beb8d6d0ff482002d607a4a5e7ddc0b1da921eb1c759a39652d50650",
                                    "15167bd90d8d12b7bed6cfcd192e5306b4e1216c584cc9c50977d8ea7f30e281",
                                    "1ca7ba56343a590938e8c410969a3947596d5e121309da4048c66a4d950e67c7",
                                    "4430f196eb4eb2ff7d9806df3fad24bd892c123d66855e0e9ad2f81242892211",
                                    "52fd640dca47a5008d088dd235189c51fc8495106ae0a39bb021dd809350e29c",
                                    "5a980b472b83f2974fbb4ecf1061cc8ba78472476347de63d56222b8323f3d18",
                                    "7bd334b88615bc7016ee5e1fcc0e2860fbc90f187d9b2f37150b9da59cb21610",
                                    "961b69abaab45da0ffccf1bb8be8ea853b617afad66594b8414a574a33f22651",
                                    "a4e8e3247a94ebc46bb94c8b914971f5ad793847ccf16b1f41b0c88f5a366d80",
                                    "bc369356384d79a8fe050a841aef947bda1114404e1249bb5ac883b4a91ae707",
                                    "ea0ba1b92d9815a5d808d92bc8b85e97d2c3f3aa6f8c8407ab6add9cd7e5be7a"
                                ]
                            },
                            {
                                "name": "ee64e69e3c67b6db4180164ace85772c427d49160d44da1a27032fe2be2cd16c",
                                "value": 50,
                                "ufficio": "Sviluppo e Architetture",
                                "link": [
                                    "117484657fdfa49f6f202c4a01f5869cd80e1a3dd60c339d9145c67a9fb03cae",
                                    "15167bd90d8d12b7bed6cfcd192e5306b4e1216c584cc9c50977d8ea7f30e281",
                                    "1f4ef15b42d71da6eaa84c1666da8f3aae74ebcab35d1321b195f4e6e67b7565",
                                    "52fd640dca47a5008d088dd235189c51fc8495106ae0a39bb021dd809350e29c",
                                    "5a44504d34546036745dcb5deeddab47d39a00fb19734ca7145dcc5a9dda9ea7",
                                    "bc369356384d79a8fe050a841aef947bda1114404e1249bb5ac883b4a91ae707",
                                    "cb6238988950d6394c11c8b1cb351bdf9c78aa272ad810159bb299ebe5b3da07",
                                    "f43e34018b549d8863e860d611e560ddc05865730aece3e8859a85dbbde6a3d0",
                                    "ff12b55ee827fc0697d9f8c059da7ab3b2c16cdcd329686754e8e0ee92c3b5a9"
                                ]
                            },
                            {
                                "name": "f0c2bb337febad5eab9639010c46aa5a111eff6a15b323eec978c6aa946c36e5",
                                "value": 50,
                                "ufficio": "Sviluppo e Architetture",
                                "link": [
                                    "7b138a4913227d7a34f13a5c994fca2a492f6051b242e9eb45d9af1d99bc982e",
                                    "8464812682363064de4d541a02afca217bb03d68d9409cbb02a96d553d50fde0",
                                    "d080f6246f89a229aff787012e414f09e420a8f174d2c85a2f342cebd9d54864",
                                    "db8d910dfd966f6af426d67ef70da670fccc08932340c385b8d340151e7e7ac8"
                                ]
                            },
                            {
                                "name": "f158062016d7c912ba1494b35e8cd64d07656b38a4a0d0992e4dc016800a3788",
                                "value": 50,
                                "ufficio": "Analisi funzionali",
                                "link": [
                                    "1bed37d0037f2f6a95cf5d417703ce4a2eda90363991a28e65ea1c788f83008b",
                                    "1f4ef15b42d71da6eaa84c1666da8f3aae74ebcab35d1321b195f4e6e67b7565",
                                    "6120dd083a4c1cf13dd2c8ca1f92591ac48875307ff66fc8da019512f3d7a3df",
                                    "67678ae13f18c6a16575a01c50d4528d22e5e3862d512414f3dd81590aecfbd2",
                                    "73cbc0bd39e2e5b7ddabdcd8e3ce3e32df2057dd17cea502a971e71e705b0cea",
                                    "8464812682363064de4d541a02afca217bb03d68d9409cbb02a96d553d50fde0",
                                    "8ddfe2ebab57cffc3af0f950c74433476a320a56aaf55fe5a0e2f74b31526a71",
                                    "9d97a74ebd18d7acffed0070ca4bc846160f8159d8efe722f90554cc3d309c3d",
                                    "cd799a97931432b5e176c88142dc61546e826bc2e69a5bc0ec58df54d78c9b74",
                                    "ce118f98f944cb4b7bf553a6c3361f5e5ffb39bc33050ac457ff5e68c3726eea",
                                    "d080f6246f89a229aff787012e414f09e420a8f174d2c85a2f342cebd9d54864",
                                    "db8d910dfd966f6af426d67ef70da670fccc08932340c385b8d340151e7e7ac8"
                                ]
                            },
                            {
                                "name": "f3280920c064eb900b091aa992d081b27c1f9f3c2e8aae488b69be093a4932e4",
                                "value": 50,
                                "ufficio": "Crediti",
                                "link": [
                                    "516c0937b94123241b706f185cbaf5039307313eaf7200e1e0ba64bc880c17f5",
                                    "63f169eba9e68ec4a33ef5cb7cefbcf129975ac567f887dab1718ef65cd9971a"
                                ]
                            },
                            {
                                "name": "f3eae844e383579728b6d9069cc00301c5b0941b1c10a464c7b9a424618cef2a",
                                "value": 50,
                                "ufficio": "Risorse Umane e Progetti Evolutivi",
                                "link": [
                                    "36976cfe80dcf459c6a92c1d326df99d4e0c262970320e221f4093becab351c3",
                                    "50125cffec52c899c2da565099f3b084c25f25b17db6facc28758add900ad765",
                                    "50228aad62253ede25fa405a08929d5bda792f6d07a6554a5f0eae03de60d209",
                                    "9445045a5965f1248b6d49d8030bc56fdf6882ac9ee60a13c0eb87244b8c7060",
                                    "d3a8ea432eb64faeb9c3661d563d76d6a0b9beec571b803e8a9b68e2f81111c4",
                                    "e1e1ab4cf8b84afd5499dc456da34147501ef9dec70cb33c3115a7dcc1f9211b"
                                ]
                            },
                            {
                                "name": "f43e34018b549d8863e860d611e560ddc05865730aece3e8859a85dbbde6a3d0",
                                "value": 50,
                                "ufficio": "Commerciale Banking & Credito",
                                "link": [
                                    "2782ff4e895a9d5f2a1edc00d3922d7f7e640c3ee5a6400fb8c4ab3532249c3e",
                                    "2b3a1884d7e6e625a7670bab5cf9d5ba47a62ce3d3413da9d7d3998ba89a9084",
                                    "ee64e69e3c67b6db4180164ace85772c427d49160d44da1a27032fe2be2cd16c",
                                    "ff12b55ee827fc0697d9f8c059da7ab3b2c16cdcd329686754e8e0ee92c3b5a9"
                                ]
                            },
                            {
                                "name": "f4f2d477cc383f7885fc3724f0a204025e3ef04f34c7478a185beac0c595ff6c",
                                "value": 50,
                                "ufficio": "Sviluppo e Architetture",
                                "link": [
                                    "4e12e345bdb2e98fc1ce84b6b9724b164ab5570d3102f6cfd7759a367b58d857",
                                    "bc369356384d79a8fe050a841aef947bda1114404e1249bb5ac883b4a91ae707",
                                    "bd935ad4c48672c5e4d791aac29afac44ac184d2694524e290563ca81cb2da60"
                                ]
                            },
                            {
                                "name": "f57cdd8423add2b2919fce520a9a2d9a34184b0f716c1e6ae5508ef4ee31056e",
                                "value": 50,
                                "ufficio": "Sistemi e Monitoring",
                                "link": [
                                    "02a0e9c62180eb9c2d8b7fb9e978e4ed45848287660235d5c51e2f110af4e0a9",
                                    "370b3c12a845f303bf28657161f8285295c5c8625e7a4daf9c464c6957c03ed9",
                                    "50530ca013a6c0cf2eb8037d8032df3ae078924f85236bf5403db4404265bb62",
                                    "5a44504d34546036745dcb5deeddab47d39a00fb19734ca7145dcc5a9dda9ea7",
                                    "67fd6c0efd48daf4780f975393ab5252432b35da210995dc6845d95261be3f3e",
                                    "6aa56775705605384583392112cc3a974b7bded505375979500264fb35310747",
                                    "8c3e02eabd321bb7ae9ba21563338ba3df344ab68b381680bcc3a75a2a8ccdf4",
                                    "ce118f98f944cb4b7bf553a6c3361f5e5ffb39bc33050ac457ff5e68c3726eea"
                                ]
                            },
                            {
                                "name": "f680ca858e1602fe43773de835e5ead85a16f0a59a88465afbb6badbc99abd8a",
                                "value": 50,
                                "ufficio": "Commerciale Investimenti",
                                "link": [
                                    "1918402b88bbadb987f2e2d0b0bc9444f824af4bd707b1abcfe9bee5174fc7fa",
                                    "213a6c50db2433bd2d9a3300f4508d4c8ab4f102d19763dfb18fcaa06364c2eb",
                                    "2475b9470c6235901e7e3d9cc1201f817a6244a0603a072db7f4cee5785fa448",
                                    "249e0a20ac231db9725c70ada8c80912404edc3cd67822a40f5169798f64b8e4",
                                    "29e353b18fae9b63560f47b3f9cbcc291aad0650ad44705c79a9a4abd05ebe9c",
                                    "36976cfe80dcf459c6a92c1d326df99d4e0c262970320e221f4093becab351c3",
                                    "3779faf7954bbba479b99fbb0c6543df87cabb3d70654b2486c354a023406372",
                                    "50125cffec52c899c2da565099f3b084c25f25b17db6facc28758add900ad765",
                                    "7db5e7074c95a92b6e3bd1a1cd1ca89bcc8269cdfe18d25a15156a26a0ad538c",
                                    "d499a998e542bcb1baf5189c1cd28f4ca585e9b74de5eb8bd317cb1869296794",
                                    "e118679565fed3549f6e05b398f1275b4cb0c955e46ec0c6bea540974364cd48"
                                ]
                            },
                            {
                                "name": "f6d9c7262c3bbcc3b8c695a1e98caf93d40ca532dd02620709ece64a72e042ed",
                                "value": 50,
                                "ufficio": "Data Intelligence",
                                "link": [
                                    "246fa0d14d762dcb827e140c56d3ef32baf59f87dd78b8e27cdecc6670ac8a73",
                                    "50125cffec52c899c2da565099f3b084c25f25b17db6facc28758add900ad765",
                                    "684154a13c4aba0b25fe1a0bdd4667ee40d5ed056108566cb09c6f8614bd27aa",
                                    "74910197266fef7d298dd12740f389762cac70dc2e07f05868b98db34241dc1b",
                                    "7d82b46491ea76531341ae71f72e7a616761d08e10c9cc71afb7bc8b22dfbec1",
                                    "915e1d4b27b8f8d1ff4a6f3fd44f14629b0aa665d34f20020877d7d3ac517c40",
                                    "b93c96af6004749e2165b74e24a7178092634997794da2c1916a4a41786bd3f4",
                                    "da19ae9bdf22abe2a9cb0962b11f8e355978c0261556c1951bff89a793042d65",
                                    "f43e34018b549d8863e860d611e560ddc05865730aece3e8859a85dbbde6a3d0",
                                    "ff12b55ee827fc0697d9f8c059da7ab3b2c16cdcd329686754e8e0ee92c3b5a9"
                                ]
                            },
                            {
                                "name": "fa225faeed4228a77a50974305de27453cc15565991105d05fa313720f58ae84",
                                "value": 50,
                                "ufficio": "Finance Unit",
                                "link": [
                                    "d737b37cfaabf6ae06942149c5b4ee5d6f51bb83f96c22a97a02dd55aae26797"
                                ]
                            },
                            {
                                "name": "fa5deed70dae7bbed11f872ecc4a3cf64ff21e2099602e3dfbe91fdc869493f3",
                                "value": 50,
                                "ufficio": "Chief Technology Officer",
                                "link": [
                                    "352097edcec7e4cc4332657c589d7506b13fa583c8078c419c1f1e22fc6bb66a",
                                    "413612c9c7052356812afdabf6504212199907ccb172b3e1e5b4e8ca29eac0a7"
                                ]
                            },
                            {
                                "name": "faad46c5aeb820d0a642a5f11aefea24266c0c23285b78fc7011e9aa672988d6",
                                "value": 50,
                                "ufficio": "Commerciale Banking & Credito",
                                "link": [
                                    "246fa0d14d762dcb827e140c56d3ef32baf59f87dd78b8e27cdecc6670ac8a73",
                                    "29e353b18fae9b63560f47b3f9cbcc291aad0650ad44705c79a9a4abd05ebe9c",
                                    "6c5a331a8710274d8a04ac9c315eafef0ec060aaeaf649c4322812b900a972d4",
                                    "8f9707cd5ae227dc0ea5e7ddb76fe9ab62e93c62829debd8ba5f599ef62b7502",
                                    "b93c96af6004749e2165b74e24a7178092634997794da2c1916a4a41786bd3f4",
                                    "ed67329cdf04b5a943a12c63cd602a6d7f06a61d433a960d8c5514f13ca40ca9"
                                ]
                            },
                            {
                                "name": "fabb5db77ad8282ec0c282f5d954afed22a64202a4a5436b78366c670981fdb8",
                                "value": 50,
                                "ufficio": "Risk & Fraud",
                                "link": [
                                    "e6bd9b91c6b370580e55f4dca3a3241027babbf65d92298a5c2804b26167fc07"
                                ]
                            },
                            {
                                "name": "fb445e49cf6af4fe7c09b1e2965bd032ce19b8ed8d38af0dfb2fcf9340cddd52",
                                "value": 50,
                                "ufficio": "Analisi funzionali",
                                "link": [
                                    "03570a9667986e67fbc32a36c58c2a83620615f978d54065c0118b9ecf3bb513",
                                    "5e1084862fb3dc2334e3b24edef9e94744739cf28be459c808c84d5f7f0f6f70",
                                    "73cbc0bd39e2e5b7ddabdcd8e3ce3e32df2057dd17cea502a971e71e705b0cea",
                                    "abc7401e3265278becd57c05a0f1675fbb4b94f39845020e6ddb03f448020e02",
                                    "bf2a52af189f34bcf54851fcdd6a8deb89d31ec18c9e53273f05739ddd5badc1",
                                    "ed67329cdf04b5a943a12c63cd602a6d7f06a61d433a960d8c5514f13ca40ca9"
                                ]
                            },
                            {
                                "name": "fbf35a4655c13d8be858814ee9f290bd64fd8712cb3cfa37e890a70515ebbcd5",
                                "value": 50,
                                "ufficio": "Risorse Umane e Progetti Evolutivi",
                                "link": [
                                    "0a79f316c74167ab585e990f9704eccefe51f1fa8c0c57b89499f7a76a1663ed",
                                    "15b1cc14db0ab645de4add03c990a9870ae632200c5344a48b97bb3e4bb11e13",
                                    "2385eaf1b82db67a6b1f4fa4e968876b08c7e15fd10c6622c584148972c1e0df",
                                    "26b9e209584bf3efd59e440e6838fef4d67394ca48f2a9eb7533b7d4b23f015b",
                                    "2b3a1884d7e6e625a7670bab5cf9d5ba47a62ce3d3413da9d7d3998ba89a9084",
                                    "959f1d51b386cac9dba6dcfed0bbceac0d98a8e30e284abf4108fdcd111f14ac",
                                    "b0298f17afb1ed5c68b4f757fa10546b537cca65b13c8fd60ef1d439183ad7d7",
                                    "d3a8ea432eb64faeb9c3661d563d76d6a0b9beec571b803e8a9b68e2f81111c4",
                                    "d99dc1f4e0881e5d348869f26afef39bec730a7dbd5585548ee85f6bb02bfe6a",
                                    "e1e1ab4cf8b84afd5499dc456da34147501ef9dec70cb33c3115a7dcc1f9211b",
                                    "edc927c302d834fd6d1a19493931750c25e5dc949f2b2a8a8d05fb339ea75146",
                                    "f3eae844e383579728b6d9069cc00301c5b0941b1c10a464c7b9a424618cef2a"
                                ]
                            },
                            {
                                "name": "fc0122f6d05c2ec54028830dc458440a2ff21f5a1bd11b664fec0fc9d6b346cc",
                                "value": 50,
                                "ufficio": "Sviluppo e Architetture",
                                "link": [
                                    "50125cffec52c899c2da565099f3b084c25f25b17db6facc28758add900ad765",
                                    "814b7e522b4b800335488042e5dad575528905ba0ad796d4ec52b02ad98c5f77",
                                    "ec8901cc98702644ab46c54add211d0edfb0f416476369cdd9758afcfbe21609"
                                ]
                            },
                            {
                                "name": "fd31d2adc3e247d19130898042f9db743626bbbcb5bf50efa7602024d9349351",
                                "value": 50,
                                "ufficio": "Chief Technology Officer",
                                "link": [
                                    "89581076ad0b28dc87a57bce316f2d6d7a95bbe17220b598b8cfdc5f1685fbb8"
                                ]
                            },
                            {
                                "name": "ff12b55ee827fc0697d9f8c059da7ab3b2c16cdcd329686754e8e0ee92c3b5a9",
                                "value": 50,
                                "ufficio": "Commerciale Banking & Credito",
                                "link": [
                                    "150dc7a0f15ffab503fdaa3cd09471f3c6055764f95b1f6cb4ff191bfc4a1532",
                                    "15167bd90d8d12b7bed6cfcd192e5306b4e1216c584cc9c50977d8ea7f30e281",
                                    "21128e9f23759392be2b2b17596d9d7c496d188162fd9ece9f8aa096e189a929",
                                    "2782ff4e895a9d5f2a1edc00d3922d7f7e640c3ee5a6400fb8c4ab3532249c3e",
                                    "2b3a1884d7e6e625a7670bab5cf9d5ba47a62ce3d3413da9d7d3998ba89a9084",
                                    "2d5cdaed96acc015fa15ee3403b327066febef58d4ff9a20e06dfb7b261628ce",
                                    "38ce0ce0122e0bcdd595c12f417196d4acd690cf7b2d6cda61bc4c7208166e04",
                                    "4430f196eb4eb2ff7d9806df3fad24bd892c123d66855e0e9ad2f81242892211",
                                    "4af4cbc00eeadb01960175f8e148342ce43279cc161fd67b1cac2ee1e6ee60dc",
                                    "52fd640dca47a5008d088dd235189c51fc8495106ae0a39bb021dd809350e29c",
                                    "5a44504d34546036745dcb5deeddab47d39a00fb19734ca7145dcc5a9dda9ea7",
                                    "773ed157955ddce568e45fe6df032c00be2dc8274003ab9a49aee3a472b0dd03",
                                    "7ad8ce7b05bf6d2f96c6cf7d8d2c20304a9fe6cf4d7a944608666d8f36b454c9",
                                    "a4e8e3247a94ebc46bb94c8b914971f5ad793847ccf16b1f41b0c88f5a366d80",
                                    "b93c96af6004749e2165b74e24a7178092634997794da2c1916a4a41786bd3f4",
                                    "bc369356384d79a8fe050a841aef947bda1114404e1249bb5ac883b4a91ae707",
                                    "bd935ad4c48672c5e4d791aac29afac44ac184d2694524e290563ca81cb2da60",
                                    "ee64e69e3c67b6db4180164ace85772c427d49160d44da1a27032fe2be2cd16c",
                                    "f43e34018b549d8863e860d611e560ddc05865730aece3e8859a85dbbde6a3d0",
                                    "f6d9c7262c3bbcc3b8c695a1e98caf93d40ca532dd02620709ece64a72e042ed",
                                    "faad46c5aeb820d0a642a5f11aefea24266c0c23285b78fc7011e9aa672988d6"
                                ]
                            },
                            {
                                "name": "ffd5bbb942400ed12a334991d5f08a56b853c94044196cf6721fcbeedefc2c78",
                                "value": 50,
                                "ufficio": "Commerciale Investimenti",
                                "link": [
                                    "2475b9470c6235901e7e3d9cc1201f817a6244a0603a072db7f4cee5785fa448",
                                    "88237b6a9bdc01eacd137201074f44ffd2e4c2f0192ed388bef9e180d4b6fdc6",
                                    "a88b39bfb012c98803e66f0b018c25d1ffa5766c21260b623d9ddff1b90edc37",
                                    "f680ca858e1602fe43773de835e5ead85a16f0a59a88465afbb6badbc99abd8a"
                                ]
                            },
                            {
                                "name": "ffdd2a8bbeedc5f07cf40d7708d90c44d18260c146641e835b194e0f8c0a0490",
                                "value": 50,
                                "ufficio": "Controlli, Processi e Immobiliare",
                                "link": [
                                    "55ff0b8efbcb37483f73f0ffe87b240272eefce6390e3c3454881cdc8b6b5638",
                                    "6101c7c61c9aebce7ec0d0ac3ecfbf7be4b2ea92b01a55a3b49e244fdb4af554",
                                    "7bd334b88615bc7016ee5e1fcc0e2860fbc90f187d9b2f37150b9da59cb21610"
                                ]
                            }
                        ]
                    }
                ],
                "chart_options": {
                    "colors": [
                        ":generic_transparent", "",
                        ":generic_transparent", "",
                        ":generic", "",
                        ":gray", "",
                        ":generic_transparent", "",
                        ":generic_transparent", "",
                        ":generic_transparent", "",
                        ":generic_transparent", "",
                        ":gray", "",
                        ":generic_transparent", "",
                        ":generic_transparent", "",
                        ":gray", "",
                        ":gray", "",
                        ":generic_transparent", "",
                        ":gray", "",
                        ":gray", "",
                        ":gray", "",
                        ":gray", "",
                        ":generic", "",
                        ":generic_transparent", "",
                        ":generic_transparent", "",
                        ":gray", "",
                        ":generic", "",
                        ":gray", "",
                        ":generic_transparent", "",
                        ":gray", "",
                        ":gray", "",
                        ":gray", "",
                        ":gray", "",
                        ":generic", "",
                        ":generic_transparent", "",
                        ":gray", "",
                        ":generic_transparent", "",
                        ":gray", "",
                        ":generic", "",
                        ":gray", "",
                        ":gray", "",
                        ":generic_transparent", "",
                        ":gray", "",
                        ":gray", "",
                        ":gray", "",
                        ":gray", "",
                        ":gray", "",
                        ":gray", "",
                        ":gray", "",
                        ":generic_transparent", "",
                        ":gray", "",
                        ":generic", "",
                        ":gray", "",
                        ":generic_transparent", "",
                        ":generic_transparent", "",
                        ":gray", "",
                        ":generic_transparent", "",
                        ":gray", "",
                        ":generic_transparent", "",
                        ":gray", "",
                        ":gray", "",
                        ":gray", "",
                        ":generic_transparent", "",
                        ":generic_transparent", "",
                        ":generic", "",
                        ":generic", "",
                        ":gray", "",
                        ":generic_transparent", "",
                        ":generic_transparent", "",
                        ":gray", "",
                        ":gray", "",
                        ":gray", "",
                        ":gray", "",
                        ":gray", "",
                        ":gray", "",
                        ":generic_transparent", "",
                        ":generic", "",
                        ":gray", "",
                        ":gray", "",
                        ":gray", "",
                        ":gray", "",
                        ":gray", "",
                        ":generic", "",
                        ":generic_transparent", "",
                        ":gray", "",
                        ":gray", "",
                        ":gray", "",
                        ":gray", "",
                        ":generic", "",
                        ":generic", "",
                        ":gray", "",
                        ":gray", "",
                        ":gray", "",
                        ":gray", "",
                        ":gray", "",
                        ":generic_transparent", "",
                        ":gray", "",
                        ":gray", "",
                        ":gray", "",
                        ":generic_transparent", "",
                        ":generic_transparent", "",
                        ":generic", "",
                        ":generic_transparent", "",
                        ":generic", "",
                        ":generic_transparent", "",
                        ":generic_transparent", "",
                        ":gray", "",
                        ":gray", "",
                        ":generic_transparent", "",
                        ":gray", "",
                        ":generic_transparent", "",
                        ":gray", "",
                        ":generic", "",
                        ":generic_transparent", "",
                        ":gray", "",
                        ":generic", "",
                        ":generic", "",
                        ":gray", "",
                        ":gray", "",
                        ":gray", "",
                        ":generic_transparent", "",
                        ":generic_transparent", "",
                        ":generic_transparent", "",
                        ":gray", "",
                        ":generic_transparent", "",
                        ":gray", "",
                        ":gray", "",
                        ":generic_transparent", "",
                        ":gray", "",
                        ":gray", "",
                        ":gray", "",
                        ":generic_transparent", "",
                        ":gray", "",
                        ":generic", "",
                        ":generic_transparent", "",
                        ":generic", "",
                        ":generic_transparent", "",
                        ":gray", "",
                        ":gray", "",
                        ":generic_transparent", "",
                        ":generic_transparent", "",
                        ":generic", "",
                        ":generic_transparent", "",
                        ":gray", "",
                        ":gray", "",
                        ":generic_transparent", "",
                        ":generic_transparent", "",
                        ":gray", "",
                        ":gray", "",
                        ":gray", "",
                        ":gray", "",
                        ":gray", "",
                        ":gray", "",
                        ":generic", "",
                        ":generic_transparent", "",
                        ":generic_transparent", "",
                        ":generic_transparent", "",
                        ":generic_transparent", "",
                        ":gray", "",
                        ":generic_transparent", "",
                        ":generic_transparent", "",
                        ":generic_transparent", "",
                        ":gray", "",
                        ":generic_transparent", "",
                        ":generic", "",
                        ":gray", "",
                        ":gray", "",
                        ":generic", "",
                        ":generic_transparent", "",
                        ":gray", "",
                        ":generic_transparent", "",
                        ":gray", "",
                        ":generic", "",
                        ":generic", "",
                        ":generic", "",
                        ":gray", "",
                        ":gray", "",
                        ":gray", "",
                        ":gray", "",
                        ":gray", "",
                        ":generic_transparent", "",
                        ":generic_transparent", "",
                        ":gray", "",
                        ":generic_transparent", "",
                        ":gray", "",
                        ":generic_transparent", "",
                        ":generic_transparent", "",
                        ":gray", "",
                        ":gray", "",
                        ":gray", "",
                        ":generic_transparent", "",
                        ":gray", "",
                        ":gray", "",
                        ":generic_transparent", "",
                        ":generic", "",
                        ":generic_transparent", "",
                        ":gray", "",
                        ":generic_transparent", "",
                        ":gray", "",
                        ":gray", "",
                        ":gray", "",
                        ":generic_transparent", "",
                        ":gray", "",
                        ":gray", "",
                        ":generic_transparent", "",
                        ":generic_transparent", "",
                        ":generic", "",
                        ":gray", "",
                        ":generic", "",
                        ":gray", "",
                        ":generic", "",
                        ":gray", "",
                        ":gray", "",
                        ":gray", "",
                        ":gray", "",
                        ":gray", "",
                        ":generic_transparent", "",
                        ":gray", "",
                        ":gray", "",
                        ":gray", "",
                        ":gray", "",
                        ":gray", "",
                        ":gray", "",
                        ":generic", "",
                        ":generic", "",
                        ":gray", "",
                        ":generic", "",
                        ":generic", "",
                        ":generic_transparent", "",
                        ":gray", "",
                        ":gray", "",
                        ":gray", "",
                        ":generic", "",
                        ":generic_transparent", "",
                        ":gray", "",
                        ":gray", "",
                        ":gray", "",
                        ":generic_transparent", "",
                        ":gray", "",
                        ":gray", "",
                        ":generic_transparent", "",
                        ":gray", "",
                        ":generic", "",
                        ":generic_transparent", "",
                        ":gray", "",
                        ":gray", "",
                        ":gray", ""
                    ]
                },
                "type": "graph",
                "package_id": "card.package.agile",
                "kpi_id": "kpi_001"
            }
        ]
    },
    {
        "card_key": "IT e Innov. Digitale|Sviluppo e Architetture",
        "complete_key": "IT e Innov. Digitale|Sviluppo e Architetture|card.package.agile|kpi_002",
        "package_id": "card.package.agile",
        "kpi_id": "kpi_002",
        "charts": [
            {
                "chart_id": "001",
                "chart_data": {
                    "titles": [
                        "Dipartimento",
                        "Ufficio",
                        "Comm. 1",
                        "Comm. 2",
                        "Comm. 3",
                        "Comm. 4",
                        "Comm. 5",
                        "Comm. 6",
                        "Comm. 7"
                    ],
                    "datas": [
                        [
                            "Comm. investimenti & wealth m.",
                            "Commerciale Investimenti",
                            1,
                            "-",
                            3,
                            "-",
                            "-",
                            "-",
                            "-"
                        ],
                        [
                            "It e innov. digitale",
                            "Analisi funzionali",
                            1,
                            1,
                            2,
                            12,
                            3,
                            "-",
                            1
                        ],
                        [
                            "It e innov. digitale",
                            "Chief Technology Office",
                            "-",
                            1,
                            "-",
                            1,
                            "-",
                            4,
                            "-"
                        ],
                        [
                            "It e innov. digitale",
                            "Sistemi e Monitoring",
                            "-",
                            "-",
                            "-",
                            "-",
                            18,
                            "-",
                            "-"
                        ],
                        [
                            "It e innov. digitale",
                            "Sviluppo e Architetture",
                            2,
                            1,
                            2,
                            12,
                            18,
                            4,
                            4
                        ],
                        [
                            "It e innov. digitale",
                            "User Experience",
                            1,
                            "-",
                            "-",
                            "-",
                            "-",
                            "-",
                            4
                        ],
                        [
                            "Opearations & gestione imm.",
                            "Crediti",
                            3,
                            "-",
                            2,
                            "-",
                            "-",
                            "-",
                            "-",
                        ],
                        [
                            "Opearations & gestione imm.",
                            "Finance Unit",
                            "-",
                            4,
                            "-",
                            "-",
                            "-",
                            "-",
                            "-"
                        ],
                    ]
                },
                "chart_options": {
                    "highlight_row": [4],
                    "background_color": ":white",
                    "border_color": ":target",
                    "header_background_color": ":matrix_header"
                },
                "type": "matrix",
                "package_id": "card.package.agile",
                "kpi_id": "kpi_002"
            }
        ]
    },
    {
        "card_key": "IT e Innov. Digitale|Sviluppo e Architetture",
        "complete_key": "IT e Innov. Digitale|Sviluppo e Architetture|card.package.agile|kpi_003",
        "package_id": "card.package.agile",
        "kpi_id": "kpi_003",
        "charts": [
            {
                "chart_id": "001",
                "chart_data": {
                    "datasets": [
                        {
                            "borderColor": ":generic",
                            "backgroundColor": ":generic",
                            "label": "Persone",
                            "type": "bubble",
                            "data": [
                                { "Person": "01bc86e78cd69cc938888e5d58529827da10d3afacc31a4e746b1b235c362cb0", "xLabel": "Outbound Degree", "yLabel": "Inbound Degree", "x": 79, "y": 65, "r": 10 },
                                { "Person": "117484657fdfa49f6f202c4a01f5869cd80e1a3dd60c339d9145c67a9fb03cae", "xLabel": "Outbound Degree", "yLabel": "Inbound Degree", "x": 6 , "y": 16, "r": 10 },
                                { "Person": "18f8f073dd89fcf946d09157db5342889d19543390c8cd4c9dd8b05bc65fd518", "xLabel": "Outbound Degree", "yLabel": "Inbound Degree", "x": 8 , "y": 8 , "r": 10 },
                                { "Person": "1f4ef15b42d71da6eaa84c1666da8f3aae74ebcab35d1321b195f4e6e67b7565", "xLabel": "Outbound Degree", "yLabel": "Inbound Degree", "x": 17, "y": 28, "r": 10 },
                                { "Person": "238ae5b17e2e2c57af472e3f8cfba32aed5cf2f12286424acd7971a63c794c39", "xLabel": "Outbound Degree", "yLabel": "Inbound Degree", "x": 3 , "y": 9 , "r": 10 },
                                { "Person": "2f02900e1dd4918db6a502619ac506938f4be868a26074349313ed3ab6713f1f", "xLabel": "Outbound Degree", "yLabel": "Inbound Degree", "x": 4 , "y": 0 , "r": 10 },
                                { "Person": "42d3f609a82d7d42d4b1fbb92e1fb77c095d318ed908b236393aa914bdbd7acc", "xLabel": "Outbound Degree", "yLabel": "Inbound Degree", "x": 80, "y": 84, "r": 10 },
                                { "Person": "432f4b6475380ae4688eba30a14f2c02c391d3690924ca8134b9d1620af15f28", "xLabel": "Outbound Degree", "yLabel": "Inbound Degree", "x": 1 , "y": 1 , "r": 10 },
                                { "Person": "4e12e345bdb2e98fc1ce84b6b9724b164ab5570d3102f6cfd7759a367b58d857", "xLabel": "Outbound Degree", "yLabel": "Inbound Degree", "x": 1 , "y": 3 , "r": 10 },
                                { "Person": "516c0937b94123241b706f185cbaf5039307313eaf7200e1e0ba64bc880c17f5", "xLabel": "Outbound Degree", "yLabel": "Inbound Degree", "x": 5 , "y": 9 , "r": 10 },
                                { "Person": "59294899f0e52923d6f08c1a86900c906187345c14c72a70fb51510e7fa00aec", "xLabel": "Outbound Degree", "yLabel": "Inbound Degree", "x": 3 , "y": 7 , "r": 10 },
                                { "Person": "5a44504d34546036745dcb5deeddab47d39a00fb19734ca7145dcc5a9dda9ea7", "xLabel": "Outbound Degree", "yLabel": "Inbound Degree", "x": 16, "y": 16, "r": 10 },
                                { "Person": "659a5f5d597cd6834db5df465fcd21c9eaf134b14d21dbfdef9b2d0060133573", "xLabel": "Outbound Degree", "yLabel": "Inbound Degree", "x": 1 , "y": 0 , "r": 10 },
                                { "Person": "67678ae13f18c6a16575a01c50d4528d22e5e3862d512414f3dd81590aecfbd2", "xLabel": "Outbound Degree", "yLabel": "Inbound Degree", "x": 9 , "y": 17, "r": 10 },
                                { "Person": "6ec9cfba69a90f24827db98d1a905f97613cd4cc592b0e6f0026b5aae81a0118", "xLabel": "Outbound Degree", "yLabel": "Inbound Degree", "x": 13, "y": 5 , "r": 10 },
                                { "Person": "71852c01834ad9f0e850f43c6418b83681f7acb82eb134a23d164ae3db8e7760", "xLabel": "Outbound Degree", "yLabel": "Inbound Degree", "x": 2 , "y": 6 , "r": 10 },
                                { "Person": "73cbc0bd39e2e5b7ddabdcd8e3ce3e32df2057dd17cea502a971e71e705b0cea", "xLabel": "Outbound Degree", "yLabel": "Inbound Degree", "x": 3 , "y": 9 , "r": 10 },
                                { "Person": "853ac84efec82736323b7c9a6cdcffe8ae2cf3da4bde5591d1568837b6c96ee9", "xLabel": "Outbound Degree", "yLabel": "Inbound Degree", "x": 57, "y": 12, "r": 10 },
                                { "Person": "8787740c45c655c0511fa208a842b48fd6818c89e1f9a4390482faaa868a027f", "xLabel": "Outbound Degree", "yLabel": "Inbound Degree", "x": 2 , "y": 5 , "r": 10 },
                                { "Person": "8c3e02eabd321bb7ae9ba21563338ba3df344ab68b381680bcc3a75a2a8ccdf4", "xLabel": "Outbound Degree", "yLabel": "Inbound Degree", "x": 4 , "y": 11, "r": 10 },
                                { "Person": "9949bc108015faaf4b49124f1164a7bc6d8db0c7ea9d32d634a4e89d74134ad9", "xLabel": "Outbound Degree", "yLabel": "Inbound Degree", "x": 2 , "y": 4 , "r": 10 },
                                { "Person": "a88b39bfb012c98803e66f0b018c25d1ffa5766c21260b623d9ddff1b90edc37", "xLabel": "Outbound Degree", "yLabel": "Inbound Degree", "x": 12, "y": 11, "r": 10 },
                                { "Person": "abc7401e3265278becd57c05a0f1675fbb4b94f39845020e6ddb03f448020e02", "xLabel": "Outbound Degree", "yLabel": "Inbound Degree", "x": 1 , "y": 5 , "r": 10 },
                                { "Person": "b04e2eca334e777155ad38dcf329026d75656094a8665ff4448e4d1bba89eed1", "xLabel": "Outbound Degree", "yLabel": "Inbound Degree", "x": 1 , "y": 5 , "r": 10 },
                                { "Person": "b080642b5854215716c65a72781e55050b41240865a8621530419a1348d810f4", "xLabel": "Outbound Degree", "yLabel": "Inbound Degree", "x": 68, "y": 82, "r": 10 },
                                { "Person": "b14ceff609c278e1c1891d643b7aa9eb267e80ce5e050863aeeddee9bcb25bd0", "xLabel": "Outbound Degree", "yLabel": "Inbound Degree", "x": 6 , "y": 8 , "r": 10 },
                                { "Person": "ce118f98f944cb4b7bf553a6c3361f5e5ffb39bc33050ac457ff5e68c3726eea", "xLabel": "Outbound Degree", "yLabel": "Inbound Degree", "x": 59, "y": 20, "r": 10 },
                                { "Person": "d737b37cfaabf6ae06942149c5b4ee5d6f51bb83f96c22a97a02dd55aae26797", "xLabel": "Outbound Degree", "yLabel": "Inbound Degree", "x": 9 , "y": 1 , "r": 10 },
                                { "Person": "d9b07283f475aeb6d05e04c9acb0f0bd609e9b5220b21f7a99a5c342a64139dc", "xLabel": "Outbound Degree", "yLabel": "Inbound Degree", "x": 2 , "y": 3 , "r": 10 },
                                { "Person": "db8d910dfd966f6af426d67ef70da670fccc08932340c385b8d340151e7e7ac8", "xLabel": "Outbound Degree", "yLabel": "Inbound Degree", "x": 10, "y": 8 , "r": 10 },
                                { "Person": "ecd3496bb4ed76576e96edd522f6903474e2a27d352d654ae562fb4ad2b2d881", "xLabel": "Outbound Degree", "yLabel": "Inbound Degree", "x": 4 , "y": 13, "r": 10 },
                                { "Person": "ed67329cdf04b5a943a12c63cd602a6d7f06a61d433a960d8c5514f13ca40ca9", "xLabel": "Outbound Degree", "yLabel": "Inbound Degree", "x": 12, "y": 53, "r": 10 },
                                { "Person": "ee64e69e3c67b6db4180164ace85772c427d49160d44da1a27032fe2be2cd16c", "xLabel": "Outbound Degree", "yLabel": "Inbound Degree", "x": 9 , "y": 8 , "r": 10 },
                                { "Person": "f0c2bb337febad5eab9639010c46aa5a111eff6a15b323eec978c6aa946c36e5", "xLabel": "Outbound Degree", "yLabel": "Inbound Degree", "x": 4 , "y": 7 , "r": 10 },
                                { "Person": "f4f2d477cc383f7885fc3724f0a204025e3ef04f34c7478a185beac0c595ff6c", "xLabel": "Outbound Degree", "yLabel": "Inbound Degree", "x": 3 , "y": 10, "r": 10 },
                                { "Person": "fc0122f6d05c2ec54028830dc458440a2ff21f5a1bd11b664fec0fc9d6b346cc", "xLabel": "Outbound Degree", "yLabel": "Inbound Degree", "x": 3 , "y": 10, "r": 10 }
                            ]
                        }
                    ]
                },
                "chart_options": {
                    "plugins": {
                        "quadrants": {
                            "x_divider": 50,
                            "y_divider": 50,
                            "topLeft": ":survey_aa",
                            "topRight": ":survey_ab",
                            "bottomRight": ":survey_bb",
                            "bottomLeft": ":survey_ba",
                            "topLeftText": "Receiver",
                            "topRightText": "Pivot",
                            "bottomRightText": "Dispatcher",
                            "bottomLeftText": "Detached",
                            "font": "bold 24px Helvetica",
                            "textColor": ":chart_text"
                        },
                        "tooltip": {
                            "callbacks": {
                                "label": ":tooltip_label_addPercent_y_addPercent_x"
                            }
                        }
                    },
                    "responsive": true,
                    "scales": {
                        "x": {
                            "title": {
                                "display": true,
                                "text": "Outbound Degree"
                            },
                            "ticks": {
                                "maxTicksLimit": 11.0,
                                "callback": ":ticks_addPercent"
                            },
                            "suggestedMin": 0.0,
                            "suggestedMax": 100.0
                        },
                        "y": {
                            "title": {
                                "display": true,
                                "text": "Inbound Degree"
                            },
                            "ticks": {
                                "maxTicksLimit": 11.0,
                                "callback": ":ticks_addPercent"
                            },
                            "suggestedMin": 0.0,
                            "suggestedMax": 100.0
                        }
                    }
                },
                "type": "bubble",
                "package_id": "card.package.agile",
                "kpi_id": "kpi_003"
            }
        ]
    },
    {
        "card_key": "IT e Innov. Digitale|Sviluppo e Architetture",
        "complete_key": "IT e Innov. Digitale|Sviluppo e Architetture|card.package.agile|kpi_004",
        "package_id": "card.package.agile",
        "kpi_id": "kpi_004",
        "charts": [
            {
                "chart_id": "001",
                "chart_data": {
                    "labels": [
                        "01b...cb0",
                        "117...cae",
                        "18f...518",
                        "1f4...565",
                        "238...c39",
                        "2f0...f1f",
                        "42d...acc",
                        "432...f28",
                        "4e1...857",
                        "516...7f5",
                        "592...aec",
                        "5a4...ea7",
                        "659...573",
                        "676...bd2",
                        "6ec...118",
                        "718...760",
                        "73c...cea",
                        "853...ee9",
                        "878...27f",
                        "8c3...df4",
                        "994...ad9",
                        "a88...c37",
                        "abc...e02",
                        "b04...ed1",
                        "b08...0f4",
                        "b14...bd0",
                        "ce1...eea",
                        "d73...797",
                        "d9b...9dc",
                        "db8...ac8",
                        "ecd...881",
                        "ed6...ca9",
                        "ee6...16c",
                        "f0c...6e5",
                        "f4f...f6c",
                        "fc0...6cc"
                    ],
                    "datasets": [
                        {
                            "label": "Target",
                            "borderColor": ":target",
                            "backgroundColor": ":target",
                            "data": [
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0
                            ],
                            "type": "line",
                            "borderJoinStyle": "round",
                            "tension": 0.3,
                            "pointHitRadius": 25,
                            "pointRadius": 1
                        },
                        {
                            "label": "Closeness",
                            "borderColor": ":generic",
                            "backgroundColor": ":generic",
                            "data": [
                                1.69,
                                10.17,
                                13.56,
                                28.81,
                                5.08,
                                6.78,
                                3.39,
                                1.69,
                                1.69,
                                8.47,
                                5.08,
                                27.12,
                                1.69,
                                15.25,
                                22.03,
                                3.39,
                                5.08,
                                96.61,
                                3.39,
                                6.78,
                                3.39,
                                20.34,
                                1.69,
                                1.69,
                                1.69,
                                10.17,
                                100.00,
                                15.25,
                                3.39,
                                16.95,
                                6.78,
                                13.56,
                                15.25,
                                6.78,
                                5.08,
                                5.08
                            ],
                            "type": "bar"
                        }
                    ]
                },
                "chart_options": {
                    "plugins": {
                        "tooltip": {
                            "callbacks": {
                                "label": ":tooltip_label_addPercent_y"
                            }
                        }
                    },
                    "responsive": true,
                    "scales": {
                        "y": {
                            "suggestedMin": 0.0,
                            "ticks": {
                                "callback": ":ticks_addPercent"
                            },
                            "suggestedMax": 100.0
                        }
                    }
                },
                "type": "bar",
                "package_id": "card.package.agile",
                "kpi_id": "kpi_004"
            }
        ]
    },
    {
        "card_key": "IT e Innov. Digitale|Sviluppo e Architetture",
        "complete_key": "IT e Innov. Digitale|Sviluppo e Architetture|card.package.agile|kpi_005",
        "package_id": "card.package.agile",
        "kpi_id": "kpi_005",
        "charts": [
            {
                "chart_id": "001",
                "chart_data": {
                    "labels": [
                        "01b...cb0",
                        "117...cae",
                        "18f...518",
                        "1f4...565",
                        "238...c39",
                        "2f0...f1f",
                        "42d...acc",
                        "432...f28",
                        "4e1...857",
                        "516...7f5",
                        "592...aec",
                        "5a4...ea7",
                        "659...573",
                        "676...bd2",
                        "6ec...118",
                        "718...760",
                        "73c...cea",
                        "853...ee9",
                        "878...27f",
                        "8c3...df4",
                        "994...ad9",
                        "a88...c37",
                        "abc...e02",
                        "b04...ed1",
                        "b08...0f4",
                        "b14...bd0",
                        "ce1...eea",
                        "d73...797",
                        "d9b...9dc",
                        "db8...ac8",
                        "ecd...881",
                        "ed6...ca9",
                        "ee6...16c",
                        "f0c...6e5",
                        "f4f...f6c",
                        "fc0...6cc"
                    ],
                    "datasets": [
                        {
                            "label": "Target",
                            "borderColor": ":target",
                            "backgroundColor": ":target",
                            "data": [
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0,
                                25.0
                            ],
                            "type": "line",
                            "borderJoinStyle": "round",
                            "tension": 0.3,
                            "pointHitRadius": 25,
                            "pointRadius": 1
                        },
                        {
                            "label": "Betweness",
                            "borderColor": ":generic",
                            "backgroundColor": ":generic",
                            "data": [
                                3.51,
                                8.65,
                                31.56,
                                26.12,
                                15.68,
                                9.41,
                                10.01,
                                3.51,
                                2.39,
                                10.47,
                                31.08,
                                29.22,
                                3.51,
                                21.51,
                                51.43,
                                13.39,
                                7.08,
                                81.12,
                                5.21,
                                7.91,
                                13.90,
                                38.16,
                                3.51,
                                3.51,
                                3.51,
                                12.37,
                                100.00,
                                25.25,
                                7.39,
                                21.95,
                                8.42,
                                22.41,
                                10.02,
                                9.61,
                                3.18,
                                1.98
                            ],
                            "type": "bar"
                        }
                    ]
                },
                "chart_options": {
                    "plugins": {
                        "tooltip": {
                            "callbacks": {
                                "label": ":tooltip_label_addPercent_y"
                            }
                        }
                    },
                    "responsive": true,
                    "scales": {
                        "y": {
                            "suggestedMin": 0.0,
                            "ticks": {
                                "callback": ":ticks_addPercent"
                            },
                            "suggestedMax": 100.0
                        }
                    }
                },
                "type": "bar",
                "package_id": "card.package.agile",
                "kpi_id": "kpi_005"
            }
        ]
    },
    {
        "card_key": "IT e Innov. Digitale|Sviluppo e Architetture",
        "complete_key": "IT e Innov. Digitale|Sviluppo e Architetture|card.package.agile|kpi_006",
        "package_id": "card.package.agile",
        "kpi_id": "kpi_006",
        "charts": [
            {
                "chart_id": "001",
                "chart_data": {
                    "labels": [
                        "01b...cb0",
                        "117...cae",
                        "18f...518",
                        "1f4...565",
                        "238...c39",
                        "2f0...f1f",
                        "42d...acc",
                        "432...f28",
                        "4e1...857",
                        "516...7f5",
                        "592...aec",
                        "5a4...ea7",
                        "659...573",
                        "676...bd2",
                        "6ec...118",
                        "718...760",
                        "73c...cea",
                        "853...ee9",
                        "878...27f",
                        "8c3...df4",
                        "994...ad9",
                        "a88...c37",
                        "abc...e02",
                        "b04...ed1",
                        "b08...0f4",
                        "b14...bd0",
                        "ce1...eea",
                        "d73...797",
                        "d9b...9dc",
                        "db8...ac8",
                        "ecd...881",
                        "ed6...ca9",
                        "ee6...16c",
                        "f0c...6e5",
                        "f4f...f6c",
                        "fc0...6cc"
                    ],
                    "datasets": [
                        {
                            "label": "Target",
                            "borderColor": ":target",
                            "backgroundColor": ":target",
                            "data": [
                                15.0,
                                15.0,
                                15.0,
                                15.0,
                                15.0,
                                15.0,
                                15.0,
                                15.0,
                                15.0,
                                15.0,
                                15.0,
                                15.0,
                                15.0,
                                15.0,
                                15.0,
                                15.0,
                                15.0,
                                15.0,
                                15.0,
                                15.0,
                                15.0,
                                15.0,
                                15.0,
                                15.0,
                                15.0,
                                15.0,
                                15.0,
                                15.0,
                                15.0,
                                15.0,
                                15.0,
                                15.0,
                                15.0,
                                15.0,
                                15.0,
                                15.0
                            ],
                            "type": "line",
                            "borderJoinStyle": "round",
                            "tension": 0.3,
                            "pointHitRadius": 25,
                            "pointRadius": 1
                        },
                        {
                            "label": "Eigenvector",
                            "borderColor": ":generic",
                            "backgroundColor": ":generic",
                            "data": [
                                3.26,
                                8.65,
                                26.51,
                                25.33,
                                14.11,
                                8.56,
                                9.00,
                                3.51,
                                2.41,
                                11.09,
                                32.32,
                                32.14,
                                2.87,
                                23.66,
                                45.25,
                                12.98,
                                7.71,
                                100.00,
                                5.10,
                                7.83,
                                11.67,
                                33.96,
                                3.40,
                                3.05,
                                3.12,
                                13.60,
                                80.85,
                                20.70,
                                6.20,
                                23.04,
                                7.91,
                                18.15,
                                11.02,
                                8.45,
                                3.43,
                                1.82
                            ],
                            "type": "bar"
                        }
                    ]
                },
                "chart_options": {
                    "plugins": {
                        "tooltip": {
                            "callbacks": {
                                "label": ":tooltip_label_addPercent_y"
                            }
                        }
                    },
                    "responsive": true,
                    "scales": {
                        "y": {
                            "suggestedMin": 0.0,
                            "ticks": {
                                "callback": ":ticks_addPercent"
                            },
                            "suggestedMax": 100.0
                        }
                    }
                },
                "type": "bar",
                "package_id": "card.package.agile",
                "kpi_id": "kpi_006"
            }
        ]
    },
    {
        "card_key": "IT e Innov. Digitale|Sviluppo e Architetture",
        "complete_key": "IT e Innov. Digitale|Sviluppo e Architetture|card.package.agile|kpi_008",
        "package_id": "card.package.agile",
        "kpi_id": "kpi_008",
        "charts": [
            {
                "chart_id": "001",
                "chart_data": {
                    "datasets": [
                        {
                            "borderColor": ":generic",
                            "backgroundColor": ":generic",
                            "label": "Persone",
                            "type": "bubble",
                            "data": [
                                { "Person": "01bc86e78cd69cc938888e5d58529827da10d3afacc31a4e746b1b235c362cb0", "xLabel": "Relazioni con l'esterno", "yLabel": "Coinvolgimento colleghi", "x": 31, "y": 70, "r": 10 },
                                { "Person": "117484657fdfa49f6f202c4a01f5869cd80e1a3dd60c339d9145c67a9fb03cae", "xLabel": "Relazioni con l'esterno", "yLabel": "Coinvolgimento colleghi", "x": 25, "y": 58, "r": 10 },
                                { "Person": "18f8f073dd89fcf946d09157db5342889d19543390c8cd4c9dd8b05bc65fd518", "xLabel": "Relazioni con l'esterno", "yLabel": "Coinvolgimento colleghi", "x": 4 , "y": 25, "r": 10 },
                                { "Person": "1f4ef15b42d71da6eaa84c1666da8f3aae74ebcab35d1321b195f4e6e67b7565", "xLabel": "Relazioni con l'esterno", "yLabel": "Coinvolgimento colleghi", "x": 35, "y": 51, "r": 10 },
                                { "Person": "238ae5b17e2e2c57af472e3f8cfba32aed5cf2f12286424acd7971a63c794c39", "xLabel": "Relazioni con l'esterno", "yLabel": "Coinvolgimento colleghi", "x": 12, "y": 2 , "r": 10 },
                                { "Person": "2f02900e1dd4918db6a502619ac506938f4be868a26074349313ed3ab6713f1f", "xLabel": "Relazioni con l'esterno", "yLabel": "Coinvolgimento colleghi", "x": 3 , "y": 3 , "r": 10 },
                                { "Person": "42d3f609a82d7d42d4b1fbb92e1fb77c095d318ed908b236393aa914bdbd7acc", "xLabel": "Relazioni con l'esterno", "yLabel": "Coinvolgimento colleghi", "x": 38, "y": 81, "r": 10 },
                                { "Person": "432f4b6475380ae4688eba30a14f2c02c391d3690924ca8134b9d1620af15f28", "xLabel": "Relazioni con l'esterno", "yLabel": "Coinvolgimento colleghi", "x": 35, "y": 33, "r": 10 },
                                { "Person": "4e12e345bdb2e98fc1ce84b6b9724b164ab5570d3102f6cfd7759a367b58d857", "xLabel": "Relazioni con l'esterno", "yLabel": "Coinvolgimento colleghi", "x": 21, "y": 31, "r": 10 },
                                { "Person": "516c0937b94123241b706f185cbaf5039307313eaf7200e1e0ba64bc880c17f5", "xLabel": "Relazioni con l'esterno", "yLabel": "Coinvolgimento colleghi", "x": 15, "y": 9 , "r": 10 },
                                { "Person": "59294899f0e52923d6f08c1a86900c906187345c14c72a70fb51510e7fa00aec", "xLabel": "Relazioni con l'esterno", "yLabel": "Coinvolgimento colleghi", "x": 13, "y": 74, "r": 10 },
                                { "Person": "5a44504d34546036745dcb5deeddab47d39a00fb19734ca7145dcc5a9dda9ea7", "xLabel": "Relazioni con l'esterno", "yLabel": "Coinvolgimento colleghi", "x": 6 , "y": 21, "r": 10 },
                                { "Person": "659a5f5d597cd6834db5df465fcd21c9eaf134b14d21dbfdef9b2d0060133573", "xLabel": "Relazioni con l'esterno", "yLabel": "Coinvolgimento colleghi", "x": 41, "y": 12, "r": 10 },
                                { "Person": "67678ae13f18c6a16575a01c50d4528d22e5e3862d512414f3dd81590aecfbd2", "xLabel": "Relazioni con l'esterno", "yLabel": "Coinvolgimento colleghi", "x": 29, "y": 70, "r": 10 },
                                { "Person": "6ec9cfba69a90f24827db98d1a905f97613cd4cc592b0e6f0026b5aae81a0118", "xLabel": "Relazioni con l'esterno", "yLabel": "Coinvolgimento colleghi", "x": 68, "y": 83, "r": 10 },
                                { "Person": "71852c01834ad9f0e850f43c6418b83681f7acb82eb134a23d164ae3db8e7760", "xLabel": "Relazioni con l'esterno", "yLabel": "Coinvolgimento colleghi", "x": 12, "y": 16, "r": 10 },
                                { "Person": "73cbc0bd39e2e5b7ddabdcd8e3ce3e32df2057dd17cea502a971e71e705b0cea", "xLabel": "Relazioni con l'esterno", "yLabel": "Coinvolgimento colleghi", "x": 31, "y": 59, "r": 10 },
                                { "Person": "853ac84efec82736323b7c9a6cdcffe8ae2cf3da4bde5591d1568837b6c96ee9", "xLabel": "Relazioni con l'esterno", "yLabel": "Coinvolgimento colleghi", "x": 91, "y": 38, "r": 10 },
                                { "Person": "8787740c45c655c0511fa208a842b48fd6818c89e1f9a4390482faaa868a027f", "xLabel": "Relazioni con l'esterno", "yLabel": "Coinvolgimento colleghi", "x": 32, "y": 51, "r": 10 },
                                { "Person": "8c3e02eabd321bb7ae9ba21563338ba3df344ab68b381680bcc3a75a2a8ccdf4", "xLabel": "Relazioni con l'esterno", "yLabel": "Coinvolgimento colleghi", "x": 44, "y": 19, "r": 10 },
                                { "Person": "9949bc108015faaf4b49124f1164a7bc6d8db0c7ea9d32d634a4e89d74134ad9", "xLabel": "Relazioni con l'esterno", "yLabel": "Coinvolgimento colleghi", "x": 12, "y": 24, "r": 10 },
                                { "Person": "a88b39bfb012c98803e66f0b018c25d1ffa5766c21260b623d9ddff1b90edc37", "xLabel": "Relazioni con l'esterno", "yLabel": "Coinvolgimento colleghi", "x": 42, "y": 15, "r": 10 },
                                { "Person": "abc7401e3265278becd57c05a0f1675fbb4b94f39845020e6ddb03f448020e02", "xLabel": "Relazioni con l'esterno", "yLabel": "Coinvolgimento colleghi", "x": 31, "y": 51, "r": 10 },
                                { "Person": "b04e2eca334e777155ad38dcf329026d75656094a8665ff4448e4d1bba89eed1", "xLabel": "Relazioni con l'esterno", "yLabel": "Coinvolgimento colleghi", "x": 21, "y": 45, "r": 10 },
                                { "Person": "b080642b5854215716c65a72781e55050b41240865a8621530419a1348d810f4", "xLabel": "Relazioni con l'esterno", "yLabel": "Coinvolgimento colleghi", "x": 48, "y": 62, "r": 10 },
                                { "Person": "b14ceff609c278e1c1891d643b7aa9eb267e80ce5e050863aeeddee9bcb25bd0", "xLabel": "Relazioni con l'esterno", "yLabel": "Coinvolgimento colleghi", "x": 26, "y": 18, "r": 10 },
                                { "Person": "ce118f98f944cb4b7bf553a6c3361f5e5ffb39bc33050ac457ff5e68c3726eea", "xLabel": "Relazioni con l'esterno", "yLabel": "Coinvolgimento colleghi", "x": 78, "y": 42, "r": 10 },
                                { "Person": "d737b37cfaabf6ae06942149c5b4ee5d6f51bb83f96c22a97a02dd55aae26797", "xLabel": "Relazioni con l'esterno", "yLabel": "Coinvolgimento colleghi", "x": 9 , "y": 1 , "r": 10 },
                                { "Person": "d9b07283f475aeb6d05e04c9acb0f0bd609e9b5220b21f7a99a5c342a64139dc", "xLabel": "Relazioni con l'esterno", "yLabel": "Coinvolgimento colleghi", "x": 22, "y": 31, "r": 10 },
                                { "Person": "db8d910dfd966f6af426d67ef70da670fccc08932340c385b8d340151e7e7ac8", "xLabel": "Relazioni con l'esterno", "yLabel": "Coinvolgimento colleghi", "x": 14, "y": 18, "r": 10 },
                                { "Person": "ecd3496bb4ed76576e96edd522f6903474e2a27d352d654ae562fb4ad2b2d881", "xLabel": "Relazioni con l'esterno", "yLabel": "Coinvolgimento colleghi", "x": 44, "y": 36, "r": 10 },
                                { "Person": "ed67329cdf04b5a943a12c63cd602a6d7f06a61d433a960d8c5514f13ca40ca9", "xLabel": "Relazioni con l'esterno", "yLabel": "Coinvolgimento colleghi", "x": 29, "y": 13, "r": 10 },
                                { "Person": "ee64e69e3c67b6db4180164ace85772c427d49160d44da1a27032fe2be2cd16c", "xLabel": "Relazioni con l'esterno", "yLabel": "Coinvolgimento colleghi", "x": 19, "y": 38, "r": 10 },
                                { "Person": "f0c2bb337febad5eab9639010c46aa5a111eff6a15b323eec978c6aa946c36e5", "xLabel": "Relazioni con l'esterno", "yLabel": "Coinvolgimento colleghi", "x": 41, "y": 17, "r": 10 },
                                { "Person": "f4f2d477cc383f7885fc3724f0a204025e3ef04f34c7478a185beac0c595ff6c", "xLabel": "Relazioni con l'esterno", "yLabel": "Coinvolgimento colleghi", "x": 32, "y": 31, "r": 10 },
                                { "Person": "fc0122f6d05c2ec54028830dc458440a2ff21f5a1bd11b664fec0fc9d6b346cc", "xLabel": "Relazioni con l'esterno", "yLabel": "Coinvolgimento colleghi", "x": 35, "y": 21, "r": 10 }
                            ]
                        }
                    ]
                },
                "chart_options": {
                    "plugins": {
                        "quadrants": {
                            "x_divider": 50,
                            "y_divider": 50,
                            "topLeft": ":survey_aa",
                            "topRight": ":survey_ab",
                            "bottomRight": ":survey_bb",
                            "bottomLeft": ":survey_ba",
                            "topLeftText": "Team Builder",
                            "topRightText": "Leader",
                            "bottomRightText": "Accentratore",
                            "bottomLeftText": "Solitario",
                            "font": "bold 24px Helvetica",
                            "textColor": ":chart_text"
                        },
                        "tooltip": {
                            "callbacks": {
                                "label": ":tooltip_label_addPercent_y_addPercent_x"
                            }
                        }
                    },
                    "responsive": true,
                    "scales": {
                        "x": {
                            "title": {
                                "display": true,
                                "text": "Relazioni con l'esterno"
                            },
                            "ticks": {
                                "maxTicksLimit": 11.0,
                                "callback": ":ticks_addPercent"
                            },
                            "suggestedMin": 0.0,
                            "suggestedMax": 100.0
                        },
                        "y": {
                            "title": {
                                "display": true,
                                "text": "Coinvolgimento colleghi"
                            },
                            "ticks": {
                                "maxTicksLimit": 11.0,
                                "callback": ":ticks_addPercent"
                            },
                            "suggestedMin": 0.0,
                            "suggestedMax": 100.0
                        }
                    }
                },
                "type": "bubble",
                "package_id": "card.package.agile",
                "kpi_id": "kpi_008"
            }
        ]
    }
];