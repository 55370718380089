import { SingleMetricType } from "../../types";

/*MOD20221128: Rimuovere*/
export const metricsLic: SingleMetricType[] = [
    {
        "card_key": "IT e Innov. Digitale|Sviluppo e Architetture",
        "complete_key": "IT e Innov. Digitale|Sviluppo e Architetture|card.package.agile|kpi_001",
        "kpi_id": "kpi_001",
        "kpi_status": "on",
        "package_id": "card.package.agile",
        "polarity": -1,
        "score": 0.4,
        "trend_fe_format": "",
        "value_format": ":addPercent",
        "value": 36,
        "target_value": 25.0,
        "color": ":grey",
        "insights": [
            {
                "id": "insight_001.high.concentration",
                "replaceValues": {}
            },
            {
                "id": "insight_002.well.integrated",
                "replaceValues": {}
            },
            {
                "id": "insight_003.some.sparse",
                "replaceValues": {}
            }
        ]
    },
    {
        "card_key": "IT e Innov. Digitale|Sviluppo e Architetture",
        "complete_key": "IT e Innov. Digitale|Sviluppo e Architetture|card.package.agile|kpi_002",
        "kpi_id": "kpi_002",
        "kpi_status": "on",
        "package_id": "card.package.agile",
        "polarity": -1,
        "score": 0.4,
        "trend_fe_format": "",
        "value_format": "",
        "value": 7.0,
        "target_value": 25.0,
        "color": ":grey",
        "insights": [
            {
                "id": "insight_001.similar",
                "replaceValues": {}
            },
            {
                "id": "insight_002.interdependence",
                "replaceValues": {
                    "$$parents$$": "Analisi funzionali e Sistemi e Monitoring"
                }

            },
            {
                "id": "insight_003.high.cross",
                "replaceValues": {
                    "$$value$$": "21%"
                }
            }
        ]
    },
    {
        "card_key": "IT e Innov. Digitale|Sviluppo e Architetture",
        "complete_key": "IT e Innov. Digitale|Sviluppo e Architetture|card.package.agile|kpi_003",
        "kpi_id": "kpi_003",
        "kpi_status": "on",
        "package_id": "card.package.agile",
        "polarity": -1,
        "score": 0.4,
        "trend_fe_format": "",
        "value_format": "",
        "value": 3,
        "target_value": 25.0,
        "color": ":grey",
        "insights": [
            {
                "id": "insight_001.low.profile",
                "replaceValues": {
                    "$$value$$": "81%"
                }
            },
            {
                "id": "insight_002.unbalanced.internal",
                "replaceValues": {}
            },
            {
                "id": "insight_003.responsible",
                "replaceValues": {
                    "$$numerator$$": "2",
                    "$$denominator$$": "3"
                }
            }
        ]
    },
    {
        "card_key": "IT e Innov. Digitale|Sviluppo e Architetture",
        "complete_key": "IT e Innov. Digitale|Sviluppo e Architetture|card.package.agile|kpi_004",
        "kpi_id": "kpi_004",
        "kpi_status": "on",
        "package_id": "card.package.agile",
        "polarity": -1,
        "score": 0.4,
        "trend_fe_format": "",
        "value_format": ":addPercent",
        "value": 48,
        "target_value": 25.0,
        "color": ":grey",
        "insights": [
            {
                "id": "insight_001.centrality",
                "replaceValues": {
                    "$$value$$": "42%"
                }

            },
            {
                "id": "insight_002.low.centrality",
                "replaceValues": {
                    "$$numerator$$": "4",
                    "$$denominator$$": "44",
                    "$$threshold$$": "25%"
                }

            },
            {
                "id": "insight_003.low.over.average",
                "replaceValues":
                {
                    "$$value$$": "60%"
                }

            }
        ]
    },
    {
        "card_key": "IT e Innov. Digitale|Sviluppo e Architetture",
        "complete_key": "IT e Innov. Digitale|Sviluppo e Architetture|card.package.agile|kpi_005",
        "kpi_id": "kpi_005",
        "kpi_status": "on",
        "package_id": "card.package.agile",
        "polarity": -1,
        "score": 0.4,
        "trend_fe_format": "",
        "value_format": ":addPercent",
        "value": 16,
        "target_value": 25.0,
        "color": ":grey",
        "insights": [
            {
                "id": "insight_001.high.centrality",
                "replaceValues": {}
            },
            {
                "id": "insight_002.external.bridge",
                "replaceValues":
                {
                    "$$value$$": "3"
                }

            },
            {
                "id": "insight_003.low.cross",
                "replaceValues":
                {
                    "$$value$$": "79%"
                }

            }
        ]
    },
    {
        "card_key": "IT e Innov. Digitale|Sviluppo e Architetture",
        "complete_key": "IT e Innov. Digitale|Sviluppo e Architetture|card.package.agile|kpi_006",
        "kpi_id": "kpi_006",
        "kpi_status": "on",
        "package_id": "card.package.agile",
        "polarity": -1,
        "score": 0.4,
        "trend_fe_format": "",
        "value_format": ":addPercent",
        "value": 33,
        "target_value": 25.0,
        "color": ":grey",
        "insights": [
            {
                "id": "insight_001.average.influence",
                "replaceValues": {}
            },
            {
                "id": "insight_002.influence",
                "replaceValues":
                {
                    "$$value$$": "2"
                }

            },
            {
                "id": "insight_003.high.disconnection",
                "replaceValues": {}
            }
        ]
    },
    {
        "card_key": "IT e Innov. Digitale|Sviluppo e Architetture",
        "complete_key": "IT e Innov. Digitale|Sviluppo e Architetture|card.package.agile|kpi_008",
        "kpi_id": "kpi_008",
        "kpi_status": "on",
        "package_id": "card.package.agile",
        "polarity": -1,
        "score": 0.4,
        "trend_fe_format": "",
        "value_format": "",
        "value": 3.0,
        "target_value": 25.0,
        "color": ":grey",
        "insights": [
            {
                "id": "insight_001.internal.relation",
                "replaceValues": {}
            },
            {
                "id": "insight_002.no.responsible.leader",
                "replaceValues": {}
            },
            {
                "id": "insight_003.isolation",
                "replaceValues":
                {
                    "$$value$$": "31%"
                }

            }
        ]
    }
];
