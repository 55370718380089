type ResponseType = {
  bread0: string;
  bread1: { name: string; url?: string };
  bread2: { name: string; url?: string };
  bread3: { name: string; url?: string };
};

const pathIdentifier: (str: string) => ResponseType = (path: string) => {
  const pathArray: string[] = path.split("/");

  const responsetype: ResponseType = {
    bread0: "",
    bread1: { name: "", url: "" },
    bread2: { name: "", url: "" },
    bread3: { name: "", url: "" },
  };

  if (pathArray[1] === "settings") {
    responsetype.bread0 = "breadcrumbs.settings";
    responsetype.bread1 = {
      name: "breadcrumbs.settings.profile",
      url: "settings/profile",
    };
    return responsetype;
  }

  if (pathArray[1] === "connectors") {
    responsetype.bread0 = "breadcrumbs.connectors";
    responsetype.bread1 = pathArray[2]
      ? { name: decodeURIComponent("breadcrumbs.connectors." + (pathArray[2] === 'list' ? 'list' : 'add')), url: pathArray[1]+"/"+pathArray[2] }
      : responsetype.bread1;
    return responsetype;
  }

  if (pathArray[1] === "departments" || pathArray[1] ===  "company") {
    
    responsetype.bread0 = "breadcrumbs.analyze";
    responsetype.bread1 = {
      name: "breadcrumbs.analyze.yourcompany",
      url: pathArray[1],
    };
    responsetype.bread2 = pathArray[2]
      ? { name: decodeURIComponent(pathArray[2]), url: pathArray[2] }
      : responsetype.bread2;
    responsetype.bread3 = pathArray[3]
      ? { name: decodeURIComponent(pathArray[3]), url: pathArray[3] }
      : responsetype.bread3;

    return responsetype;
  }

  if (pathArray[1] === "initiatives") {
    
    responsetype.bread0 = "breadcrumbs.monitoring";
    responsetype.bread1 = {
      name: "breadcrumbs.monitoring.initiatives",
      url: pathArray[1],
    };
    responsetype.bread2 = pathArray[2]
      ? { name: decodeURIComponent(pathArray[2]), url: pathArray[2] }
      : responsetype.bread2;

    return responsetype;
  }

  if (pathArray[1] === "configure") {
    responsetype.bread0 = "breadcrumbs.configure";
    responsetype.bread1 = {
      name:
        pathArray[2] === "hr-database"
          ? "breadcrumbs.hrdb"
          : pathArray[2] === "users"
          ? "breadcrumbs.users"
          : "breadcrumbs.target",
      url: pathArray[1] + "/" + pathArray[2],
    };

    return responsetype;
  }

  if (pathArray[1] === "updates") {
    responsetype.bread0 = "breadcrumbs.updates";
    responsetype.bread1 = {
      name: pathArray[2] === "docs"
          ? "breadcrumbs.docs"
          : pathArray[2] === "alerts"
          ? "breadcrumbs.alerts"
          : "breadcrumbs.faq",
      url: pathArray[1] + "/" + pathArray[2],
    };

    return responsetype;
  }

  return responsetype;
};

export default pathIdentifier;
