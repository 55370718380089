import Heading from "../../components/Heading"

import { Box, Typography } from '@mui/material';
import UserCard from "../../components/configure/UserCard";
import { useTranslation } from "react-i18next";
import { useAuthenticator } from "@aws-amplify/ui-react";

const PageUsersConf = () => {
  const { t } = useTranslation();
  
  const { user } = useAuthenticator((context) => [context.user]);
  



  return (
    <Box className="container">
      <Heading title="users.title"/>
      <Box className="flexTop wrap">
        <Box  className="flex wrap-column marginTop1">
          <Typography variant="h4" fontWeight={"bold"} className="h4 width100">
            {t("users.header.profile")}
          </Typography>
          <UserCard classCss="profile" role="admin" main={true} user = {user}/>
        </Box>
      </Box>
    </Box>
  );
 
}

export default PageUsersConf;