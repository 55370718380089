import { Box, SvgIcon, TableCell, TableRow, Typography } from "@mui/material";
import SeverityBadge, { SEVERITY_BADGE_TYPES, SEVERITY_BADGE_COLORS } from "../SeverityBadge/SeverityBadge";
import {InitiativeDetailType} from "../../types";
import { useTranslation } from "react-i18next";
import { FormatType, getFormat } from "../charts/chartFormatting";

type PropsType={
  infoRow: InitiativeDetailType;
}

const InitiativeTableRow = ({infoRow}:PropsType) => {
  const {t} = useTranslation();
  const myFormat = getFormat(infoRow.value_format as keyof FormatType);
  
  const getArrowColor = (severity: string):string => {
    switch (severity) {
      case SEVERITY_BADGE_COLORS.critic:
        return "#CC1A00";
      case SEVERITY_BADGE_COLORS.risk:
        return "#EB9B00";
      case SEVERITY_BADGE_COLORS.target:
        return "#2DB517";
      default:
        return "rgb(217,218,220)";
    }
  }

  const getIcon = (trend:number):string =>{
    switch (trend) {
      case 1:
        return "M9.5 4.5V6.5H16.09L4.5 18.09L5.91 19.5L17.5 7.91V14.5H19.5V4.5H9.5Z";
      case -1:
        return "M19.5 9.5H17.5V16.09L5.91 4.5L4.5 5.91L16.09 17.5H9.5V19.5H19.5V9.5Z";
      default:
        return "M15 5L13.59 6.41L18.17 11H2V13H18.17L13.58 17.59L15 19L22 12L15 5Z";
      
    }
  }

  return (
    <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
      {
        infoRow.card_key.split('|').map((value, index) => 
          <TableCell key={value + index} component="th" scope="row" align="center">
            <Typography>{value}</Typography>
          </TableCell>
        )
      }
      <TableCell component="th" scope="row" align="center">
        <Typography>{t(infoRow.package_name)}</Typography>
      </TableCell>
      <TableCell component="th" scope="row" align="center">
        <Typography>{t(infoRow.package_name + "." + infoRow.kpi_id + ".title")}</Typography>
      </TableCell>
      <TableCell align="center">
        <Box sx={{ margin: "auto", display: "flex", placeItems: "center", alignItems: "center", justifyContent: "center" }} >
          <SeverityBadge type={SEVERITY_BADGE_TYPES.initiative} severity={infoRow.color}/>
          <Typography className="marginLeft">{myFormat(infoRow.curr_value)}</Typography>
        </Box>
      </TableCell>
      <TableCell align="center">
        <SvgIcon className="width1_2" >
          <path d={getIcon(infoRow.variation_value)} fill={getArrowColor(infoRow.variation_color)}/>
        </SvgIcon>
        <Typography sx={{fontSize: "x-small", color: getArrowColor(infoRow.variation_color)}}>{Math.floor(infoRow.variation_delta)}%</Typography>
      </TableCell>
    </TableRow>
  );
};

export default InitiativeTableRow;