import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "../App";
import PageMetric from "../pages/analyze/PageMetric";
import PageOffice from "../pages/analyze/PageOffice";
import PageInitiativeDetail from "../pages/monitor/PageInitiativeDetail";
import PageInitiatives from "../pages/monitor/PageInitiatives";
import PageSecurity from "../pages/settings/PageSecurity";
import PageSettings from "../pages/settings/PageSettings";
import PageUpdates from "../pages/settings/PageUpdates";
import PageUser from "../pages/settings/PageUser";

import PageConnectorAdd from "../pages/connectors/PageConnectorAdd";
import PageConnectorsList from "../pages/connectors/PageConnectorsList";

import PageTree from "../pages/analyze/PageTree";
import PageHRDBConf from "../pages/configure/PageHRDBConf";
import PageTargetConf from "../pages/configure/PageTargetConf";
import PageUsersConf from "../pages/configure/PageUsersConf";
import PageNotFound from "../pages/PageNotFound";
import PageUnAuth from "../pages/PageUnAuth";
import PageAlertsUpdate from "../pages/update/PageAlertsUpdate";
import PageDocsUpdate from "../pages/update/PageDocsUpdate";
import PageFaqUpdate from "../pages/update/PageFaqUpdate";

const Routing = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="/company" element={<PageTree />} />

          <Route path="/company/packages" element={<PageOffice />}>
            <Route path=":metric" element={<PageMetric />} />
          </Route>

          <Route path="/initiatives" element={<PageInitiatives />} />
          <Route
            path="/initiatives/:initiativeId"
            element={<PageInitiativeDetail />}
          />

          <Route path="/connectors/list" element={<PageConnectorsList />} />
          <Route path="/connectors/:connector" element={<PageConnectorAdd />} />

          <Route path="/configure/users" element={<PageUsersConf />} />
          <Route path="/configure/hr-database" element={<PageHRDBConf />} />
          <Route path="/configure/target" element={<PageTargetConf />} />

          <Route path="/updates/docs" element={<PageDocsUpdate />} />
          <Route path="/updates/faq" element={<PageFaqUpdate />} />
          <Route path="/updates/alerts" element={<PageAlertsUpdate />} />

          <Route path="/settings" element={<PageSettings />}>
            <Route path="profile" element={<PageUser />} />
            <Route path="security" element={<PageSecurity />} />
            <Route path="updates" element={<PageUpdates />} />
          </Route>
        </Route>

        <Route path="/unauth" element={<PageUnAuth />} />

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Routing;
