import { Typography } from "@mui/material";
import Box from "@mui/material/Box";

import { CardTypeF } from "../../../types";
import SeverityBadge from "../../SeverityBadge/SeverityBadge";

type Props = {
  cardInfo: CardTypeF;
};

const CardMetricsGraph = ({ cardInfo }: Props) => {
  

  return (
   
     
  
      <Box className="flex" sx={metricsContainer}>
        <Box className="flex" sx={{ gap: ".2rem" }}>
          <SeverityBadge severity=":red" type="l2-metric" />
          <Typography variant="h2" fontWeight={"bold"}>
            {cardInfo.red_counter}
          </Typography>
        </Box>
        <Box className="flex" sx={{ gap: ".2rem" }}>
          <SeverityBadge severity=":yellow" type="l2-metric" />
          <Typography variant="h2" fontWeight={"bold"}>
            {cardInfo.yellow_counter}
          </Typography>
        </Box>
        <Box className="flex" sx={{ gap: ".2rem" }}>
          <SeverityBadge severity=":green" type="l2-metric" />
          <Typography variant="h2" fontWeight={"bold"}>
            {cardInfo.green_counter}
          </Typography>
        </Box>
      </Box>
    
  );
};

export default CardMetricsGraph;

const metricsContainer = {
  paddingTop: ".5rem",
  width: "100%",
  justifyContent: "space-around",
};
