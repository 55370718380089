import { Box, Modal, SvgIcon, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { userConf } from "../../styles/styles";
import { userCard } from "../../styles/styles";
import Account from "../Account";
import { useState } from "react";
import ChangePassword from "./ChangePassword";

type PropsType = {
  classCss: string;
  role: string;
  main?: boolean;
  user: any;
};

type RoleType = {
  roleText: string;
  path: string;
  
};

const UserCard = ({ classCss, role, main, user }: PropsType) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const getLabel = (role: string): RoleType => {
    let roleText: string = "";
    let path: string = "";
    if (role === "admin") {
      roleText = "users.card.access.role.admin";
      path =
        "M12.51 9.99C12.51 8.34 11.16 6.99 9.51 6.99C7.86 6.99 6.51 8.34 6.51 9.99C6.51 11.64 7.86 12.99 9.51 12.99C11.16 12.99 12.51 11.64 12.51 9.99ZM9.51 10.99C8.96 10.99 8.51 10.54 8.51 9.99C8.51 9.44 8.96 8.99 9.51 8.99C10.06 8.99 10.51 9.44 10.51 9.99C10.51 10.54 10.06 10.99 9.51 10.99ZM16.01 12.99C17.12 12.99 18.01 12.1 18.01 10.99C18.01 9.88 17.12 8.99 16.01 8.99C14.9 8.99 14 9.88 14.01 10.99C14.01 12.1 14.9 12.99 16.01 12.99ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM5.85 17.11C6.53 16.57 8.12 16 9.51 16C9.58 16 9.66 16.01 9.74 16.01C9.98 15.37 10.41 14.72 11.04 14.15C10.48 14.05 9.95 13.99 9.51 13.99C8.21 13.99 6.12 14.44 4.78 15.42C4.28 14.38 4 13.22 4 11.99C4 7.58 7.59 3.99 12 3.99C16.41 3.99 20 7.58 20 11.99C20 13.19 19.73 14.33 19.25 15.36C18.25 14.77 16.89 14.49 16.01 14.49C14.49 14.49 11.51 15.3 11.51 17.19V19.97C9.24 19.84 7.22 18.76 5.85 17.11Z";
    } else {
      roleText = "users.card.access.role.viewer";
      path =
        "M12 6.5C15.79 6.5 19.17 8.63 20.82 12C19.17 15.37 15.79 17.5 12 17.5C8.21 17.5 4.83 15.37 3.18 12C4.83 8.63 8.21 6.5 12 6.5ZM12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 9.5C13.38 9.5 14.5 10.62 14.5 12C14.5 13.38 13.38 14.5 12 14.5C10.62 14.5 9.5 13.38 9.5 12C9.5 10.62 10.62 9.5 12 9.5ZM12 7.5C9.52 7.5 7.5 9.52 7.5 12C7.5 14.48 9.52 16.5 12 16.5C14.48 16.5 16.5 14.48 16.5 12C16.5 9.52 14.48 7.5 12 7.5Z";
    }
    return { roleText, path };
  };

  const getBlueprint = (usertype:string) => {
    let styleUser: any = {};
    usertype === "profile" ? styleUser = userConf.blueprintProfile : styleUser = userConf.bluprintOther;
    return styleUser;
  }

  const { t } = useTranslation();

  const { roleText, path } = getLabel(role);

  const styleUser = getBlueprint(classCss);



  return (
    <Box sx={styleUser}>
      <Box>
        <Box className="flex">
          <Box className="flex">
            <Account />
            <Box className="margin-right-1">
              <Typography variant="h6" fontWeight={"bold"}>
                {user?.attributes.name}
              </Typography>
              <Typography variant="h6" fontWeight={"bold"}>
                {user["attributes"]["custom:company"]}
              </Typography>
            </Box>

            <SvgIcon
              className="margin-right-1"
              sx={userCard.editIcon}
            >
              <path d="M14.0588 9.02L14.9788 9.94L5.91878 19H4.99878V18.08L14.0588 9.02ZM17.6588 3C17.4088 3 17.1488 3.1 16.9588 3.29L15.1288 5.12L18.8788 8.87L20.7088 7.04C21.0988 6.65 21.0988 6.02 20.7088 5.63L18.3688 3.29C18.1688 3.09 17.9188 3 17.6588 3ZM14.0588 6.19L2.99878 17.25V21H6.74878L17.8088 9.94L14.0588 6.19Z" />
            </SvgIcon>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={styleModal}>
                <ChangePassword handleClose={handleClose} />
              </Box>
            </Modal>
          </Box>
          <Box sx={userCard.labelCategory}>
            <SvgIcon sx={userCard.labelIcon}>
              <path d={path} />
            </SvgIcon>
            <Typography variant="body2" sx={userCard.labelText}>
              {t(roleText)}
            </Typography>
          </Box>
        </Box>
        <Box className="flex-left">
          <Box sx={userCard.body}>
            <SvgIcon sx={userCard.bodyIcon}>
              <path d="M12 7V3H2V21H22V7H12ZM10 19H4V17H10V19ZM10 15H4V13H10V15ZM10 11H4V9H10V11ZM10 7H4V5H10V7ZM20 19H12V9H20V19ZM18 11H14V13H18V11ZM18 15H14V17H18V15Z" />
            </SvgIcon>
            <Typography variant="body2" className=" h6" sx={userCard.labelText}>
              {t("users.card.department")} Human resources
            </Typography>
          </Box>
          <Box sx={userCard.body}>
            <SvgIcon sx={userCard.bodyIcon}>
              <path d="M11 7L9.6 8.4L12.2 11H2V13H12.2L9.6 15.6L11 17L16 12L11 7ZM20 19H12V21H20C21.1 21 22 20.1 22 19V5C22 3.9 21.1 3 20 3H12V5H20V19Z" />
            </SvgIcon>
            <Typography variant="body2" className="h6" sx={userCard.labelText}>
              {t("users.card.access")}:{" "}
              <span className="bold">1</span>
            </Typography>
          </Box>
        </Box>

        {/*
        <Box className="user-card-button">
          {main ? (
            <>
              <Button className="user-card-button-details" onClick={()=>{nav("/settings/profile")}}>
                {t("configure.users.card.button.details")}
              </Button>
            </>
          ) : (
            <>
              <Button className="user-card-button-profile">
                {t("configure.users.card.button.profile")}
              </Button>
            </>
          )}
        </Box>*/}
      </Box>
    </Box>
  );
};

export default UserCard;

const styleModal = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: '25px',
  boxShadow: 24,
  p: 4,
};