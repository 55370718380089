import { BreadContextType } from "../../../types";


export const initBread:BreadContextType = 
{
  root:[{
    url:"/company",
    name:"breadcrumbs.analyze.yourcompany"
  }],
  crumbs: [],

}