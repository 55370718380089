import { InitiativeType } from '../../../types';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Slice con action e reducers.
const initiativeDetailSlice = createSlice({
  name: "initiativeDetail", // Nome della slice
  initialState: null as InitiativeType | null, // Valore iniziale vuoto
  // Reducer con le azioni che si possono fare sulla slice (in questo caso solo una)
  reducers: {
    updateInitDetail(state, action: PayloadAction<InitiativeType>) {      
      return action.payload;
    }
  },
});

// Action generate che verrano utilizzate per aggiornare lo store.
export const { updateInitDetail } = initiativeDetailSlice.actions;

export default initiativeDetailSlice.reducer;