import {
  Box, Input,
  Stack, Typography
} from "@mui/material";


import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

type Props = {
  kpiOn: boolean;
  control: any;
  name: string;
};

const TargetInputControl = ({ kpiOn, control, name }: Props) => {

  const {t} = useTranslation()

  return (
    <Stack
      sx={{
        marginTop: "2rem",
        display: "flex",
        alignItems: "center",
      }}
      spacing={3}
    >
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >

        <Typography
          id="input-slider"
          gutterBottom
          sx={{ color: kpiOn ? "black" : "grey" }}
        >
         { t("target.kpi.select.new")}
        </Typography>
        <Controller
          name={name}
          control={control}
          render={({
            field: { onChange, value },
            fieldState: { error },
            formState,
          }) => (
            <>
              <Input
                size="small"
                disabled={!kpiOn}
                value={value}
                error={error ? true : false}
                onChange={onChange}
                name={"target_input"}
                inputProps={{
                  step: 1,
                  min: 0,
                  type: "number",
                }}
              />
              <>
                {error && (
                  <Typography
                    color={"error"}
                    sx={{ maxWidth: "150px", textAlign: "center" }}
                  >
                    {error.message}
                  </Typography>
                )}
              </>
            </>
          )}
        />
      </Box>

      <Box sx={{ "& > button": { m: 1 } }}></Box>
    </Stack>
  );
};

export default TargetInputControl;
