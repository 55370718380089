import { useAuthenticator } from "@aws-amplify/ui-react";
import { Box } from "@mui/material";
import PageNav from "../../components/pagenav/PageNav";
import SimpleLoading from "../../components/SimpleLoading";
import FaqAccordion from "../../components/update/FaqAccordion";
import FaqContactBox from "../../components/update/FaqContactBox";
import { useFaq } from "../../hooks/index";
import { pageFaq } from "../../styles/styles";

const PageFaqUpdate = () => {
  const { user } = useAuthenticator((context) => [context.user]);

  const { faqSearch, handleSearchParam, loaded } = useFaq(
    user.getSignInUserSession()?.getIdToken().payload.user_signature,
    user.getSignInUserSession()?.getIdToken().getJwtToken() || ""
  );

  return (
    <>
      {" "}
      {Array.isArray(faqSearch) && (
        <Box className="container">
          <PageNav
            title="faq.title"
            length={0}
            handleSearchParam={handleSearchParam}
          >
            <></>
          </PageNav>
          <Box sx={pageFaq.body}>
            <Box sx={pageFaq.accordion}>
              {loaded ? (
                <>
                  {faqSearch.length > 0 &&
                    faqSearch.map((faq, index) => (
                      <FaqAccordion key={index} faq={faq} />
                    ))}
                </>
              ) : (
                <SimpleLoading />
              )}
            </Box>
            <Box sx={pageFaq.contacts}>
              <FaqContactBox />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default PageFaqUpdate;
