import { LoadingButton } from "@mui/lab";
import { Button, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import {v4 as uuid} from 'uuid'

type ButtonType = {
  label: string;
  onClick: any;
  disabled?:boolean;
  type?:string;
  isLoading?:boolean;
};

type PropsType = {
  activeStep: number;
  info: { buttons: ButtonType[] }[];
};

const ButtonWizard = ({ activeStep, info }: PropsType) => {
  const {t} = useTranslation();
  
  return (
    <Box
      className="flex"
      sx={{
        flexDirection: "row-reverse",
        width: "100%",
        marginBottom:"3rem"
      }}
    >
      {info[activeStep].buttons.map((button, index) => {
        if (button.label !== "") {
          switch (button.type) {
            case "loadingButton":
              return (
                <LoadingButton
                  variant="contained"
                  color="success"
                  loading={button.isLoading || false}
                  loadingIndicator={
                    <CircularProgress size={26} sx={{ color: "white" }} />
                  }
                  sx={{
                    fontSize: "1.2rem",
                    padding: "0.5rem 1rem",
                    borderRadius: "20px",
                    textTransform: "none",
                    color: "white ",
                    fontWeight: "bold",
                    backgroundColor: !button.disabled ?   "#00bfa5" : "lightgrey"
                  }}
                  key={uuid()}
                  onClick={button.onClick}
                  disabled={button.disabled}
                >
                  {t(button.label)}
                </LoadingButton>
              );
            default:
              return (
                <Button
                  variant="contained"
                  color="success"
                  sx={{
                    fontSize: "1.2rem",
                    padding: "0.5rem 1rem",
                    borderRadius: "20px",
                    textTransform: "none",
                    color: "white",
                    fontWeight: "bold",
                  }}
                  key={uuid()}
                  onClick={button.onClick}
                  disabled={button.disabled}
                >
                  {t(button.label)}
                </Button>
              );
          }
        }
        else {
          return <Box key={uuid()}></Box>
        }
      })}
    </Box>
  );
};

export default ButtonWizard;






