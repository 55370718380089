import { Box, Button,  Popover, Typography } from "@mui/material";
import { MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { initiativeStyle } from "../../styles/styles";
import { SuggestedInitiativesType } from "../../types";



type Props = {
  init: SuggestedInitiativesType;
};

const SuggestedInitiativePopOver = ({ init }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);


  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const { t } = useTranslation();

  return (
    <>
      <Button variant="text" onClick={handleClick}>
        <Box sx={initiativeStyle.suggested.popover.buttonContainer}>
          <Typography variant="subtitle2" sx={initiativeStyle.suggested.popover.buttonText}>
            {t("initiatives.suggested.card.button.down")}
          </Typography>
          <ArrowDropDownIcon sx={initiativeStyle.suggested.popover.buttonDown} />
        </Box>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        PaperProps={{
          sx: {
            bgcolor: "ts.card.chip",
            borderRadius: "12px",
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
            padding: ".8rem",
            width: 370,
          },
        }}
      >
        <Typography sx={initiativeStyle.suggested.popover.text} variant="body2" component="div">
          {init.description}
        </Typography>
        <Typography variant="subtitle2" sx={initiativeStyle.suggested.popover.buttonText}>
          {t("initiatives.initiativedetail.metrics")}
        </Typography>
        {init.metrics.map((metric) =>
          <Box className="flexGap05" key={"box" + metric}>
            <Typography variant="body2" key={metric}>
              {"- " + t(metric.replaceAll("|",".") + ".title")}
            </Typography>
          </Box>
        )}
        <Typography variant="subtitle2" sx={initiativeStyle.suggested.popover.buttonText}>
          {t("initiatives.initiativedetail.info.scope")}
        </Typography>
        {init.scope.map((ini) =>
          <Box className="flexGap05" key={"box" + ini}>
            <Typography variant="body2" key={ini}>
              {"- " + ini.split("|")[ini.split("|").length-1]}
            </Typography>
          </Box>
        )}
      </Popover>
    </>
  );
};

export default SuggestedInitiativePopOver;

