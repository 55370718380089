import { initSelectedKpi } from './initSelectedKpi';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectedKpiSliceType } from '../../../types';

// Slice con action e reducers.
const selectedKpiSlice = createSlice({
    name: "search", // Nome della slice
    initialState: initSelectedKpi, // Valore iniziale vuoto
    // Reducer con le azioni che si possono fare sulla slice (in questo caso solo una)
    reducers: {
      updateSelectedKpi(state, action: PayloadAction<SelectedKpiSliceType>) {      
        return  action.payload;
      }
    },
  });
  
  // Action generate che verrano utilizzate per aggiornare lo store.
  export const { updateSelectedKpi } = selectedKpiSlice.actions;
  
  export default selectedKpiSlice.reducer;
  