import { useAuthenticator } from "@aws-amplify/ui-react";
import { Box } from "@mui/material";
import PageNav from "../../components/pagenav/PageNav";
import SimpleLoading from "../../components/SimpleLoading";
import AlertGridHeader from "../../components/update/AlertGridHeader";
import AlertGridRow from "../../components/update/AlertGridRow";
import { useAlerts } from "../../hooks/index";
import "../../styles/global.css";

const PageAlertsUpdate = () => {
  const { user } = useAuthenticator((context) => [context.user]);

  const { alertSearch, handleSearchParam, loaded } = useAlerts(user.getSignInUserSession()?.getIdToken().payload.user_signature, user.getSignInUserSession()?.getIdToken().getJwtToken() || "", "");

  return (
    <Box className="container">
      <PageNav
        title="alerts.title"
        length={alertSearch.length}
        handleSearchParam={handleSearchParam}
      >
        {/*<DocumentSort />*/}
      </PageNav>
      <Box className="flex100">
        {
          loaded? 
          <>
        <AlertGridHeader />
        {alertSearch.map((alert) => (
          <AlertGridRow key={alert.alert_key} alert={alert} />
        ))}
        </>
        :
        <SimpleLoading />
      }
      </Box>
    </Box>
  );
};

export default PageAlertsUpdate;
