import { Alert, Button, Stack, SvgIcon } from "@mui/material";
import { useTranslation } from "react-i18next";
import { dragDropStyle } from "../../../styles/styles";
import TableHR from "../../configure/TableHR";

type Props = {
  rows?: (
    | string
    | { value: number | string; status: string; message: string }
  )[][];
  columns?: string[];
  status: string;
  k:number;
  handleClose:()=>void;
};



const StepKCheck = ({ rows, columns, status,k , handleClose }: Props) => {

  const {t} = useTranslation();

  return (
    <Stack sx={{minWidth: "70%", display:"flex", alignItems:"center", marginTop: "2rem" }}>
        {
        status === "error" && 
        <Alert
                  severity="error"
                  sx={{ ...dragDropStyle.alert.warning,
                    
                    marginBottom:"1rem"
                  
                  }}
                  icon={
                    <SvgIcon sx={{padding:0}}>
                      <path
                        d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
                        fill="#ef5350"
                      />
                    </SvgIcon>
                  }
                >{t("hrdb.modal.step.k_check.error.before") + k + t("hrdb.modal.step.k_check.error.after")}</Alert>
      }
      <TableHR rows={rows} columns={columns} />
      {status === "error" && (
        <Button
          size="small"
          onClick={handleClose}
          variant="outlined"
          sx={dragDropStyle.org.button}
        >
         {t("hrdb.modal.button.retry")}
        </Button>)}
    </Stack>
  );
};

export default StepKCheck;
