import { Box, Typography } from "@mui/material"


import { useTranslation } from 'react-i18next';
import PhoneIcon from '@mui/icons-material/Phone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { faqContactBox } from "../../styles/styles";



const FaqContactBox = () => {
    const {t}  = useTranslation();
  
  return (
    
    <>
    <Typography variant="h6" sx={faqContactBox.contactsTitle}>
        {t("faq.contacts.title")}
    </Typography>
    <Box sx={faqContactBox.row}>
        <PhoneIcon  sx={faqContactBox.rowIcon}/>
        <Typography variant="h6" sx={faqContactBox.rowText}>
        {t("faq.contacts.phone")}: (+39) 320-1917805
    </Typography>
    </Box>
    <Box sx={faqContactBox.row}>
        <MailOutlineIcon sx={faqContactBox.rowIcon}/>
        <Typography variant="h6" sx={faqContactBox.rowText}>
        {t("faq.contacts.email")}: support@teamsight.net
    </Typography>
    </Box>
    </>
   
  )
}

export default FaqContactBox;