import { useAuthenticator } from "@aws-amplify/ui-react";
import { Stack } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import Header from "../../components/analyze/company-l3-metrics/Header";

import MetricsLicenseTabs from "../../components/analyze/company-l3-metrics/MetricsLicenseTabs";
import Loading from "../../components/Loading";

import { useTitle } from "../../hooks/useTitle";

const PageOffice = () => {
  const { user } = useAuthenticator((context) => [context.user]);
  const [searchParams] = useSearchParams();
  const cardKey: string | undefined =
    searchParams.get("id") !== null
      ? (searchParams.get("id") as string)
      : undefined;
      
  const { titleCard, loading } = useTitle(
    user.getSignInUserSession()?.getIdToken().payload.user_signature,
    user.getSignInUserSession()?.getIdToken().getJwtToken() || "",
    "card",
    "card",
    cardKey
  );

 /* const { singleMetricCard } = useCards(
    searchParams.get("id") || "",
    undefined,
    "metric"
  );*/

  return (
    <Stack spacing={2}>
      { loading? 
      <Loading />
      :
      <Header officeName={titleCard?.card_key.includes("|")? titleCard?.card_key.slice(titleCard?.card_key.lastIndexOf('|') + 1) : titleCard?.card_key || ""} severity={titleCard?.color || ":grey"} />
}
      <MetricsLicenseTabs
        metricsSev={{
          critic: titleCard?.red_counter || 0,
          risk: titleCard?.yellow_counter || 0,
          target: titleCard?.green_counter || 0,
        }}

       
      />
    </Stack>
  );
};

export default PageOffice;
