import { CardTypeF } from './../types';
import axios from "axios";


//const apiUrl: string = "https://wi4rtbm7mk.execute-api.eu-west-1.amazonaws.com/my-iris-cards";
const apiUrl:string = process.env.REACT_APP_API_URL_ROOT +"/cards"

const fetchCards = async (user_signature: string, auth:string, scope:string, levInfo:string, id?:string): Promise<CardTypeF[] | CardTypeF> => {


   try {
    const response = await axios.get(apiUrl, {
        params: id? {
          user_signature: user_signature,
          scope:scope,
          level_info:levInfo,
          complete_key:  id
        } : 
        {
          user_signature: user_signature,
          scope:scope,
          level_info:levInfo
        },
        headers: {'Authorization': auth},
      });

     if (response === undefined) throw new Error("Response is undefined");
      
     const result:CardTypeF[] = response.data;
     return result;
   } catch (error: any) {
     if (axios.isAxiosError(error)) {
       if (error.response?.status === 500){
       console.log(error)
        throw new Error("Internal server error")}
       if (error.response?.status === 404){
        console.log(error)
         throw new Error("The resource was not found");}
    }
    console.log(error)
     throw error;
  }

};

export default fetchCards;
