import { Outlet } from "react-router-dom";

type Props = {};

const PageSettings = (props: Props) => {
  return (
    <>
      <div>PageSettings</div>
      <Outlet />
    </>
  );
};

export default PageSettings;
