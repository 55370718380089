import { useCallback, useEffect, useState } from "react";
import { InitiativeType, InitiativeDetailTableType, SuggestedInitiativesType } from "../types";
import { fetchInitiatives, fetchInitiativeDetails } from "../api/fetchInitiatives";
import { mockInitiativesSuggested } from "../mocks/initiatives/mockup_initiative_suggested";
import { useAppSelector } from "./reduxHooks";

export const useInitiatives = (user_signature: string, auth: string, levelInfo: string, initiative_key?: string, complete_key?: string, involved?: boolean) => {
  const [initiatives, setInitiatives] = useState<InitiativeType[]>([]);
  const [initiativesSearch, setInitiativesSearch] = useState<InitiativeType[]>([]);
  const [searchParam, setSearchParam] = useState<string>("");
  const [onError, setOnError] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);

  const storedInitiative: InitiativeType | null = useAppSelector(
    (state) => state.initiativeDetailSlice
  );

  const handleSearchParam = (param: string) => {
    setSearchParam(param);
  };

  const handleSearch = useCallback(() => {
    setInitiativesSearch(
      initiatives.filter((init) =>
        init.title.toLocaleLowerCase().includes(searchParam.toLowerCase()) ||
        init.description.toLocaleLowerCase().includes(searchParam.toLowerCase()) ||
        init.owner.toLocaleLowerCase().includes(searchParam.toLowerCase())
      )
    );
  },[initiatives, searchParam]);
  
  useEffect(() => {
    let mounted = true;

    if (mounted) handleSearch();

    return () => {
      mounted = false;
    };
  }, [searchParam, handleSearch]);
  
  useEffect(() => {
    try{
      (async()=>{
        const initiatives = !initiative_key || storedInitiative === null ? await fetchInitiatives(user_signature, auth, levelInfo, initiative_key, complete_key, involved) : [storedInitiative]
        setInitiatives(initiatives);
        setInitiativesSearch(initiatives);
        setOnError(false);
        setLoaded(true);
      })()}
      catch(error:any){
        setOnError(true);
        setLoaded(false);
      }
  },[user_signature, auth, levelInfo, initiative_key, complete_key, involved]);
  
  return { initiativesSearch, handleSearchParam, onError, loaded };
};

export const useInitiativesDetails = (user_signature: string, auth: string, levelInfo: string, initiative_key: string) => {
  const [initiativeDetails, setInitiativeDetails] = useState<InitiativeDetailTableType>();
  const [onError, setOnError] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    try{
      (async()=>{
        const initiativeDetails = await fetchInitiativeDetails(user_signature, auth, levelInfo, initiative_key)
        setInitiativeDetails(initiativeDetails);
        setOnError(false);
        setLoaded(true);
      })()}
      catch(error:any){
        setOnError(true);
        setLoaded(false);
      }
  },[]);
  
  return { initiativeDetails, onError, loaded };
};

/*MOD20230111: Rimuovere l'argomento user da useInitiatives*/
export const useSuggestedInitiatives = (user: string|undefined) => {
  const [suggested] = useState<SuggestedInitiativesType[]>(
    mockInitiativesSuggested
  );
  /*MOD20230111: Rimuovere la condizione*/
  return user === "Teamsight Srl" ? { suggested } : { suggested: [] };
};