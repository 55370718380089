import { Box, Paper, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import { kpisListType } from "../../../types";

const CustomPaper = (props: any) => {
  return <Paper elevation={8} {...props} />;
};

type Props = {
  setKpi: (
    kpi:
      | { package: string; name: string; format: string; key: string }
      | null
      | undefined
  ) => void;
  kpis: kpisListType[];
  valueKpi: {
    package: string;
    name: string;
    format: string;
    key: string;
    upperLabel: string;
  } | null;
  changeValueKpi: (newValue: {
    package: string;
    name: string;
    format: string;
    key: string;
    upperLabel: string;
  } | null) => void;
  changeMan : (value:boolean)=>void;
  changeKpi: (value:boolean)=>void;
  changeChecked : (value: string[])=>void
};
const KpiAutocomplete = ({ setKpi, kpis, valueKpi, changeValueKpi, changeMan, changeChecked, changeKpi }: Props) => {
  
  const { t } = useTranslation();
  const options = kpis.map((option) => {
    return {
      upperLabel: t(option.package).toLocaleUpperCase(),
      ...option,
    };
  });

  return (
    <>
      
      {kpis.length > 0 && (
        <Autocomplete
          value={valueKpi}
          clearIcon={<></>}
          renderOption={ (props, option) => (
              <Typography sx={{borderTop:"0.5px solid", borderColor:"rgba(101,142,230,0.2)", "& :hover":{backgroundColor:"rgba(244,246,254,1)"}}} key={option.key} {...props}>
                {t(option.name)}
                </Typography>
            
            )
          }
          renderGroup={(params) => (
            <Box key={params.key}>
              <Typography
                className="h4"
                sx={{
                  color: "rgb(15, 20, 33)",
                  backgroundColor: "rgba(244,246,254,1)",
                  PaddingTop:"0.6rem"
                }}
              >
                {params.group}
              </Typography>
              <>
                {params.children}
              </>
            </Box>
          )}
          onChange={(
            event: any,
            newValue: null | {
              package: string;
              key: string;
              format: string;
              name: string;
              upperLabel: string;
            }
          ) => {
            changeValueKpi(newValue);
            setKpi(newValue);
            changeKpi(true);
            changeMan(false)

          }}
          isOptionEqualToValue={(option, value) => option.key === value.key}
          id="grouped-demo"
          options={options.sort(
            (a, b) => -b.upperLabel.localeCompare(a.upperLabel)
          )}
          groupBy={(option) => option.upperLabel}
          getOptionLabel={(option) => t(option.name)}
          sx={{ width: 550 }}
          PaperComponent={CustomPaper}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("target.select")}
         
            />
          )}
        />
      )}
    </>
  );
};

export default KpiAutocomplete;
