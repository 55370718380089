import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Box, Button, Popover, Stack, Typography } from "@mui/material";
import { MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { OfficeListTypeF } from "../../../types";
import { sortOfficesBySeverity } from "../../../util/sortOfficesBySeverity";
import { SEVERITY_BADGE_COLORS } from "../../SeverityBadge/SeverityBadge";

type Props = {
  officeList: OfficeListTypeF[]  | undefined;
};

const OfficesPopOver = ({ officeList }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const { t } = useTranslation();

  return (
    <>
      <Button
        sx={{
          padding: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",

          color: "ts.card.text.main",
          textTransform: "none",
        }}
        onClick={handleClick}
      >
        <Typography variant="body2" fontWeight={"bolder"}>
          {t("cards.details")}
        </Typography>
        <ArrowRightIcon sx={{ marginRight: "1.4rem" }} />
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            bgcolor: "ts.card.chip",
            borderRadius: "12px",
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
            padding: ".8rem",
            width: 222,
          },
        }}
      >
        <Stack>
          {sortOfficesBySeverity(officeList || []).map((office) => (
            <Box
              key={office.id}
              sx={{ display: "flex", alignItems: "center", gap: ".5rem" }}
            >
              <Box
                sx={{
                  bgcolor:
                    office.color === SEVERITY_BADGE_COLORS.critic
                      ? "ts.severity.critic"
                      : office.color === SEVERITY_BADGE_COLORS.risk
                      ? "ts.severity.risk"
                      : "ts.severity.target",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%",
                }}
              ></Box>
              <Typography variant="body2" sx={{ maxWidth: "80%" }}>
                {office.id}
              </Typography>
            </Box>
          ))}
        </Stack>
      </Popover>
    </>
  );
};

export default OfficesPopOver;
