import { initBread } from './initBread';

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BreadContextType} from "../../../types";



// Slice con action e reducers.
const breadSlice = createSlice({
  name: "breadcrumb", // Nome della slice
  initialState: initBread, // Valore iniziale vuoto
  // Reducer con le azioni che si possono fare sulla slice (in questo caso solo una)
  reducers: {
    updateBread(state, action: PayloadAction<BreadContextType>) {      
      return  action.payload;
    }
  },
});

// Action generate che verrano utilizzate per aggiornare lo store.
export const { updateBread } = breadSlice.actions;

export default breadSlice.reducer;