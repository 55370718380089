import { Box, SvgIcon, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { topbarSearch } from "../styles/styles";

type Props = {
  handleSearchParam: (param: string) => void;
};

const Search = ({ handleSearchParam }: Props) => {
  const { t } = useTranslation();

  const [value, setValue] = useState("");


const handleChange = (param:string) =>{
  setValue(param);
  handleSearchParam(param);
  
  

}

  return (
    <Box className="flexGap05" sx={{paddingBottom:"0.5rem", paddingTop:"0.5rem"}}>
      <TextField
        onChange={(e) => handleChange(e.target.value)}
        size="small"
        value={value}
        label={
          <Box className="flexGap05">
            <SvgIcon sx={{ color: "ts.sidebar.bg" }}>
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
            </SvgIcon>
            <Typography variant="body1" sx={{ color: "ts.sidebar.bg" }}>
              {t("misc.search")}
            </Typography>
          </Box>
        }
        
        sx={topbarSearch}
      />
    </Box>
  );
};

export default Search;
