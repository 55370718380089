import { Box, Button, Popover, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  handleOrder: (method: "title" |  "resources" | "severity") => void;
  order: "title" | "resources"  | "severity";
  handleOrderOfs?: (method: "title" | "resources" | "severity") => void;
};

const AllDepsFilter = ({ handleOrder, order, handleOrderOfs }: Props) => {

  const {t} = useTranslation();


  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography variant="body2" fontWeight={"bolder"}>
        {t("header.sorting.label")}
      </Typography>
      <Button
        onClick={handleClick}
        sx={{
          color: "ts.sidebar.selected",
          textTransform: "none",
          display: "flex",
          alignItems: "center",
          gap: ".3rem",
        }}
      >
        <Typography variant="body2">
          {{
            title: "A-Z",
            offices: "Uffici",
            resources: t("header.sorting.resources"),
            severity: t("header.sorting.score"),
          }[order] || "A-Z"}
        </Typography>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            bgcolor: "ts.card.chip",

            padding: ".4rem",
          },
        }}
      >
        <Stack>
          <Button
            onClick={() => {
              handleClose();
              handleOrder("title");
            }}
            sx={{
              textTransform: "none",
              color: "ts.card.text.title",
              "&:hover": { bgcolor: "ts.card.bg" },
            }}
          >
            A-Z
          </Button>
          {/*<Button
            onClick={() => {
              handleClose();
              handleOrder("offices");
            }}
            sx={{
              textTransform: "none",
              color: "ts.card.text.title",
              "&:hover": { bgcolor: "ts.card.bg" },
            }}
          >
            Uffici
          </Button>*/}
          <Button
            onClick={() => {
              handleClose();
             
              handleOrder("resources");
            }}
            sx={{
              textTransform: "none",
              color: "ts.card.text.title",
              "&:hover": { bgcolor: "ts.card.bg" },
            }}
          >
            {t("header.sorting.resources")}
          </Button>
          
          <Button
            onClick={() => {
              handleClose();
              
              handleOrder("severity");
            }}
            sx={{
              textTransform: "none",
              color: "ts.card.text.title",
              "&:hover": { bgcolor: "ts.card.bg" },
            }}
          >
            {t("header.sorting.score")}
          </Button>
        </Stack>
      </Popover>
    </Box>
  );
};

export default AllDepsFilter;
