import { Box, Chip, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CardTypeF, OfficeListTypeF } from "../../../types";
import { useLicense } from "../../../hooks";

type Props = {
  officeList?: OfficeListTypeF[];
  cardInfo?: CardTypeF;
};

const CardBody = ({ officeList, cardInfo }: Props) => {

  const { license } = useLicense();

  const { t } = useTranslation();

  return (
    <Stack sx={{ marginTop: "1rem", marginBottom: "1rem" }} spacing={1}>
      {
        license.sidebar?.monitoring?.filter(item => item.id === 'monitoring.initiatives').length > 0 && <Box sx={{ display: "flex", alignItems: "center", gap: ".5rem" }}>
          <Chip
            size={"small"}
            label={
              cardInfo?.initiatives
            }
            sx={{ bgcolor: "ts.card.chip" }}
          />
          <Typography variant="body2">
            {t("cards.initiatives")}
          </Typography>
        </Box>
      }
      <Box sx={{ display: "flex", alignItems: "center", gap: ".5rem" }}>
        {officeList && (
          <>
            <Chip
              size={"small"}
              label={`${
                cardInfo?.child_critical
              }/${cardInfo?.child_number}`}
              sx={{ bgcolor: "ts.card.chip" }}
            />

            <Typography variant="body2">
              {t("cards.criOff")}
            </Typography>
          </>
        )}
      </Box>
    </Stack>
  );
};

export default CardBody;
