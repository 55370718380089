import 'chart.js/auto';
import { Box } from "@mui/material";
import { getColor, PaletteType } from "./paletteChart";
import { Chart } from 'react-chartjs-2';
import type { ChartTypeRegistry } from "chart.js";
import { Chart as ChartJS } from "chart.js";
import { FormatType, getFormat } from "./chartFormatting";
import { allPlugins, allPluginsName, getPlugin, PluginType } from './jsPlugins';
import { useTranslation } from 'react-i18next';

type Props = {
  type: keyof ChartTypeRegistry;
  data: any;
  options: any;
};

const JsChart = ({ type, data, options }: Props) => {
  const { t } = useTranslation();
  
  ChartJS.unregister(...allPlugins)
  for(const plugin in options?.plugins){
    if (allPluginsName.includes(plugin)){
      ChartJS.register(getPlugin(plugin as keyof PluginType))
    }
  }

  const applyShift = (array: any, shif: number) => {
    shif -= array.length * Math.floor(shif / array.length);
    array.push.apply(array, array.splice(0, shif));
    return array
  }
  
  if (options?.plugins?.tooltip?.callbacks?.label && typeof (options.plugins.tooltip.callbacks.label) === "string" && options.plugins.tooltip.callbacks.label.startsWith(":")) {
    options.plugins.tooltip.callbacks.label = getFormat(options.plugins.tooltip.callbacks.label as keyof FormatType)
  }

  if (options?.plugins?.tooltip?.callbacks?.title && typeof (options.plugins.tooltip.callbacks.title) === "string" && options.plugins.tooltip.callbacks.title.startsWith(":")) {
    options.plugins.tooltip.callbacks.title = getFormat(options.plugins.tooltip.callbacks.title as keyof FormatType)
  }

  if (options?.scales?.x?.ticks?.callback && typeof (options.scales.x.ticks.callback) === "string" && options.scales.x.ticks.callback.startsWith(":")) {
    options.scales.x.ticks.callback = getFormat(options.scales.x.ticks.callback as keyof FormatType)
  }

  if (options?.scales?.y?.ticks?.callback && typeof (options.scales.y.ticks.callback) === "string" && options.scales.y.ticks.callback.startsWith(":")) {
    options.scales.y.ticks.callback = getFormat(options.scales.y.ticks.callback as keyof FormatType)
  }

  if (options?.scales?.x?.title?.text) {
    options.scales.x.title.text = t(options.scales.x.title.text)
  }

  if (options?.scales?.y?.title?.text) {
    options.scales.y.title.text = t(options.scales.y.title.text)
  }

  if (options?.plugins?.title?.text) {
    options.plugins.title.text = t(options.plugins.title.text)
  }

  data?.datasets?.map((dataset: any) => {
    if(dataset?.backgroundColor && typeof(dataset?.backgroundColor) === "string" && dataset?.backgroundColor.startsWith(":")) {
      dataset.backgroundColor = getColor(dataset?.backgroundColor as keyof PaletteType);
    }
    if(dataset?.borderColor && typeof(dataset?.borderColor) === "string" && dataset?.borderColor.startsWith(":")) {
      dataset.borderColor = getColor(dataset?.borderColor as keyof PaletteType);
    }
    if(dataset?.backgroundColor && typeof(dataset?.backgroundColor) === "object") {
      dataset.backgroundColor = dataset.backgroundColor.map((element: any) => {
        if(element.startsWith(":")) {
          element = getColor(element as keyof PaletteType);
        }
        return element
      });
    }
    if(dataset?.borderColor && typeof(dataset?.borderColor) === "object") {
      dataset.borderColor = dataset.borderColor.map((element: any) => {
        if(element.startsWith(":")) {
          element = getColor(element as keyof PaletteType);
        }
        return element
      });
    }
    if (dataset?.label) {
      dataset.label = t(dataset.label)
    }

    if (dataset.shift_data) {
      dataset.data = applyShift(dataset.data, dataset.shift_data === '$$timezone$$' ? new Date().getTimezoneOffset() / 60 : dataset.shift_data)
      delete dataset.shift_data
    }
    return dataset
  });
  
  data.labels = data?.labels?.map((label: any) => {
    return t(label)
  });

  return (
    <>
      {data && 
        <Box sx={{ height: "100%", maxWidth:"99%" }}>
          <Chart type={type} data={data} options={options}/>
        </Box>
      }
    </>
  );
};

export default JsChart;
