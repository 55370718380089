import { StepConnector, Box, Stepper, Step, StepLabel } from "@mui/material";
import { useTranslation } from "react-i18next";

type PropsType = {
  steps: Array<{ name: string }>;
  activeStep: number;
};

const StepperWizard = ({ steps, activeStep }: PropsType) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        connector={<StepConnector />}
      >
        {steps.map((label, index) => (
          <Step key={label.name}>
            <StepLabel
              sx={{
                ".MuiStepLabel-labelContainer": {
                  fontWeight: "bold",
                },
              }}
            >
              {t(label.name)}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default StepperWizard;
