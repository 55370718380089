import { InitiativeType } from '../../../types';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Slice con action e reducers.
const initiativeTemplateSlice = createSlice({
  name: "initiativeTemplate", // Nome della slice
  initialState: null as InitiativeType | null, // Valore iniziale vuoto
  // Reducer con le azioni che si possono fare sulla slice (in questo caso solo una)
  reducers: {
    updateInitiativeTemplateGeneric(state, action: PayloadAction<InitiativeType | null>) {
      return action.payload;
    },
    updateInitiativeTemplateScope(state, action: PayloadAction<string[]>) {
      if(state) state.scope = action.payload;
      return state;
    },
    updateInitiativeTemplateMetric(state, action: PayloadAction<string[]>) {
      if(state) state.kpis = action.payload;
      return state;
    },
  },
});

// Action generate che verrano utilizzate per aggiornare lo store.
export const { updateInitiativeTemplateGeneric, updateInitiativeTemplateScope, updateInitiativeTemplateMetric } = initiativeTemplateSlice.actions;

export default initiativeTemplateSlice.reducer;