import { useAuthenticator } from "@aws-amplify/ui-react";
import { Box } from "@mui/material";
import InitiativesGridHeader from "../../components/monitor/InitiativesGridHeader";
import InitiativesGridRow from "../../components/monitor/InitiativesGridRow";
import PageNav from "../../components/pagenav/PageNav";
import { useInitiatives } from "../../hooks/useInitiatives";
import { initiativeStyle } from "../../styles/styles";
import Loading from "../Loading";


const Initiatives = () => {
  const { user } = useAuthenticator((context) => [context.user]);

  const { initiativesSearch, handleSearchParam, onError, loaded } = useInitiatives(
    user.getSignInUserSession()?.getIdToken().payload.user_signature,
    user.getSignInUserSession()?.getIdToken().getJwtToken() || "",
    "master"
  );

  return (
    <Box sx={initiativeStyle.main.container}>
      <PageNav
        title="breadcrumbs.monitoring.initiatives"
        length={initiativesSearch.length}
        handleSearchParam={handleSearchParam}
      >
        {/*<Button disabled sx={initiativeStyle.main.buttonAdvanced}>
            {"monitor.initiatives.nav.advanced"}
        </Button>*/}
      </PageNav>
      <Box sx={initiativeStyle.main.containerRow}>
        <InitiativesGridHeader />
        { !loaded && !onError && <Loading /> } 
        { loaded && !onError && initiativesSearch.map((init) => 
          <InitiativesGridRow init={init} key={init.initiative_key} />
        )}
      </Box>
    </Box>
  );
};

export default Initiatives;
