import { useCallback, useEffect, useState } from "react";

import fetchDocs from "../api/fetchDocs";
import { DocumentType } from "../types";

export const useDocuments = (signature:string, auth: string, cat?:string) => {
  const [documents,setDocuments] = useState<DocumentType[]>([]);
  const [categoryList] = useState<string[]>([]);
  const [onError, setOnError] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [docSearch, setDocSearch] = useState<DocumentType[]>([]);

  useEffect( () => {
    try{
    (async()=>{
      
        const init= cat ? await fetchDocs(signature, auth, cat) as DocumentType[] : await fetchDocs(signature, auth ) as DocumentType[];
        setDocuments(init);
        setDocSearch(init);
        setOnError(false);
        setLoaded(true);
        
    })()}
    catch(error:any){
      setOnError(true);
      setLoaded(false);
    }
   
}, [])



  const [searchParam, setSearchParam] = useState<string>("");
 

  const handleSearchParam = (param: string) => {
    setSearchParam(param);
  };

  const handleSearch = useCallback(() => {
    setDocSearch(
      documents.filter(
        (doc) =>
          doc.title.toLocaleLowerCase().includes(searchParam) ||
          doc.abstract.toLocaleLowerCase().includes(searchParam) ||
          doc.author.toLocaleLowerCase().includes(searchParam)
      )
    );
  }, [documents, searchParam]);

  // let receivedCatList = Array.from(
  //   new Set(documents.map((doc: DocumentType) => doc.category))
  // ) as string[];

  //FIXME:questo use effect genera un loop, ho tolto received cat list dalle dipendenze per evitare
  useEffect(() => {
    let mounted = true;

    if (mounted) {
      // setCategoryList(receivedCatList);

      handleSearch();
    }

    return () => {
      mounted = false;
    };
  }, [handleSearch]);


  return { docSearch, categoryList, handleSearchParam, loaded, onError };
};
