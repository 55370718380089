import { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import "./App.css";
import NavDrawer from "./layout/navigation/NavDrawer";
import { DepCardType } from "./types";
import "./styles/global.css";
import "@aws-amplify/ui-react/styles.css";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import { useLicense } from "./hooks";
import components from "./components/SignInHeader";
import { I18n } from "aws-amplify";
import { translations } from "@aws-amplify/ui-react";
import Loading from "./components/Loading";
import usei18n from "./i18n";
import i18next from "i18next";
import { hotjar } from "react-hotjar";
import { Alert, AlertTitle } from "@mui/material";
I18n.putVocabularies(translations);

function App() {
  const [dataOffice, setDataOffice] = useState<DepCardType>();
  const [callAgain, setCallAgain]=useState<boolean>(true);

  const handleDataOffice = (newOffices: DepCardType) => {
    setDataOffice(newOffices);
  };

  const { pathname } = useLocation();

  const context = { dataOffice, handleDataOffice };

  const { route, user } = useAuthenticator((context) => [context.route]);

  useEffect(() => {
    if (user && !i18next.isInitialized) {
      usei18n(user);
    }
  }, [user]);

  const { getLicense, license, onError } = useLicense(user);

  if (!license.loaded && route === "authenticated" && callAgain ) {
    getLicense();
    setCallAgain(false)
  }

  useEffect(() => {
    if (process.env.REACT_APP_HOTJAR_SITE_ID !== "") {
      hotjar.initialize(
        parseInt(process.env.REACT_APP_HOTJAR_SITE_ID || ""),
        6
      );
    }
  }, []);


  return (
    <div className="App">
      <Authenticator
        hideSignUp
        loginMechanisms={["username"]}
        variation="modal"
        components={components}
      >
        {({ user, signOut }) =>
          onError.state ? (
            <>
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {onError.message.toString()}
              </Alert>
            </>
          ) : license.loaded && pathname === "/" ? (
            <Navigate to="/company" />
          ) : license.loaded ? (
            <NavDrawer pathname={pathname}>
              <Outlet context={context} />
            </NavDrawer>
          ) : (
            <Loading />
          )
        }
      </Authenticator>
    </div>
  );
}

export default App;
