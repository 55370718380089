import { kpisListSimpleType, kpisListType } from './../types';
import {  useEffect, useState } from "react";
import { fetchKpis } from '../api/fetchKpis';

export const useKpis = (signature:string, auth: string) => {
  const [formatKpis, setFormatKpis] = useState<kpisListType[]>([]);
  const [onError, setOnError] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    try{
    (async()=>{
        const init=  await fetchKpis(signature, auth, 'withFormat') as kpisListSimpleType[];
        const formattedKpis:kpisListType[] = init.map((kpi)=>{ return {package: kpi.key.substring(0, kpi.key.indexOf("|")), key:kpi.key , format:kpi.format, name:kpi.key.replace("|", ".") + ".title" }})
        setFormatKpis(formattedKpis);
        setOnError(false);
        setLoaded(true);
    })()}
    catch(error:any){
      setOnError(true);
      setLoaded(false);
    }
  }, [])

  const [selectedKpi, setSelectedKpi] = useState<{ package: string; key: string; format: string; name:string } | undefined | null>();
  const changeSelectedKpi = (kpi: { package: string; key: string; format: string; name:string } | null | undefined) => { setSelectedKpi(kpi); };
  const [valueKpi, setValueKpi] = useState<{
    package: string;
    name: string;
    format: string;
    key: string;
    upperLabel: string;
  } | null>(null);

  const changeValueKpi = (newvalue: {
    package: string;
    name: string;
    format: string;
    key: string;
    upperLabel: string;
  } | null)=>{
    setValueKpi(newvalue)
  }

  return { formatKpis, selectedKpi, changeSelectedKpi, loaded, onError, valueKpi, changeValueKpi };
};
