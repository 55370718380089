
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LicenseEvalType } from "../../../types";


// Slice con action e reducers.
const licenseSlice = createSlice({
  name: "license", // Nome della slice
  initialState: [] as any, // Valore iniziale vuoto
  // Reducer con le azioni che si possono fare sulla slice (in questo caso solo una)
  reducers: {
    updateLicense(state, action: PayloadAction<LicenseEvalType>) {      
      return  action.payload;
    }
  },
});

// Action generate che verrano utilizzate per aggiornare lo store.
export const { updateLicense } = licenseSlice.actions;

export default licenseSlice.reducer;

