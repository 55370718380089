import { combineReducers } from "@reduxjs/toolkit";
import licenseSlice from "./slices/license/licenseSlice";
import initiativeDetailSlice from "./slices/initiative/initiativeDetailSlice";
import initiativeTemplateSlice from "./slices/initiative/initiativeTemplateSlice";
import searchSlice from "./slices/search/searchSlice";
import visualDepSlice from "./slices/visualDep/visualDepSlice";
import sortingDepSlice from "./slices/sortingDep/sortingDepSlice";
import selectedKpiSlice from "./slices/selectedKpi/selectedKpiSlice";
import breadSlice from "./slices/bread/breadSlice";

// Root per tutti i reducers.
const rootReducer = combineReducers({
  licenseSlice,
  initiativeDetailSlice,
  initiativeTemplateSlice,
  searchSlice,
  visualDepSlice,
  sortingDepSlice,
  selectedKpiSlice,
  breadSlice
});

export default rootReducer;
