import { unparse } from 'papaparse';
import { useEffect, useState } from "react";

import sendHrdbFileWarn from "../api/sendHrdbFileWarn";
import { AnonResponseType, AnonStepType, ColumnsType } from "../types";
import * as CryptoJS from "crypto-js";
import sendHrdbFile from '../api/sendHrdbFile';
import { Buffer } from "buffer";
import { useLicense } from './useLicense';

export const useHrdbAnon = (signature: string, auth: string, handleOpen?: () => void) => {
  const { license } = useLicense();
  const k_number:number | undefined = license.k_check 



  const [people, setPeople] = useState<any[]>([]);
  const [head, setHead] = useState<any[]>([]);
  const [file, setFile] = useState<any[]>([]);
  const [keyword, setKeyword] = useState<string>("");
  const [onError, setOnError] = useState<{status:boolean, message:string}>({status:false, message:""})

  const [activeStep, setActiveStep] = useState<number>(0);
  const [stepEmpty, setStepEmpty] = useState<AnonStepType>({
    result: "",
    rows: [],
    columns: [],
  });
  const [stepOrg, setStepOrg] = useState<AnonStepType>({
    result: "",
    rows: [],
    columns: [],
  });
  const [stepCheck, setStepCheck] = useState<AnonStepType>({
    result: "",
    rows: [],
    columns: [],
  });

  const handleForward = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const steps: { name: string; id_step: string }[] = [
    { name: "hrdb.modal.step.mapping.name", id_step: "mapping" },
    { name: "hrdb.modal.step.hashing.name", id_step: "hashing" },
    { name: "hrdb.modal.step.empty_values.name", id_step: "empty_values" },
    { name: "hrdb.modal.step.hierarchy.name", id_step: "hierarchy" },
    { name: "hrdb.modal.step.k_check.name", id_step: "k_check" },
    { name: "hrdb.modal.step.success.name", id_step: "success" },
  ];

  let buttonsConfig: {
    buttons: {
      label: string;
      onClick: (() => void) | ((id: string) => void) | void;
      disabled?: boolean;
    }[];
  }[] = [];

  const storePeople = (newPeople: any[]) => {
    setPeople(newPeople);
  };

  const storeHead = (newHead: any[]) => {
    setHead(newHead);
  };

  const storeFile = (newFile: any[]) => {
    setFile(newFile);
  };

  const storeKeyword = (key: string) => {
    setKeyword(key);
  };

  const initialColumns: ColumnsType = {
    items: {
      id: "items",
      title: "hrdb.modal.step.mapping.items",
      list: head || [],
    },
    node_id: {
      id: "node_id",
      title: "hrdb.modal.step.mapping.mail",
      list: [],
    },
    org: {
      id: "org",
      title: "hrdb.modal.step.mapping.order",
      list: [],
    },
    attributes: {
      id: "attributes",
      title: "hrdb.modal.step.mapping.attributes",
      list: [],
    },
  };

  const [columns, setColumns] = useState(initialColumns);

  const changeColumns = (col: ColumnsType) => {
    setColumns(col);
  };

  buttonsConfig = [
    {
      buttons: [
        {
          label: "hrdb.modal.button.next",
          onClick: handleForward,
          disabled: columns.node_id.list.length === 0 || columns.org.list.length === 0 ,
        },
      ],
    },
    {
      buttons: [
        { label: "", onClick: ()=>{} },
        { label: "hrdb.modal.button.back", onClick: handleBack },
       
      ],
    },
    {
      buttons: [
        {
          label: "hrdb.modal.button.next",
          onClick: handleForward,
          disabled: stepOrg.result === "",
        },
       
      ],
    },
    {
      buttons: [
        {
          label: "hrdb.modal.button.next",
          onClick: handleForward,
          disabled: stepCheck.result === "",
        },
        { label: "hrdb.modal.button.back", onClick: handleBack },
      ],
    },
    {
      buttons: [
        {
          label: "hrdb.modal.button.next",
          onClick: handleForward,
          disabled: stepCheck.result === "error",
        },
        { label: "hrdb.modal.button.back", onClick: handleBack },
      ],
    },
    {
      buttons: [ { label: "", onClick: ()=>{} },
      { label: "hrdb.modal.button.back", onClick: handleBack },],
    },
  ];

  useEffect(() => {
    if ((head || []).length > 0 && handleOpen) {
      changeColumns(initialColumns);
      handleOpen();
      setActiveStep(0);
    }
  }, [head]);

  const hashWithClientKey = (key: string, mail: string) => {
    if (mail === "" || !mail) {
      return "";
    } else {
      const hash = CryptoJS.SHA256(
        (key.toLowerCase() + mail.toLowerCase()).toString()
      );
      let encrypted: string = hash.toString(CryptoJS.enc.Hex);
      return encrypted;
    }
  };

  const findActiveStep = (idStep: string) => {
    const idx: number = steps.findIndex((s) => s.id_step === idStep);
    return idx;
  };

  const hashClientFile = async (
    hrdb: any[],
    mapping: ColumnsType,
    keyw: string
  ) => {

    /*const idColumn: string = mapping.node_id.list[0];
    const indexChange = hrdb[0].indexOf(idColumn);
    const indexCrypt:any = [];
    (columns.org.list.concat(columns.attributes.list)).forEach(element => {
      indexCrypt.push(hrdb[0].indexOf(element));
    });
    const newFile: any[] = [];

    head.map((col, index) =>{ return head[index] = col.replace(" ", "_")});
    const newHead:any = []
    head.forEach((col:any, index:any) => {
      if (index == indexChange || indexCrypt.includes(index)) {
        newHead.push(col);
      }
    });
    newFile.push(newHead);
    if (indexChange !== -1) {
      file.forEach((row, index) => {
        if (index !== 0) {
          const newRow:any = []
          row.forEach((col:any, index:any) => {
            if (index == indexChange) {
              col = hashWithClientKey(keyw, col);
              newRow.push(col);
            }
            if (indexCrypt.includes(index)) {
              col = CryptoJS.AES.encrypt(col, keyw).toString()
              newRow.push(col);
            }
          });
          newFile.push(newRow);
        }
      });
    }
    setFile(newFile);
    const resultFile = unparse(newFile, {
      header: false,
      skipEmptyLines: true,
      delimiter: ";",
    });*/

    const idColumn: string = mapping.node_id.list[0];
    const indexChange = hrdb[0].indexOf(idColumn);
    const newFile: any[] = [];

    newFile[0] = head.map((col, index) =>{ return head[index] = col.replace(" ", "_")});
    if (indexChange !== -1) {
      file.forEach((row, index) => {
        if (index !== 0) {
          row[indexChange] = hashWithClientKey(keyw, row[indexChange]);
          newFile.push(row);
        }
      });
    }
    setFile(newFile);
    const resultFile = unparse(newFile, {
      header: false,
      skipEmptyLines: true,
      delimiter: ";",
    });

    const encodedFile = await Buffer.from(await resultFile).toString("base64");

    columns.node_id.list = columns.node_id.list.map((col, index) =>{ return columns.node_id.list[index] = col.replace(" ", "_")});
    columns.org.list = columns.org.list.map((col, index) => {return columns.org.list[index] =  col.replace(" ", "_")});
    columns.attributes.list = columns.attributes.list.map((col, index) => { return columns.attributes.list[index] =  col.replace(" ", "_")})

    
    const map = {
      node_id: columns.node_id.list,
      org: columns.org.list,
      attributes: columns.attributes.list
    };

   
    try{
    const response: AnonResponseType = await sendHrdbFile(encodedFile, k_number, map, signature, auth);
    setPeople([]);
    setHead([]);
    setFile([]);
    setColumns(initialColumns);
    setActiveStep(findActiveStep(response.id_active_step));
    setStepEmpty({ result: "", rows: [], columns: [] });
    setStepCheck({ result: "", rows: [], columns: [] });
    setStepOrg({ result: "", rows: [], columns: [] });

    setStepEmpty(response.empty_values);
    //setStepEmpty({result:"", rows: people, columns:head});
    if (response.hierarchy) {
      setStepOrg(response.hierarchy);
    }
    if (response.k_check) {
      setStepCheck(response.k_check);
    }}
    catch(error){
      console.log("errore")
      setOnError({message:error as string, status:true})
    }
  };

  const sendClientFile = async (
    idStep:string
  ) => {

    try{
    
    const response: AnonResponseType = await sendHrdbFileWarn(idStep, signature, auth);
    setActiveStep(findActiveStep(response.id_active_step));
    setStepEmpty({ result: "", rows: [], columns: [] });
    setStepCheck({ result: "", rows: [], columns: [] });
    setStepOrg({ result: "", rows: [], columns: [] });
    setStepEmpty(response.empty_values);
    //setStepEmpty({result:"", rows: people, columns:head});
    if (response.hierarchy) {
      setStepOrg(response.hierarchy);
    }
    if (response.k_check) {
      setStepCheck(response.k_check);
    }}
    catch (error){
      setOnError({message:error as string, status:true})
    }
  };

   const k = k_number ? k_number : 5

  return {
    activeStep,
    buttonsConfig,
    columns,
    changeColumns,
    people,
    head,
    storeHead,
    storePeople,
    storeFile,
    storeKeyword,
    keyword,
    hashClientFile,
    file,
    steps,
    stepCheck,
    stepEmpty,
    stepOrg,
    sendClientFile,
    onError,
    k
  };
};
