import arrayToTree from "array-to-tree";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { fetchKpiOrg } from "../api/fetchKpis";
import { checkboxtree, hrKpiType } from "../types";

export const useKpiOrg = (signature: string, auth: string) => {
  const {t} = useTranslation()
  const [expanded, setExpanded] = useState<string[]>([]);
  const [filtered, setFiltered] = useState<string>("");
  const [loadedHrOrg, setLoadedHrOrg] = useState<boolean>(false);
  const [onErrorHrOrg, setOnErrorHrOrg] = useState<boolean>(false);
  const [filteredAllNodes, setFilteredAllNodes] = useState<
    (
      | {
          id: string;
          name: string;
          parent_id: null;
        }
      | {
          id: string;
          name: string;
          parent_id: string;
        }
    )[]
  >([]);

  const [newNodes, setNewNodes] = useState<checkboxtree[]>([]);

  useEffect(() => {
    try {
      (async () => {
        const init = (await fetchKpiOrg(signature, auth, 'hierarchy')) as hrKpiType[];
        init.map((item) => {return item.name = t(item.name)})
        setFilteredAllNodes(
          init.filter((single) => single.name.toLowerCase().includes(filtered))
        );

        setNewNodes(
          arrayToTree(
            init.map((node: any) => {
              return {
                value: node.id,
                label: node.name,
                title: node.parent_id,
                className:
                  "class" +
                  node.id
                    .split(" ")
                    .join("_")
                    .split(".")
                    .join("")
                    .split(",")
                    .join(""),
              };
            }),
            {
              parentProperty: "title",
              customID: "value",
            }
          )
        );

        setOnErrorHrOrg(false);
        setLoadedHrOrg(true);
      })();
    } catch (error: any) {
      setOnErrorHrOrg(true);
      setLoadedHrOrg(false);
    }
  }, []);

  const [checked, setChecked] = useState<string[]>([]);
  const [filteredTree, setFilteredTree] = useState<checkboxtree[]>();

  const changeFilteredTree = (newFi: checkboxtree[]) => {
    setFilteredTree(newFi);
  };

  const changeChecked = (newCheck: string[]) => {
    setChecked(newCheck);
  };

  const changeExpanded = (newExpand: string[]) => {
    setExpanded(newExpand);
  };

  useEffect(() => {
    let initTree = filteredAllNodes.map((node) => {
      return {
        value: node.id,
        label: node.name,
        title: node.parent_id,
        className:
          "class" +
          node.id.split(" ").join("_").split(".").join("").split(",").join(""),
      };
    });

    const finaleTree = arrayToTree(initTree, {
      parentProperty: "title",
      customID: "value",
    }) as checkboxtree[];

    setFilteredTree(finaleTree as checkboxtree[]);
  }, [filteredAllNodes]);

  const getAllValuesFromNodes = (
    nodes: checkboxtree[],
    firstLevel: boolean
  ): string[] => {
    if (firstLevel) {
      const values: string[] = [];
      for (let n of nodes) {
        values.push(n.value);
        if (n.children) {
          values.push(...getAllValuesFromNodes(n.children, false));
        }
      }
      return values;
    } else {
      const values = [];
      for (let n of nodes) {
        values.push(n.value);
        if (n.children) {
          values.push(...getAllValuesFromNodes(n.children, false));
        }
      }
      return values;
    }
  };

  const getAllLeafsFromNodes = (
    nodes: checkboxtree[],
    firstLevel: boolean
  ): string[] => {
    if (firstLevel) {
      const values: string[] = [];
      for (let n of nodes) {
        if (!n.children || n.children.length === 0) {
          values.push(n.value);
        }
        if (n.children) {
          values.push(...getAllLeafsFromNodes(n.children, false));
        }
      }
      return values;
    } else {
      const values = [];
      for (let n of nodes) {
        if (!n.children || n.children.length === 0) {
          values.push(n.value);
        }
        if (n.children) {
          values.push(...getAllLeafsFromNodes(n.children, false));
        }
      }
      return values;
    }
  };

  const onChangeText = (e: any, searchedNodes: checkboxtree[]) => {
    setFiltered(e.target.value.toLowerCase());
    if (e.target.value === "") {
      setExpanded([]);
    } else {
      setExpanded(getAllValuesFromNodes(searchedNodes, true));
    }
  };

  const selectAllNodes = (all: boolean) => {
    all ? setChecked(getAllLeafsFromNodes(newNodes, true)) : setChecked([]);
  };

  const changeFiltered = (val:string) =>{
    setFiltered("")
  }

  return {
    selectAllNodes,
    onChangeText,
    getAllLeafsFromNodes,
    newNodes,
    changeFilteredTree,
    getAllValuesFromNodes,
    filtered,
    filteredTree,
    changeChecked,
    changeExpanded,
    checked,
    expanded,
    loadedHrOrg,
    onErrorHrOrg,
    changeFiltered
  };
};
