import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { cardDocStyle } from "../../styles/styles";


type PropsType ={
  title: string,
  author:string,
  abstract:string
}

const CardDocBody = (props: PropsType) => {
  const { t } = useTranslation();

  return (
    <Box
    
    sx={cardDocStyle.body.container}
    >
      <Typography
        variant="subtitle2"
        component="div"
        sx={cardDocStyle.body.bold}
      >
        {props.title}
      </Typography>
      <Typography variant="body2"  sx={cardDocStyle.body.spacing}>
        {t("documents.card.author") + ": " + props.author}
      </Typography>

      <Typography
        variant="subtitle2"
        sx={cardDocStyle.body.bold}
      >
        {t("documents.card.abstract")}
      </Typography>
      <Typography variant="body2" component="div">
        {props.abstract}
      </Typography>
    </Box>
  );
};

export default CardDocBody;
