import { Box, Button, Chip, SvgIcon, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import SuggestedInitiativePopOver from "./SuggestedInitiativePopOver";
import { initiativeStyle } from "../../styles/styles";
import { InitiativeType, SuggestedInitiativesType } from "../../types";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import { suggInitiativeCard } from "../../styles/styles";
import logoGk from "../../images/logoGK.jpg";
import logoEh from "../../images/logoEH.jpg";
import InitiativesModal from "./InitiativesModal";
import { useAppDispatch } from "../../hooks";
import { useAuthenticator } from "@aws-amplify/ui-react";
import dayjs from 'dayjs';
import { updateInitiativeTemplateGeneric } from "../../redux/slices/initiative/initiativeTemplateSlice";

type Props = {
  init: SuggestedInitiativesType;
};

const SuggestedInitiativesCard = ({ init }: Props) => {
  const dispatch = useAppDispatch();
  const { user } = useAuthenticator((context) => [context.user]);
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () =>{
    const initForm : InitiativeType = {
      initiative_key: "",
      status: "active",
      title: init.title,
      description: init.description,
      owner: user.attributes?.email || "",
      insert_date: "",
      starting_date: dayjs(new Date()).format("YYYY-MM-DD"),
      ending_date: "",
      color: "",
      score: 0,
      scope: init.scope,
      kpis: init.metrics,
    };
    dispatch(updateInitiativeTemplateGeneric(initForm));
    setOpenModal(true);
  }
  const { t } = useTranslation();

  return (
    <Box  sx={initiativeStyle.suggested.card.blueprint}>
      <Box>
        <Box  sx={initiativeStyle.suggested.card.labelCategory}>
          <SvgIcon sx={initiativeStyle.suggested.card.labelIcon}>
            <path d="M19.88 17.97C20.32 17.27 20.58 16.46 20.58 15.58C20.58 13.09 18.57 11.08 16.08 11.08C13.59 11.08 11.58 13.09 11.58 15.58C11.58 18.07 13.59 20.08 16.07 20.08C16.95 20.08 17.77 19.82 18.46 19.38L21.58 22.5L23 21.08L19.88 17.97ZM16.08 18.08C14.7 18.08 13.58 16.96 13.58 15.58C13.58 14.2 14.7 13.08 16.08 13.08C17.46 13.08 18.58 14.2 18.58 15.58C18.58 16.96 17.46 18.08 16.08 18.08ZM15.72 9.58C14.98 9.6 14.27 9.76 13.62 10.03L13.07 9.2L9.27 15.38L6.26 11.86L2.63 17.67L1 16.5L6 8.5L9 12L13 5.5L15.72 9.58ZM18.31 10.08C17.67 9.8 16.98 9.63 16.26 9.59L21.38 1.5L23 2.68L18.31 10.08Z" />
          </SvgIcon>
          <Typography variant="body2" sx={initiativeStyle.suggested.card.labelText}>
            {t("initiatives.suggested.category")}
          </Typography>
        </Box>
        <Typography
          variant="h6"
          fontWeight={"bold"}
          sx={initiativeStyle.suggested.card.bodyTitle}
        >
          {init.title}
        </Typography>
        <Box sx={initiativeStyle.suggested.card.bodyCritical}>
          <Chip
            sx={initiativeStyle.suggested.card.bodyCriticalChip}
            size={"small"}
            label={init.critical}
          />
          <Typography variant="body2">
            {t("cards.criOff")}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ margin: "1rem" }}>
        <Button onClick={handleOpenModal} sx={suggInitiativeCard.button.create}>
          <AddIcon />
          {t("initiatives.suggested.card.button.add")}
        </Button>
        <InitiativesModal openModal={openModal} setOpenModal={setOpenModal}/>
        <Button sx={suggInitiativeCard.button.act}>
          <Box sx={suggInitiativeCard.button.overlay}>
            <SvgIcon sx={{fontSize: "30pt", color:"text.primary"}}>
              <path d="M18 8.5H17V6.5C17 3.74 14.76 1.5 12 1.5C9.24 1.5 7 3.74 7 6.5V8.5H6C4.9 8.5 4 9.4 4 10.5V20.5C4 21.6 4.9 22.5 6 22.5H18C19.1 22.5 20 21.6 20 20.5V10.5C20 9.4 19.1 8.5 18 8.5ZM9 6.5C9 4.84 10.34 3.5 12 3.5C13.66 3.5 15 4.84 15 6.5V8.5H9V6.5ZM18 20.5H6V10.5H18V20.5ZM12 17.5C13.1 17.5 14 16.6 14 15.5C14 14.4 13.1 13.5 12 13.5C10.9 13.5 10 14.4 10 15.5C10 16.6 10.9 17.5 12 17.5Z" />
            </SvgIcon>
          </Box>
          {t("initiatives.suggested.card.button.act")}
          <img
            style={initiativeStyle.suggested.card.img}
            src={init.partner === "gk" ? logoGk : logoEh}
            alt="logo"
          />
        </Button>
      </Box>
      <SuggestedInitiativePopOver init={init} />
    </Box>
  );
};

export default SuggestedInitiativesCard;