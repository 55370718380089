import { Box, Button, SvgIcon, Tab, Tabs, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import InitiativeDetailTable from "./InitiativeDetailTable";
import InitiativesTabPanel from "./InitiativesTabPanel";

type TabsType = {
  name: string;
};

const initTabs: TabsType[] = [
  { name: "initiatives.initiativedetail.tab.header.01" },
];

const InitiativesTabs = () => {
  const value = 0
  const { t } = useTranslation();
  const handlePopoverOpen = () => {}
  const handlePopoverClose = () => {}
  const handleChange = () => {}

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }} className="flex">
        <Tabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{
            sx: {
              bgcolor: "ts.sidebar.bg",
            },
          }}
          textColor="secondary"
        >
          {initTabs.map((tab, index) => (
            <Tab
              disabled={true}
              key={index}
              sx={{ textTransform: "none" }}
              label={
                <Box className="flexGap05">
                  <Typography
                    variant="subtitle1"
                    color={"ts.text.secondary"}
                    fontWeight={value === index ? "bold" : "normal"}
                  >
                    {t(tab.name)}
                  </Typography>
                </Box>
              }
            />
          ))}
        </Tabs>
        <Box>
          <Box
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            <Button
              disabled
              variant="contained"
              sx={{
                borderRadius: "5px",
                bgcolor: "ts.initiative.action",
              }}
            >
              <SvgIcon sx={{ padding: ".1rem", color:"ts.primary.white" }}>
                <path d="M18 15V18H6V15H4V18C4 19.1 4.9 20 6 20H18C19.1 20 20 19.1 20 18V15H18ZM17 11L15.59 9.59L13 12.17V4H11V12.17L8.41 9.59L7 11L12 16L17 11Z" />
              </SvgIcon>
            </Button>
          </Box>
        </Box>
      </Box>
      <InitiativesTabPanel value={value} index={0} key={0}>
        <InitiativeDetailTable/>
      </InitiativesTabPanel>
    </Box>
  );
};

export default InitiativesTabs;