import { PaletteMode } from "@mui/material";
import { ThemeProvider } from "@mui/system";
import { createContext, useMemo, useState } from "react";
import { themeTeamsight } from "./theme";

type ContextType = {
  mode: string;
  toggleMode: () => void;
};

export const ColorModeContext = createContext<ContextType>({
  mode: "light",
  toggleMode: () => {},
});

type Props = {
  children: React.ReactNode;
};

const ColorContextProvider = ({ children }: Props) => {
  const [mode, setMode] = useState<PaletteMode>("light");

  const colorMode = useMemo(
    () => ({
      toggleMode: () =>
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light")),
      mode,
    }),
    [mode]
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={themeTeamsight}>{children}</ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default ColorContextProvider;
