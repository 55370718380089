import { Grid, Typography, Box } from "@mui/material";
//import { useTranslation } from "react-i18next";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { alertGrid } from "../../styles/styles";
import SeverityBadge from "../SeverityBadge/SeverityBadge";

import { AlertType } from "../../types";



type Props = {
  alert: AlertType;
}

const AlertGridRow = ({alert}: Props) => {
  

  //const { t } = useTranslation();


  return (
    <Grid container spacing={0} >
      <Grid
        item
        xs="auto"
        sm="auto"
        md={12}
        className="width100"
        sx={alertGrid.gridRow}
      >
        <Grid container spacing={0}  >
          <Grid item xs={4} sm={4} md={4} lg={3} >
            <Box sx={alertGrid.row.rowName}>
              <SeverityBadge severity="alert" type="point" />
              <Typography
                variant="subtitle2"
                sx={{...alertGrid.gridRowName, ...alertGrid.gridRowItem}}
              >
                {alert.title}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4} sm={4} md={5} lg={5} >
            <Typography
              variant="body2"
              sx={{...alertGrid.gridRowAim, ...alertGrid.gridRowItem}}
            >
              {alert.message}
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
            sm={2}
            md={2}
            lg={2}
            sx={alertGrid.gridRowItem}
          >
            <Typography variant="body2">
              {alert.author}
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
            sm={2}
            md={2}
            lg={2}
            sx={alertGrid.gridRowItem}
          >
            <Typography variant="body2">{alert.date}</Typography>
          </Grid>

          {/* <Grid
            item
            xs={2}
            sm={2}
            md={2}
            lg={1}
            className="alert-grid-row-item "
          > */}
            {/* <Box className="alert-grid-row-buttons">
              <Button className="alert-grid-row-button">
                <ArrowForwardIosIcon />
              </Button>
            </Box> */}
          {/* </Grid> */}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AlertGridRow;
