import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ChartType } from "../../../types";
import JsChart from "../../charts/JsChart";
import AmGraph from "../../charts/AmGraph";
import Matrix from "../../charts/Matrix";

type Props = {
  chart: ChartType;
  selectedChart: string;
};

const MetricChart = ({ chart, selectedChart }: Props) => {
  const { t } = useTranslation();

  if (selectedChart !== chart.chart_id) return(<></>)

  switch (chart?.type) {
    case "line":
    case "bar":
    case "doughnut":
    case "pie":
    case "bubble":
    case "scatter":
      return (
        <JsChart
          type={chart.type}
          data={chart.chart_data || null}
          options={chart.chart_options || null}
        />
      );
    case "graph":
      return (
        <AmGraph
          data={chart?.chart_data || null}
          options={chart?.chart_options || null}
        />
      );
    case "matrix":
      return (
        <Matrix
          data={chart?.chart_data || null}
          options={chart?.chart_options || null}
        />
      );
    default:
      return (
        <Typography variant="h5">
          {t("card.metrics.chart.nodata.label")}
        </Typography>
      );
  }
};

export default MetricChart;
