import { useEffect, useState } from 'react';
import fetchHrdbFile from '../api/fetchHrdbFile';
import { HRFilesType } from './../types';


const useHrDb = (signature: string, auth: string) => {
      const [hrFile, setHrFile] = useState<HRFilesType>({rows:[], columns:[]});
      const [onError, setOnError] = useState<boolean>(false);
      const [loaded, setLoaded] = useState<boolean>(false);
      useEffect( () => {
        try{
        (async()=>{
            const init=await fetchHrdbFile(signature, auth) as HRFilesType[];
            setHrFile(init[0])
            setOnError(false);
            setLoaded(true);
        })()}
        catch(error:any){
          console.log(error)
          setOnError(true);
          setLoaded(false);
        }
    }, [])
    
  return {hrFile, onError, loaded}

} 

export default useHrDb;