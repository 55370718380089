import PackageCheckBoxTree from "../../configure/target/PackageCheckBoxTree";
import { useKpiOrg } from "../../../hooks/useKpiOrg"
import { useAuthenticator } from "@aws-amplify/ui-react";
import SimpleLoading from "../../../components/SimpleLoading";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { InitiativeType } from "../../../types";
import { useEffect, useState } from "react";
import { updateInitiativeTemplateMetric } from "../../../redux/slices/initiative/initiativeTemplateSlice";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

type Props = {
  onFormStatusChange: (compiled: boolean) => void;
};

const Metrics = ({ onFormStatusChange }: Props) => {
  const { user } = useAuthenticator((context) => [context.user]);
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  
  const storedTemplate: InitiativeType = useAppSelector(
    (state) => state.initiativeTemplateSlice
  ) || {
    initiative_key: "",
    status: "active",
    title: "",
    description: "",
    owner: "",
    insert_date: "",
    starting_date: "",
    ending_date: "",
    color: "",
    score: 0,
    scope: [],
    kpis: [],
  };

  const [kpis, setKpis] = useState<string[]>(storedTemplate.kpis);

  const { selectAllNodes, onChangeText, newNodes, changeFilteredTree, changeExpanded, filtered, filteredTree, changeChecked, checked, expanded, loadedHrOrg, changeFiltered } = useKpiOrg(
    user.getSignInUserSession()?.getIdToken().payload.user_signature,
    user.getSignInUserSession()?.getIdToken().getJwtToken() || ""
  );

  useEffect(()=>{
    dispatch(updateInitiativeTemplateMetric(kpis));
    if (kpis.length > 0) onFormStatusChange(true);
    else onFormStatusChange(false);
  },[kpis])

  useEffect(()=>{
    setKpis(checked);
  },[checked]);
  
  useEffect(()=>{
    if (storedTemplate){
      changeChecked(storedTemplate.kpis);
    }
  },[])

  return (
    <>{ !loadedHrOrg ? <SimpleLoading minWidth="0px" /> :
      <Stack sx={{ maxWidth: "44%" }}>
        <Typography variant="body1">
          {t("initiative.wizard.step2.metrics")}
        </Typography>
        <PackageCheckBoxTree
          checked={checked}
          setChecked={changeChecked}
          filtered={filtered}
          onChangeText={onChangeText}
          filteredTree={filteredTree || []}
          selectAllNodes={selectAllNodes}
          expanded={expanded}
          setExpanded={changeExpanded}
          newNodes={newNodes}
          setFilteredTree={changeFilteredTree}
          changeFiltered={changeFiltered}
        />
      </Stack>
    }</>
  );
};

export default Metrics;