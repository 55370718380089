import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks";
import { updateInitDetail } from "../../redux/slices/initiative/initiativeDetailSlice";
import { initiativeStyle } from "../../styles/styles";
import { InitiativeType } from "../../types";
import SeverityBadge from "../SeverityBadge/SeverityBadge";

type Props = {
  init: InitiativeType;
};

const InitiativesGridRow = ({ init }: Props) => {
  const dispatch = useAppDispatch();
  const nav = useNavigate();

  const { t } = useTranslation();
  const handleClick = (initiative: InitiativeType) => {
    dispatch(updateInitDetail(initiative));
    nav(`/initiatives/${encodeURIComponent(initiative.title)}?init_key=${initiative.initiative_key}`);
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} sm={12} md={12} sx={initiativeStyle.grid.row.main}>
        <Grid container spacing={0}>
          <Grid item xs={3} sm={3} md={3} lg={3} sx={initiativeStyle.grid.row.item}>
            <Typography variant="body2" sx={initiativeStyle.grid.row.name}>{t(init.title)}</Typography>
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3} sx={initiativeStyle.grid.row.item}>
            <Typography variant="body2" sx={initiativeStyle.grid.row.description}>{t(init.description)}</Typography>
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2} sx={ initiativeStyle.grid.row.item}>
            <Typography variant="body2" noWrap>{t(init.owner)}</Typography>
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={1} sx={ initiativeStyle.grid.row.item}>
            <Typography variant="body2">{init.starting_date}</Typography>
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={1} sx={ initiativeStyle.grid.row.item}>
            <Typography variant="body2">{init.ending_date}</Typography>
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2} sx={ initiativeStyle.grid.row.item}>
            <Box sx={ initiativeStyle.grid.row.buttons}>
              <SeverityBadge severity={init.color} type="circle" />
              <Box className="initiatives-grid-row-container">
                {/*
                <Button className="initiatives-grid-row-button">
                  <EditOutlinedIcon />
                </Button>
                */}
                <Button
                  sx={ initiativeStyle.grid.row.button}
                  onClick={() => {
                    handleClick(init);
                  }}
                >
                  <ArrowForwardIosIcon />
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InitiativesGridRow;