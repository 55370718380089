import { useState } from "react";
import putTarget from "../api/putTarget";
import { SelectedKpiType, singleKpiTargetRequestType } from './../types';

import { FormInput } from "../types";
import { useTranslation } from "react-i18next";

export const useTarget = ( signature: string, auth: string,  selectedkpi:SelectedKpiType, checked:string[],) => {
  const {t} = useTranslation();
  const [onErrorPut, setOnErrorPut] = useState<boolean>(false);
  const [loadedPut, setLoadedPut] = useState<boolean>(false);
  const [loadingButton, setLoadingButton] = useState<boolean>(false)
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");


  const [kpiOn, setKpiOn] = useState<boolean>(true);

  const handleCloseSnack = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  const handleChangeKpi = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKpiOn(event.target.checked);
  };

  const [manOn, setManOn] = useState<boolean>(false);

  const handleChangeMan = (event: React.ChangeEvent<HTMLInputElement>) => {
    setManOn(event.target.checked);
  };

  const changeMan = (newV: boolean) => {
    setManOn(newV);
  };

  const changeKpi = (newV: boolean) => {
    setKpiOn(newV);
  };

  

  const changeLoadingButton = (newStatus:boolean ) => {
    setLoadingButton(newStatus);
  }
  

  const changeTarget = async (data:FormInput,) => {

    let finalTarget:number | number[] | null = selectedkpi?.format  === ":addDynamicTimeUnit" ? ((data.target_days * 86400) + (data.target_hrs * 3600) + (data.target_mins *60)) : selectedkpi?.format  === ":addPercent" ? data.target_percentage : data.target_input


    finalTarget = !kpiOn || !manOn ? null : finalTarget

    const kpi: singleKpiTargetRequestType=   selectedkpi
     ? 
     {
      package_id:selectedkpi.key.split("|")[0],
      kpi_id: selectedkpi.key.split("|")[1], 
      target_status: kpiOn ? "on": "off",
      target: finalTarget,
      target_format:selectedkpi.format,
      target_source:manOn ? "manual" : "auto",
    }
      : 
    {
      package_id:"",
      kpi_id: "",
      target_status:"on",
      target: 0,
      target_format:"",
      target_source:"",
    }
  

    try {
      const response = await putTarget(signature, auth, kpi,  checked);
      if(typeof response === "string")
      {
      setOnErrorPut(true);
      setLoadedPut(false);
      changeLoadingButton(false)
      setSnackMessage(response)
      setOpenSnack(true)
      }
      if(response === 200)
      {
        changeLoadingButton(false)
        setSnackMessage(t("generic.success.message"))
        setOpenSnack(true)
        setOnErrorPut(false);
        setLoadedPut(true);
      }
     
    } catch (error: any) {

      setOnErrorPut(error);
      setLoadedPut(false);
      changeLoadingButton(false)
      setSnackMessage(t("generic.error.message"))
      setOpenSnack(true)
      throw error;
    }
  };

  return {changeTarget, changeMan, changeKpi, onErrorPut, loadedPut, kpiOn, manOn, handleChangeKpi, handleChangeMan, loadingButton, changeLoadingButton, openSnack, snackMessage, handleCloseSnack}
  }