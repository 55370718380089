import { Box, SvgIcon, Tooltip, Typography } from "@mui/material";

import TableCell from "@mui/material/TableCell";

import TableRow from "@mui/material/TableRow";
import { t } from "i18next";
import React from "react";

import { v4 as uuid } from "uuid";

type Props = {
  data: (
    | string
    | { value: number | string; status: string; message?: string }
  )[];
  index: number;
};

const TableHRRow = ({ data, index }: Props) => {
  return (
    <>
      {data && (
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }} key={uuid()}>
          <>
            {data.map((r, index) => {
              return (
                <React.Fragment key={uuid()}>
                  {typeof r === "string" ? (
                    <TableCell
                      sx={{ minWidth: "20%" }}
                      align="center"
                      key={uuid()}
                      id={"cell" + Math.random() + r}
                    >
                      <Typography
                        className="body2"
                        sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                      >
                        {r}
                      </Typography>
                    </TableCell>
                  ) : typeof r === "number" ? (
                    <TableCell
                      align="center"
                      sx={{ minWidth: "20%" }}
                      key={uuid()}
                      id={"specialnumbercell" + Math.random() + r}
                    >
                      {
                        <>
                          <Box className="flexCenter">
                            <Typography
                              className="body2"
                              sx={{ marginRight: "1rem", width: "50%" }}
                            >
                              {r}
                            </Typography>

                            <SvgIcon>
                              <path
                                d="M0 0h24v24H0V0zm0 0h24v24H0V0z"
                                fill="none"
                              />
                              <path
                                d="M16.59 7.58L10 14.17l-3.59-3.58L5 12l5 5 8-8zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
                                fill="rgb(72,191,74)"
                              />
                            </SvgIcon>
                          </Box>
                        </>
                      }
                    </TableCell>
                  ) : (
                    <TableCell
                      align="center"
                      sx={{ minWidth: "20%" }}
                      key={uuid()}
                      id={"specialcell" + Math.random()}
                    >
                      {
                        <>
                          <Box className="flexCenter">
                            <Typography
                              className="body2"
                              sx={{ marginRight: "1rem", width: "50%" }}
                            >
                              {r.value}
                            </Typography>
                            {r.status === "error" ? (
                            <Tooltip title={r.message ? t(r.message) as string : ""} placement="right">
                              <SvgIcon>
                                <path d="M0 0h24v24H0z" fill="none" />{" "}
                                <path
                                  d="M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
                                  fill="rgb(235,64,52)"
                                />
                              </SvgIcon>
                              </Tooltip>
                            ) : r.status === "success" ? (
                              <SvgIcon>
                                <path
                                  d="M0 0h24v24H0V0zm0 0h24v24H0V0z"
                                  fill="none"
                                />
                                <path
                                  d="M16.59 7.58L10 14.17l-3.59-3.58L5 12l5 5 8-8zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
                                  fill="rgb(72,191,74)"
                                />
                              </SvgIcon>
                            ) : (
                              <Tooltip title={r.message ? t(r.message) as string : ""} placement="right" >
                              <SvgIcon>
                                <path d="M0 0h24v24H0V0z" fill="none" />
                                <path
                                  d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
                                  fill="rgb(240, 175, 36)"
                                />
                              </SvgIcon>
                              </Tooltip>
                            )}
                          </Box>
                        </>
                      }
                    </TableCell>
                  )}
                </React.Fragment>
              );
            })}
          </>
        </TableRow>
      )}
    </>
  );
};

export default TableHRRow;
