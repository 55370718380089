import { Heading, Image, Link, Text, View } from "@aws-amplify/ui-react";
import logo from "../images/logo.png";



const components = {
  Footer() {
   

    return (
      <View textAlign="center" >
        <Text paddingTop="2rem">
         Powered by <Link className="signin-footer" href="https://teamsight.net" isExternal={true}>
      TEAMSIGHT
    </Link>
        </Text>
      </View>
    );
  },
 
    SignIn: {
      Header() {
  
        return (
          <View textAlign="center" >
             <Image
            width="15%"
            alt="teamsight logo"
            src={logo}
            paddingTop="1rem"
          />
            
          <Heading
            
            level={3}
          >
            Welcome to  <span className="signin-heading">MyIRIS</span>
          </Heading>
          </View>
        );
      }
  }
  }
    export default components;