import { TextField, Box, SvgIcon, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";

type Props = {
  handleSearchParam: (param: string) => void;
};

const SearchDep = ({ handleSearchParam }: Props) => {
  const { t } = useTranslation();

  return (
    <Box className="flexGap05">
      <TextField
        onChange={(e) => handleSearchParam(e.target.value)}
        size="small"
        label={
          <Box className="flexGap05">
            <SvgIcon>
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
            </SvgIcon>
            <Typography variant="body1">{t("misc.search")}</Typography>
          </Box>
        }
        sx={{
          width: 454,

          "& label.Mui-focused": {
            color: "ts.sidebar.bg",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "ts.sidebar.bg",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "text.primary",
              border: "1px solid",
            },
            "&:hover fieldset": {
              borderColor: "ts.sidebar.text.inactive",
            },
            "&.Mui-focused fieldset": {
              borderColor: "ts.sidebar.bg",
            },
          },
        }}
      />
      {/* {focus && (
        <IconButton
          onClick={() => {
            handleSearchParam("");
           
          }}
        >
          <SvgIcon>
            <path
              d="M6.4 19.8 4.2 17.6 9.8 12 4.2 6.4 6.4 4.2 12 9.8 17.6 4.2 19.8 6.4 14.2 12 19.8 17.6 17.6 19.8 12 14.2Z"
              fill="black"
            />
          </SvgIcon>
        </IconButton>
      )} */}
    </Box>
  );
};

export default SearchDep;
